import React, { useEffect, useState } from 'react';
import { ClipLoader } from "react-spinners";
import { toast } from 'react-toastify';
import { InputNumber } from 'antd';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { IMintSale } from '../../../interfaces/MintSale';
import axios from 'axios';

const MintSaleAdmin = () => {
    const [saleSupply, setSaleSupply] = useState<number | null>(300);
    const [mintConfig, setMintConfig] = useState<IMintSale>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const initialize = async () => {
        try {
            const mintConfig = (await axios.get('/api/mint/mintConfig')).data;
            console.log({ mintConfig });
            setMintConfig(mintConfig);
        } catch (_) {
        }
    }

    const handleSetSaleSupply = async () => {
        if (!saleSupply || saleSupply <= 0) {
            return toast.warn("Invalid value");
        }

        setIsProcessing(true);

        try {
            await axios.post('/api/mint/manageSaleSupply', { saleSupply });
            await initialize();

            toast.success("Successful to set sale supply");
        } catch (e: any) {
            console.log('e', e);
            if (e?.response?.data) {
                toast.error(e.response.data.message.toString(), { autoClose: 3500 });
            } else {
                toast.error("Failed to set sale supply");
            }
        }

        setIsProcessing(false);
    }

    useEffect(() => {
        (async () => {
            await initialize();
        })();
    }, []);

    return (
        <div className="flex flex-col items-center w-full mt-[50px]">
            <h1 className="text-[20px] font-medium mt-5">Manage Mint Sale Supply</h1>

            <div className='w-full max-w-[460px] px-[10px]'>
                <div>{`Sale supply: ${mintConfig?.saleSupply || 0}`}</div>
                <div className='flex flex-col gap-[10px] w-full'>
                    {/* Sale supply */}
                    <div className='flex items-center gap-[10px] w-full'>
                        <span className='whitespace-nowrap'>Current Sale Supply:</span>
                        <InputNumber
                            value={saleSupply}
                            className='w-full'
                            onChange={(value) => setSaleSupply(value)}
                        />
                    </div>

                    <div className='flex justify-end items-center w-full'>
                        <button
                            disabled={isLoading || isProcessing}
                            className={`flex justify-center items-center gap-[10px] gradient-btn full-rounded py-[5px] w-full md:w-1/2 ${(isLoading || isProcessing) ? 'opacity-60' : 'opacity-100'}`}
                            onClick={() => handleSetSaleSupply()}
                        >
                            {!isProcessing ? 'Set' : 'Processing...'}
                            {isProcessing ? <ClipLoader size={15} color="#ffffff" /> : null}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MintSaleAdmin;