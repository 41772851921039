import React from "react";
import { QuestTypeEnum } from "../../enums";
import useSound from "use-sound";

// @ts-ignore
import Slide from 'react-reveal/Slide';

interface IProps {
    show: boolean;
    questType: QuestTypeEnum;
    handleShow: (status: boolean) => void;
}

const DescriptionModal = (props: IProps) => {
    const { show, questType, handleShow } = props;
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });

    return (
        show ? (
            <Slide top duration={500}>
                <div
                    className="fixed top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] bg-black/50 z-[1040] overflow-y-auto hidden justify-center mobile-height"
                    style={{ display: show ? "flex" : "none" }}
                >
                    <div className="flex justify-center items-center w-full h-full py-[4rem] md:py-[5rem]">
                        <div className="flex flex-col items-center max-w-[900px] max-h-[70%] w-full h-full mx-[2rem] rounded-[10px] font-semibold p-[36px_30px_30px] flex-1 bg-[#090C0E] shadow-[0px_0px_7px_#00A5FE] relative z-[1050]">
                            <button
                                className="sm:flex justify-center items-center absolute top-0 right-0 p-[10px] cursor-pointer bg-none border-none rounded-[6px] transform hover:scale-110 transition-all"
                                onClick={() => handleShow(false)}
                                onMouseEnter={() => playHover()}
                            >
                                <svg width="14" height="14" className="fill-white transition-[fill_200ms_ease_0s]">
                                    <path d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z"></path>
                                </svg>
                            </button>

                            <div className="w-full">
                                <h1 className="font-semibold text-[26px] leading-[41px] mt-0 w-full text-center text-white">
                                    {
                                        questType == QuestTypeEnum.Standard ? (
                                            "#1 Brawlers’ Bounty"
                                        ) : questType == QuestTypeEnum.Risk ? (
                                            "#2 Enter the Arena"
                                        ) : (
                                            "-"
                                        )
                                    }
                                </h1>
                            </div>

                            <div className="grow w-full h-[300px] mt-[25px] overflow-x-hidden overflow-y-auto no-scroll-bar">
                                <div className="flex flex-col gap-[20px] text-[20px] font-normal leading-[20px]">
                                {
                                    questType === QuestTypeEnum.Standard && (
                                    <>
                                        <h3>In the Solana ecosystem's vast digital frontier, the renowned BiteClub calls to you.</h3>
                                        <h3>BiteClub, a gaming platform where skill and fortune meet, offers a thrilling opportunity for the bold.</h3>
                                        <h3>Driven by adventure and potential profits, you embark on a seven-day journey to join BiteClub and earn your first cut.</h3>
                                        <h3>As you traverse the unpredictable Solana blockchain, you'll face challenges and competitors alike.</h3>
                                        <h3>Each day tests your determination and resilience as you navigate the terrain, uncover blockchain secrets, and hone your skills.</h3>
                                        <h3>After seven intense days, you arrive at BiteClub, weary but victorious. The polished platform awaits, ready for the high-stakes games you're now prepared for.</h3>
                                        <h3>As a new BiteClub member, you're ready to claim your first cut and carve your destiny in Solana's premier gaming platform.</h3>
                                        <h3>Completing this initiation quest earns you a place among BiteClub's elite, ready for the challenges and rewards of this innovative enterprise.</h3>
                                    </>
                                    )
                                }
                                
                                {
                                    questType === QuestTypeEnum.Risk && (
                                    <>
                                        <h3>As a new wolf in the BiteClub, you step into the arena for the first time, your eyes set on the prize ahead.</h3>
                                        <h3>In the center of the arena, a bag of bones lies untouched, a tantalizing bounty for those bold enough to claim it.</h3>
                                        <h3>Driven by curiosity and the thrill of competition, you decide to fetch it, not knowing what lies inside.</h3>
                                        <h3>As you venture into the heart of the arena, you're aware of the risks. Other wolves, also drawn to the bag of bones, could pose a threat.</h3>
                                        <h3>Each step tests your courage and strategy. Will you successfully claim the bones, or will you face the wrath of competing wolves?</h3>
                                        <h3>Regardless of the outcome, you know this is just the beginning. Life is a game of chance, with potential for great rewards or significant losses.</h3>
                                        <h3>As a participant in the arena, you're ready to face the challenges and embrace the thrill of this high-stakes game.</h3>
                                        <h3>Whether you emerge with the bag of bones or suffer injuries, you know each quest is a step towards becoming a seasoned member of the BiteClub.</h3>
                                    </>
                                    )
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="overlay"></div>
                </div>
            </Slide>
        ) : (
            null
        )
    );
};

export default DescriptionModal;