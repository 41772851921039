import React from 'react';

interface IProps {
    className?: string;
}

const GreenCheckIcon = ({ className }: IProps) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <circle cx="20" cy="20" r="19" fill="black" stroke="#46FF78" strokeWidth="2" />
            <path d="M10 21.5909C13.4751 22.5939 15.26 24.5079 16.5455 27C21.8324 17.2669 22.7038 16.5328 30 13" stroke="#46FF78" strokeWidth="4.44" />
        </svg>
    );
};

export default GreenCheckIcon;