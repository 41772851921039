import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import copy from 'copy-to-clipboard';
import Carousel from "react-multi-carousel";
import ClipLoader from "react-spinners/ClipLoader";
import ApexCharts from 'apexcharts';
import Tooltip from '@mui/material/Tooltip';
import { toast } from "react-toastify";
import { Connection, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";
import { Metaplex } from "@metaplex-foundation/js";
import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import User from "../../interfaces/User";
import Game from "../../interfaces/Game";
import { displayName } from "../../utils";
import { CoinflipFaceEnum, Dice2BettingTypeEnum } from "../../enums";
import { coinflipItems, diceItems, gDiceItems, rpsItems, weekDayData } from "../../data";
import { ReactComponent as GrayPenIcon } from '../../assets/gray-pen.svg';
import { ReactComponent as WhiteSolSymbol } from '../../assets/sol-symbol-white.svg';
import { ReactComponent as LockIcon } from '../../assets/lock.svg';
import { ReactComponent as LopperIcon } from '../../assets/looper.svg';
import { ReactComponent as CheckIcon } from '../../assets/check.svg';
import blankUserIcon from '../../assets/blank-user.svg';
import useSound from "use-sound";
import "./index.scss";

// @ts-ignore
import Slide from "react-reveal/Slide";
import CopyIcon from "../svgs/CopyIcon";

dayjs.extend(utc);

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3,
        partialVisibilityGutter: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        partialVisibilityGutter: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        partialVisibilityGutter: 5,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
        partialVisibilityGutter: 5,
    }
};

const connection = new Connection(process.env.REACT_APP_RPC_URL!, "confirmed");
const metaplex = new Metaplex(connection);
const botPubkey = process.env.REACT_APP_BOT_PUBKEY;
const defaultText = 'Copy to clipboard';
const copiedText = 'Copied';

let chart: any;

const UserStats = () => {
    let flag = false;
    const { publicKey } = useParams();
    const wallet = useWallet();
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
    const [user, setUser] = useState<User>();
    const [nickname, setNickname] = useState<string>("");
    const [userAvatar, setUserAvatar] = useState<string>("");
    const [recentGames, setRecentGames] = useState<Game[]>([]);
    const [totalGames, setTotalGames] = useState<number>(0);
    const [totalVolume, setTotalVolume] = useState<number>(0);
    const [totalProfit, setTotalProfit] = useState<number>(0);
    const [highestWinstreak, setHighestWinstreak] = useState<number>(0);
    const [nftList, setNftList] = useState<string[]>([]);
    const [weekData, setWeekData] = useState<any[]>([]);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [clipboardText, setClipboardText] = useState<string>(defaultText);
    const [copied, setCopied] = useState<boolean>(false);
    const [isHoveredOnCopyIcon, setIsHoveredOnCopyIcon] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingNFTs, setIsLoadingNFTs] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const handleShowEditModal = (status: boolean) => {
        if (status) {
            // document.body.style.overflow = "hidden"
        } else {
            // document.body.style.overflow = "visible"
        }
        setShowEditModal(status);
    }

    const getUser = async (publicKey: string) => {
        try {
            setIsLoading(true);

            const [responseUser, responseGameRPS, responseGameDice, responseGameDice2, responseCoinflipGame, responseUserHistories] = await Promise.all([
                axios.get(`/api/u/${publicKey}`),
                axios.get(`/api/rps-game/u/${publicKey}`),
                axios.get(`/api/dice-game/u/${publicKey}`),
                axios.get(`/api/dice2-game/u/${publicKey}`),
                axios.get(`/api/coinflip-game/u/${publicKey}`),
                axios.get(`/api/leaderboard/userHistories/${publicKey}`),
            ]);

            const [user, rpsGameRsp, diceGameRsp, dice2GameRsp, coinflipGameRsp] = [responseUser.data, responseGameRPS.data, responseGameDice.data, responseGameDice2.data, responseCoinflipGame.data];
            const [recentRpsGames, recentDiceGames, recentDice2Games, recentCoinflipGames] = [rpsGameRsp.recentGames, diceGameRsp.recentGames, dice2GameRsp.recentGames, coinflipGameRsp.recentGames]

            for (let i = 0; i < recentRpsGames.length; i++) {
                recentRpsGames[i].gameType = "rps";
            }
            for (let i = 0; i < recentDiceGames.length; i++) {
                recentDiceGames[i].gameType = "dice";
            }
            for (let i = 0; i < recentDice2Games.length; i++) {
                recentDice2Games[i].gameType = "dice2";
            }
            for (let i = 0; i < recentCoinflipGames.length; i++) {
                recentCoinflipGames[i].gameType = "coinflip";
            }

            const recentGames: Game[] = recentRpsGames.concat(recentDiceGames).concat(recentDice2Games).concat(recentCoinflipGames);
            recentGames.sort((a, b) => {
                if (a.updatedAt < b.updatedAt)
                    return 1
                else
                    return -1
            });
            console.log('recentGames', recentGames);
            const totalGames = rpsGameRsp.totalCount + diceGameRsp.totalCount + dice2GameRsp.totalCount + coinflipGameRsp.totalCount;

            if (user) {
                setNickname(user?.username || "");
                setUserAvatar(user?.userAvatar || "");
                setHighestWinstreak(user.highestWinstreakCount ?? 0);
            }

            const weekData = responseUserHistories.data.weekData;

            setUser(user);
            setTotalGames(totalGames);
            setTotalVolume(responseUserHistories.data.totalVolume);
            setTotalProfit(responseUserHistories.data.totalProfit);
            setRecentGames(recentGames.slice(0, 100));
            setWeekData(weekData);

            setIsLoading(false);
        } catch (e: any) {
            console.log("e", e);
            setIsLoading(false);
        }
    };

    const initialize = async () => {
        try {
            if (wallet?.publicKey) {
                setIsLoadingNFTs(true);

                const nfts = await metaplex.nfts().findAllByOwner({ owner: wallet?.publicKey });
                let metadata: any;
                if (nfts) {
                    const nftList: string[] = [];

                    await Promise.all(
                        nfts.map(async (item, _) => {
                            if (item?.uri) {
                                try {
                                    metadata = (await axios.get(item?.uri)).data;
                                    nftList.push(metadata?.image);
                                } catch (_) { }
                            }
                        })
                    );

                    setNftList(nftList);
                }

                setIsLoadingNFTs(false);
            }
        } catch {
            setIsLoadingNFTs(false);
        }
    }

    const changeProfile = async () => {
        if (nickname.length > 20) return toast.error("Maximum charachter is 20");

        try {
            setIsProcessing(true);

            const data = {
                username: nickname,
                userAvatar: userAvatar
            };

            await axios.post("/api/u/edit", data);
            if (user) {
                const updatedUser = { ...user, username: nickname, userAvatar: userAvatar };
                setUser(updatedUser);
            }
            setShowEditModal(false);
            setIsProcessing(false);
            toast.success("Successful");
        } catch (e: any) {
            setIsProcessing(false);
            toast.error(e.response.data.message.toString());
        }
    };

    useEffect(() => {
        if (chart) {
            chart.destroy();
        }
        if (!flag && weekData.length > 0) {
            const categories = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
            let data: number[] = [];

            const weekDataInfo: any[] = [];
            for (let i = 0; i < weekData.length; i++) {
                const updatedAt = weekData[i].updatedAt.toString();
                const weekDay = dayjs.utc(updatedAt).day();

                weekDataInfo.push({
                    weekDay: weekDay,
                    volume: weekData[i].volume
                });
            }

            for (let i = 0; i < categories.length; i++) {
                data[i] = weekDataInfo.filter(x => x.weekDay == (weekDayData as any)[categories[i]]).reduce((sum, obj) => sum + obj.volume, 0) / LAMPORTS_PER_SOL;
            }

            flag = true;

            var options = {
                chart: {
                    type: 'area',
                    fontFamily: 'font-primary',
                    foreColor: '#808080',
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    show: false
                },
                stroke: {
                    curve: 'smooth'
                },
                series: [
                    {
                        name: 'volume',
                        data: data
                    }
                ],
                tooltip: {
                    enabled: true,
                    x: {
                        show: false
                    },
                    style: {
                        fontSize: '12px',
                        fontFamily: '"Tisa Sans Pro", sans-serif',
                    },
                },
                xaxis: {
                    categories: categories,
                    position: 'top',
                    labels: {
                        show: true,
                        style: {
                            fontSize: '12px',
                            fontFamily: '"Tisa Sans Pro", sans-serif',
                            fontWeight: 500,
                        },
                        offsetY: -10,
                    },
                    axisTicks: {
                        show: false,
                    },
                    tooltip: {
                        enabled: true,
                        style: {
                            fontFamily: '"Tisa Sans Pro", sans-serif',
                        },
                    },
                },
                yaxis: {
                    labels: {
                        show: true,
                        style: {
                            fontSize: '12px',
                            fontFamily: '"Tisa Sans Pro", sans-serif',
                            fontWeight: 500,
                        },
                    },
                }
            }

            chart = new ApexCharts(document.querySelector('#chart'), options)
            chart.render();
        }
    }, [weekData]);

    useEffect(() => {
        setUser(undefined);
        if (!publicKey) return;

        getUser(publicKey);
    }, [publicKey]);

    useEffect(() => {
        if (wallet?.publicKey) {
            initialize();
        }
    }, [wallet?.publicKey]);

    return (
        <div className="flex flex-col md:flex-row w-full min-h-full h-fit user-stats relative">
            <div className="flex w-full max-w-[770px] md:bg-[#090C0E] md:rounded-tr-[10px]">
                <div className="w-full md:w-1/2 h-full">
                    {/* Profile */}
                    <div className="w-full">
                        <h5 className="text-[14px] text-[#808080] font-medium leading-[20px] mx-[28px] mt-[29px] hidden md:block">PROFILE</h5>
                        {
                            !isLoading ? (
                                user ? (
                                    <div className="flex flex-col items-center gap-[10px] w-full mt-[17px] md:mt-[11px]">
                                        <div className="w-[80px] md:w-[150px] h-[80px] md:h-[150px] rounded-full relative">
                                            <img
                                                src={user?.userAvatar || blankUserIcon}
                                                alt="avatar"
                                                className="w-full h-full rounded-full"
                                            />
                                            <LopperIcon className="absolute top-[-30px] left-[-30px] w-[140px] h-[140px] md:hidden" />
                                        </div>

                                        <div className="relative">
                                            {
                                                wallet?.publicKey?.toString() == publicKey && (
                                                    <div className="flex items-center w-full h-full absolute left-[-20px]">
                                                        <button onClick={() => handleShowEditModal(true)} onMouseEnter={() => playHover()}>
                                                            <GrayPenIcon className="hover:scale-125 transition-transform" />
                                                        </button>
                                                    </div>
                                                )
                                            }
                                            <span className="user-name text-[20px] md:text-[32px] font-bold leading-[30px] md:leading-[47px]">
                                                {displayName(user)}
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex justify-center items-center min-h-[120px] md:min-h-[207px]">
                                        <span className="text-[16px] md:text-[20px]">User was not found</span>
                                    </div>
                                )
                            ) : (
                                <div className="flex justify-center items-center min-h-[120px] md:min-h-[207px]">
                                    <span className="text-[16px] md:text-[20px]">Loading user...</span>
                                </div>
                            )
                        }
                    </div>

                    {/* General Statistics */}
                    <div className="w-full mt-[29px] md:mt-[21px]">
                        <h5 className="text-[12px] md:text-[14px] text-[#808080] font-medium leading-[17px] md:leading-[20px] mx-[22px] md:mx-[28px]">GENERAL STATISTICS</h5>
                        <div className="grid grid-cols-4 md:grid-cols-2 md:gap-x-[10px] md:gap-y-[20px] mt-[10px] md:mt-[24px] w-fit mx-auto">
                            <div className="flex flex-col items-center gap-[4px]">
                                <div className="flex justify-center items-center">
                                    <span className="text-[14px] md:text-[20px] font-bold leading-[21px] md:leading-[30px]">{totalGames}</span>
                                </div>
                                <div className="text-[10px] md:text-[12px] text-[#808080] font-bold leading-[15px] md:leading-[18px]">Total Games</div>
                            </div>

                            <div className="flex flex-col items-center gap-[4px]">
                                <div className="flex justify-center items-center gap-[6px]">
                                    <span className="text-[14px] md:text-[20px] font-bold leading-[21px] md:leading-[30px]">
                                        {(totalVolume / LAMPORTS_PER_SOL).toLocaleString()}
                                    </span>
                                    <WhiteSolSymbol />
                                </div>
                                <div className="text-[10px] md:text-[12px] text-[#808080] font-bold leading-[15px] md:leading-[18px]">Total Volume</div>
                            </div>

                            <div className="flex flex-col items-center gap-[4px]">
                                <div className="flex justify-center items-center gap-[6px]">
                                    <span className="text-[14px] md:text-[20px] font-bold leading-[21px] md:leading-[30px]">
                                        {(totalProfit / LAMPORTS_PER_SOL).toLocaleString()}
                                    </span>
                                    <WhiteSolSymbol />
                                </div>
                                <div className="text-[10px] md:text-[12px] text-[#808080] font-bold leading-[15px] md:leading-[18px]">Net Profit</div>
                            </div>

                            <div className="flex flex-col items-center gap-[4px]">
                                <div className="flex justify-center items-center">
                                    <span className="text-[14px] md:text-[20px] font-bold leading-[21px] md:leading-[30px]">
                                        {highestWinstreak}
                                    </span>
                                </div>
                                <div className="text-[10px] md:text-[12px] text-[#808080] font-bold leading-[15px] md:leading-[18px]">Highest Winstreak</div>
                            </div>

                            {/* Referral system */}
                            {
                                user && (
                                    <div className="col-span-4 md:col-span-2 flex flex-row md:flex-col items-center gap-[18px] md:gap-[14px] mt-[28px] md:mt-[38px]">
                                        <div className="flex justify-center items-center gap-[10px] text-[14px] text-[#808080] leading-[20px] font-medium uppercase w-full h-[40px] bg-[#11161B] border border-solid border-[#808080] rounded-[10px]">
                                            <span>Copy Referral Code</span>

                                            <Tooltip title={clipboardText} placement="top">
                                                <button
                                                    onClick={() => {
                                                        copy(`${window.location.origin}?ref=${user?._id}`);
                                                        setCopied(true);
                                                        setClipboardText(copiedText);
                                                        setTimeout(() => {
                                                            setCopied(false);
                                                        }, 300);
                                                    }}
                                                    onMouseEnter={() => {
                                                        setIsHoveredOnCopyIcon(true);
                                                        playHover();
                                                    }}
                                                    onMouseLeave={() => setIsHoveredOnCopyIcon(false)}
                                                    onMouseOut={() => {
                                                        setTimeout(() => {
                                                            setClipboardText(defaultText);
                                                        }, 200);
                                                    }}
                                                >
                                                    <CopyIcon
                                                        color={copied ? '#23F7DD' : isHoveredOnCopyIcon ? '#B5B5BA' : '#77777D'}
                                                    />
                                                </button>
                                            </Tooltip>
                                        </div>

                                        <div className="flex flex-col items-center gap-[4px] min-w-[111px]">
                                            <div className="flex justify-center items-center gap-[6px]">
                                                <span className="text-[14px] md:text-[20px] font-bold leading-[21px] md:leading-[30px]">
                                                    {((user?.totalRefFeesReceived || 0) / LAMPORTS_PER_SOL)}
                                                </span>
                                                <WhiteSolSymbol />
                                            </div>
                                            <div className="text-[10px] md:text-[12px] text-[#808080] font-bold leading-[15px] md:leading-[18px]">Referral Rewards</div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className="w-1/2 h-full border-l border-solid border-[#11161B] hidden md:block">
                    {/* Achievements */}
                    <div className="w-full">
                        <h5 className="text-[14px] text-[#808080] font-medium leading-[20px] mx-[28px] mt-[29px]">ACHIEVEMENTS</h5>
                        <div className="flex justify-center items-center w-full max-w-[292px] h-[104px] mt-[61px] gradient-btn-no-bg rounded-[10px] mx-auto">
                            <LockIcon />
                        </div>
                    </div>
                </div>
            </div>

            {/* Game history */}
            <div className="flex flex-col grow gap-[30px] md:gap-[71px]">
                <div className="order-2 md:order-1">
                    <div className="w-full pl-[10px] md:pl-[25px] pr-[25px]">
                        <div id="chart" className="w-full max-w-[580px] mt-[15px] md:mt-[41px]"></div>
                    </div>
                </div>

                <div className="order-1 md:order-2 grow w-full max-h-[200px] md:max-h-[450px] md:mx-[28px] mt-[28px] md:mt-[-88px] overflow-y-auto">
                    <h5 className="text-[12px] md:text-[14px] text-[#808080] font-medium leading-[17px] md:leading-[20px] px-[22px] bg-[#11161B] sticky top-0 pb-[10px]">
                        GAME HISTORY
                    </h5>
                    {
                        !isLoading ? (
                            recentGames?.length > 0 ? (
                                <table className='w-full md:w-fit md:mt-[31px]'>
                                    <thead>
                                        <tr className="text-[10px] md:text-[12px] text-[#808080] font-medium leading-[14px] md:leading-[17px]">
                                            <th className="pb-[5px] md:pb-[10px]">Date</th>
                                            <th className="pb-[5px] md:pb-[10px]">Time</th>
                                            <th className="pb-[5px] md:pb-[10px]">Your Move</th>
                                            <th className="pb-[5px] md:pb-[10px]">Opponent Move</th>
                                            <th className="pb-[5px] md:pb-[10px]">Win Amount</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            recentGames.map((item, index) => {
                                                let moveImg = '';
                                                let opponentMoveImg = '';
                                                let winAmount = 0;

                                                if (item.gameType == 'rps') {
                                                    if (item?.creator?.toString() == user?._id?.toString()) {
                                                        moveImg = rpsItems[item.creatorMove!].icon;
                                                        opponentMoveImg = rpsItems[item.opponentMove!].icon;
                                                    } else {
                                                        moveImg = rpsItems[item.opponentMove!].icon;
                                                        opponentMoveImg = rpsItems[item.creatorMove!].icon;
                                                    }
                                                    winAmount = item.winner ? item.winner.toString() == user?._id?.toString() ? item.amount / LAMPORTS_PER_SOL : -item.amount / LAMPORTS_PER_SOL : 0
                                                }

                                                if (item.gameType == 'dice') {
                                                    if (item?.creator?.toString() == user?._id?.toString()) {
                                                        moveImg = diceItems[item.creatorMove! - 1].icon;
                                                        opponentMoveImg = diceItems[item.opponentMove! - 1].icon;
                                                    } else {
                                                        moveImg = diceItems[item.opponentMove! - 1].icon;
                                                        opponentMoveImg = diceItems[item.creatorMove! - 1].icon;
                                                    }
                                                    winAmount = item.winner ? item.winner.toString() == user?._id?.toString() ? item.amount / LAMPORTS_PER_SOL : -item.amount / LAMPORTS_PER_SOL : 0
                                                }

                                                if (item.gameType == 'dice2') {
                                                    if (user?._id?.toString() == item.player?.toString()) {
                                                        winAmount = item.bettingAmount ? (item.isWin ? item.bettingAmount / LAMPORTS_PER_SOL : -item.bettingAmount / LAMPORTS_PER_SOL) : 0;
                                                    } else {
                                                        winAmount = item.bettingAmount ? (item.isWin ? -item.bettingAmount / LAMPORTS_PER_SOL : item.bettingAmount / LAMPORTS_PER_SOL) : 0;
                                                    }
                                                }

                                                if (item.gameType == 'coinflip') {
                                                    let opponentFace: CoinflipFaceEnum;

                                                    if (item.isWin) {
                                                        opponentFace = item.bettingFace == CoinflipFaceEnum.Head ? CoinflipFaceEnum.Head : CoinflipFaceEnum.Tail;
                                                    } else {
                                                        opponentFace = item.bettingFace == CoinflipFaceEnum.Head ? CoinflipFaceEnum.Tail : CoinflipFaceEnum.Head;
                                                    }

                                                    if (user?._id?.toString() == item.player?.toString()) {
                                                        winAmount = item.bettingAmount ? (item.isWin ? item.bettingAmount / LAMPORTS_PER_SOL : -item.bettingAmount / LAMPORTS_PER_SOL) : 0;
                                                    } else {
                                                        winAmount = item.bettingAmount ? (item.isWin ? -item.bettingAmount / LAMPORTS_PER_SOL : item.bettingAmount / LAMPORTS_PER_SOL) : 0;
                                                    }

                                                    if (publicKey !== botPubkey) {
                                                        moveImg = coinflipItems[item.bettingFace!].icon;
                                                        opponentMoveImg = coinflipItems[opponentFace].icon;
                                                    } else {
                                                        moveImg = coinflipItems[opponentFace].icon;
                                                        opponentMoveImg = coinflipItems[item.bettingFace!].icon;
                                                    }
                                                }

                                                return (
                                                    <tr
                                                        key={index}
                                                        className='text-[12px] md:text-[14px] font-medium leading-[17px] md:leading-[20px] text-center'
                                                    >
                                                        <td className='px-[9px] md:px-[21px] py-[5px]'>
                                                            {dayjs(item.updatedAt).format('DD.MM.YY')}
                                                        </td>
                                                        <td className='px-[9px] md:px-[21px] py-[5px]'>
                                                            {dayjs(item.updatedAt).format('h:mm A')}
                                                        </td>
                                                        {
                                                            item.gameType == 'dice2' ? (
                                                                publicKey !== botPubkey ? (
                                                                    <>
                                                                        <td className="px-[21px] md:px-[56px] py-[5px]">
                                                                            <div className="flex justify-center items-center gap-[5px]">
                                                                                {
                                                                                    item.bettingType == Dice2BettingTypeEnum.Low && (
                                                                                        <>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[0].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[1].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[2].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    item.bettingType == Dice2BettingTypeEnum.High && (
                                                                                        <>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[3].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[4].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[5].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    item.bettingType == Dice2BettingTypeEnum.Even && (
                                                                                        <>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[1].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[3].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[5].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    item.bettingType == Dice2BettingTypeEnum.Odd && (
                                                                                        <>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[0].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[2].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[4].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-[21px] md:px-[56px] py-[5px]">
                                                                            <div className="w-[20px] h-[20px]">
                                                                                <img src={gDiceItems[item?.winningNumber! - 1].icon} alt="move" className="w-full object-cover" />
                                                                            </div>
                                                                        </td>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <td className="px-[21px] md:px-[56px] py-[5px]">
                                                                            <div className="w-[20px] h-[20px]">
                                                                                <img src={gDiceItems[item?.winningNumber! - 1].icon} alt="move" className="w-full object-cover" />
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-[21px] md:px-[56px] py-[5px]">
                                                                            <div className="flex justify-center items-center gap-[5px]">
                                                                                {
                                                                                    item.bettingType == Dice2BettingTypeEnum.Low && (
                                                                                        <>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[0].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[1].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[2].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    item.bettingType == Dice2BettingTypeEnum.High && (
                                                                                        <>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[3].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[4].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[5].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    item.bettingType == Dice2BettingTypeEnum.Even && (
                                                                                        <>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[1].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[3].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[5].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    item.bettingType == Dice2BettingTypeEnum.Odd && (
                                                                                        <>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[0].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[2].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                            <div className="w-[20px] h-[20px]">
                                                                                                <img src={gDiceItems[4].icon} alt="move" className="w-full object-cover" />
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                    </>
                                                                )
                                                            ) : (
                                                                <>
                                                                    <td className="px-[21px] md:px-[56px] py-[5px]">
                                                                        <div className="flex justify-center items-center w-full">
                                                                            <div className="w-[20px] h-[20px]">
                                                                                <img src={moveImg} alt="move" className="w-full object-cover" />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="px-[21px] md:px-[56px] py-[5px]">
                                                                        <div className="flex justify-center items-center w-full">
                                                                            <div className="w-[20px] h-[20px]">
                                                                                <img src={opponentMoveImg} alt="move" className="w-full object-cover" />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </>
                                                            )
                                                        }
                                                        <td className="px-[21px] md:px-[56px] py-[5px]">
                                                            <span className={winAmount > 0 ? 'text-[#00FF00]' : winAmount < 0 ? 'text-[#FF0000]' : 'text-[#808080]'}>
                                                                {item?.gameType !== 'coinflip' && item?.gameType !== 'dice2' ? (item.amount / LAMPORTS_PER_SOL).toLocaleString() : (item?.bettingAmount! / LAMPORTS_PER_SOL).toLocaleString()} SOL
                                                            </span>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            ) : (
                                <h5 className="text-[12px] md:text-[14px] text-[#808080] text-center">There is no games played.</h5>
                            )
                        ) : (
                            <div></div>
                        )
                    }
                </div>
            </div>

            {/* Edit modal */}
            <div
                className="justify-center items-start md:items-center p-[50px_25px] md:p-0 absolute top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] sm:bg-black/50 z-[1040] overflow-y-auto"
                style={{ display: showEditModal ? "flex" : "none" }}
            >
                <div className="flex flex-col justify-center items-center w-full">
                    <Slide top duration={500}>
                        <div className="relative flex flex-col items-center w-full max-w-[442px] p-[20px_30px] md:p-[40px_50px] bg-[#11161B] rounded-[8px] shadow-[0px_0px_10px_#00A5FE] z-[1050]">
                            {/* Close button */}
                            <button
                                className="sm:flex justify-center items-center absolute top-0 right-0 p-[10px] cursor-pointer bg-none border-none rounded-[6px] transform hover:scale-110 transition-all"
                                onClick={() => handleShowEditModal(false)}
                                onMouseEnter={() => playHover()}
                            >
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 16L16 1M1 1L16 16" stroke="#BEBEBE" strokeWidth="2" />
                                </svg>
                            </button>

                            <h3 className="text-[20px] md:text-[26px] text-center font-inter font-bold leading-[34px] md:leading-[39px] underline">Username</h3>
                            <div className="flex justify-center w-full px-[20px]">
                                <input
                                    value={nickname}
                                    onChange={e => setNickname(e.target.value)}
                                    className="w-4/5 h-[42px] rounded-[10px] border-2 border-solid border-[#00A5FE] bg-[#090C0E] mt-[20px] px-[10px]"
                                />
                            </div>

                            <h3 className="text-[20px] md:text-[26px] text-center font-inter font-bold leading-[34px] md:leading-[39px] mt-[30px] md:mt-[50px] underline">Avatar</h3>

                            <div className="flex justify-center items-center w-full mt-[20px] md:mt-[30px] min-h-[80px] md:min-h-[100px]">
                                {
                                    !isLoadingNFTs ? (
                                        nftList.length > 0 ? (
                                            <Carousel
                                                responsive={responsive}
                                                partialVisible={true}
                                                draggable={false}
                                                slidesToSlide={3}
                                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                                className="w-full"
                                            >
                                                {
                                                    nftList.map((item, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className="w-[80px] md:w-[100px] h-[80px] md:h-[100px] rounded-[10px] border-2 border-solid border-[#00A5FE] cursor-pointer relative transition duration-300 hover:transform hover:border-white"
                                                                onClick={() => setUserAvatar(item)}
                                                                onMouseEnter={() => playHover()}
                                                            >
                                                                <img
                                                                    src={item}
                                                                    alt="profile"
                                                                    className="rounded-[10px] cursor-pointer"
                                                                />

                                                                {
                                                                    userAvatar == item && (
                                                                        <div className="flex justify-center items-center w-full h-full absolute top-0 left-0">
                                                                            <CheckIcon />
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Carousel>
                                        ) : (
                                            <div className="text-[12px] md:text-[14px] text-[#808080]">There is no NFTs</div>
                                        )
                                    ) : (
                                        <ClipLoader color="#ffffff" />
                                    )
                                }
                            </div>

                            <button
                                className="flex justify-center items-center w-[160px] md:w-[200px] h-[42px] md:h-[53px] text-[16px] md:text-[20px] font-bold leading-[18px] md:leading-[24px] gradient-btn rounded-[10px] mt-[30px] md:mt-[50px] transition duration-300 hover:transform hover:scale-110"
                                onClick={() => changeProfile()}
                                onMouseEnter={() => playHover()}
                            >
                                {isProcessing ? "Confirming..." : "Confirm"}{" "}
                                {isProcessing && <ClipLoader size={16} color="#ffffff" />}
                            </button>
                        </div>
                    </Slide>
                </div>
            </div>
        </div>
    );
};

export default UserStats;