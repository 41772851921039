export enum CoinflipFaceEnum {
    Head,
    Tail,
    None,
}

export enum CoinflipStatusEnum {
    Pending,
    Open,
    Close,
}

export enum LotteryStatusEnum {
    Pending,
    Open,
    Close,
}

export enum RpsEnum {
    Rock,
    Paper,
    Scissor,
}

export enum ShuffleBetTypeEnum {
    SOL,
    NFT,
}

export enum TimeFilterTypeEnum {
    Today,
    Week,
    All,
}

export enum SortTypeEnum {
    TotalVolume,
    TotalProfit,
}

export enum FreeChanceTypeEnum {
    Spin,
    Card,
    None
}

export enum FreeRewardTypeEnum {
    Sol,
    Ticket,
}

export enum GameTypeEnum {
    Rps,
    Dice,
    Solanaball,
    Coinflip
}

export enum QuestTypeEnum {
    Standard,
    Risk
}

export enum GiftTypeEnum {
    Tip,
    DailyReward
}

export enum Dice2BettingTypeEnum {
    Low,
    High,
    Even,
    Odd
}

export enum Dice2StatusEnum {
    Pending,
    Open,
    Close,
}

export enum RoutesTypeEnum {
    Main,
    Feature,
    Other
}

export enum NewYearEventRewardTypeEnum {
    FeeWager,
    Bones
}