import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swiper, { SwiperRefNode } from 'react-id-swiper';
import { useSelector } from 'react-redux';
import { CombinedReducer } from '../../store';
import User from '../../interfaces/User';
import solSymbolLarge from '../../assets/sol-symbol-large.svg';
import useSound from "use-sound";

const CustomSlider = () => {
  const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
  const swiperRef = useRef<SwiperRefNode>(null);
  const [slideCount, setSlideCount] = useState<number>(0);
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  const user = useSelector<CombinedReducer, User>((state) => state.user);

  const params = {
    effect: "fade" as any,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    speed: 2000,

  }

  const handleSlideTo = (index: number) => {
    if (swiperRef?.current?.swiper) {
      swiperRef?.current?.swiper?.slideToLoop(index);
    }
  }

  useEffect(() => {
    if (swiperRef?.current?.swiper) {
      const slideCount = swiperRef.current.swiper.slides.length;
      swiperRef.current.swiper.on('slideChange', () => {
        if (swiperRef?.current?.swiper) {
          setCurrentSlideIndex(swiperRef.current.swiper?.realIndex);
        }
      })
      setSlideCount(slideCount - 2);
    }
  }, [swiperRef]);

  return (
    <div className="w-full overflow-hidden relative">
      {/* Pagination bullets */}
      <div className='absolute top-[23px] md:top-[32px] left-0 w-full z-10'>
        <div className='flex justify-center items-center w-full'>
          <div className='flex justify-center items-center gap-[5px] h-[20px] px-[10px] bg-[#858585] rounded-full'>
            {
              new Array(slideCount).fill(0).map((_, index) => {
                return (
                  <button
                    key={index}
                    disabled={index == currentSlideIndex}
                    className={`h-[10px] rounded-full ${index == currentSlideIndex ? 'w-[20px] bg-[#090C0E] border border-solid border-[#090C0E]' : 'w-[10px] bg-black'}`}
                    onClick={() => handleSlideTo(index)}
                    onMouseEnter={() => playHover()}
                  />
                )
              })
            }
          </div>
        </div>
      </div>

      <Swiper {...params} ref={swiperRef}>
        <div className='h-[calc(100vh-59px)] md:h-[calc(100vh-70px)]'>
          <div className="w-full h-full bg-[#090C0E] bg-[url('/src/assets/slide1-mobile.webp')] sm:bg-[url('/src/assets/slide1.webp')] bg-bottom md:bg-top bg-no-repeat bg-cover">
            {/* Sol symbol large */}
            <div className='absolute top-[104px] left-0 w-full z-10 hidden md:block'>
              <div className='flex justify-center items-center w-full'>
                <img src={solSymbolLarge} alt='sol-symbol-large' />
              </div>
            </div>

            {/* Slide contents */}
            <div className="main-box flex items-start w-full h-full px-[29px] md:px-[230px] z-20">
              <div className='pt-[175px] md:pt-[180px] z-20'>
                <h1 className="text-[32px] md:text-[60px] font-bold leading-normal tracking-[1.6px] md:tracking-[3px]">
                  The BiteClub
                </h1>
                <h5 className="text-[22px] md:text-[32px] font-medium leading-normal tracking-[1.1px] md:tracking-[1.6px]">
                  Play together now!
                </h5>
                <Link
                  to={'/coinflip'}
                  className='main-btn flex justify-center items-center w-[233px] md:w-[350px] h-[50px] md:h-[75px] bg-[rgba(0,165,254,0.40)] border border-solid border-[#00A5FE] shadow-[0px_0px_4px_2px_#00A5FE] rounded-[20px] mt-[20px] md:mt-[26px] transition-all duration-300 ease-in-out hover:bg-[rgba(0,165,254,0.60)] hover:border-[#336699] hover:shadow-[0px_0px_18px_4px_#4186D7]'
                >
                  <span className='text-[16px] md:text-[24px] font-extrabold leading-normal tracking-[0.785px] md:tracking-[1.2px]'>Play</span>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className='h-[calc(100vh-59px)] md:h-[calc(100vh-70px)]'>
          <div className="w-full h-full bg-[#090C0E] bg-[url('/src/assets/slide2-mobile.webp')] sm:bg-[url('/src/assets/slide2.webp')] bg-bottom md:bg-center bg-no-repeat bg-cover">
            {/* Slide contents */}
            <div className="main-box flex items-start w-full h-full px-[29px] md:px-[230px] z-20">
              <div className='pt-[175px] md:pt-[180px] z-20'>
                <h1 className="text-[32px] md:text-[60px] font-bold leading-normal tracking-[1.6px] md:tracking-[3px]">
                  Adventure
                </h1>
                <h5 className="text-[22px] md:text-[32px] font-medium leading-normal tracking-[1.1px] md:tracking-[1.6px]">
                  Begin your NFT journey
                </h5>
                <Link
                  to={'/quests'}
                  className='main-btn flex justify-center items-center w-[233px] md:w-[350px] h-[50px] md:h-[75px] bg-[linear-gradient(95deg,rgba(255,178,122,0.40)_4.9%,rgba(255,210,177,0.40)_16.09%,rgba(211,96,12,0.40)_44.74%,rgba(121,51,0,0.40)_80.33%,rgba(255,122,26,0.40)_95.92%)] border border-solid border-[#FFB27A] shadow-[0px_0px_4px_2px_#FF7A1A] rounded-[20px] mt-[20px] md:mt-[26px] transition-all duration-300 ease-in-out hover:bg-[rgba(255,178,122,0.60)] hover:border-[#FFB27A] hover:shadow-[0px_0px_18px_4px_#FFB27A]'
                >
                  <span className='text-[16px] md:text-[24px] font-extrabold leading-normal tracking-[0.785px] md:tracking-[1.2px]'>Quest</span>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className='h-[calc(100vh-59px)] md:h-[calc(100vh-70px)]'>
          <div className="w-full h-full bg-[#090C0E] bg-[url('/src/assets/slide3-mobile.webp')] sm:bg-[url('/src/assets/slide3.webp')] bg-bottom md:bg-center bg-no-repeat bg-cover">
            {/* Slide contents */}
            <div className="main-box flex items-start w-full h-full px-[29px] md:px-[230px] z-20">
              <div className='pt-[175px] md:pt-[180px] z-20'>
                <h1 className="text-[32px] md:text-[60px] font-bold leading-normal tracking-[1.6px] md:tracking-[3px]">
                  FREE Wager
                </h1>
                <h5 className="text-[22px] md:text-[32px] font-medium leading-normal tracking-[1.1px] md:tracking-[1.6px]">
                  Shop with $BNS for FREE Wagers
                </h5>
                <Link
                  to={'/store'}
                  className='main-btn flex justify-center items-center w-[233px] md:w-[350px] h-[50px] md:h-[75px] bg-[rgba(0,165,254,0.40)] border border-solid border-[#00A5FE] shadow-[0px_0px_4px_2px_#00A5FE] rounded-[20px] mt-[20px] md:mt-[26px] transition-all duration-300 ease-in-out hover:bg-[rgba(0,165,254,0.60)] hover:border-[#336699] hover:shadow-[0px_0px_18px_4px_#4186D7]'
                >
                  <span className='text-[16px] md:text-[24px] font-extrabold leading-normal tracking-[0.785px] md:tracking-[1.2px]'>Shop</span>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className='h-[calc(100vh-59px)] md:h-[calc(100vh-70px)]'>
          <div className="w-full h-full bg-[#090C0E] bg-[url('/src/assets/slide4-mobile.webp')] sm:bg-[url('/src/assets/slide4.webp')] bg-bottom md:bg-center bg-no-repeat bg-cover">
            {/* Slide contents */}
            <div className="main-box flex items-start w-full h-full px-[29px] md:px-[230px] z-20">
              <div className='pt-[175px] md:pt-[180px] z-20'>
                <h1 className="text-[32px] md:text-[60px] font-bold leading-normal tracking-[1.6px] md:tracking-[3px]">
                  Daily Wager Race
                </h1>
                <h5 className="text-[22px] md:text-[32px] font-medium leading-normal tracking-[1.1px] md:tracking-[1.6px]">
                  Win Solana daily
                </h5>
                <Link
                  to={'/leaderboard'}
                  className='main-btn flex justify-center items-center w-[233px] md:w-[350px] h-[50px] md:h-[75px] bg-[linear-gradient(90deg,rgba(174,134,37,0.40)_0%,rgba(247,239,168,0.40)_46.35%,rgba(210,172,71,0.40)_75.52%,rgba(237,201,103,0.40)_100%)] border border-solid border-[#AE8625] shadow-[0px_0px_4px_2px_#D2AC47] rounded-[20px] mt-[20px] md:mt-[26px] transition-all duration-300 ease-in-out hover:bg-[rgba(174,134,37,0.60)] hover:border-[#AE8625] hover:shadow-[0px_0px_18px_4px_#AE8625]'
                >
                  <span className='text-[16px] md:text-[24px] font-extrabold leading-normal tracking-[0.785px] md:tracking-[1.2px]'>Participate</span>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className='h-[calc(100vh-59px)] md:h-[calc(100vh-70px)]'>
          <div className="w-full h-full bg-[#090C0E] bg-[url('/src/assets/slide5-mobile.webp')] sm:bg-[url('/src/assets/slide5.webp')] bg-bottom md:bg-center bg-no-repeat bg-cover">
            {/* Slide contents */}
            <div className="main-box flex items-start w-full h-full px-[29px] md:px-[230px] z-20">
              <div className='pt-[175px] md:pt-[180px] z-20'>
                <h1 className="text-[32px] md:text-[60px] font-bold leading-normal tracking-[1.6px] md:tracking-[3px]">
                  Talk about the BiteClub
                </h1>
                <h5 className="text-[22px] md:text-[32px] font-medium leading-normal tracking-[1.1px] md:tracking-[1.6px]">
                  Refer friends and Earn!
                </h5>
                <Link
                  to={`/user-stats/${user?.publicKey}`}
                  onClick={(e) => {
                    if (!user?.publicKey) {
                      toast.warn("Please connect wallet");
                      e.preventDefault();
                    }
                  }}
                  onMouseEnter={() => playHover()}
                  className='main-btn flex justify-center items-center w-[233px] md:w-[350px] h-[50px] md:h-[75px] bg-[linear-gradient(90deg,rgba(55,207,96,0.40)_0%,rgba(154,255,181,0.40)_46.35%,rgba(0,185,63,0.40)_75.52%,rgba(0,255,133,0.40)_100%)] border border-solid border-[#37CF60] shadow-[0px_0px_4px_2px_#46FF78] rounded-[20px] mt-[20px] md:mt-[26px] transition-all duration-300 ease-in-out hover:bg-[rgba(55,207,96,0.60)] hover:border-[#37CF60] hover:shadow-[0px_0px_18px_4px_#37CF60]'
                >
                  <span className='text-[16px] md:text-[24px] font-extrabold leading-normal tracking-[0.785px] md:tracking-[1.2px]'>Refer and Earn</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Swiper>
    </div>
  );
};

export default CustomSlider;
