import React from "react";
import { ClipLoader } from "react-spinners";
import healthHeartIcon from "../../assets/health-heart.svg";
import filledHeartIcon from "../../assets/filledheart.svg";
import filledCheckIcon from "../../assets/filled-check.svg";
import { IQuestNftInfo } from "../../interfaces/Quests";
import { questTokenAmountPerHeal } from "../../data";
import useSound from "use-sound";

interface IProps {
    show: boolean;
    isHealing: boolean;
    nftListToHeal: IQuestNftInfo[];
    healedNfts: string[];
    handleShow: (status: boolean) => void;
    setNftListToHeal: (nftListToHeal: IQuestNftInfo[]) => void;
    setHealedNfts: (healedNfts: string[]) => void;
}

const HealingStatusModal = (props: IProps) => {
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
    const {
        show,
        isHealing,
        nftListToHeal,
        healedNfts,
        handleShow,
        setNftListToHeal,
        setHealedNfts,
    } = props;

    return (
        < div
            className="fixed top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] bg-black/50 z-[1040] overflow-y-auto hidden justify-center mobile-height"
            style={{ display: show ? "flex" : "none" }
            }
        >
            <div className="flex justify-center items-center w-full h-full pt-[7rem] pb-[9rem] md:py-[5rem]">
                <div className="flex flex-col items-center max-w-[900px] w-full h-full mx-[2rem] font-semibold rounded-[10px] p-[25px] md:p-[25px_30px_50px] flex-1 text-center bg-[#090C0E] shadow-[0px_0px_7px_#00A5FE] relative z-[1050]">
                    {/* Close Button */}
                    <button
                        disabled={isHealing}
                        className="sm:flex justify-center items-center absolute top-0 right-0 p-[10px] cursor-pointer bg-none border-none rounded-[6px] transform hover:scale-110 transition-all"
                        onClick={() => {
                            handleShow(false);
                            setNftListToHeal([]);
                            setHealedNfts([]);
                        }}
                        onMouseEnter={() => playHover()}
                    >
                        <svg width="14" height="14" className="fill-white transition-[fill_200ms_ease_0s]">
                            <path d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z"></path>
                        </svg>
                    </button>

                    {/* Modal Header */}
                    <div className="w-full relative">
                        <h1 className="text-[16px] md:text-[28px] text-[#EDC967] text-center font-normal leading-[100%] md:leading-[40px] w-full">Healing</h1>
                    </div>

                    {/* Modal Body */}
                    <div className="grow w-full mt-[20px] md:mt-[15px] overflow-x-hidden overflow-y-auto md:overflow-hidden no-scroll-bar">
                        <div className="flex flex-col md:flex-row gap-[25px] md:gap-[10px] w-full">
                            {/* Transactions */}
                            <div className="grow flex flex-col order-2 md:order-1">
                                <h5 className="sticky top-0 text-[14px] md:text-[20px] text-[#808080] text-left font-normal leading-[20px] md:leading-[28px] tracking-[0.1em] pb-[12px] md:pb-[20px] bg-[#090C0E] uppercase">Transactions</h5>

                                <div className="w-full h-[600px] overflow-x-hidden overflow-y-auto no-scroll-bar">
                                    {
                                        nftListToHeal.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="flex items-center w-full h-[26px] md:h-[52px] bg-[#11161B] mb-[5px]"
                                                >
                                                    <div className="w-[40px] md:w-[66px] text-left pl-[6px] md:pl-[8px]">
                                                        <span className="text-[11px] md:text-[20px] font-normal leading-[20px] md:leading-[28px] tracking-[0.1em] uppercase">
                                                            {`#${index + 1}`}
                                                        </span>
                                                    </div>
                                                    <div className="w-[26px] md:w-[45px]">
                                                        <img
                                                            src={item.image}
                                                            alt="nft-image"
                                                            className="w-full h-full rounded-[6px] md:rounded-[10px]"
                                                        />
                                                    </div>
                                                    <div className="grow text-left pl-[6px] md:pl-[18px]">
                                                        <span className="text-[10px] md:text-[16px] font-normal leading-[14px] md:leading-[22px]">
                                                            {item.name}
                                                        </span>
                                                    </div>
                                                    <div className="w-[30px] md:w-[80px]">
                                                        <div className="flex justify-center items-center gap-[5px]">
                                                            <span className="text-[10px] md:text-[20px] font-normal">
                                                                {`+ ${item.maxHealthValue - item.healthValue}`}
                                                            </span>
                                                            <img src={filledHeartIcon} className="w-[10px] md:w-[16px]" />
                                                        </div>
                                                    </div>
                                                    <div className="w-[50px] md:w-[150px] text-right">
                                                        {
                                                            healedNfts.includes(item.mintAddress) ? (
                                                                <div className="flex justify-end items-center">
                                                                    <img
                                                                        src={filledCheckIcon}
                                                                        alt="nft-image"
                                                                        className="h-[15px] md:h-[25px] pr-[12px] md:pr-[22px]"
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <span className="text-[8px] md:text-[14px] text-[#808080] font-normal pr-[6px] md:pr-[22px]">Pending...</span>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>

                            {/* Summary */}
                            <div className="flex flex-col gap-[5px] md:gap-[20px] order-1 md:order-2 w-full md:w-[220px]">
                                <h5 className="text-[14px] md:text-[20px] text-[#808080] text-left font-normal leading-[20px] md:leading-[28px] tracking-[0.1em] uppercase">Summary</h5>

                                <div className="flex flex-row md:flex-col justify-between items-center gap-[36px] md:gap-[20px] w-full bg-[#11161B] p-[14px_20px] md:p-[18px_20px_20px]">
                                    {/* Total Bones Cost */}
                                    <div className="flex flex-col items-center gap-[5px]">
                                        <span className="text-[14px] md:text-[16px] text-[#FF0000] font-bold leading-[21px] md:leading-[24px]">
                                            {`${nftListToHeal.reduce((s, obj) => s + (obj.maxHealthValue - obj.healthValue), 0) * questTokenAmountPerHeal} Bones`}
                                        </span>
                                        <span className="text-[14px] md:text-[16px] text-[#808080] font-normal leading-[20px] md:leading-[22px] capitalize">Total Bones Cost</span>
                                    </div>

                                    {/* Total Healing */}
                                    <div className="flex flex-col items-center gap-[5px]">
                                        <div className="flex justify-center items-center gap-[7px]">
                                            <span className="text-[14px] md:text-[20px] font-normal">
                                                {`+ ${nftListToHeal.reduce((s, obj) => s + (obj.maxHealthValue - obj.healthValue), 0)}`}
                                            </span>
                                            <img
                                                src={healthHeartIcon}
                                                alt="health-heart-icon"
                                                className="w-[11px] md:w-[16px]"
                                            />
                                        </div>
                                        <span className="text-[14px] md:text-[16px] text-[#808080] font-normal leading-[20px] md:leading-[22px] capitalize">Total Healing</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="overlay"></div>
        </div >
    );
};

export default HealingStatusModal;