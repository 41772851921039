import { IDice2, IDice2Game } from "../interfaces/DiceGame2"


enum Dice2ActionTypes {
    SET_DICE2_RECENT_ROUNDS = 'SET_DICE2_RECENT_ROUNDS',
    SET_DICE2_CURRENT_ROUND = 'SET_DICE2_CURRENT_ROUND',
    SET_DICE2_CURRENT_USERS_INFO = 'SET_DICE2_CURRENT_USERS_INFO',
    UPDATE_DICE2_CURRENT_USERS_INFO = 'UPDATE_DICE2_CURRENT_USERS_INFO',
    UPDATE_DICE2_CURRENT_ROUND = 'UPDATE_DICE2_CURRENT_ROUND',
    UPDATE_DICE2_RECENT_ROUNDS = 'UPDATE_DICE2_RECENT_ROUNDS'
}

export interface IDice2InitialState {
    recentRounds: IDice2[],
    currentRound: IDice2,
    usersGameInfo: IDice2Game[]
}

interface SetRecentRoundsAction {
    type: Dice2ActionTypes.SET_DICE2_RECENT_ROUNDS,
    payload: IDice2[]
}

interface SetCurrentRoundAction {
    type: Dice2ActionTypes.SET_DICE2_CURRENT_ROUND,
    payload: IDice2
}

interface SetCurrentUsersInfoAction {
    type: Dice2ActionTypes.SET_DICE2_CURRENT_USERS_INFO,
    payload: IDice2Game[]
}

interface UpdateCurrentUsersInfoAction {
    type: Dice2ActionTypes.UPDATE_DICE2_CURRENT_USERS_INFO,
    payload: IDice2Game
}

interface UpdateCurrentRound {
    type: Dice2ActionTypes.UPDATE_DICE2_CURRENT_ROUND,
    payload: IDice2
}

interface UpdateRecentRounds {
    type: Dice2ActionTypes.UPDATE_DICE2_RECENT_ROUNDS,
    payload: IDice2
}

type Dice2Action = SetRecentRoundsAction | SetCurrentRoundAction | SetCurrentUsersInfoAction | UpdateCurrentUsersInfoAction | UpdateCurrentRound | UpdateRecentRounds;

const initialState: IDice2InitialState = {
    recentRounds: [],
    currentRound: {},
    usersGameInfo: []
}

export default (state = initialState, action: Dice2Action): IDice2InitialState => {

    switch (action.type) {
        case Dice2ActionTypes.SET_DICE2_RECENT_ROUNDS: {
            return { ...state, recentRounds: action.payload }
        }

        case Dice2ActionTypes.SET_DICE2_CURRENT_ROUND: {
            return { ...state, currentRound: action.payload }
        }

        case Dice2ActionTypes.SET_DICE2_CURRENT_USERS_INFO: {
            return { ...state, usersGameInfo: action.payload }
        }

        case Dice2ActionTypes.UPDATE_DICE2_CURRENT_USERS_INFO: {
            return {
                ...state,
                usersGameInfo: [...state.usersGameInfo, action.payload]
            }
        }

        case Dice2ActionTypes.UPDATE_DICE2_CURRENT_ROUND: {
            return {
                ...state,
                currentRound: action.payload
            }
        }

        case Dice2ActionTypes.UPDATE_DICE2_RECENT_ROUNDS: {
            return {
                ...state,
                recentRounds: [...state.recentRounds.slice(1), action.payload]
            }
        }
        default:
            return state
    }
}