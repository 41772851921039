import React from "react";
import { useNavigate } from "react-router-dom";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { CoinflipFaceEnum, Dice2BettingTypeEnum } from "../../enums";
import userIcon from "../../assets/user.svg";
import UserGroupIcon from "../svgs/UserGroupIcon";
import SolanaIconBlue from "../svgs/SolanaIconBlue";
import SolanaIconPurple from "../svgs/SolanaIconPurple";
import SolanaIconGolden from "../svgs/SolanaIconGolden";
import SolanaIconCopper from "../svgs/SolanaIconCopper";
import SolanaIconGreen from "../svgs/SolanaIconGreen";
import { IDice2Game } from "../../interfaces/DiceGame2";
import useSound from "use-sound";

interface IProps {
    position?: 'top-left' | 'bottom-left' | 'top-right' | 'bottom-right';
    currentUsersInfo?: IDice2Game[] | undefined;
    isCompleted?: boolean;
    winningNumber?: number;
    isMobile?: boolean;
    className?: string
}

const GameStatsBar = (props: IProps) => {
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
    const navigate = useNavigate();
    const { position, currentUsersInfo, isCompleted, winningNumber, isMobile, className } = props;
    let headerBg;
    let boxShadow;
    let iconBgColor = '';
    let color;
    let text = '';
    let solanaIcon = <SolanaIconBlue />;
    let isWinner = false;
    let bettingType = Dice2BettingTypeEnum.Low;

    switch (position) {
        case 'top-left':
            headerBg = 'bg-[linear-gradient(95deg,rgba(5,187,244,0.30)_4.9%,rgba(104,153,248,0.30)_16.09%,rgba(10,57,180,0.30)_44.74%,rgba(19,96,177,0.30)_80.33%,rgba(0,165,254,0.30)_95.92%)]';
            boxShadow = 'shadow-[0px_0px_5px_#00A5FE]';
            iconBgColor = '#00A5FE';
            color = 'text-[#00A5FE]';
            text = 'LOW';
            solanaIcon = <SolanaIconBlue />;
            isWinner = !!winningNumber && winningNumber >= 1 && winningNumber <= 3;
            bettingType = Dice2BettingTypeEnum.Low;
            break;
        case 'bottom-left':
            headerBg = 'bg-[linear-gradient(95deg,rgba(176,149,255,0.30)_4.9%,rgba(159,141,211,0.30)_16.09%,rgba(107,33,201,0.30)_44.74%,rgba(107,33,201,0.30)_80.33%,rgba(128,86,246,0.30)_95.92%)]';
            boxShadow = 'shadow-[0px_0px_5px_#8056F6]';
            iconBgColor = '#8056F6';
            color = 'text-[#8056F6]';
            text = 'EVENS';
            solanaIcon = <SolanaIconPurple />;
            isWinner = !!winningNumber && winningNumber % 2 == 0;
            bettingType = Dice2BettingTypeEnum.Even;
            break;
        case 'top-right':
            headerBg = 'bg-[linear-gradient(90deg,rgba(174,134,37,0.30)_0%,rgba(247,239,168,0.30)_46.35%,rgba(210,172,71,0.30)_75.52%,rgba(237,201,103,0.30)_100%)]';
            boxShadow = 'shadow-[0px_0px_5px_#EDC967]';
            iconBgColor = '#EDC967';
            color = 'text-[#EDC967]';
            text = 'HIGH';
            solanaIcon = <SolanaIconGolden />;
            isWinner = !!winningNumber && winningNumber >= 4 && winningNumber <= 6;
            bettingType = Dice2BettingTypeEnum.High;
            break;
        case 'bottom-right':
            headerBg = 'bg-[linear-gradient(95deg,rgba(255,178,122,0.30)_4.9%,rgba(255,210,177,0.30)_16.09%,rgba(211,96,12,0.30)_44.74%,rgba(121,51,0,0.30)_80.33%,rgba(255,122,26,0.30)_95.92%)]';
            boxShadow = 'shadow-[0px_0px_5px_#FF7A1A]';
            iconBgColor = '#FF7A1A';
            text = 'ODDS';
            color = 'text-[#FF7A1A]';
            solanaIcon = <SolanaIconCopper />;
            isWinner = !!winningNumber && winningNumber % 2 == 1;
            bettingType = Dice2BettingTypeEnum.Odd;
            break;
    }

    const filteredUsersInfo = (currentUsersInfo || []).filter((x) => x.bettingType == bettingType);

    return (
        <div className={`flex flex-col w-full h-full md:z-20 ${className ?? ''}`}>
            <div className="py-[3px] h-full">
                <div className={`h-full bg-[#11161B] rounded-[2px] ${boxShadow} ${isWinner ? 'dice2-win-board' : ''}`}>
                    <div className={`flex justify-between items-center w-full h-[36px] px-[14px] md:px-[22px] ${headerBg} ${isWinner ? 'win-header' : ''}`}>
                        <div className="flex items-center gap-[9px]">
                            <UserGroupIcon color={isWinner ? '#46FF78' : iconBgColor} />
                            <span className={`text-[10px] md:text-[16px] font-medium leading-[15px] md:leading-[23px] ${color} ${isWinner ? 'win-numbers' : ''}`}>
                                {filteredUsersInfo.length}
                            </span>
                        </div>
                        <div className={`text-[10px] font-bold tracking-widest md:text-[14px] ${color} ${isWinner ? 'win-numbers' : ''}`}>{text}</div>
                        <div className="flex items-center gap-[5px]">
                            <span className={`text-[10px] md:text-[16px] font-medium leading-[15px] md:leading-[23px] ${color} ${isWinner ? 'win-numbers' : ''}`}>
                                {filteredUsersInfo.reduce(
                                    (total, obj) => total + obj.bettingAmount,
                                    0
                                ) / LAMPORTS_PER_SOL}
                            </span>
                            {
                                isWinner ? (
                                    <SolanaIconGreen />
                                ) : (
                                    solanaIcon
                                )
                            }
                        </div>
                    </div>

                    <div className="grow pl-[19px] md:pl-[27px] pr-[44px] md:pr-[52px]">
                        {
                            filteredUsersInfo && filteredUsersInfo.map((item, index) => {
                                return (
                                    <div key={index} className="flex justify-between items-center w-full mt-[10px]">
                                        <div
                                            className="flex items-center gap-[6px] cursor-pointer"
                                            onClick={() => navigate(`/user-stats/${item?.player?.publicKey}`)}
                                            onMouseEnter={() => playHover()}
                                        >
                                            <img src={userIcon} alt="user" />
                                            <span className={`text-[10px] md:text-[16px] font-medium leading-[15px] md:leading-[23px] ${isCompleted && isWinner ? 'text-green-500' : 'text-white'}`}>
                                                {isMobile
                                                    ? item.player.username
                                                        ? item.player.username.length > 4
                                                            ? String(item.player.username).substring(0, 4) + "..."
                                                            : item.player.username
                                                        : String(item.player.publicKey).substring(0, 2) +
                                                        "..." +
                                                        String(item.player.publicKey).substring(42)
                                                    : item.player.username
                                                        ? item.player.username.length > 12
                                                            ? String(item.player.username).substring(0, 12) + "..."
                                                            : item.player.username
                                                        : String(item.player.publicKey).substring(0, 4) +
                                                        "..." +
                                                        String(item.player.publicKey).substring(40)}
                                            </span>
                                        </div>

                                        <span className="text-[10px] md:text-[16px] font-medium leading-[15px] md:leading-[23px]">
                                            {item.bettingAmount ? (item.bettingAmount / LAMPORTS_PER_SOL) : 'Free'}
                                        </span>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>

        </div>
    );
};

export default GameStatsBar;