import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";

export const wagerNfts = [
    new PublicKey('BaP8weJ514ctSpqmKrfFgMqU9MgQFzN6Asufverta5to'),
    new PublicKey('3QgWeW2eDcnRFXW348xvzsgV5mCfyxDxo3DEqY26hiEw'),
    new PublicKey('HiQbWRtJM7v9n15vkkrXyKGMH2mLMBoCutEsbsLUpSgr'),
    new PublicKey('7qrjHrtZnwBG19uHfUrgQ2Sd2kvYAaFdPEkUut4RNNxJ'),
    new PublicKey('9y6QnjCLw7S4zQxpEspyxMDJK395hWRveHx4nxhTeGt4'),
];

export const YEAR_IN_SECONDS = 365 * 86400;
export const MONTH_IN_SECONDS = 30 * 86400;
export const DAY_IN_SECONDS = 86400;
export const HOUR_IN_SECONDS = 3600;
export const MINUTE_IN_SECONDS = 60;
export const ARENA_MIN_DAILY_WAGER_AMOUNT = 25 * LAMPORTS_PER_SOL;