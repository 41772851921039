import React from "react";

interface IProps {
    className?: string;
}

const GradientDiceIcon = ({ className }: IProps) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="197" height="228" viewBox="0 0 197 228" fill="none">
            <path d="M0 55.5411L131.118 0L196.296 115.14L62.3886 177.021L0 55.5411Z" fill="url(#paint0_linear_507_998)" />
            <path d="M0 55.5411L131.118 0L196.296 115.14L62.3886 177.021L0 55.5411Z" fill="url(#paint1_linear_507_998)" />
            <path d="M0 55.5411L131.118 0L196.296 115.14L62.3886 177.021L0 55.5411Z" fill="url(#paint2_linear_507_998)" fillOpacity="0.6" />
            <path d="M0 55.5411L131.118 0L196.296 115.14L62.3886 177.021L0 55.5411Z" fill="url(#paint3_linear_507_998)" fillOpacity="0.6" />
            <path d="M0 55.5411L131.118 0L196.296 115.14L62.3886 177.021L0 55.5411Z" fill="url(#paint4_linear_507_998)" fillOpacity="0.6" />
            <path d="M62.3972 176.77L196.305 115.143L188.696 172.459L66.455 228L62.3972 176.77Z" fill="url(#paint5_linear_507_998)" />
            <path d="M62.3972 176.77L196.305 115.143L188.696 172.459L66.455 228L62.3972 176.77Z" fill="url(#paint6_linear_507_998)" fillOpacity="0.8" />
            <path d="M62.3972 176.77L196.305 115.143L188.696 172.459L66.455 228L62.3972 176.77Z" fill="url(#paint7_linear_507_998)" fillOpacity="0.6" />
            <path d="M62.3972 176.77L196.305 115.143L188.696 172.459L66.455 228L62.3972 176.77Z" fill="url(#paint8_linear_507_998)" fillOpacity="0.6" />
            <path d="M62.3972 176.77L196.305 115.143L188.696 172.459L66.455 228L62.3972 176.77Z" fill="url(#paint9_linear_507_998)" fillOpacity="0.6" />
            <path d="M0 55.2861L62.6422 177.02L66.4464 227.996L9.63727 116.407L0 55.2861Z" fill="url(#paint10_linear_507_998)" />
            <path d="M0 55.2861L62.6422 177.02L66.4464 227.996L9.63727 116.407L0 55.2861Z" fill="url(#paint11_linear_507_998)" fillOpacity="0.8" />
            <path d="M0 55.2861L62.6422 177.02L66.4464 227.996L9.63727 116.407L0 55.2861Z" fill="url(#paint12_linear_507_998)" fillOpacity="0.6" />
            <path d="M0 55.2861L62.6422 177.02L66.4464 227.996L9.63727 116.407L0 55.2861Z" fill="url(#paint13_linear_507_998)" fillOpacity="0.6" />
            <path d="M0 55.2861L62.6422 177.02L66.4464 227.996L9.63727 116.407L0 55.2861Z" fill="url(#paint14_linear_507_998)" fillOpacity="0.6" />
            <g filter="url(#filter0_i_507_998)">
                <path d="M95.9995 36.5204L132.002 45.9132C162.812 70.2271 133.5 98.9562 126.222 100.952C118.944 102.948 95.0719 110.362 94.3776 110.749C93.6833 111.136 91.3463 118.615 91.3463 118.615C84.7052 126.652 74.6827 120.967 75.3413 114.361C75.9998 107.756 79.5998 101.671 79.887 100.676C80.1742 99.6817 84.5657 94.7711 85.0012 95.0431C85.4367 95.315 121.433 83.5884 121.433 83.5884C129.155 74.4253 130.011 69.5888 121.999 61.8367C109.581 58.6261 94.521 54.5373 90.9621 54.8973C88.587 55.2437 87.2664 55.1879 84.7213 59.5076L81.722 64.3205C74.2786 68.3049 70.8202 68.2183 67.5429 60.6964C70.7676 47.1828 76.9524 39.5834 95.9995 36.5204Z" fill="white" />
            </g>
            <g filter="url(#filter1_i_507_998)">
                <path d="M177.345 134.012L181.891 140.933C188.104 165.03 166.873 174.488 157.677 180.176C148.48 185.865 138.158 187.752 137.303 187.655C136.447 187.558 121.096 186.286 121.096 186.286C108.586 198.301 104.765 187.534 109.803 183.322C114.841 179.11 125.238 171.722 126.765 172.18C128.291 172.638 136.754 172.756 136.774 172.922C136.794 173.087 146.487 173.578 147.586 173.834C148.685 174.09 158.721 168.083 161.484 165.245C164.248 162.407 162.898 164.077 166.251 159.648C164.462 152.185 163.783 145.626 163.122 146.715C157.551 149.654 154.649 151.116 151.435 152.013C141.734 150.135 152.434 139.601 158.313 137.272C171.584 131.874 171.14 133.129 177.345 134.012Z" fill="white" />
            </g>
            <g filter="url(#filter2_i_507_998)">
                <ellipse cx="76.0782" cy="141.514" rx="9.63727" ry="9.13004" fill="white" />
            </g>
            <g filter="url(#filter3_i_507_998)">
                <ellipse cx="88.5293" cy="203.026" rx="11.2484" ry="5.63302" transform="rotate(-34.9995 88.5293 203.026)" fill="white" />
            </g>
            <g filter="url(#filter4_i_507_998)">
                <ellipse cx="55.8524" cy="190.089" rx="10.463" ry="2.97069" transform="rotate(68.5327 55.8524 190.089)" fill="white" />
            </g>
            <g filter="url(#filter5_i_507_998)">
                <path d="M46.4033 167.18C47.1477 172.576 46.7373 173.395 44.2286 170.121C41.18 164.952 39.6717 161.867 37.0309 156.312L29.1105 131.296L25.8051 120.978C24.1197 116.417 23.0087 114.355 20.9676 110.839C21.0845 113.432 21.0668 114.516 22.0055 120.793C22.2154 123.4 22.515 124.785 23.4697 127.212L28.9065 140.681C29.5114 145.192 29.0575 146.125 26.6823 144.635L18.6321 127.484C15.1962 117.149 13.7942 110.839 12.1636 98.7299C11.3251 92.6052 11.8253 90.9716 15.3084 91.5198C20.3071 96.8237 23.6719 100.461 31.7734 123.555C34.653 130.968 35.6779 134.885 37.482 141.861C40.2242 152.02 42.2268 157.554 46.4033 167.18Z" fill="white" />
            </g>
            <defs>
                <filter id="filter0_i_507_998" x="67.543" y="36.5205" width="78.2717" height="92.7751" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6.73608" />
                    <feGaussianBlur stdDeviation="3.36804" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_507_998" />
                </filter>
                <filter id="filter1_i_507_998" x="107.65" y="133.223" width="75.3612" height="65.3074" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6.73608" />
                    <feGaussianBlur stdDeviation="3.36804" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_507_998" />
                </filter>
                <filter id="filter2_i_507_998" x="66.4409" y="132.384" width="19.2744" height="24.9958" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6.73608" />
                    <feGaussianBlur stdDeviation="3.36804" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_507_998" />
                </filter>
                <filter id="filter3_i_507_998" x="78.7625" y="195.094" width="19.5337" height="22.6013" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6.73608" />
                    <feGaussianBlur stdDeviation="3.36804" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_507_998" />
                </filter>
                <filter id="filter4_i_507_998" x="51.1292" y="180.29" width="9.44653" height="26.3337" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6.73608" />
                    <feGaussianBlur stdDeviation="3.36804" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_507_998" />
                </filter>
                <filter id="filter5_i_507_998" x="11.8096" y="91.4209" width="34.927" height="87.3708" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6.73608" />
                    <feGaussianBlur stdDeviation="3.36804" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_507_998" />
                </filter>
                <linearGradient id="paint0_linear_507_998" x1="98.2748" y1="0" x2="98.2748" y2="177.021" gradientUnits="userSpaceOnUse">
                    <stop />
                    <stop offset="0.711409" />
                    <stop offset="1" stopColor="#D0EFFF" />
                </linearGradient>
                <linearGradient id="paint1_linear_507_998" x1="7" y1="58" x2="145" y2="143" gradientUnits="userSpaceOnUse">
                    <stop offset="0.0733097" stopColor="#00A5FE" />
                    <stop offset="1" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint2_linear_507_998" x1="168" y1="70.5" x2="67.5" y2="158.5" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EDC967" />
                    <stop offset="1" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint3_linear_507_998" x1="196" y1="114" x2="30" y2="99" gradientUnits="userSpaceOnUse">
                    <stop offset="0.082972" stopColor="#FF0000" />
                    <stop offset="1" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint4_linear_507_998" x1="48.5" y1="160.5" x2="165" y2="49" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#8056F6" />
                    <stop offset="1" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint5_linear_507_998" x1="129.437" y1="115.143" x2="129.437" y2="228" gradientUnits="userSpaceOnUse">
                    <stop />
                    <stop offset="0.738394" />
                </linearGradient>
                <linearGradient id="paint6_linear_507_998" x1="68.0002" y1="226.5" x2="204.472" y2="127.754" gradientUnits="userSpaceOnUse">
                    <stop offset="0.0348581" stopColor="#00A5FE" />
                    <stop offset="1" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint7_linear_507_998" x1="189.5" y1="172" x2="65.659" y2="181.813" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EDC967" />
                    <stop offset="1" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint8_linear_507_998" x1="196" y1="116.5" x2="73.8346" y2="212.517" gradientUnits="userSpaceOnUse">
                    <stop offset="0.082972" stopColor="#FF0000" />
                    <stop offset="1" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint9_linear_507_998" x1="65.5002" y1="178.5" x2="194.968" y2="163.241" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#8056F6" />
                    <stop offset="1" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint10_linear_507_998" x1="33.2661" y1="55.2861" x2="33.2661" y2="227.996" gradientUnits="userSpaceOnUse">
                    <stop />
                    <stop offset="0.738394" />
                </linearGradient>
                <linearGradient id="paint11_linear_507_998" x1="66" y1="221" x2="109.226" y2="151.739" gradientUnits="userSpaceOnUse">
                    <stop offset="0.0348581" stopColor="#00A5FE" />
                    <stop offset="1" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint12_linear_507_998" x1="5.5" y1="129" x2="62.4485" y2="177.152" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EDC967" />
                    <stop offset="1" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint13_linear_507_998" x1="1.06633e-06" y1="55" x2="-55.0278" y2="123.862" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00612489" stopColor="#FF0000" />
                    <stop offset="1" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint14_linear_507_998" x1="63.5" y1="174" x2="-6.49755" y2="143.47" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#8056F6" />
                    <stop offset="1" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default GradientDiceIcon;