import React from "react";

const SolanaIconGolden = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="17" viewBox="0 0 23 17" fill="none">
            <g clipPath="url(#clip0_507_1197)">
                <path d="M6.15669 0.6875H20.9998L16.8437 4.24985H2.00061L6.15669 0.6875Z" fill="url(#paint0_linear_507_1197)" stroke="#EDC967" />
                <path d="M16.843 6.625H1.99988L6.15596 10.1874H20.9991L16.843 6.625Z" fill="url(#paint1_linear_507_1197)" stroke="#EDC967" />
                <path d="M6.15632 12.5605H20.9994L16.8434 16.1229H2.00024L6.15632 12.5605Z" fill="url(#paint2_linear_507_1197)" stroke="#EDC967" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_507_1197" x1="-0.556962" y1="2.54264" x2="21.6245" y2="4.11821" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EDC967" />
                    <stop offset="1" stopColor="#11161B" />
                </linearGradient>
                <linearGradient id="paint1_linear_507_1197" x1="23.5567" y1="8.48014" x2="1.37515" y2="10.0557" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EDC967" />
                    <stop offset="1" stopColor="#11161B" />
                </linearGradient>
                <linearGradient id="paint2_linear_507_1197" x1="-0.557326" y1="14.4157" x2="21.6242" y2="15.9913" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EDC967" />
                    <stop offset="1" stopColor="#11161B" />
                </linearGradient>
                <clipPath id="clip0_507_1197">
                    <rect width="23" height="17" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default SolanaIconGolden;