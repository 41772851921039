import React from 'react';

interface IProps {
    color: string;
}

const UserGroupIcon = (props: IProps) => {
    const { color } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="23" viewBox="0 0 25 23" fill="none">
            <circle cx="12" cy="9" r="4" fill={color} />
            <path d="M16.7612 23H7.2751C5.0114 21.8486 4.49983 20 5.99982 15C7.5 13.0001 8.5 13.0001 10.2934 13.9246C12.0544 14.5808 12.7299 14.4543 13.7429 13.9246C15.5 13 16.2768 12.6886 18 15.0001C19.5 21.0001 18.6701 21.8653 16.7612 23Z" fill={color} />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.83952 5.12535C8.94398 4.76838 9 4.39073 9 4C9 1.79086 7.20914 0 5 0C2.79086 0 1 1.79086 1 4C1 6.20914 2.79086 8 5 8C5.85983 8 6.6563 7.7287 7.30847 7.26704C7.62066 6.42218 8.15454 5.68475 8.83952 5.12535Z" fill={color} />
            <path fillRule="evenodd" clipRule="evenodd" d="M19 8C21.2091 8 23 6.20914 23 4C23 1.79086 21.2091 0 19 0C16.7909 0 15 1.79086 15 4C15 4.39073 15.056 4.76838 15.1605 5.12535C15.8455 5.68475 16.3793 6.42218 16.6915 7.26704C17.3437 7.7287 18.1402 8 19 8Z" fill={color} />
            <path d="M20 20.0001C19.9135 16.1873 19.646 14.1655 15.5 12.5001L17 9.00013C20.0082 8.93402 21.5929 9.13765 24 10.5001C24.0188 13.2493 24.0242 14.947 24 19.0001L20 20.0001Z" fill={color} />
            <path d="M4.01611 20.0109C4.1026 16.1981 4.37009 14.1763 8.51611 12.5109L7.01611 9.01087C4.00795 8.94476 2.42319 9.14839 0.0161133 10.5109C-0.00269127 13.2601 -0.00806808 14.9578 0.0161133 19.0109L4.01611 20.0109Z" fill={color} />
        </svg>
    );
};

export default UserGroupIcon;