import User from "../interfaces/User";
import { Connection } from "@solana/web3.js";
import { DAY_IN_SECONDS, HOUR_IN_SECONDS, MINUTE_IN_SECONDS, MONTH_IN_SECONDS, YEAR_IN_SECONDS } from "./config";

export const shortWalletAddress = (publicKey: string, slicePoint = 3) => {
    return publicKey.slice(0, slicePoint) + "..." + publicKey.slice(-slicePoint);
}

export const displayName = (user: User) => {
    return user?.username
        ?
        (
            user?.username.length > 10
                ?
                shortWalletAddress(user?.username)
                :
                user?.username
        )
        : shortWalletAddress(user.publicKey)
}

export const shortName = (username: string, lengthLimit = 10, slicePoint = 3) => {
    return username.length > lengthLimit
        ?
        shortWalletAddress(username, slicePoint)
        :
        username
}

export const shortMessage = (message: string) => {
    return message.length > 20
        ?
        message.slice(0, 20) + "..."
        :
        message
}

export function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const getBlockTime = async () => {
    const connection = new Connection(process.env.REACT_APP_RPC_URL!, "confirmed");
    const slot = await connection.getSlot();
    const currentBlockTime = await connection.getBlockTime(slot);

    return currentBlockTime || 0;
}

export const getFormattedTokenBalance = (amount: number): string => {
    if (amount < 1000) {
        return amount.toFixed(1);
    } else if (amount >= 1000 && amount < 1000000) {
        return `${(amount / 1000).toFixed(1)}k`
    } else {
        return `${(amount / 1000000).toFixed(1)}M`
    }
}

export const getFormattedTimeDiff = (timeDiff: number): string => {
    if (timeDiff / YEAR_IN_SECONDS >= 1) {
        if (Math.floor(timeDiff / YEAR_IN_SECONDS) == 1) {
            return '1 year ago';
        } else {
            return `${Math.floor(timeDiff / YEAR_IN_SECONDS)} years ago`;
        }
    } else if (timeDiff / MONTH_IN_SECONDS >= 1) {
        if (Math.floor(timeDiff / MONTH_IN_SECONDS) == 1) {
            return '1 month ago';
        } else {
            return `${Math.floor(timeDiff / MONTH_IN_SECONDS)} months ago`;
        }
    } else if (timeDiff / DAY_IN_SECONDS >= 1) {
        if (Math.floor(timeDiff / DAY_IN_SECONDS) == 1) {
            return '1 day ago';
        } else {
            return `${Math.floor(timeDiff / DAY_IN_SECONDS)} days ago`;
        }
    } else if (timeDiff / HOUR_IN_SECONDS >= 1) {
        if (Math.floor(timeDiff / HOUR_IN_SECONDS) == 1) {
            return '1 hour ago';
        } else {
            return `${Math.floor(timeDiff / HOUR_IN_SECONDS)} hours ago`;
        }
    } else if (timeDiff / MINUTE_IN_SECONDS >= 1) {
        if (Math.floor(timeDiff / MINUTE_IN_SECONDS) == 1) {
            return '1 minute ago';
        } else {
            return `${Math.floor(timeDiff / MINUTE_IN_SECONDS)} minutes ago`;
        }
    } else {
        return 'less than a minute ago';
    }
}

export const getLoanEventTitle = (loanStatus: 'offer' | 'take' | 'cancel' | 'repay' | 'extend'): string => {
    let title = 'Placed an offer';

    if (loanStatus !== 'offer') {
        if (loanStatus === 'take') title = 'Offer has been taken';
        if (loanStatus === 'cancel') title = 'Revoked an offer';
        if (loanStatus === 'repay') title = 'Loan has been repaid';
        if (loanStatus === 'extend') title = 'Offer has been extended';
    }

    return title;
}

export const getPointsAccordingToIndex = (index: number) => {
    if (index == 0) {
        return 5;
    } else if (index == 1) {
        return 10;
    } else if (index == 2) {
        return 25;
    } else if (index == 3) {
        return 100;
    } else if (index == 4) {
        return 300;
    }
}

export const createTwitterShareUrl = (text: string) => {
    const encodedText = encodeURIComponent(text);
    return `https://twitter.com/intent/tweet?text=${encodedText}`;
};
