import React from "react";
import { useNavigate } from "react-router-dom";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { CoinflipFaceEnum } from "../../enums";
import CoinflipGame from "../../interfaces/CoinflipGame";
import userIcon from "../../assets/user.svg";
import userGroupLeftIcon from "../../assets/user-group-left.svg";
import userGroupRightIcon from "../../assets/user-group-right.svg";
import solSymbolLeftIcon from "../../assets/sol-symbol-left.svg";
import solSymbolRightIcon from "../../assets/sol-symbol-right.svg";
import useSound from "use-sound";

interface IProps {
    position?: string;
    currentUsersInfo?: CoinflipGame[] | undefined;
    isCompleted?: boolean;
    winningFace?: CoinflipFaceEnum;
    isMobile?: boolean;
    className?: string
}

const GameStatsBar = (props: IProps) => {
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
    const { position, currentUsersInfo, isCompleted, winningFace, isMobile, className } = props;
    const navigate = useNavigate();
    const userGroupIcon = position == "left" ? userGroupLeftIcon : userGroupRightIcon;
    const solSymbolIcon = position == "left" ? solSymbolLeftIcon : solSymbolRightIcon;

    const itemFilter = position == "left" ? CoinflipFaceEnum.Head : CoinflipFaceEnum.Tail;
    const filteredUsersInfo = (currentUsersInfo || []).filter((x) => x.bettingFace == itemFilter);

    return (
        <div className={`flex flex-col min-w-[180px] md:min-w-[274px] md:z-20 ${className ?? ''}`}>
            <div className="py-[3px] h-full">
                <div className={`
                h-full bg-[#11161B] rounded-[2px] 
                ${position == 'left' ? 'shadow-[0px_0px_5px_#00A5FE]' : 'shadow-[0px_0px_5px_#EDC967]'} 
                ${itemFilter == winningFace ? 'win-board' : ''}
            `}>
                    <div className={`flex justify-between items-center w-full h-[36px] px-[14px] md:px-[22px] ${position}-coinflip-header ${itemFilter == winningFace ? 'win-header' : ''}`}>
                        <div className="flex items-center gap-[9px]">
                            <img className={`${itemFilter == winningFace ? 'win-user-group' : ''}`} src={userGroupIcon} alt="user-group" />
                            <span className={`text-[10px] md:text-[16px] font-medium leading-[15px] md:leading-[23px] ${position == 'left' ? 'text-[#00A5FE]' : 'text-[#EDC967]'} ${itemFilter == winningFace ? 'win-numbers' : ''}`}>
                                {filteredUsersInfo.length}
                            </span>
                        </div>

                        <div className="flex items-center gap-[5px]">
                            <span className={`text-[10px] md:text-[16px] font-medium leading-[15px] md:leading-[23px] ${position == 'left' ? 'text-[#00A5FE]' : 'text-[#EDC967]'} ${itemFilter == winningFace ? 'win-numbers' : ''}`}>
                                {filteredUsersInfo.reduce(
                                    (total, obj) => total + obj.bettingAmount,
                                    0
                                ) / LAMPORTS_PER_SOL}
                            </span>
                            <img className={`${itemFilter == winningFace ? 'win-sol-symbol' : ''}`} src={solSymbolIcon} alt="sol-symbol" />
                        </div>
                    </div>

                    <div className="grow pl-[19px] md:pl-[27px] pr-[44px] md:pr-[52px]">
                        {
                            filteredUsersInfo && filteredUsersInfo.map((item, index) => {
                                return (
                                    <div key={index} className="flex justify-between items-center w-full mt-[10px]">
                                        <div
                                            className="flex items-center gap-[6px] cursor-pointer"
                                            onClick={() => navigate(`/user-stats/${item?.player?.publicKey}`)}
                                            onMouseEnter={() => playHover()}
                                        >
                                            <img src={userIcon} alt="user" />
                                            <span className={`text-[10px] md:text-[16px] font-medium leading-[15px] md:leading-[23px] ${isCompleted && item.bettingFace == winningFace ? 'text-green-500' : 'text-white'}`}>
                                                {isMobile
                                                    ? item.player.username
                                                        ? item.player.username.length > 4
                                                            ? String(item.player.username).substring(0, 4) + "..."
                                                            : item.player.username
                                                        : String(item.player.publicKey).substring(0, 2) +
                                                        "..." +
                                                        String(item.player.publicKey).substring(42)
                                                    : item.player.username
                                                        ? item.player.username.length > 12
                                                            ? String(item.player.username).substring(0, 12) + "..."
                                                            : item.player.username
                                                        : String(item.player.publicKey).substring(0, 4) +
                                                        "..." +
                                                        String(item.player.publicKey).substring(40)}
                                            </span>
                                        </div>

                                        <span className="text-[10px] md:text-[16px] font-medium leading-[15px] md:leading-[23px]">
                                            {item.bettingAmount ? (item.bettingAmount / LAMPORTS_PER_SOL) : 'Free'}
                                        </span>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>

        </div>
    );
};

export default GameStatsBar;