import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import { Connection, PublicKey, SystemProgram } from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";
import * as anchor from "@project-serum/anchor";
import { Program, AnchorProvider } from "@project-serum/anchor";
import { Wallet } from '@project-serum/anchor/dist/cjs/provider';
import shuffleIdl from "../../../idls/shuffle-idl.json";

const connection = new Connection(process.env.REACT_APP_RPC_URL!, "confirmed");
const programID = new PublicKey(shuffleIdl.metadata.address);

const ShuffleAdmin = () => {
    const wallet = useWallet();

    const [isShuffleInitialized, setIsShuffleInitialized] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);

    async function getProvider() {
        const provider = new AnchorProvider(connection, wallet as Wallet, { commitment: 'processed' });
        return provider;
    }

    const initialize = async () => {
        try {
            const provider = await getProvider();
            const program = new Program(shuffleIdl as anchor.Idl, programID, provider);
            const poolAccount = PublicKey.findProgramAddressSync(
                [Buffer.from('sol-shuffle-pool')],
                program.programId
            )[0];

            const poolAccountInfo = await connection.getAccountInfo(poolAccount);
            if (poolAccountInfo == null) {
                setIsShuffleInitialized(false);
            } else {
                setIsShuffleInitialized(true);
            }
        } catch (_) {
            setIsShuffleInitialized(false);
        }
    }

    const handleInitializeShuffle = async () => {
        if (isShuffleInitialized) {
            return toast.warn("Already initialized");
        }

        try {
            setLoading(true);
            const provider = await getProvider();
            const program = new Program(shuffleIdl as anchor.Idl, programID, provider);

            const poolAccount = PublicKey.findProgramAddressSync(
                [Buffer.from('sol-shuffle-pool')],
                program.programId
            )[0];

            const escrowVault = PublicKey.findProgramAddressSync(
                [
                    Buffer.from('escrow-vault'),
                    poolAccount.toBuffer()
                ],
                program.programId
            )[0];

            const [poolSigner, poolSignerBump] = PublicKey.findProgramAddressSync(
                [
                    poolAccount.toBuffer()
                ],
                program.programId
            );

            const txh = await program.methods
                .initPool(poolSignerBump)
                .accounts(
                    {
                        authority: provider.wallet.publicKey,
                        pool: poolAccount,
                        treasuryWallet: provider.wallet.publicKey,
                        escrowVault: escrowVault,
                        poolSigner: poolSigner,
                        systemProgram: SystemProgram.programId,
                    }
                )
                .rpc();

            console.log("txh: ", txh);
            toast.success("Successful")
            setLoading(false);
            setIsShuffleInitialized(true);
        } catch (e: any) {
            console.log("error: ", e);
            setLoading(false);
            toast.error("Failed to initialize shuffle");
        }
    }

    useEffect(() => {
        (async () => {
            await initialize()
        })()
    }, []);

    return (
        <div className="flex flex-col items-center w-full mt-[50px]">
            <h1 className="text-[20px] font-medium mt-5 mb-2">Initialize Sol Shuffle</h1>
            <button
                disabled={isLoading || isShuffleInitialized}
                className={`flex justify-center items-center gap-[10px] gradient-btn full-rounded py-[5px] w-[220px] ${(isLoading || isShuffleInitialized) ? 'opacity-60' : 'opacity-100'}`}
                onClick={() => handleInitializeShuffle()}
            >
                {isShuffleInitialized ? 'Initialized' : isLoading ? 'Initializing...' : 'Initialize'}
                {isLoading ? <ClipLoader size={15} color="#ffffff" /> : null}
            </button>
        </div>
    );
};

export default ShuffleAdmin;