import React, { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import axios from 'axios';
import { Button } from 'antd';
import { toast } from 'react-toastify';

enum NetworkStatusEnum {
    Good,
    Warning,
    Bad,
}

const NetworkStatusAdmin = () => {
    const [networkStatus, setNetworkStatus] = useState<NetworkStatusEnum>(NetworkStatusEnum.Good);

    const initialize = async () => {
        try {
            const networkStatusConfig = (await axios.get('/api/leaderboard/getNetworkStatus')).data;
            console.log({ networkStatusConfig });

            if (!networkStatusConfig) {
                setNetworkStatus(NetworkStatusEnum.Good);
            } else {
                setNetworkStatus(networkStatusConfig.networkStatus);
            }
        } catch { }
    }

    const handleSet = async () => {
        try {
            await axios.post('/api/leaderboard/manageNetworkStatus', { networkStatus });
            toast.success('Successful to manage network status');
        } catch (e) {
            console.log('e', e);
            toast.error('Failed to manage network status');
        }
    }

    useEffect(() => {
        (async () => {
            await initialize();
        })();
    }, []);

    return (
        <div className="flex flex-col items-center w-full">
            <h1 className="text-[20px] font-medium mt-5">Manage Network Status</h1>

            <div className='flex flex-col mt-[20px]'>
                <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group" style={{ color: 'white', fontSize: '18px' }}>Network status</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={networkStatus}
                        onChange={(e) => setNetworkStatus(Number(e.target.value))}
                    >
                        <FormControlLabel value={NetworkStatusEnum.Good} control={<Radio />} label="Good" />
                        <FormControlLabel value={NetworkStatusEnum.Warning} control={<Radio />} label="Warning" />
                        <FormControlLabel value={NetworkStatusEnum.Bad} control={<Radio />} label="Bad" />
                    </RadioGroup>
                </FormControl>

                <Button
                    type='primary'
                    className='mt-[20px]'
                    onClick={handleSet}
                >
                    Set
                </Button>
            </div>
        </div>
    );
};

export default NetworkStatusAdmin;