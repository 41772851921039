import React from "react";

const SolanaIconCopper = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="17" viewBox="0 0 23 17" fill="none">
            <g clipPath="url(#clip0_510_2071)">
                <path d="M6.15681 0.6875H21L16.8439 4.24985H2.00073L6.15681 0.6875Z" fill="url(#paint0_linear_510_2071)" stroke="#FF7A1A" />
                <path d="M16.8431 6.625H2L6.15608 10.1874H20.9992L16.8431 6.625Z" fill="url(#paint1_linear_510_2071)" stroke="#FF7A1A" />
                <path d="M6.15645 12.5605H20.9995L16.8435 16.1229H2.00037L6.15645 12.5605Z" fill="url(#paint2_linear_510_2071)" stroke="#FF7A1A" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_510_2071" x1="-0.55684" y1="2.54264" x2="21.6247" y2="4.11821" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF7A1A" />
                    <stop offset="1" stopColor="#11161B" />
                </linearGradient>
                <linearGradient id="paint1_linear_510_2071" x1="23.5568" y1="8.48014" x2="1.37527" y2="10.0557" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF7A1A" />
                    <stop offset="1" stopColor="#11161B" />
                </linearGradient>
                <linearGradient id="paint2_linear_510_2071" x1="-0.557204" y1="14.4157" x2="21.6243" y2="15.9913" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF7A1A" />
                    <stop offset="1" stopColor="#11161B" />
                </linearGradient>
                <clipPath id="clip0_510_2071">
                    <rect width="23" height="17" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default SolanaIconCopper;