import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { IMintSale } from "../../interfaces/MintSale";
import groupImg from '../../assets/store-group-img.webp';
import axios from "axios";

const Mint = () => {
    const [mintConfig, setMintConfig] = useState<IMintSale>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const initialize = async () => {
        try {
            const mintConfig = (await axios.get('/api/mint/mintConfig')).data;
            console.log({ mintConfig });
            setMintConfig(mintConfig);
        } catch (_) {
        }
    }

    const handleBuyNft = async () => {
        if (!mintConfig) {
            return toast.warn("Mint is not open!");
        }

        setIsProcessing(true);

        try {
            await axios.post('/api/mint/buyNft');
            await initialize();

            toast.success("Successful to mint NFT.");
        } catch (e: any) {
            console.log('e', e);
            if (e?.response?.data) {
                toast.error(e.response.data.message.toString(), { autoClose: 3500 });
            } else {
                toast.error("Failed to mint NFT.");
            }
        }

        setIsProcessing(false);
    }

    useEffect(() => {
        (async () => {
            await initialize();
        })();
    }, []);

    return (
        <div className="flex flex-col items-center gap-[40px] md:gap-[58px] w-full min-h-full bg-[url('/src/assets/mint-page-bg.webp')] bg-center bg-no-repeat bg-cover px-[10px]">
            {/* BiteClub Free 0.05 Wager Mint */}
            <div className="order-1 text-[16px] md:text-[36px] font-bold leading-[24px] md:leading-[53px] mt-[48px] md:mt-[60px]">BiteClub Free 0.05 Wager Mint </div>


            {/* Stpes */}
            <div className="order-3 md:order-2 flex flex-col justify-center gap-[16px] w-full">
                {/* Here is how it works: */}
                <div className="flex md:hidden justify-center items-center text-[14px] font-medium leading-[20px]">Here is how it works:</div>

                <div className="flex flex-wrap justify-center gap-[11px] md:gap-[40px] w-full">
                    {/* Step 1 */}
                    <div className="flex flex-col justify-center gap-[7.7px] md:gap-[16px] w-[178px] md:w-[370px] h-[77px] md:h-[160px] rounded-[5px] md:rounded-[10px] border-[0.5px] md:border border-solid border-[#808080] bg-[rgba(26,33,41,0.80)] p-[12px_24px] md:p-[24px_60px]">
                        <div className="text-[8.6px] md:text-[18px] text-[rgba(255,255,255,0.80)] text-center font-bold leading-[13px] md:leading-[27px] w-full">Step 1</div>
                        <div className="text-[10px] md:text-[16px] font-medium leading-[14px] md:leading-[23px] w-full">Click on “Mint” to receive the 0.05 Sol Free Wager</div>
                    </div>

                    {/* Step 2 */}
                    <div className="flex flex-col justify-center gap-[7.7px] md:gap-[16px] w-[178px] md:w-[370px] h-[77px] md:h-[160px] rounded-[5px] md:rounded-[10px] border-[0.5px] md:border border-solid border-[#808080] bg-[rgba(26,33,41,0.80)] p-[12px_20px] md:p-[24px_60px]">
                        <div className="text-[8.6px] md:text-[18px] text-[rgba(255,255,255,0.80)] text-center font-bold leading-[13px] md:leading-[27px] w-full">Step 2</div>
                        <div className="text-[10px] md:text-[16px] font-medium leading-[14px] md:leading-[23px] w-full">Visit our Coinflip Game under: https://play.biteclub.io/coinflip</div>
                    </div>

                    {/* Step 3 */}
                    <div className="flex flex-col justify-center gap-[7.7px] md:gap-[16px] w-[178px] md:w-[370px] h-[77px] md:h-[160px] rounded-[5px] md:rounded-[10px] border-[0.5px] md:border border-solid border-[#808080] bg-[rgba(26,33,41,0.80)] p-[12px_16px] md:p-[24px_60px]">
                        <div className="text-[8.6px] md:text-[18px] text-[rgba(255,255,255,0.80)] text-center font-bold leading-[13px] md:leading-[27px] w-full">Step 3</div>
                        <div className="text-[10px] md:text-[16px] font-medium leading-[14px] md:leading-[23px] w-full">Click on “Free”, approve burning your Free Wager. Good Luck!</div>
                    </div>
                </div>
            </div>

            {/* Mint  */}
            <div className="order-2 md:order-3 flex justify-center items-center gap-[12px] md:gap-[81px] w-full max-w-[367px] md:max-w-[822px] h-[181px] md:h-[407px] rounded-[10px] border border-solid border-[rgba(128,128,128,0.70)] bg-[rgba(26,33,41,0.70)]">
                <div className="flex justify-center items-center w-[129px] md:w-[270px] h-[118px] md:h-[247px]">
                    <img src={groupImg} alt="group-img" className="w-full h-full rounded-[20px] border border-solid border-[#808080]" />
                </div>
                <div className="flex flex-col justify-center items-center gap-[9px] md:gap-[19px] w-[149px] md:w-[311px]">
                    <span className="text-[19px] md:text-[50px] font-medium leading-[27px] md:leading-[57px]">{`${mintConfig?.totalBoughtCount || 0} / ${mintConfig?.saleSupply || 0}`}</span>
                    <span className="text-[11.5px] md:text-[24px] font-medium leading-[16px] md:leading-[34px]">PRICE: FREE</span>
                    <button
                        disabled={isLoading || isProcessing}
                        className={`flex justify-center items-center text-[15px] md:text-[32px] font-bold leading-normal w-full h-[33px] md:h-[69px] rounded-[6px] md:rounded-[6px] border border-solid border-[#00A5FE] bg-[#012847] shadow-[0px_0px_23px_-4.5px_#00A5FE] ${(isLoading || isProcessing) ? 'opacity-60' : 'opacity-100'}`}
                        onClick={handleBuyNft}
                    >
                        {!isProcessing ? 'MINT' : 'MINTING...'}
                        {
                            isProcessing ? (
                                <>
                                    <ClipLoader size={30} color="#ffffff" className="!hidden md:!inline-block" />
                                    <ClipLoader size={16} color="#ffffff" className="!inline-block md:!hidden" />
                                </>
                            ) : null
                        }
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Mint;