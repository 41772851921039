import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Skeleton, Tooltip, Switch, Input, Space, Button, Popover } from 'antd';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { toast } from 'react-toastify';
import Highlighter from 'react-highlight-words';
import axios from "axios";
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import "./index.scss";

let searchInput: any;

const UserStatsAdmin = () => {
    const [searchText, setSearchText] = useState<string>('');
    const [searchedColumn, setSearchedColumn] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>();
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownOpenChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: (text: any) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('')
    };

    const columns = [
        {
            title: 'UserPubkey',
            dataIndex: 'userPubkey',
            key: 'userPubkey',
            responsive: ["sm"],
            ...getColumnSearchProps('userPubkey'),
            render: (userPubkey: string) => (
                <Tooltip placement="topLeft" title={userPubkey}>
                    <Link
                        className="userpubkey"
                        to={`/user-stats/${userPubkey}`}
                    >
                        {userPubkey.slice(0, 4) + "..." + userPubkey.slice(-4)}
                    </Link>
                </Tooltip>
            ),
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            responsive: ["sm"],
            ...getColumnSearchProps('username'),
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            responsive: ["sm"],
        },
        {
            title: 'RegisteredAt',
            dataIndex: 'registeredAt',
            key: 'registeredAt',
            responsive: ["sm"],
        },
        {
            title: 'BannedStatus',
            dataIndex: 'bannedStatus',
            key: 'bannedStatus',
            align: 'center',
            width: 'auto',
            responsive: ["sm"],
            render: (bannedStatus: any, record: any) => {
                return (
                    <Switch
                        size="small"
                        checked={bannedStatus == true}
                        onChange={e => handleManageAccount(e, record.userId)}
                    />
                )
            },
        },
        {
            title: 'RemoveRefs',
            dataIndex: 'removeRefs',
            key: 'removeRefs',
            align: 'center',
            width: 'auto',
            responsive: ["sm"],
            render: (_: any, record: any) => {
                return (
                    <Popover
                        placement="topLeft"
                        title="Confirm to remove"
                        content={
                            <div className="flex items-center gap-[20px]">
                                <button
                                    className="underline"
                                    onClick={() => handleRemoveRefs(record.userId)}
                                >
                                    Yes
                                </button>
                                <button
                                    className="underline"
                                    onClick={() => setSelectedUserId(null)}
                                >
                                    No
                                </button>
                            </div>
                        }
                        trigger="click"
                        open={selectedUserId == record.userId}
                        onOpenChange={() => setSelectedUserId(record.userId)}
                    >
                        <Button
                            ghost
                            danger
                            className='flex justify-center items-center mx-auto'
                        >
                            <DeleteOutlined />
                        </Button>
                    </Popover>
                )
            },
        },
    ];

    const onChangeCheckBox = (e: CheckboxChangeEvent) => {
        setIsChecked(e.target.checked);
    };

    const handleManageAccount = async (nextStatus: boolean, userId: string) => {
        try {
            setIsProcessing(true);

            await axios.post(
                "/api/u/setBannedStatus",
                {
                    userId: userId,
                    nextStatus: nextStatus
                }
            );

            await initialize(false);
            toast.success("Succeeded");

            setIsProcessing(false);
        } catch (e: any) {
            console.log(e)
            setIsProcessing(false);
            toast.error("Failed. Try again.");
        }
    }

    const handleRemoveRefs = async (userId: string) => {
        try {
            setIsProcessing(true);

            await axios.post(
                "/api/u/removeRefs",
                {
                    userId: userId,
                }
            );

            await initialize(false);
            toast.success("Succeeded");

            setIsProcessing(false);
            setSelectedUserId(null);
        } catch (e: any) {
            console.log(e)
            setIsProcessing(false);
            toast.error("Failed. Try again.");
        }
    }

    const initialize = async (isInit: boolean) => {
        try {
            if (isInit)
                setLoading(true);

            const allUsers: any[] = (await axios.post("/api/u/getAllUsersForAdmin")).data;
            const tableData = await Promise.all(allUsers.map(async (item, index) => {
                let rowData = {
                    key: index,
                    userId: item?._id,
                    userPubkey: item?.publicKey,
                    username: item?.username ?? "-",
                    balance: (item?.balance / LAMPORTS_PER_SOL).toLocaleString(),
                    registeredAt: `${(new Date(item?.createdAt)).toLocaleString()}`,
                    bannedStatus: item?.bannedStatus
                }

                return rowData;
            }));

            setTableData(tableData);

            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await initialize(true)
        })()
    }, [])

    return (
        <div className="flex flex-col items-center w-full mt-[50px]">
            <h1 className="text-[20px] font-medium mb-1">User Stats</h1>

            <Skeleton loading={isLoading || !tableData} active>
                <div className="w-full max-w-[1200px] px-[60px]">
                    <div className='flex justify-start items-center w-full text-start mb-1'>
                        <Checkbox onChange={onChangeCheckBox} checked={isChecked}>
                            View only banned users
                        </Checkbox>
                    </div>

                    <Table
                        columns={columns as any}
                        dataSource={isChecked ? tableData?.filter(item => item.bannedStatus == true) : tableData}
                        scroll={{ x: 1000 }}
                        loading={isProcessing}
                        className="w-full"
                    />
                </div>
            </Skeleton>
        </div>
    );
};

export default UserStatsAdmin;