import React from "react";
import { Virtuoso } from "react-virtuoso";
import { useSelector } from "react-redux";
import TicketNumbers from "./TicketNumbers";
import { CombinedReducer } from "../../store";
import GlobalState from "../../interfaces/GlobalState";

interface IParams {
    showModal: boolean;
    boughtTickets: number[][];
    onMouseEnter: () => void;
    handleShowModal: (show: boolean) => void;
}

const MyTicketsModal = (params: IParams) => {
    const { showModal, boughtTickets, onMouseEnter, handleShowModal } = params;
    const globalState = useSelector<CombinedReducer, GlobalState>((state) => state.globalState);

    return (
        <div
            className="justify-center fixed top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] sm:bg-black/50 z-[1040] overflow-y-auto"
            style={{ display: showModal ? "flex" : "none" }}
        >
            <div className={`flex flex-col items-center w-full h-fit mx-[10px] mt-[90px] md:mt-[100px] mb-[65px] md:mb-[40px] xl:pl-[70px] ${globalState?.isLiveChatExpanded ? 'xl:pr-[336px]' : 'xl:pr-[70px]'}`}>
                <div className="relative flex flex-col items-center w-full max-w-[500px] h-[calc(100vh-155px)] md:h-[calc(100vh-140px)] p-[30px_10px] md:p-[40px_20px] bg-[#090C0E] rounded-[8px] border-[1.5px] border-solid border-[#000000] shadow-[0px_0px_8px_#00A5FE] z-[1050]">
                    {/* Close button */}
                    <button
                        className="sm:flex justify-center items-center absolute top-0 right-0 p-[10px] cursor-pointer bg-none border-none rounded-[6px] transform hover:scale-110 transition-all"
                        onClick={() => handleShowModal(false)}
                        onMouseEnter={() => onMouseEnter}
                    >
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 16L16 1M1 1L16 16" stroke="#BEBEBE" strokeWidth="2" />
                        </svg>
                    </button>

                    <div className="text-[17.5px] md:text-[20px] text-center font-extrabold leading-[25px] md:leading-[30px]">My Tickets</div>
                    <div
                        className="w-full max-w-[296px] md:max-w-[396px] max-h-[calc(100vh-194px)] md:max-h-[calc(100vh-199px)] border border-solid border-[#808080] bg-[#1A2129] mt-[22px] md:mt-[30px] py-[7px] md:py-[12px] overflow-y-auto thin-scroll-bar md:no-scroll-bar"
                        style={{ height: `${boughtTickets.length * 30 + 20 * 2}px` }}
                    >
                        <div className="flex flex-col items-center gap-[10px] h-full">
                            {
                                boughtTickets.length > 0 ? (
                                    <Virtuoso
                                        className="flex justify-center w-full h-full overflow-x-hidden thin-scroll-bar my-tickets-list"
                                        data={boughtTickets}
                                        itemContent={(index, item) => {
                                            return (
                                                <TicketNumbers
                                                    key={index}
                                                    numArray={item}
                                                    className="text-[11px] md:text-[14px] gap-[5.5px] md:gap-[8px] my-[8px]"
                                                    ballSize="w-[25px] md:w-[35px]"
                                                    plusFont="text-[18px] md:text-[24px] font-medium"
                                                />
                                            );
                                        }}
                                    />
                                ) : (
                                    <div className="text-[14px] font-medium">There is no tickets bought.</div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="overlay"></div>
        </div>
    );
};

export default MyTicketsModal;