import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import useSound from "use-sound";
import axios from "axios";
import ReactGA from "react-ga";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useWallet } from "@solana/wallet-adapter-react";
import { Connection, LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { getAssociatedTokenAddress, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import * as anchor from "@project-serum/anchor";
import { Program, AnchorProvider } from "@project-serum/anchor";
import { Wallet } from '@project-serum/anchor/dist/cjs/provider';
import { coinflipBetAmountList, coinflipItems, storeWagerItems } from "../../data";
import User from "../../interfaces/User";
import Coinflip from "../../interfaces/Coinflip";
import CoinflipGame from "../../interfaces/CoinflipGame";
import { CoinflipFaceEnum, CoinflipStatusEnum } from "../../enums";
import { CombinedReducer } from "../../store";
import { Sockets } from "../../reducers/sockets";
import { ICoinflipInitialState } from "../../reducers/coinflip";
import { delay, getBlockTime } from "../../utils";
import { wagerNfts } from "../../utils/config";
import GameStatsBar from "./GameStasBar";
import HeadLargeIcon from "../../assets/head-large.svg";
import TailLargeIcon from "../../assets/tail-large.svg";
import linkIcon from "../../assets/link.svg";
import OnchainNotifyContainerBadgeLg from "../svgs/OnchainNotifyContainerBadgeLg";
import OnchainNotifyContainerBadgeSm from "../svgs/OnchainNotifyContainerBadgeSm";
import { ReactComponent as CheckIcon } from '../../assets/check.svg';
import groupImg from '../../assets/store-group-img.webp';
import solLightGrayIcon from '../../assets/sol-symbol-light-gray.svg';
import wagerSystemIdl from '../../idls/wager_system-idl.json';


import "./index.scss";

let flag = false;
const GAME_FEE = 4;

function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const connection = new Connection(process.env.REACT_APP_RPC_URL!, "confirmed");
const programID = new PublicKey(wagerSystemIdl.metadata.address);

const CoinflipGames = () => {
    const wallet = useWallet();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const provider = new AnchorProvider(connection, wallet as Wallet, { commitment: 'processed' });
    const program = new Program(wagerSystemIdl as anchor.Idl, programID, provider);

    const [currentUsersInfo, setCurrentUsersInfo] = useState<CoinflipGame[]>();
    const [playCoinflip] = useSound("/sound/pokerchipsound.mp3", { volume: 1 });
    const [playNewBetSound] = useSound('/sound/pop.mp3', { volume: 1 });
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
    const prevUsersInfo = usePrevious(currentUsersInfo);
    const OnchainNotifyContainerRef = useRef<HTMLDivElement | null>(null);

    const [currentRound, setCurrentRound] = useState<Coinflip>();
    const [recentRounds, setRecentRounds] = useState<Coinflip[]>([]);
    const [bettingAmount, setBettingAmount] = useState<number>(coinflipBetAmountList[1]);
    const [currentCoinflipStatus, setCurrentCoinflipStatus] = useState<CoinflipStatusEnum>();
    const [endTime, setEndtime] = useState<number>(Date.now());
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [isHead, setIsHead] = useState<boolean>(false);
    const [winningFace, setWinningFace] = useState<CoinflipFaceEnum>(CoinflipFaceEnum.None);
    const [creatingGame, setCreatingGame] = useState(false);
    const [isFreeBetSelected, setIsFreeBetSelected] = useState<boolean>(false);
    const [hasWagerNft, setHasWagerNft] = useState<boolean>(false);
    const [wagerNftVaultBalances, setWagerNftVaultBalances] = useState<number[]>(new Array(5).fill(0));
    const [claimedFreeBet, setClaimedFreeBet] = useState<boolean>(false);
    const [betForFree, setBetForFree] = useState<boolean>(false);
    const [wagerNftModalOpen, setWagerNftModalOpen] = useState<boolean>(false);
    const [attentionModalOpen, setAttentionModalOpen] = useState<boolean>(false);
    const [selectedWagerIndex, setSelectedWagerIndex] = useState<number>();
    const [showOnchainNotifyMessage, setShowOnchainNotifyMessage] = useState<boolean>(true);
    const [checkedOnchainNavigation, setCheckedOnchainNavigation] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);


    const user = useSelector<CombinedReducer, User>((state) => state.user);
    const coinflip = useSelector<CombinedReducer, ICoinflipInitialState>((state) => state.coinflip);
    const sockets = useSelector<CombinedReducer, Sockets>((state) => state.sockets);
    const [animateFifthFlip, setAnimateFifthFlip] = useState(false);


    const validateBetting = async (bettingAmount: number) => {
        if (isDisabled) return;

        if (!user?.publicKey) {
            toast.warn("Please connect wallet!");
            return false;
        }

        if (
            user?.balance == 0 ||
            user?.balance / LAMPORTS_PER_SOL < bettingAmount * (1 + GAME_FEE / 100)
        ) {
            toast.warn("Insufficient balance");
            return false;
        }

        return true;
    };

    const onComplete = () => {
        setEndtime(Date.now());

        setTimeout(() => {
            playCoinflip();
        }, 500);
    };

    const handleBetGame = async (bettingFace: CoinflipFaceEnum) => {
        if (!isFreeBetSelected) {
            const isVaild = await validateBetting(bettingAmount);
            if (!isVaild) return;
        }

        if (!(currentRound && currentRound.status == CoinflipStatusEnum.Open)) return;

        try {
            setIsDisabled(true);
            setCreatingGame(true);

            if (isFreeBetSelected) {
                await axios.post("/api/coinflip-game/freebetting", {
                    roundId: currentRound._id,
                    bettingFace: bettingFace,
                });

                setBetForFree(true);
                setIsFreeBetSelected(false);
                setBettingAmount(coinflipBetAmountList[1]);
            } else {
                await axios.post("/api/coinflip-game/betting", {
                    roundId: currentRound._id,
                    bettingAmount: bettingAmount,
                    bettingFace: bettingFace,
                });
            }

            setCreatingGame(false);

            // google analytics event
            ReactGA.event({
                category: "COINFLIP",
                action: "BETTING",
                label: "DEGEN_GAME",
            });
        } catch (e: any) {
            setIsDisabled(false);
            setCreatingGame(false);
            toast.error(e.response.data.message.toString());
        }
    };

    const handleClaimFreeBet = async () => {
        try {
            if (!wallet.publicKey) {
                return toast.warn("Please connect wallet");
            }

            if (selectedWagerIndex == undefined) {
                return toast.warn("Invalid NFT index");
            }

            setIsProcessing(true);

            const poolAccount = PublicKey.findProgramAddressSync(
                [Buffer.from('wager-system-pool')],
                program.programId
            )[0];

            const userInfoAccount = PublicKey.findProgramAddressSync(
                [
                    Buffer.from('user-info'),
                    wallet.publicKey.toBuffer()
                ],
                program.programId
            )[0];

            const wagerNft = wagerNfts[selectedWagerIndex];

            const userWagerNftAccount = await getAssociatedTokenAddress(wagerNft, wallet.publicKey);

            const txh = await program.methods
                .burn()
                .accounts({
                    signer: wallet.publicKey,
                    pool: poolAccount,
                    userInfo: userInfoAccount,
                    wagerNftMint: wagerNft,
                    userWagerNftAccount: userWagerNftAccount,
                    rent: anchor.web3.SYSVAR_RENT_PUBKEY,
                    systemProgram: anchor.web3.SystemProgram.programId,
                    tokenProgram: TOKEN_PROGRAM_ID,
                })
                .rpc();

            console.log('txh', txh);

            await delay(5000);

            let isUserInfoAccountExist = false;
            while (!isUserInfoAccountExist) {
                try {
                    const userInfoAccountInfo = await connection.getAccountInfo(userInfoAccount);
                    if (userInfoAccountInfo) {
                        isUserInfoAccountExist = true;
                    } else {
                        await delay(1000);
                    }
                } catch {
                    await delay(1000);
                }
            }

            setClaimedFreeBet(true);
            toast.success('NFT burn complete');

            setTimeout(() => {
                setIsProcessing(false);
                setWagerNftModalOpen(false);
                setAttentionModalOpen(false);
            }, 1500);
        } catch (e: any) {
            console.log("error", e);
            setIsProcessing(false);
            if (e?.response?.data) {
                toast.error(e.response.data.message.toString());
            } else {
                toast.error("Failed to claim free bet");
            }
        }
    }

    const handleOnchainNavigation = () => {
        const showNotifyMessage = window.localStorage.getItem('showNotifyMessage');
        if (!showNotifyMessage || showNotifyMessage == 'true') {
            setShowOnchainNotifyMessage(true);
        } else {
            navigate('/On-Chain');
        }
    }

    const useChatOutsideAlerter = (ref: any) => {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowOnchainNotifyMessage(false);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useChatOutsideAlerter(OnchainNotifyContainerRef);

    useEffect(() => {
        if (prevUsersInfo && currentUsersInfo && prevUsersInfo.length < currentUsersInfo.length) {
            playNewBetSound();
        }
    }, [currentUsersInfo]);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    useEffect(() => {
        const storedValue = window.localStorage.getItem('showNotifyMessage');
        if (storedValue === 'false') {
            setShowOnchainNotifyMessage(false);
        }
    }, []);

    useEffect(() => {
        (async () => {
            const rsp = await axios.post('/api/u/getCurrentDateAndTime');
            const { currentDate } = rsp.data;

            if (user?.claimedAt && new Date(user?.claimedAt).toLocaleDateString() == new Date().toLocaleDateString()) {
                if (currentDate != 22 && currentDate != 25) {
                    setClaimedFreeBet(true);
                }
            }
        })()
    }, [user?.claimedAt]);

    // check if the wallet holds free bet nft
    useEffect(() => {
        (async () => {
            if (wallet?.publicKey && user?.publicKey) {
                try {
                    const userInfoAccount = PublicKey.findProgramAddressSync(
                        [
                            Buffer.from('user-info'),
                            wallet.publicKey.toBuffer()
                        ],
                        program.programId
                    )[0];

                    const userInfoAccountInfo = await connection.getAccountInfo(userInfoAccount);
                    if (userInfoAccountInfo) {
                        const [userInfo, blocktime] = await Promise.all([
                            program.account.userInfo.fetch(userInfoAccount),
                            getBlockTime()
                        ]);

                        const rsp = await axios.post('/api/u/getCurrentDateAndTime');
                        const { currentDate } = rsp.data;

                        if (
                            (userInfo
                                && (userInfo as any).availableWagerAmount.toNumber() > 0
                                && new Date((userInfo as any).burnedAt.toNumber() * 1000).toLocaleDateString() == new Date(blocktime * 1000).toLocaleDateString())
                            || (user?.claimedAt && new Date(user?.claimedAt).toLocaleDateString() == new Date().toLocaleDateString())
                        ) {
                            if (currentDate == 22 || currentDate == 25) {
                                if (new Date((userInfo as any).burnedAt.toNumber() * 1000).toLocaleDateString() != new Date(blocktime * 1000).toLocaleDateString()) {
                                    setClaimedFreeBet(false);
                                } else {
                                    setClaimedFreeBet(true);
                                }
                            } else {
                                setClaimedFreeBet(true);
                            }
                        } else {
                            setClaimedFreeBet(false);
                        }
                    }
                } catch { }

                try {
                    let hasWagerNft = false;
                    const wagerNftVaultBalances: number[] = [];

                    await Promise.all(wagerNfts.map(async (wagerNft, index) => {
                        try {
                            const wagerNftAccount = await getAssociatedTokenAddress(wagerNft, wallet.publicKey!);

                            const wagerNftBalance = (await connection.getTokenAccountBalance(wagerNftAccount)).value.uiAmount;
                            if ((wagerNftBalance || 0) > 0) {
                                hasWagerNft = true;
                            }
                            wagerNftVaultBalances[index] = wagerNftBalance || 0;
                        } catch {
                            wagerNftVaultBalances[index] = 0;
                        }
                    }));

                    setWagerNftVaultBalances(wagerNftVaultBalances);
                    setHasWagerNft(hasWagerNft);
                } catch {
                    setHasWagerNft(false);
                }
            }
        })();
    }, [wallet, user?.publicKey]);

    useEffect(() => {
        if (user?.publicKey) {
            if (user?.betAt && new Date(user?.betAt).toLocaleDateString() == new Date().toLocaleDateString()) {
                setBetForFree(true);
            } else {
                setBetForFree(false);
            }
        }
    }, [user?.publicKey]);

    useEffect(() => {
        const { usersGameInfo } = coinflip;
        const currentUserInfo = usersGameInfo.find(
            (x) => x.player.publicKey.toString() === wallet.publicKey?.toString()
        );

        if (currentUserInfo) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [wallet, coinflip?.usersGameInfo]);

    useEffect(() => {
        const getCoinflipGameInfo = async () => {
            try {
                const currentRound: Coinflip = (await axios.get("/api/coinflip-game/currentRound")).data;

                if (currentRound && currentRound.status == CoinflipStatusEnum.Open) {
                    const config = {
                        params: {
                            coinflipId: currentRound._id,
                        },
                    };
                    const currentUsersInfo: CoinflipGame[] = (
                        await axios.get("/api/coinflip-game/currentUsersInfo", config)
                    ).data;

                    dispatch({ type: "SET_CURRENT_USERS_INFO", payload: currentUsersInfo });
                }

                dispatch({ type: "SET_CURRENT_ROUND", payload: currentRound });
            } catch { }

            try {
                const recentRounds: Coinflip[] = (await axios.get("/api/coinflip-game/recentRounds")).data;
                dispatch({ type: "SET_RECENT_ROUNDS", payload: recentRounds.reverse() });
            } catch { }
        };

        getCoinflipGameInfo();
    }, []);

    useEffect(() => {
        if (!sockets.coinflip) return;
        if (!(sockets && sockets.user)) return;

        if (!flag) {
            sockets.coinflip.on("newCoinflip", (coinflip: Coinflip) => {
                setIsCompleted(false);
                setIsDisabled(false);
                setWinningFace(CoinflipFaceEnum.None);

                dispatch({ type: "SET_CURRENT_USERS_INFO", payload: [] });
                dispatch({ type: "SET_CURRENT_ROUND", payload: coinflip });
            });
            sockets.coinflip.on("newBetting", (newBetting: CoinflipGame) => {
                dispatch({ type: "UPDATE_CURRENT_USERS_INFO", payload: newBetting })
            });
            sockets.coinflip.on("updateCoinflip", (updatedCoinflip: Coinflip) => {
                dispatch({ type: "UPDATE_CURRENT_ROUND", payload: updatedCoinflip });
                if (updatedCoinflip.status == CoinflipStatusEnum.Pending) {
                    setIsCompleted(true);
                    setIsHead(updatedCoinflip.winningFace == CoinflipFaceEnum.Head);
                } else setWinningFace(updatedCoinflip.winningFace!);
            });
            sockets.coinflip.on("updateRecentRounds", (oldCoinflip: Coinflip) => {
                // Start the animation
                setAnimateFifthFlip(true);

                // Reset the animation after it finishes
                setTimeout(() => {
                    setAnimateFifthFlip(false);
                }, 500); // match the duration of the animation in CSS

                dispatch({ type: "UPDATE_RECENT_ROUNDS", payload: oldCoinflip });
            });
        }

        flag = true;

        return () => {
            if (sockets.coinflip) {
                sockets.coinflip.off("newCoinflip");
                sockets.coinflip.off("newBetting");
                sockets.coinflip.off("updateCoinflip");
                sockets.coinflip.off("updateRecentRounds");
            }
            flag = false;
        };
    }, [sockets?.coinflip]);

    useEffect(() => {
        const { recentRounds } = coinflip;
        setRecentRounds(recentRounds);
    }, [coinflip?.recentRounds]);

    useEffect(() => {
        const { usersGameInfo } = coinflip;
        setCurrentUsersInfo(usersGameInfo);
    }, [coinflip?.usersGameInfo]);

    useEffect(() => {
        const { currentRound } = coinflip;
        const bar: any = document.querySelector(".coinflip-countdown-bar");
        document.documentElement.style.setProperty("--initial", "10000");

        if (currentRound && currentRound?.remainingTime) {
            setCurrentCoinflipStatus(currentRound.status);
            setEndtime(Date.now() + currentRound.remainingTime);
            document.documentElement.style.setProperty(
                "--duration",
                (currentRound.remainingTime + 300).toString()
            );
            bar.classList.remove("coinflip-countdown-bar");
            const offsetWidth = bar.offsetWidth;
            bar.classList.add("coinflip-countdown-bar");
        } else {
            setCurrentCoinflipStatus(CoinflipStatusEnum.Close);
            setEndtime(Date.now());
            document.documentElement.style.setProperty("--duration", "0");
        }
        setCurrentRound(currentRound);
    }, [coinflip?.currentRound]);

    return (
        <div className="flex flex-col md:flex-row justify-center min-h-full h-fit px-[5px]">
            <GameStatsBar
                position="left"
                currentUsersInfo={currentUsersInfo}
                isCompleted={isCompleted}
                winningFace={winningFace}
                className="hidden md:block"
            />

            <div className="flex flex-col md:grow bg-[#090C0E]">
                <div className="coinflip-countdown-bar rounded-[5px] pt-[1px] pb-[1px] md:rounded-[10px] md:pt-[1px] md:pb-[2px] relative">
                    <div className="progress-bar h-[23px] rounded-[5px_9999px_9999px_5px] md:h-[33px] md:rounded-[10px_9999px_9999px_10px]"></div>
                    <div className="absolute w-full h-full top-0 left-0 flex justify-center items-center gap-[5px]">
                        <span>{'Starting in: '}</span>
                        {endTime > Date.now() ? (
                            <div className="w-[20px]">
                                <Countdown
                                    date={endTime}
                                    intervalDelay={0}
                                    precision={1}
                                    renderer={(time: any) => (
                                        <h3>
                                            {`${time.seconds.toString().padStart(2, "0")}.${time.milliseconds / 100}`}
                                        </h3>
                                    )}
                                    onComplete={() => onComplete()}
                                    className="w-50px"
                                />
                            </div>
                        ) : (
                            <span>{"00.0"}</span>
                        )}
                    </div>
                </div>

                <div className="flex justify-center md:items-center grow mt-[15px] md:mt-0 relative">
                    {/* Link button */}
                    <div className="absolute top-[-9px] md:top-[9.5px] right-[16px] md:right-[34.5px]">
                        <div className="flex justify-center items-center w-[40px] h-[40px] bg-[#222c36] md:bg-inherit border md:border-none border-solid border-[#808080] rounded-full relative">
                            <button
                                className="flex justify-center items-center mt-[1px] ml-[1px]"
                                onClick={() => {
                                    setShowOnchainNotifyMessage(false);
                                    handleOnchainNavigation();
                                }}
                                onMouseEnter={() => playHover()}
                            >
                                <img src={linkIcon} className="w-[25px] h-[25px] hover:scale-110 duration-150" alt="link-icon" />
                            </button>

                            {
                                showOnchainNotifyMessage && (
                                    <div ref={OnchainNotifyContainerRef} className="absolute bottom-[-70px] right-[-3px] z-10 w-[134px] md:w-[198px] h-[69px] md:h-[73px]">
                                        <div className="relative w-full h-full">
                                            <OnchainNotifyContainerBadgeLg className="w-full hidden md:block" />
                                            <OnchainNotifyContainerBadgeSm className="w-full block md:hidden" />

                                            <div className="absolute w-full top-0 left-0 pt-[22px] md:pt-[20px]">
                                                <p className="w-full text-[12px] md:text-[20px] text-left md:text-center font-bold px-[13px]">Play on-chain</p>

                                                <div className="flex justify-between items-center w-full pl-[13px] pr-[6px] mt-[2px] md:mt-0">
                                                    <div className="flex justify-center items-center gap-[10px]">
                                                        <div
                                                            className="relative w-[10px] h-[10px] bg-[#090C0E] border border-solid border-[#808080] cursor-pointer transition-all duration-300 ease-in-out hover:bg-[#222c36]"
                                                            onClick={() => setCheckedOnchainNavigation(!checkedOnchainNavigation)}
                                                            onMouseEnter={() => playHover()}
                                                        >
                                                            {
                                                                checkedOnchainNavigation && (
                                                                    <div className="w-full h-full absolute top-0 left-0">
                                                                        <CheckIcon className="w-full h-full" />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        <span className="text-[9px] md:text-[14px] text-[#808080] font-medium leading-[1]">Don’t show again</span>
                                                    </div>

                                                    <button
                                                        className="flex justify-center items-center text-[10px] font-medium leading-[1] w-[25px] h-[18px] bg-[#222C36] border border-solid border-[#808080] rounded-[2px] transition-all duration-300 ease-in-out hover:bg-[green]"
                                                        onClick={() => {
                                                            if (checkedOnchainNavigation) {
                                                                window.localStorage.setItem('showNotifyMessage', 'false');
                                                            }
                                                            navigate('/On-Chain');
                                                        }}
                                                        onMouseEnter={() => playHover()}
                                                    >
                                                        OK
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>

                    <div>
                        {/* Recent flips */}
                        <div className="flex justify-center items-center gap-[10px]">
                            <div className="flex justify-center items-center gap-[5px] md:gap-[10px]">
                                {
                                    recentRounds.map((item, index) => {
                                        const isLastFlip = index === recentRounds.length - 1;
                                        const isFifthFlip = index === 0;
                                        const shouldRoll = index > 0 && animateFifthFlip;
                                        const shouldAnimateFirst = isFifthFlip && animateFifthFlip;
                                        const shouldAnimateLast = isLastFlip;
                                        return (
                                            <img
                                                key={index}
                                                src={coinflipItems[item?.winningFace!].icon}
                                                alt={coinflipItems[item?.winningFace!].title}
                                                className={`w-[25px] md:w-[40px] aspect-square ${shouldAnimateFirst ? 'first-coin' : ''} ${shouldAnimateLast ? 'last-flip-glow-and-fade' : ''} ${shouldRoll ? 'roll-ccw' : ''} ${animateFifthFlip ? 'fifth-flip' : ''}`}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </div>

                        {/* Coinflip */}
                        <div className="flex justify-center w-[140px] md:w-[250px] aspect-square mt-[57px] md:mt-[90px] mx-auto">
                            {
                                recentRounds.length > 0 ? (
                                    recentRounds[recentRounds.length - 1].winningFace == CoinflipFaceEnum.Head ? (
                                        <img src={HeadLargeIcon} alt="coin" className={`w-full rotate-180 ${!isCompleted ? 'block' : 'hidden'}`} />
                                    ) : (
                                        <img src={TailLargeIcon} alt="coin" className={`w-full ${!isCompleted ? 'block' : 'hidden'}`} />
                                    )
                                ) : (
                                    <img src={HeadLargeIcon} alt="coin" className={`w-full rotate-180 ${!isCompleted ? 'block' : 'hidden'}`} />
                                )
                            }

                            <div
                                id="coin"
                                className={`flip-status ${isHead ? "heads" : "tails"} ${isCompleted ? 'block' : 'hidden'}`}
                            >
                                <div className="side-a"></div>
                                <div className="side-b"></div>
                            </div>
                        </div>

                        {/* Bet amount list */}
                        <div className="relative w-full flex flex-wrap justify-center items-center gap-[10px] md:gap-[20px] mt-[57px] md:mt-[100px]">
                            {
                                (hasWagerNft || claimedFreeBet) && (
                                    <button
                                        disabled={isDisabled || betForFree}
                                        className={`free-bet-buttons
                                    w-[51px] h-[31px] md:w-[100px] md:h-[60px] text-[10px] md:text-[20px] font-medium leading-[17px] md:leading-[32px] rounded-[2px]
                                    ${isFreeBetSelected ? 'active border-2 border-solid border-transparent' : 'border-2 border-solid border-[#808080]/50 text-[#808080]'} 
                                    ${(currentCoinflipStatus !== CoinflipStatusEnum.Open || isDisabled || betForFree) ? 'opacity-30' : 'opacity-100'}
                                `}
                                        onClick={() => {
                                            if (claimedFreeBet) {
                                                setBettingAmount(0);
                                                setIsFreeBetSelected(true);
                                            } else {
                                                setWagerNftModalOpen(true);
                                            }
                                        }}
                                        onMouseEnter={() => playHover()}
                                    >
                                        {
                                            !betForFree && (
                                                <>
                                                    <span className="special-hover free-bet"></span>
                                                    <span className="special-hover free-bet"></span>
                                                    <span className="special-hover free-bet"></span>
                                                    <span className="special-hover free-bet"></span>
                                                </>
                                            )
                                        }

                                        {
                                            claimedFreeBet ? (
                                                'FREE'
                                            ) : (
                                                'FREE'
                                            )
                                        }
                                    </button>
                                )
                            }

                            {
                                coinflipBetAmountList.map((item, index) => {
                                    return (

                                        <button
                                            disabled={isDisabled}
                                            key={index}
                                            className={`hover-bet-buttons
                                                w-[51px] h-[31px] md:w-[100px] md:h-[60px] text-[12px] md:text-[22px] font-medium leading-[17px] md:leading-[32px] rounded-[2px]
                                                ${bettingAmount == item ? 'active border-2 border-solid border-transparent' : 'border-2 border-solid border-[#808080]/50 text-[#808080]'} 
                                                ${(currentCoinflipStatus !== CoinflipStatusEnum.Open || isDisabled) ? 'opacity-30' : 'opacity-100'}
                                            `}
                                            onClick={() => {
                                                setIsFreeBetSelected(false);
                                                setBettingAmount(item);
                                            }}
                                            onMouseEnter={() => playHover()}
                                        >
                                            <span className="special-hover"></span>
                                            <span className="special-hover"></span><span className="special-hover"></span><span className="special-hover"></span>

                                            {item}
                                        </button>
                                    );
                                })
                            }
                        </div>


                        {/* Control buttons */}
                        <div className="w-full flex flex-wrap justify-center items-center gap-[10px] md:gap-[20px] mt-[25px] md:mt-[50px]">
                            <button
                                disabled={isDisabled}
                                className={`
                                    flex justify-center items-center gap-2 head-btn heads-tails-button w-[170px] h-[40px] md:w-[340px] md:h-[70px] text-[12px] md:text-[24px] font-extrabold leading-[18px] md:leading-[36px] 
                                    ${(currentCoinflipStatus !== CoinflipStatusEnum.Open || isDisabled) ? 'opacity-30' : 'opacity-100'}
                                `}
                                onClick={() => handleBetGame(CoinflipFaceEnum.Head)}
                                onMouseEnter={() => playHover()}
                            >
                                HEADS
                                {creatingGame ? <ClipLoader size={24} color="#ffffff" /> : null}
                            </button>
                            <button
                                disabled={isDisabled}
                                className={`
                                    flex justify-center items-center gap-2 tail-btn heads-tails-button w-[170px] h-[40px] md:w-[340px] md:h-[70px] text-[12px] md:text-[24px] font-extrabold leading-[18px] md:leading-[36px] 
                                    ${(currentCoinflipStatus !== CoinflipStatusEnum.Open || isDisabled) ? 'opacity-30' : 'opacity-100'}
                                `}
                                onClick={() => handleBetGame(CoinflipFaceEnum.Tail)}
                                onMouseEnter={() => playHover()}
                            >
                                TAILS
                                {creatingGame ? <ClipLoader size={24} color="#ffffff" /> : null}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile game stats */}
            <div className="grow w-full flex justify-center gap-[10px] bg-[#090C0E] pt-[24px] pb-[60px] md:hidden">
                <GameStatsBar
                    position="left"
                    currentUsersInfo={currentUsersInfo}
                    isCompleted={isCompleted}
                    winningFace={winningFace}
                />
                <GameStatsBar
                    position="right"
                    currentUsersInfo={currentUsersInfo}
                    isCompleted={isCompleted}
                    winningFace={winningFace}
                />
            </div>

            <GameStatsBar
                position="right"
                currentUsersInfo={currentUsersInfo}
                isCompleted={isCompleted}
                winningFace={winningFace}
                className="hidden md:block"
            />

            {/* Wager NFTs modal */}
            <div
                className="fixed top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] bg-black/50 z-[1050] overflow-y-auto hidden justify-center mobile-height"
                style={{ display: wagerNftModalOpen ? "flex" : "none" }}
            >
                <div className="flex justify-center items-start md:items-center w-full md:h-full py-[7rem] md:py-[5rem]">
                    <div className="flex flex-col items-center w-full max-w-[680px] mx-[5px] md:mx-[2rem] rounded-sm md:rounded-lg font-semibold p-[25px_18px] md:p-[36px_30px_30px] flex-1 text-center bg-[#090C0E] shadow-[0px_0px_7px_#00A5FE] relative z-[1050]">
                        <button
                            className="sm:flex justify-center items-center absolute top-0 right-0 p-[10px] cursor-pointer bg-none border-none rounded-[6px] transform hover:scale-110 transition-all"
                            onClick={() => setWagerNftModalOpen(false)}
                            onMouseEnter={() => playHover()}
                        >
                            <svg width="14" height="14" className="fill-white transition-[fill_200ms_ease_0s]">
                                <path d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z"></path>
                            </svg>
                        </button>

                        <div className="w-full relative">
                            <h1 className="text-[16px] md:text-[22px] text-[#EDC967] text-center font-normal leading-[100%] md:leading-[31px] w-full">Burn your wager NFT!</h1>
                        </div>

                        {/* Card group */}
                        <div className='grid grid-cols-3 items-center w-full max-w-[1188px] gap-[12px] mt-[10px]'>
                            {
                                storeWagerItems.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className='flex flex-col items-center w-[110px] sm:w-[160px] h-[157px] sm:h-[215px] bg-[#010919] border-[1.1px] sm:border-[1.75px] border-solid border-[#808080] rounded-[5.5px] sm:rounded-[8.75px] mx-auto'
                                        >
                                            <div className='flex justify-center items-center w-full h-[132px]'>
                                                <img src={groupImg} className='w-full h-full' alt='group-img' />
                                            </div>

                                            <div className='grow flex flex-col items-center'>
                                                <div className='flex justify-center items-center gap-[1px] sm:gap-[2px] w-full'>
                                                    <div className='flex justify-center items-center gap-[1.8px] sm:gap-[2.75px]'>
                                                        <span className='text-[14px] sm:text-[20px] font-bold leading-[100%]'>{item.wagerAmount}</span>
                                                        <img src={solLightGrayIcon} className='w-[11.5px] sm:w-[16px]' alt='sol-symbol' />
                                                    </div>
                                                    <span className='text-[11px] sm:text-[16px] font-bold leading-normal'>Wager</span>
                                                </div>

                                                <div className='flex justify-center items-center w-full text-[7.5px] sm:text-[14px] text-[#808080] font-medium leading-normal'>
                                                    {`Balance: ${wagerNftVaultBalances[index]}`}
                                                </div>
                                            </div>

                                            <div className='flex justify-center items-center w-full px-[12px] md:px-[20px] mb-[4.5px] sm:mb-[7px]'>
                                                <button
                                                    disabled={!wagerNftVaultBalances[index] || wagerNftVaultBalances[index] == 0}
                                                    className={`flex justify-center items-center gap-[2px] text-[10px] md:text-[20px] sm:gap-[3px] w-full h-[18px] sm:h-[30px] bg-[rgba(0,165,254,0.20)] border border-solid border-[#00A5FE] rounded-[5px] shadow-[0px_0px_20px_-4px_#00A5FE] transition-all duration-300 ease-in-out ${!wagerNftVaultBalances[index] || wagerNftVaultBalances[index] == 0 ? 'opacity-30' : 'hover:bg-[rgba(0,165,254,0.60)] hover:border-[#336699] hover:shadow-[0px_0px_18px_4px_#336699]'}`}
                                                    onClick={() => {
                                                        setSelectedWagerIndex(index);
                                                        setAttentionModalOpen(true);
                                                    }}
                                                    onMouseEnter={() => playHover()}
                                                >
                                                    Burn NFT
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="overlay"></div>
            </div>

            {/* Attention modal */}
            <div
                className="fixed top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] bg-black/50 z-[1055] overflow-y-auto hidden justify-center mobile-height"
                style={{ display: attentionModalOpen ? "flex" : "none" }}
            >
                <div className="flex justify-center items-start md:items-center w-full md:h-full py-[7rem] md:py-[5rem]">
                    <div className="flex flex-col items-center max-w-[500px] h-[220px] w-full md:h-[220px] mx-[2rem] rounded-sm md:rounded-lg font-semibold p-[25px_18px] md:p-[36px_30px_30px] flex-1 text-center bg-[#090C0E] shadow-[0px_0px_7px_#00A5FE] relative z-[1055]">                            <button
                        className="sm:flex justify-center items-center absolute top-0 right-0 p-[10px] cursor-pointer bg-none border-none rounded-[6px] transform hover:scale-110 transition-all"
                        onClick={() => setAttentionModalOpen(false)}
                        onMouseEnter={() => playHover()}
                    >
                        <svg width="14" height="14" className="fill-white transition-[fill_200ms_ease_0s]">
                            <path d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z"></path>
                        </svg>
                    </button>

                        <div className="w-full relative">
                            <h1 className="text-[16px] md:text-[22px] text-[#EDC967] text-center font-normal leading-[100%] md:leading-[31px] w-full">Attention</h1>
                        </div>

                        <div className="grow w-full mt-[32px] md:mt-[11px] overflow-x-hidden">
                            <h5 className="text-[16px] md:text-[18px] text-center font-normal leading-[22px] md:leading-[31px]">
                                {
                                    selectedWagerIndex != undefined && (
                                        `Burn your wager NFT for a ${storeWagerItems[selectedWagerIndex].wagerAmount} Wager!`
                                    )
                                }
                            </h5>
                        </div>

                        <div className="flex flex-row justify-center items-center gap-[12px] mt-0 md:mt-[35px] w-full">
                            {/* Accept Button */}
                            <button
                                disabled={isProcessing}
                                className="flex justify-center items-center gap-[8px] border-2 border-solid border-[#46FF78] rounded-full bg-[#2E4F37] min-w-[100px] md:min-w-[120px] h-[35px] md:h-[40px] md:px-[26px] text-[16px] md:text-[20px] transition duration-300 hover:transform hover:scale-110 opacity-100"
                                onClick={() => handleClaimFreeBet()}
                                onMouseEnter={() => playHover()}
                            >
                                {
                                    <div className="flex justify-center items-center gap-[10px] px-[10px]">
                                        {
                                            isProcessing ? (
                                                <>
                                                    <span>Processing</span>{" "}
                                                    <ClipLoader size={18} color="#ffffff" />
                                                </>
                                            ) : (
                                                "Burn NFT"
                                            )
                                        }
                                    </div>
                                }
                            </button>
                        </div>
                    </div>
                </div>
                <div className="overlay"></div>
            </div>
        </div>
    );
};

export default CoinflipGames;