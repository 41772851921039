import Game from "../interfaces/Game"

enum RpsGamesActions {
    LOAD_RPS_GAMES = 'LOAD_RPS_GAMES',
    ADD_RPS_GAME = 'ADD_RPS_GAME',
    UPDATE_RPS_GAME = 'UPDATE_RPS_GAME'
}

interface LoadRpsGamesAction {
    type: RpsGamesActions.LOAD_RPS_GAMES,
    payload: Game[]
}

interface AddRpsGameAction {
    type: RpsGamesActions.ADD_RPS_GAME,
    payload: Game
}

interface UpdateRpsGameAction {
    type: RpsGamesActions.UPDATE_RPS_GAME,
    payload: Game
}

type RpsGameAction = LoadRpsGamesAction | AddRpsGameAction | UpdateRpsGameAction

export default (state: Game[] = [], action: RpsGameAction): Game[] | Game => {

    switch (action.type) {
        case RpsGamesActions.LOAD_RPS_GAMES: {
            return action.payload
        }
        case RpsGamesActions.ADD_RPS_GAME: {
            if (state.find(game => game._id === action.payload._id)) return state

            return [...state, action.payload]
        }
        case RpsGamesActions.UPDATE_RPS_GAME: {
            return state.map(game => {
                if (game._id === action.payload._id) {
                    if (action.payload.status === 'ended') {
                        action.payload.status = 'countdown'
                        action.payload.countDownEnd = Date.now() + 3000
                    }
                    return action.payload
                }
                return game
            })
        }
        default:
            return state
    }
}