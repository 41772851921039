import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Countdown from "react-countdown";
import { toast } from "react-toastify";
import ReactGA from "react-ga";
import useSound from "use-sound";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import axios from "axios";
import { CombinedReducer } from "../../store";
import { delay, displayName } from "../../utils";
import User from "../../interfaces/User";
import Game from "../../interfaces/Game";
import questionDiceIcon from "../../assets/question_dice.svg";
import questionMarkIcon from "../../assets/question_mark.svg";
import closeIcon from "../../assets/close.svg";
// @ts-ignore
import Fade from 'react-reveal/Fade';

import "animate.css";

import "./index.scss";

interface IProps {
    game: Game;
}

const GameBox = (props: IProps) => {
    const { game } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [move, setMove] = useState(1);
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
    const user = useSelector<CombinedReducer, User>((state) => state.user);
    const [playJoin] = useSound("/sound/join.mp3", { volume: 0 });
    const [playWin] = useSound("/sound/win.mp3", { volume: 0.5 });
    const [playLoss] = useSound("/sound/loss.mp3", { volume: 0.2 });
    const [playCountDown] = useSound("/sound/countdown.mp3", { volume: 0.1 });
    const [finalBg, setFinalBg] = useState<string>("");
    const [show, setShow] = useState<boolean>(true);

    const isCreator = game?.creator?._id == user?._id;

    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        if (game?.status == "flipping") {

            if (game?.creator?._id == user?._id || game?.opponent?._id == user?._id) {
                let finalBg = 'draw-bg';
                if (game?.winner) {
                    if (game?.winner?._id == user?._id) {
                        finalBg = 'win-bg';
                    } else {
                        finalBg = 'lose-bg';
                    }
                }

                setFinalBg(finalBg);
            }

            setTimeout(() => {
                changeGameStatus('revealed');
            }, 3500)
        }
    }, [game?.status]);

    useEffect(() => {
        applySound(game);
    }, [game.status]);

    useEffect(() => {
        if (!game) return;

        applySound(game);
    }, [game]);

    const applySound = (game: Game) => {
        if (
            !(user?._id && [game.creator._id, game.opponent?._id].includes(user._id))
        )
            return;

        if (game.status === "ended") {
            playJoin();
        } else if (game.status === "revealed") {
            if (!game.winner) return;

            if (game.winner?._id === user._id) {
                playWin();
            } else {
                playLoss();
            }
        } else if (game.status === "countdown") {
            playCountDown();
        }
    };

    const changeGameStatus = (status: string) => {
        if (!game) return;
        if (game.status === status) return;

        game.status = status;
        dispatch({ type: "UPDATE_DICE_GAME", payload: game });

        if (status === "revealed") {
            if (user !== null) {
                if (game.winner && game.winner?._id === user._id) {
                    dispatch({ type: "UPDATE_USER_BALANCE", payload: game.amount * 2 });
                } else if (
                    !game.winner &&
                    [game.creator._id, game.opponent?._id].includes(user._id)
                ) {
                    dispatch({
                        type: "UPDATE_USER_BALANCE",
                        payload: game.amount,
                    });
                }
            }

            if (game?.creator?._id !== user?._id && game?.opponent?._id !== user?._id) {
                setShow(false);
                game.status = "removed";
                dispatch({ type: "UPDATE_DICE_GAME", payload: game });
                setShow(true);
            } else {
                setTimeout(() => {
                    setShow(false);
                    setTimeout(() => {
                        game.status = "removed";
                        dispatch({ type: "UPDATE_DICE_GAME", payload: game });
                        setShow(true);
                    }, 500);
                }, 3000);
            }
        }
    };

    const joinGame = async () => {
        if (!(user && user?.publicKey && game)) return;

        if (user._id === game.creator._id)
            return toast.error("You can not join your game");
        if (user.balance < game.amount)
            return toast.error("Balance needs to be higher than the game bet");

        try {
            await axios.post("/api/dice-game/join", {
                gameId: game._id,
                move,
            });

            // google analytics event
            ReactGA.event({
                category: "DICE",
                action: "JOIN_GAME",
                label: "DEGEN_GAME"
            });

            toast.success("Joined the game");

        } catch (e: any) {
            toast.error(e.response.data.message.toString());
        }
    };

    const cancelGame = async () => {
        if (!(user && user?.publicKey && game)) return;

        if (game.creator._id !== user._id)
            return toast.error("You can not cancel another person`s game");

        try {
            setShow(false);
            await delay(300);
            await axios.post("/api/dice-game/cancel", {
                gameId: game._id,
            });

            setShow(true);

            // google analytics event
            ReactGA.event({
                category: "DICE",
                action: "CANCEL_GAME",
                label: "DEGEN_GAME"
            });

            toast.success("Game has been cancelled");
        } catch (e: any) {
            toast.error(e.response.data.message.toString());
        }
    };

    return (
        <Fade duration={1200} opposite when={!isAnimating ? show : false} onReveal={() => setIsAnimating(false)}>
            <div className={`
                w-[362px] md:w-[315px] h-[110px] md:h-[175px] bg-[#000000] shadow-[0px_0px_7.1746px_#00A5FE] rounded-[10px] text-center relative flex flex-col justify-center gap-0 md:gap-2 overflow-hidden 
                ${game?.status == 'revealed' && finalBg ? finalBg : ''}
            `}>
                {
                    game && (
                        <>
                            {
                                isCreator && game.status == "active" && (
                                    <button
                                        className="absolute top-[12px] right-[15px]"
                                        onClick={cancelGame}
                                        onMouseEnter={() => playHover()}
                                    >
                                        <img src={closeIcon} alt="close-icon" className="close-icon" />
                                    </button>
                                )
                            }

                            <div className="flex justify-center items-center w-full">
                                {/* Left leaf */}
                                {
                                    game?.creatorMove && (
                                        <div className={`text-center ${game.status === "flipping" ? 'block' : 'hidden'}`}>
                                            <div className="flex justify-center items-center">
                                                <div className="dice">
                                                    <ol className={`die-list dice${game?.creatorMove}-roll`} data-roll={game?.creatorMove} id="die-1">
                                                        <li className="die-item" data-side="1">
                                                            <span className="dot"></span>
                                                        </li>
                                                        <li className="die-item" data-side="2">
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                        </li>
                                                        <li className="die-item" data-side="3">
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                        </li>
                                                        <li className="die-item" data-side="4">
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                        </li>
                                                        <li className="die-item" data-side="5">
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                        </li>
                                                        <li className="die-item" data-side="6">
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                <div className={`text-center w-[85px] h-[65px] ${game.status !== "flipping" && game.status !== "revealed" ? 'block' : 'hidden'}`}>
                                    <div className="flex h-[55px] md:h-[60px]">
                                        <img src={questionDiceIcon} alt={'question-dice'} className="w-full" />
                                    </div>
                                    <div
                                        className="font-normal cursor-pointer mt-1"
                                        onClick={() => navigate(`/user-stats/${game?.creator?.publicKey}`)}
                                        onMouseEnter={() => playHover()}
                                    >
                                        {displayName(game.creator)}
                                    </div>
                                </div>

                                {/* Middle */}
                                <div className="text-[14px] md:text-[22px] font-medium md:font-extrabold w-[156px] md:w-[110px] md:translate-y-[-12px]">
                                    <div className="flex items-center justify-center h-[45px] md:h-[65px]">
                                        {
                                            game.status === "countdown" ? (
                                                <Countdown
                                                    date={game.countDownEnd}
                                                    onComplete={() => changeGameStatus("flipping")}
                                                    renderer={(time) => <h3>{time.seconds}</h3>}
                                                />
                                            ) : game.status === "revealed" ? (
                                                ""
                                            ) : (
                                                "VS"
                                            )
                                        }
                                    </div>
                                    {
                                        game?.status !== "revealed" && (
                                            <div className="text-[18px] font-extrabold md:hidden">
                                                {`${(game?.amount / LAMPORTS_PER_SOL).toLocaleString()} SOL`}
                                            </div>
                                        )
                                    }
                                </div>

                                {/* Right Leaf */}
                                {
                                    !game.opponent ? (
                                        !user || game.creator._id === user._id ? (
                                            <div className="text-center w-[85px] h-[55px]">
                                                <div className="flex items-center h-[45px] md:h-[55px]">
                                                    <img
                                                        src={questionMarkIcon}
                                                        alt={'question-mark-icon'}
                                                        className="w-full h-[30px] md:h-full"
                                                    />
                                                </div>
                                                <div className="font-normal mt-1">waiting...</div>
                                            </div>
                                        ) : (
                                            <button
                                                className="w-[70px] md:w-[90px] h-[27px] md:h-[35px] text-[14px] md:text-[18px] font-extrabold gradient-btn full-rounded translate-y-[0px] md:translate-y-[-12px]"
                                                    onClick={() => joinGame()}
                                                    onMouseEnter={() => playHover()}
                                            >
                                                Join
                                            </button>
                                        )
                                    ) : (
                                        <>
                                            {
                                                game?.opponentMove && (
                                                    <div className={`text-center w-[85px] ${game.status === "flipping" ? 'block' : 'hidden'}`}>
                                                        <div className="flex justify-center items-center">
                                                            <div className="dice">
                                                                <ol className={`die-list dice${game?.opponentMove}-roll`} data-roll={game?.opponentMove} id="die-1">
                                                                    <li className="die-item" data-side="1">
                                                                        <span className="dot"></span>
                                                                    </li>
                                                                    <li className="die-item" data-side="2">
                                                                        <span className="dot"></span>
                                                                        <span className="dot"></span>
                                                                    </li>
                                                                    <li className="die-item" data-side="3">
                                                                        <span className="dot"></span>
                                                                        <span className="dot"></span>
                                                                        <span className="dot"></span>
                                                                    </li>
                                                                    <li className="die-item" data-side="4">
                                                                        <span className="dot"></span>
                                                                        <span className="dot"></span>
                                                                        <span className="dot"></span>
                                                                        <span className="dot"></span>
                                                                    </li>
                                                                    <li className="die-item" data-side="5">
                                                                        <span className="dot"></span>
                                                                        <span className="dot"></span>
                                                                        <span className="dot"></span>
                                                                        <span className="dot"></span>
                                                                        <span className="dot"></span>
                                                                    </li>
                                                                    <li className="die-item" data-side="6">
                                                                        <span className="dot"></span>
                                                                        <span className="dot"></span>
                                                                        <span className="dot"></span>
                                                                        <span className="dot"></span>
                                                                        <span className="dot"></span>
                                                                        <span className="dot"></span>
                                                                    </li>
                                                                </ol>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            <div className={`text-center w-[85px] h-[65px] ${game.status !== "flipping" && game.status !== "revealed" ? 'block' : 'hidden'}`}>
                                                <div className="flex h-[55px] md:h-[60px]">
                                                    <img src={questionDiceIcon} alt={'question-dice'} className="w-full" />
                                                </div>
                                                <div
                                                    className="font-normal cursor-pointer mt-1"
                                                        onClick={() => navigate(`/user-stats/${game?.opponent?.publicKey}`)}
                                                        onMouseEnter={() => playHover()}
                                                >
                                                    {displayName(game.opponent)}
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </div>

                            {
                                game?.status !== "revealed" && (
                                    <div className="text-[24px] font-extrabold hidden md:block">
                                        {`${(game?.amount / LAMPORTS_PER_SOL).toLocaleString()} SOL`}
                                    </div>
                                )
                            }
                        </>
                    )
                }
            </div>
        </Fade>
    );
};

export default GameBox;