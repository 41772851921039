import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import LotteryGame from "../../interfaces/LotteryGame";
import { CombinedReducer } from "../../store";
import { IInitialState } from "../../reducers/lottery";
import Lottery from "../../interfaces/Lottery";
import GlobalState from "../../interfaces/GlobalState";
import { displayName } from "../../utils";
import axios from "axios";
import greenSolImg from "../../assets/sol-symbol-green.svg";

interface IParams {
    showModal: boolean;
    onMouseEnter: () => void;
    handleShowModal: (show: boolean) => void;
}

const SolanaballWinnersModal = (params: IParams) => {
    const { showModal, onMouseEnter, handleShowModal } = params;
    const { finishedRounds } = useSelector<CombinedReducer, IInitialState>((state) => state.lottery);
    const globalState = useSelector<CombinedReducer, GlobalState>((state) => state.globalState);

    const [roundNumber, setRoundNumber] = useState<number>(0);
    const [lastFinishedRoundNumber, setLastFinishedRoundNumber] = useState<number>(0);
    const [lotteryInfo, setLotteryInfo] = useState<Lottery>();
    const [lotteryGames, setLotteryGames] = useState<LotteryGame[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getLotteryGames = async (roundNumber: number) => {
        try {
            const config = {
                params: { roundNumber },
            };
            const lotteryGames = (await axios.get("/api/lottery-game/lotteryGames", config)).data;
            return lotteryGames;
        } catch {
            return [];
        }
    }

    const getFinishedRoundInfo = async (roundNumber: number) => {
        try {
            const config = {
                params: { roundNumber },
            };
            const finishedRoundInfo = (await axios.get("/api/lottery-game/finishedRoundInfo", config)).data;
            return finishedRoundInfo;
        } catch {
            return undefined;
        }
    }

    const initialize = async () => {
        setIsLoading(true);
        try {
            if (finishedRounds.length > 0) {
                const lastFinishedRound = finishedRounds[0];
                const lotteryGames = await getLotteryGames(lastFinishedRound.lotteryCount || 0);

                console.log({ lotteryGames });

                setRoundNumber(lastFinishedRound.lotteryCount || 0);
                setLastFinishedRoundNumber(lastFinishedRound.lotteryCount || 0);
                setLotteryInfo(lastFinishedRound);
                setLotteryGames(lotteryGames);
            }
        } catch { }

        setIsLoading(false);
    }

    const getComprehensiveInfo = async (roundNumber: number) => {
        setIsLoading(true);
        try {
            const [lotteryGames, finishedRoundInfo] = await Promise.all([
                getLotteryGames(roundNumber),
                getFinishedRoundInfo(roundNumber),
            ]);

            setLotteryInfo(finishedRoundInfo);
            setLotteryGames(lotteryGames);
        } catch { }
        setIsLoading(false);
    }

    const handleNextRound = async () => {
        let nextRoundNumber = roundNumber + 1;
        if (nextRoundNumber > lastFinishedRoundNumber) {
            return;
        }
        setRoundNumber(nextRoundNumber);

        await getComprehensiveInfo(nextRoundNumber);
    }

    const handlePrevRound = async () => {
        let prevRoundNumber = roundNumber - 1;
        if (prevRoundNumber < 1) {
            return;
        }
        setRoundNumber(prevRoundNumber);

        await getComprehensiveInfo(prevRoundNumber);
    }

    useEffect(() => {
        initialize();
    }, [finishedRounds.length]);

    return (
        <div
            className="justify-center fixed top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] sm:bg-black/50 z-[1040] overflow-y-auto"
            style={{ display: showModal ? "flex" : "none" }}
        >
            <div className={`flex flex-col items-center w-full h-fit mx-[25px] mt-[90px] md:mt-[100px] mb-[65px] md:mb-[40px] xl:pl-[70px] ${globalState?.isLiveChatExpanded ? 'xl:pr-[336px]' : 'xl:pr-[70px]'}`}>
                <div className="relative flex flex-col items-center gap-[16px] md:gap-[24px] w-full max-w-[500px] h-[calc(100vh-155px)] md:h-[calc(100vh-140px)] p-[30px_20px] md:p-[32px_50px] bg-[#090C0E] rounded-[8px] border-[1.5px] border-solid border-[#000000] shadow-[0px_0px_8px_#00A5FE] z-[1050]">
                    {/* Close button */}
                    <button
                        className="sm:flex justify-center items-center absolute top-0 right-0 p-[10px] cursor-pointer bg-none border-none rounded-[6px] transform hover:scale-110 transition-all"
                        onClick={() => handleShowModal(false)}
                        onMouseEnter={() => onMouseEnter}
                    >
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 16L16 1M1 1L16 16" stroke="#BEBEBE" strokeWidth="2" />
                        </svg>
                    </button>

                    {/* Modal title */}
                    <div className="text-[24px] md:text-[32px] text-center font-bold leading-[35px] gold-font md:leading-[47.5px]">Solanaball winners</div>

                    {/* Modal body */}
                    <div className={`grow flex flex-col ${isLoading ? 'justify-center' : 'justify-start'} items-center gap-[16px] md:gap-[12px] w-full overflow-y-auto`}>

                        <div className="flex justify-center items-center gap-[12px] text-[16px] md:text-[20px] font-bold leading-[24px] md:leading-[30px]">
                            <button
                                disabled={roundNumber <= 1 || isLoading || lotteryInfo == undefined}
                                className={`${roundNumber <= 1 || isLoading || lotteryInfo == undefined ? 'opacity-60' : 'opacity-100'}`}
                                onClick={handlePrevRound}
                            >
                                {'<'}
                            </button>

                            <span>{`Round ${lotteryInfo !== undefined ? roundNumber : '-'}`}</span>

                            <button
                                disabled={roundNumber >= lastFinishedRoundNumber || isLoading || lotteryInfo == undefined}
                                className={`${roundNumber >= lastFinishedRoundNumber || isLoading || lotteryInfo == undefined ? 'opacity-60' : 'opacity-100'}`}
                                onClick={handleNextRound}
                            >
                                {'>'}
                            </button>
                        </div>

                        {
                            !isLoading && lotteryInfo !== undefined ? (
                                <div className="w-full border border-solid border-[#808080] bg-[#1A2129] overflow-y-auto thin-scroll-bar md:no-scroll-bar">
                                    {
                                        new Array(9).fill(0).map((_, i) => {
                                            return (
                                                <div key={i} className={`flex justify-center items-center w-full p-[15px] md:p-[20px] ${i !== 8 ? 'border-b border-[#808080]' : ''}`}>
                                                    <div className="w-[25%] text-[12px] md:text-[16px] font-medium leading-[17px] md:leading-[23px]">{`Division ${i + 1}:`}</div>
                                                    <div className="w-[75%] flex items-center">
                                                        {
                                                            lotteryInfo.countWinnersPerBracket![i] > 0 ? (
                                                                <div className="flex flex-col gap-[7px] md:gap-[10px] w-full">
                                                                    {
                                                                        lotteryGames.filter(x => x.matchingCountPerBracket[i] > 0).map((item, j) => {
                                                                            return (
                                                                                <div key={j} className="flex justify-center items-center w-full">
                                                                                    <div className="w-2/3 text-[12px] md:text-[16px] text-[#46FF78] text-center font-normal leading-[17px] md:leading-[22px]">
                                                                                        <span>
                                                                                            {`+ ${(lotteryInfo.solPerBracket![i] * item.matchingCountPerBracket[i] / LAMPORTS_PER_SOL).toLocaleString()} `}
                                                                                            <img src={greenSolImg} alt="sol-symbol" className="w-[12px] md:w-[16px] inline-block" />
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="w-1/3 text-[12px] md:text-[16px] text-[#46FF78] font-medium leading-[17px] md:leading-[22px]">{displayName(item.buyer)}</div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className="w-2/3 text-[12px] md:text-[16px] font-normal leading-[17px] md:leading-[22px]"></div>
                                                                    <div className="w-1/3 text-[12px] md:text-[16px] text-[#808080] font-medium leading-[17px] md:leading-[23px]">No winners</div>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            ) : (
                                <div className="grow flex justify-center items-center w-full">
                                    <ClipLoader size={35} color="#ffffff" />
                                </div>
                            )
                        }
                    </div>

                    {/* Modal footer */}
                    <div className="flex justify-center items-center">
                        {
                            lotteryInfo && lotteryInfo?.countWinnersPerBracket && lotteryInfo.countWinnersPerBracket.reduce((sum, val) => sum + val, 0) > 0 ? (
                                <span className="text-[15px] md:text-[20px] text-[#46FF78] text-center font-medium leading-[22px] md:leading-[29px]">Congratulations to all Winners!</span>
                            ) : (
                                <span className="text-[15px] md:text-[20px] text-[#808080] text-center font-medium leading-[22px] md:leading-[29px]">This round no lucky winners...</span>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="overlay"></div>
        </div>
    );
};

export default SolanaballWinnersModal;