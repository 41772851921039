import React, { useCallback, useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Statistic } from "antd";
import { toast } from "react-toastify";
import { useWallet } from "@solana/wallet-adapter-react";
import {
    AuthorizationData,
    Metadata,
    PROGRAM_ID as TMETA_PROG_ID,
    PROGRAM_ADDRESS
} from '@metaplex-foundation/mpl-token-metadata';
import { Metaplex } from "@metaplex-foundation/js";
import { PROGRAM_ID as AUTH_PROG_ID } from '@metaplex-foundation/mpl-token-auth-rules';
import {
    Connection,
    clusterApiUrl,
    PublicKey,
    Cluster,
    LAMPORTS_PER_SOL,
    Transaction,
    SYSVAR_INSTRUCTIONS_PUBKEY
} from "@solana/web3.js";
import {
    ASSOCIATED_TOKEN_PROGRAM_ID,
    createAssociatedTokenAccountInstruction,
    getAssociatedTokenAddress,
    TOKEN_PROGRAM_ID
} from "@solana/spl-token";
import * as anchor from "@project-serum/anchor";
import { Program, AnchorProvider } from "@project-serum/anchor";
import { Wallet } from '@project-serum/anchor/dist/cjs/provider';
import { INFTList } from "../../interfaces/NftList";
import { IQuestPoolInfo } from "../../interfaces/Quests";
import { getBlockTime } from "../../utils";
import questsIdl from "./old-quest-idl.json";
import wolvesNft from "../../data/wolves.json";
import { ReactComponent as CheckIcon } from '../../assets/check.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/arrow-down-filled.svg';
import { ReactComponent as ArrowRightWhiteIcon } from '../../assets/arrow-right-white.svg';
// @ts-ignore
import Slide from 'react-reveal/Slide';
import useSound from "use-sound";

import "./index.scss";

const OldQuests = () => {
    const wallet = useWallet();
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
    const { Countdown } = Statistic;
    const [open, setOpen] = useState<boolean>(false);
    const [nftList, setNftList] = useState<INFTList[]>([]);
    const [poolAccount, setPoolAccount] = useState<PublicKey>();
    const [poolInfo, setPoolInfo] = useState<IQuestPoolInfo>();
    const [acceptModalOpen, setAcceptModalOpen] = useState<boolean>(false);
    const [selectedNfIndexes, setSelectedNftIndexes] = useState<number[]>([]);
    const [isAllNftsSelected, setIsAllNftsSelected] = useState<boolean>(false);
    const [stakedNftList, setStakedNftList] = useState<INFTList[]>([]);
    const [completedNfIndexes, setCompletedNftIndexes] = useState<number[]>([]);
    const [completeModalOpen, setCompleteModalOpen] = useState<boolean>(false);
    const [dialogueModalOpen, setDialogueModalOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const connection = new Connection(process.env.REACT_APP_RPC_URL!, "confirmed");
    const programID = new PublicKey(questsIdl.metadata.address);
    const provider = new AnchorProvider(connection, wallet as Wallet, { commitment: 'processed' });
    const program = new Program(questsIdl as anchor.Idl, programID, provider);
    const metaplex = new Metaplex(connection);
    const bonesTokenMint = new PublicKey(process.env.REACT_APP_BONES_TOKEN_MINT!);

    const initialize = useCallback(async (isInit: boolean) => {
        if (isInit)
            setIsLoading(true);

        try {
            if (wallet && wallet?.publicKey) {
                const provider = await getProvider();
                const program = new Program(questsIdl as anchor.Idl, programID, provider);

                try {
                    const poolPda = PublicKey.findProgramAddressSync(
                        [Buffer.from(anchor.utils.bytes.utf8.encode('quests-pool'))],
                        program.programId
                    )[0];
                    const poolPdaInfo: any = await program.account.pool.fetch(poolPda);
                    const poolInfo: IQuestPoolInfo = {
                        authority: poolPdaInfo?.authority,
                        rewardTokenMint: poolPdaInfo?.rewardTokenMint,
                        rewardTokenVault: poolPdaInfo?.rewardTokenVault,
                        stakedCount: poolPdaInfo?.stakedCount,
                        createdAt: Math.round(Date.now() / 1000)
                    };

                    setPoolInfo(poolInfo);
                    setPoolAccount(poolPda);
                } catch (_) { }

                const nfts = await metaplex.nfts().findAllByOwner({ owner: wallet?.publicKey });

                let metadata: any;
                if (nfts) {
                    const nftList: INFTList[] = [];
                    const stakedNftList: INFTList[] = [];

                    const currentBlockTime = await getBlockTime();

                    if (!currentBlockTime) {
                        toast.error("Please check network status.");
                        return;
                    }

                    const userStakingAccounts: any[] = await program.account.userStakeInfo.all();

                    console.log({ userStakingAccounts });

                    await Promise.all(
                        nfts.map(async (item, _) => {
                            if (item?.uri) {
                                // let rowData: INFTList = {
                                //     name: item?.name,
                                //     symbol: item?.symbol,
                                //     description: "metadata?.description",
                                //     royalty: item?.sellerFeeBasisPoints,
                                //     image: "https://lh3.googleusercontent.com/vG0A5p-vA5ovAJkPYNRDFV3s1RuVIoVPVi4sqwUWqU1hB4g_LCLCxtGOu2ProLsbqz77kMvs7SbxsAPsHu4X0G2sFcfD7QfM8rc=s250-k",
                                //     mintAddress: (item as any)?.mintAddress?.toString(),
                                //     tokenAddress: item?.address?.toString()
                                // }

                                // nftList.push(rowData);
                                try {
                                    metadata = await (await fetch(item?.uri)).json();
                                    const mintAddress = (item as any)?.mintAddress?.toString();
                                    if (wolvesNft.includes(mintAddress)) {
                                        let rowData: INFTList = {
                                            name: item?.name,
                                            symbol: item?.symbol,
                                            description: metadata?.description,
                                            royalty: item?.sellerFeeBasisPoints,
                                            image: metadata?.image,
                                            mintAddress: mintAddress,
                                            tokenAddress: item?.address?.toString()
                                        }

                                        nftList.push(rowData);
                                    }
                                } catch (_) { }
                            }
                        })
                    );

                    await Promise.all(
                        userStakingAccounts.map(async (item, _) => {
                            if (item.account.userPubkey?.toString() == wallet?.publicKey?.toString()) {
                                const stakedNftMint = item.account.nftMint;
                                try {
                                    const stakedNftMetadata = await metaplex.nfts().findByMint({ mintAddress: stakedNftMint });
                                    if (stakedNftMetadata) {
                                        let rowData: INFTList = {
                                            name: stakedNftMetadata?.name,
                                            symbol: stakedNftMetadata?.symbol,
                                            description: stakedNftMetadata?.json?.description || "",
                                            royalty: stakedNftMetadata?.sellerFeeBasisPoints,
                                            image: stakedNftMetadata?.json?.image || "",
                                            mintAddress: stakedNftMint?.toString(),
                                            tokenAddress: stakedNftMetadata?.address?.toString(),
                                            stakeStartTime: item?.account?.stakedTime.toNumber(),
                                            lockingPeriod: item?.account?.lockingPeriod.toNumber(),
                                            endTime: (item?.account?.stakedTime.toNumber() + item?.account?.lockingPeriod.toNumber()),
                                            currentBlockTime: currentBlockTime!
                                        }

                                        stakedNftList.push(rowData);
                                    }
                                } catch { }
                            }
                        })
                    );

                    setNftList(nftList);
                    setStakedNftList(stakedNftList);
                }
            }

            setIsLoading(false);
        } catch (e) {
            console.log("e", e);
            setIsLoading(false);
        }
    }, [wallet?.publicKey]);

    const handleSelectNfts = (index: number) => {
        if (!selectedNfIndexes.includes(index)) {
            setSelectedNftIndexes(oldState => {
                return [...oldState, index];
            })
        } else {
            setSelectedNftIndexes(oldState => {
                return oldState.filter(item => item != index);
            })
        }
    }

    const handleSelectCompletedNfts = (index: number) => {
        if (!completedNfIndexes.includes(index)) {
            setCompletedNftIndexes(oldState => {
                return [...oldState, index];
            })
        } else {
            setCompletedNftIndexes(oldState => {
                return oldState.filter(item => item != index);
            })
        }
    }

    const handleSelectAllNfts = () => {
        if (isAllNftsSelected) {
            setSelectedNftIndexes([]);
            setIsAllNftsSelected(false);
        } else {
            const nftIndexesList = nftList.map((_item, index) => index);
            setSelectedNftIndexes(nftIndexesList);
            setIsAllNftsSelected(true);
        }
    }

    function delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function getProvider() {
        const provider = new AnchorProvider(connection, wallet as Wallet, { commitment: 'processed' });
        return provider;
    }

    const handleBulkStake = async () => {
        return toast.info("Staking is disabled for a while.");

        if (!wallet || !wallet?.publicKey) {
            toast.warn("Please connect wallet");
            return;
        }

        if (!poolAccount || !poolInfo) {
            toast.warn("Quests Pool not initialized");
            return;
        }

        if (selectedNfIndexes.length == 0) {
            toast.warning("Please select NFTs");
            return;
        }

        let transaction = new Transaction();
        let counter = 0;
        try {
            setIsProcessing(true);

            const provider = await getProvider();
            const program = new Program(questsIdl as anchor.Idl, programID, provider);

            await selectedNfIndexes.reduce(async (promise, index) => {
                await promise;

                const item = nftList[index];
                const nftMint = new PublicKey(item.mintAddress);
                const userNftTokenAccount = await getAssociatedTokenAddress(nftMint, wallet?.publicKey!);

                const nftMetadata = await metaplex.nfts().findByMint({ mintAddress: nftMint });
                const nftEdition = metaplex.nfts().pdas().edition({ mint: nftMint });
                const inflatedMeta = await Metadata.fromAccountAddress(
                    provider.connection,
                    nftMetadata.metadataAddress
                );
                const ruleSet = inflatedMeta.programmableConfig?.ruleSet;

                console.log('nftMetada', nftMetadata)
                console.log('nftEdition', nftEdition)
                console.log('ruleSet', ruleSet)

                const stakingVault = PublicKey.findProgramAddressSync(
                    [
                        Buffer.from('quests-staking-vault'),
                        nftMint.toBuffer()
                    ],
                    program.programId
                )[0];

                const userStakingAccount = PublicKey.findProgramAddressSync(
                    [
                        wallet?.publicKey!.toBuffer(),
                        userNftTokenAccount.toBuffer()
                    ],
                    program.programId
                )[0];

                const poolSigner = PublicKey.findProgramAddressSync(
                    [poolAccount!.toBuffer()],
                    program.programId
                )[0];

                const [ownerTokenRecordPda] = PublicKey.findProgramAddressSync(
                    [
                        Buffer.from('metadata'),
                        TMETA_PROG_ID.toBuffer(),
                        nftMint.toBuffer(),
                        Buffer.from('token_record'),
                        userNftTokenAccount.toBuffer(),
                    ],
                    TMETA_PROG_ID
                );

                const [destTokenRecordPda] = PublicKey.findProgramAddressSync(
                    [
                        Buffer.from('metadata'),
                        TMETA_PROG_ID.toBuffer(),
                        nftMint.toBuffer(),
                        Buffer.from('token_record'),
                        stakingVault.toBuffer(),
                    ],
                    TMETA_PROG_ID
                );

                const remainingAccounts = [];
                if (!!ruleSet) {
                    remainingAccounts.push({
                        pubkey: ruleSet,
                        isSigner: false,
                        isWritable: false,
                    });
                }

                console.log('remainingAccounts', remainingAccounts)

                const additionalComputeBudgetInstruction =
                    anchor.web3.ComputeBudgetProgram.setComputeUnitLimit({
                        units: 400000,
                    });

                counter += 1;

                if (counter <= 5) {
                    transaction.add(additionalComputeBudgetInstruction);

                    transaction.add(
                        await program.methods
                            .stake(
                                !!ruleSet
                            )
                            .accounts({
                                user: wallet?.publicKey!,
                                nftMint: nftMint,
                                pnftMetadata: nftMetadata.metadataAddress,
                                pnftEdition: nftEdition,
                                userNftTokenAccount: userNftTokenAccount,
                                stakingVault: stakingVault,
                                userStakingAccount: userStakingAccount,
                                poolSigner: poolSigner,
                                pool: poolAccount,
                                ownerTokenRecord: ownerTokenRecordPda,
                                destTokenRecord: destTokenRecordPda,
                                instructions: SYSVAR_INSTRUCTIONS_PUBKEY,
                                authorizationRulesProgram: AUTH_PROG_ID,
                                tokenProgram: TOKEN_PROGRAM_ID,
                                tokenMetadataProgram: PROGRAM_ADDRESS,
                                rent: anchor.web3.SYSVAR_RENT_PUBKEY,
                                systemProgram: anchor.web3.SystemProgram.programId,
                                associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
                            })
                            .remainingAccounts(remainingAccounts)
                            .instruction()
                    )
                }

                if (counter == 5) {
                    await wallet.sendTransaction(transaction, connection);
                    transaction = new Transaction();
                    counter = 0;
                }
            }, Promise.resolve());

            if (counter > 0) {
                await wallet.sendTransaction(transaction, connection);
                transaction = new Transaction();
                counter = 0;
            }

            await delay(5000);

            toast.success("Successful!");
            await initialize(false);

            setSelectedNftIndexes([]);
            setIsProcessing(false);
        } catch (e: any) {
            console.log("e: ", e);
            toast.error("Failed!");
            setIsProcessing(false);
        }
    }

    const handleBulkUnStake = async () => {
        if (!wallet || !wallet?.publicKey) {
            toast.warn("Please connect wallet");
            return;
        }

        if (!poolAccount || !poolInfo) {
            toast.warn("Quests Pool not initialized");
            return;
        }

        if (stakedNftList.length == 0) {
            toast.warning("You have no staked NFTs");
            return;
        }

        // const currentBlockTime = await getBlockTime();
        const completedNftsIndexes: number[] = [];
        stakedNftList.map((item, index) => {
            // if (item?.endTime! < currentBlockTime) {
            completedNftsIndexes.push(index);
            // }
        })

        if (completedNftsIndexes.length == 0) {
            toast.warning("No NFT is not completed");
            return;
        }

        setCompletedNftIndexes(completedNftsIndexes)

        let transaction = new Transaction();
        let counter = 0;
        try {
            setIsProcessing(true);

            const provider = await getProvider();
            const program = new Program(questsIdl as anchor.Idl, programID, provider);

            ///////////////////////////////////////////////////
            const userTokenAccount = await getAssociatedTokenAddress(bonesTokenMint, wallet?.publicKey!);
            const userTokenAccountInfo = await connection.getAccountInfo(userTokenAccount);
            console.log('userTokenAccount', userTokenAccount.toString())
            console.log('userTokenAccountInfo', userTokenAccountInfo)
            if (!userTokenAccountInfo) {
                counter += 1;
                transaction.add(
                    createAssociatedTokenAccountInstruction(
                        wallet?.publicKey,
                        userTokenAccount,
                        wallet?.publicKey,
                        bonesTokenMint
                    )
                )
            }
            ///////////////////////////////////////////////////

            await completedNftsIndexes.reduce(async (promise, index) => {
                await promise;

                const item = stakedNftList[index];
                const nftMint = new PublicKey(item.mintAddress);
                const userNftTokenAccount = await getAssociatedTokenAddress(nftMint, wallet?.publicKey!);

                const userNftTokenAccountInfo = await connection.getAccountInfo(userNftTokenAccount);
                if (!userNftTokenAccountInfo) {
                    // counter = 1;
                    transaction.add(
                        createAssociatedTokenAccountInstruction(
                            wallet?.publicKey!,
                            userNftTokenAccount,
                            wallet?.publicKey!,
                            nftMint
                        )
                    )
                }

                const nftMetadata = await metaplex.nfts().findByMint({ mintAddress: nftMint });
                const nftEdition = metaplex.nfts().pdas().edition({ mint: nftMint });
                const inflatedMeta = await Metadata.fromAccountAddress(
                    provider.connection,
                    nftMetadata.metadataAddress
                );
                const ruleSet = inflatedMeta.programmableConfig?.ruleSet;

                const stakingVault = PublicKey.findProgramAddressSync(
                    [
                        Buffer.from('quests-staking-vault'),
                        nftMint.toBuffer()
                    ],
                    program.programId
                )[0];

                const userStakingAccount = PublicKey.findProgramAddressSync(
                    [
                        wallet?.publicKey!.toBuffer(),
                        userNftTokenAccount.toBuffer()
                    ],
                    program.programId
                )[0];

                const poolSigner = PublicKey.findProgramAddressSync(
                    [poolAccount!.toBuffer()],
                    program.programId
                )[0];

                // ///////////////////////////////////////////////////
                // const rewardTokenVault = PublicKey.findProgramAddressSync(
                //     [Buffer.from('quests-reward-token-vault')],
                //     program.programId
                // )[0];
                // ///////////////////////////////////////////////////

                const [ownerTokenRecordPda] = PublicKey.findProgramAddressSync(
                    [
                        Buffer.from('metadata'),
                        TMETA_PROG_ID.toBuffer(),
                        nftMint.toBuffer(),
                        Buffer.from('token_record'),
                        stakingVault.toBuffer(),
                    ],
                    TMETA_PROG_ID
                );

                const [destTokenRecordPda] = PublicKey.findProgramAddressSync(
                    [
                        Buffer.from('metadata'),
                        TMETA_PROG_ID.toBuffer(),
                        nftMint.toBuffer(),
                        Buffer.from('token_record'),
                        userNftTokenAccount.toBuffer(),
                    ],
                    TMETA_PROG_ID
                );

                const remainingAccounts = [];
                if (!!ruleSet) {
                    remainingAccounts.push({
                        pubkey: ruleSet,
                        isSigner: false,
                        isWritable: false,
                    });
                }

                const additionalComputeBudgetInstruction =
                    anchor.web3.ComputeBudgetProgram.setComputeUnitLimit({
                        units: 400000,
                    });

                counter += 1;
                if (counter <= 1) {
                    transaction.add(additionalComputeBudgetInstruction);

                    transaction.add(
                        await program.methods
                            .unstake(
                                !!ruleSet
                            )
                            .accounts({
                                user: wallet?.publicKey!,
                                nftMint: nftMint,
                                pnftMetadata: nftMetadata.metadataAddress,
                                pnftEdition: nftEdition,
                                userNftTokenAccount: userNftTokenAccount,
                                stakingVault: stakingVault,
                                userStakingAccount: userStakingAccount,
                                // userRewardTokenAccount: userTokenAccount,
                                // rewardTokenVault: rewardTokenVault,
                                poolSigner: poolSigner,
                                pool: poolAccount,
                                ownerTokenRecord: ownerTokenRecordPda,
                                destTokenRecord: destTokenRecordPda,
                                instructions: SYSVAR_INSTRUCTIONS_PUBKEY,
                                authorizationRulesProgram: AUTH_PROG_ID,
                                tokenMetadataProgram: PROGRAM_ADDRESS,
                                tokenProgram: TOKEN_PROGRAM_ID,
                                rent: anchor.web3.SYSVAR_RENT_PUBKEY,
                                systemProgram: anchor.web3.SystemProgram.programId,
                                associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
                            })
                            .remainingAccounts(remainingAccounts)
                            .instruction()
                    )
                }

                if (counter == 1) {
                    await wallet.sendTransaction(transaction, connection);
                    transaction = new Transaction();
                    counter = 0;
                }
            }, Promise.resolve());

            if (counter > 0) {
                await wallet.sendTransaction(transaction, connection);
                transaction = new Transaction();
                counter = 0;
            }

            await delay(5000);

            toast.success("Successful!");

            return;
            await initialize(false);

            setCompletedNftIndexes([]);
            setIsProcessing(false);
        } catch (e: any) {
            console.log("e: ", e);
            toast.error("Failed!");
            setIsProcessing(false);
        }
    }

    useEffect(() => {
        (async () => {
            await initialize(true);
        })()
    }, [wallet?.publicKey]);

    return (
        <div className="flex flex-col items-center w-full min-h-[calc(100vh-59px)] md:min-h-[calc(100vh-70px)] rounded-tr-[10px] md:bg-[url('/src/assets/quests-bg.webp')] bg-center bg-no-repeat bg-cover">
            <div className="grow w-full pt-[20px] md:pt-[30px]">
                <div className="flex flex-col md:flex-row w-full">
                    {/* First */}
                    <div className="flex flex-col items-center min-w-[380px] md:border-r-2 md:border-r-[#808080]">
                        <h1 className="text-[38px] font-orbitron font-bold leading-[100%] hidden md:block">All Quests</h1>

                        {/* For mobile */}
                        <h5 className="md:hidden text-[16px] font-orbitron font-bold leading-[100%]">Put your Wolf on a Journey!</h5>

                        <button
                            className="flex md:hidden justify-center items-center w-[230px] h-[30px] bg-[#11161B] shadow-[inset_0px_0px_4px_#808080] rounded-[10px] mt-[27px] relative"
                        >
                            <span className="text-[14px] font-medium leading-[20px]">
                                {"#1 - Brawlers’  Bounty"}
                            </span>
                            <div className="flex justify-end items-center w-full h-full absolute top-0 left-0 px-[10px]">
                                <ArrowDownIcon className={`w-[13.5px] h-[8px] ${open ? 'rotate-180' : 'rotate-0'}`} />
                            </div>
                        </button>

                        <button
                            className="md:hidden flex justify-center items-center w-[112px] h-[30px] bg-[#11161B] shadow-[inset_0px_0px_4px_#808080] text-[12px] font-bold leading-[100%] rounded-[10px] mt-[16px]"
                            onClick={() => setDialogueModalOpen(true)}
                            onMouseEnter={() => playHover()}
                        >
                            Quest Dialogue
                        </button>
                        {/* ---------- */}

                        <div className="hidden md:flex justify-center items-center w-[291px] h-[54px] text-[22px] font-orbitron font-semibold leading-[100%] rounded-[10px] gradient-btn mt-[108px]">
                            #1 - Brawlers' Bounty
                        </div>
                    </div>

                    {/* Second */}
                    <div className="hidden md:flex flex-col grow md:border-r-2 md:border-r-[#808080]">
                        <h1 className="text-[32px] text-center font-orbitron font-bold leading-[100%] mt-[12px]">{"#1 Brawlers’ Bounty"}</h1>

                        <div className="flex flex-col gap-[20px] text-[20px] font-normal leading-[20px] mt-[25px] pl-[35px] pr-[19px] pb-[20px]">
                            <h3>In the vast, untamed digital frontier of the Solana ecosystem, whispers of the renowned BiteClub reach your keen ears.</h3>
                            <h3>As a gaming platform where skill, cunning, and fortune converge, BiteClub offers a tantalizing opportunity for those bold enough to join its ranks.</h3>
                            <h3>Feeling the call of adventure and the allure of potential profits, you set out on a seven-day journey to become a part of the BiteClub family and earn your first cut.</h3>
                            <h3>As you venture through the unpredictable landscape of the Solana blockchain, you will face formidable challenges and encounter fierce competitors.</h3>
                            <h3>With each passing day, your determination and resilience will be tested as you navigate the treacherous terrain, uncovering the secrets of the blockchain and honing your skills in this digital Wild West.</h3>
                            <h3>After seven grueling days, you will arrive at the BiteClub, weary but triumphant. As you enter the sophisticated and polished gaming platform, you know that your arduous journey has prepared you for the high-stakes games and intense competition that await.</h3>
                            <h3>As a new member of the BiteClub family, you will stand tall and proud, ready to claim your first cut and forge your destiny in the thrilling world of Solana's most prestigious gaming platform.</h3>
                            <h3>By completing this initiation quest, you will have earned your place among the ranks of BiteClub's elite, embracing the exciting challenges and rewards that come with being a part of this groundbreaking enterprise.</h3>
                        </div>
                    </div>

                    {/* Third */}
                    <div className="min-w-[320px]">
                        <div className="w-full mt-[30px] px-[20px] md:px-0">
                            <div className="grid grid-cols-2 md:grid-cols-1 gap-x-[10px] gap-y-[76px] md:gap-[92px]">
                                {/* Required Stats */}
                                <div className="flex flex-col items-center gap-[15px] md:gap-[25px]">
                                    <h3 className="text-[16px] md:text-[22px] text-center font-orbitron font-bold leading-[100%]">Required Stats</h3>
                                    <div className="flex items-center gap-[9px]">
                                        <div className="w-[22.5px] h-[22.5px] bg-[#1D262F] shadow-[0px_0px_8px_#00A5FE] relative">
                                            <div className="w-full h-full p-[5px_2px] absolute top-0 left-0">
                                                <CheckIcon className="w-full h-full" />
                                            </div>
                                        </div>
                                        <div className="flex items-center text-[14px] md:text-[20px] font-normal leading-[100%]">
                                            None
                                        </div>
                                    </div>
                                </div>

                                {/* Required Traits */}
                                <div className="flex flex-col items-center gap-[15px] md:gap-[25px]">
                                    <h3 className="text-[16px] md:text-[22px] text-center font-orbitron font-bold leading-[100%]">Required Traits</h3>
                                    <div className="flex items-center gap-[9px]">
                                        <div className="w-[22.5px] h-[22.5px] bg-[#1D262F] shadow-[0px_0px_8px_#00A5FE] relative">
                                            <div className="w-full h-full p-[5px_2px] absolute top-0 left-0">
                                                <CheckIcon className="w-full h-full" />
                                            </div>
                                        </div>
                                        <div className="flex items-center text-[14px] md:text-[20px] font-normal leading-[100%]">
                                            None
                                        </div>
                                    </div>
                                </div>

                                {/* Duration */}
                                <div className="flex flex-col items-center gap-[15px] md:gap-[25px]">
                                    <h3 className="text-[16px] md:text-[22px] text-[#EDC967] text-center font-orbitron font-bold leading-[100%]">Duration</h3>
                                    <div className="flex items-center text-[14px] md:text-[20px] font-normal leading-[100%]">
                                        7 Days
                                    </div>
                                </div>

                                {/* Risk Level */}
                                <div className="flex flex-col items-center gap-[15px] md:gap-[25px]">
                                    <h3 className="text-[16px] md:text-[22px] text-[#FF3535] text-center font-orbitron font-bold leading-[100%]">Risk Level</h3>
                                    <div className="flex items-center text-[14px] md:text-[20px] font-normal leading-[100%]">
                                        0%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row w-full mb-[60px] md:mb-[20px]">
                {/* Check Quest Status */}
                <div className="flex flex-col justify-center items-center min-w-[380px] order-2 md:order-1 mb-[14px] md:mb-0">
                    <button
                        className="flex justify-center items-center w-[200px] md:w-[291px] h-[36px] md:h-[54px] text-[16px] md:text-[22px] font-orbitron font-extrabold md:font-semibold leading-[24px] md:leading-[100%] rounded-[10px] gradient-btn quests-action-btn"
                        onClick={() => setCompleteModalOpen(true)}
                        onMouseEnter={() => playHover()}
                    >
                        <span className="hidden md:block">Check Quest Status</span>
                        <span className="md:hidden">Quest Status</span>
                    </button>
                </div>

                {/* REWARDS */}
                <div className="flex justify-center items-center grow order-1 md:order-2 mb-[90px] md:mb-0">
                    <div className="flex flex-col justify-center items-center gap-[21px] md:gap-[10px] w-[257px] md:w-[350px] h-[93px] md:h-[110px] bg-[#11161B] mix-blend-screen border-x-0 border-y-2 md:border-y-[3px] border-solid border-[#00FF00] rounded-[10px]">
                        <div className="text-[18px] md:text-[26px] font-orbitron font-bold leading-[100%]">REWARDS</div>
                        <div className="text-[15px] md:text-[22px] text-[#00FF00] font-medium leading-[100%]">100 Bones</div>
                    </div>
                </div>

                {/* Accept Quest */}
                <div className="flex flex-col justify-center items-center min-w-[320px] order-3">
                    <button
                        className="flex justify-center items-center gap-[10px] w-[200px] md:w-[260px] h-[64px] md:h-[75px] text-[20px] md:text-[22px] font-bold leading-[30px] md:leading-[100%] rounded-[10px] gradient-btn quests-action-btn"
                        onClick={() => setAcceptModalOpen(true)}
                        onMouseEnter={() => playHover()}
                    >
                        <span>Accept Quest</span>
                        <ArrowRightWhiteIcon className="w-[25px] md:w-[37px]" />
                    </button>
                </div>
            </div>

            <Slide top duration={500} when={acceptModalOpen || completeModalOpen || dialogueModalOpen}>
                {/* Modal to accept quests */}
                <div
                    className="fixed top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] bg-black/50 z-[1040] overflow-y-auto hidden justify-center mobile-height"
                    style={{ display: acceptModalOpen ? "flex" : "none" }}
                >
                    <div className="flex justify-center items-center w-full h-full py-[4rem] md:py-[5rem]">
                        <div className="flex flex-col items-center max-w-[900px] max-h-[70%] w-full h-full mx-[2rem] rounded-[10px] font-semibold p-[36px_30px_30px] flex-1 text-center bg-[#090C0E] shadow-[0px_0px_7px_#00A5FE] relative z-[1050]">
                            <button
                                className="sm:flex justify-center items-center absolute top-0 right-0 p-[10px] cursor-pointer bg-none border-none rounded-[6px] transform hover:scale-110 transition-all"
                                onClick={() => setAcceptModalOpen(false)}
                                onMouseEnter={() => playHover()}
                            >
                                <svg width="14" height="14" className="fill-white transition-[fill_200ms_ease_0s]">
                                    <path d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z"></path>
                                </svg>
                            </button>

                            <div className="w-full">
                                <h1 className="font-semibold text-[26px] leading-[41px] mt-0 w-full text-center text-white">Choose NFTs</h1>
                            </div>

                            <div className="grow w-full h-[300px] mt-[25px] overflow-x-hidden overflow-y-auto no-scroll-bar">
                                {
                                    !isLoading ? (
                                        nftList.length > 0 ? (
                                            <div className="flex flex-wrap justify-center sm:justify-start gap-[35px] w-full">
                                                {
                                                    nftList.map((item, index) => {
                                                        return (
                                                            <div key={index} className="flex-none w-[250px] sm:w-[240px]">
                                                                <div
                                                                    className={`nft-img-container  ${selectedNfIndexes.includes(index) ? 'active' : ''}`}
                                                                    onClick={() => handleSelectNfts(index)}
                                                                    onMouseEnter={() => playHover()}
                                                                >
                                                                    <div className="img-container">
                                                                        {
                                                                            item?.howrareRank && (
                                                                                <h4 className="howrare-rank">
                                                                                    <svg width="10" height="10" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" color="#f5f3f7" className="tw-mr-[-1px]">
                                                                                        <path d="M37.7729 31.7942C40.4532 30.6818 42.6963 28.8329 44.1958 26.5C45.6765 24.1601 46.4392 21.4923 46.4021 18.7827C46.4695 16.8712 46.113 14.9671 45.3546 13.1873C44.5962 11.4075 43.4518 9.78957 41.9917 8.43269C39.05 5.67243 34.7444 4.29231 29.075 4.29231H18.95V0H7.00208V4.29423H0V14.4077H7.00208V23.8692H0V33.5096H7.00208V48H18.9542V33.5096H26.0104L34.3208 48H48L37.7729 31.7942ZM33.4896 21.5635C33.0215 22.3787 32.2899 23.0391 31.3964 23.4527C30.5029 23.8663 29.4919 24.0125 28.5042 23.8712H18.9542V14.4096H28.9542C29.8679 14.2828 30.8017 14.4296 31.6184 14.8287C32.435 15.2278 33.0916 15.858 33.4917 16.6269C33.9526 17.3803 34.1949 18.2309 34.1949 19.0962C34.1949 19.9614 33.9526 20.812 33.4917 21.5654" fill="#f5f3f7"></path>
                                                                                    </svg>
                                                                                    {item?.howrareRank}
                                                                                </h4>
                                                                            )
                                                                        }
                                                                        <img src={item?.image} alt={item?.name} />
                                                                        {
                                                                            item?.floorPrice && (
                                                                                <h4 className="fp-text">
                                                                                    {`FP: ${(item?.floorPrice / LAMPORTS_PER_SOL).toLocaleString()}`}
                                                                                </h4>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className="nft-name">
                                                                        <span>{item?.name}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        ) : (
                                            <h3>You have no items</h3>
                                        )
                                    ) : (
                                        <h3 className="flex justify-center items-center gap-[10px]">
                                            Loading your NFTs from your wallet. Please hold... <ClipLoader size={24} color="#ffffff" className="flex-none" />
                                        </h3>
                                    )
                                }
                            </div>

                            <div className="flex justify-center items-center gap-[12px] mt-[35px] w-full">
                                <button
                                    disabled={isLoading || isProcessing}
                                    className="flex justify-center items-center gap-[10px] gradient-btn full-rounded min-w-[120px] h-[40px] px-[26px] text-[20px] transition duration-300 hover:transform hover:scale-110 opacity-100"
                                    onClick={handleBulkStake}
                                    onMouseEnter={() => playHover()}
                                >
                                    {
                                        isProcessing ? (
                                            <>
                                                <span>Processing</span>{" "}
                                                <ClipLoader size={18} color="#ffffff" />
                                            </>
                                        ) : (
                                            "Accept"
                                        )
                                    }
                                </button>

                                <div
                                    className="w-[22.5px] h-[22.5px] bg-[#1D262F] shadow-[0px_0px_8px_#00A5FE] cursor-pointer relative"
                                    onClick={() => handleSelectAllNfts()}
                                    onMouseEnter={() => playHover()}
                                >
                                    {
                                        isAllNftsSelected && (
                                            <div className="w-full h-full p-[5px_2px] absolute top-0 left-0">
                                                <CheckIcon className="w-full h-full" />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="overlay"></div>
                </div>

                {/* Modal to complete quests */}
                <div
                    className="fixed top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] bg-black/50 z-[1040] overflow-y-auto hidden justify-center mobile-height"
                    style={{ display: completeModalOpen ? "flex" : "none" }}
                >
                    <div className="flex justify-center items-center w-full h-full py-[4rem] md:py-[5rem]">
                        <div className="flex flex-col items-center max-w-[900px] max-h-[70%] w-full h-full mx-[2rem] rounded-[10px] font-semibold p-[36px_30px_30px] flex-1 text-center bg-[#090C0E] shadow-[0px_0px_7px_#00A5FE] relative z-[1050]">
                            <button
                                className="sm:flex justify-center items-center absolute top-0 right-0 p-[10px] cursor-pointer bg-none border-none rounded-[6px] transform hover:scale-110 transition-all"
                                onClick={() => setCompleteModalOpen(false)}
                                onMouseEnter={() => playHover()}
                            >
                                <svg width="14" height="14" className="fill-white transition-[fill_200ms_ease_0s]">
                                    <path d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z"></path>
                                </svg>
                            </button>

                            <div className="w-full">
                                <h1 className="font-semibold text-[26px] leading-[41px] mt-0 w-full text-center text-white">Redeem Ended NFTs</h1>
                            </div>

                            <div className="grow w-full h-[300px] mt-[25px] overflow-x-hidden overflow-y-auto no-scroll-bar">
                                {
                                    !isLoading ? (
                                        stakedNftList.length > 0 ? (
                                            <div className="flex flex-wrap justify-center sm:justify-start gap-[35px] w-full">
                                                {
                                                    stakedNftList.map((item, index) => {
                                                        return (
                                                            <div key={index} className="flex-none w-[250px] sm:w-[240px]">
                                                                <div
                                                                    className={`nft-img-container  ${completedNfIndexes.includes(index) ? 'active' : ''}`}
                                                                >
                                                                    <div className="img-container">
                                                                        {
                                                                            item?.howrareRank && (
                                                                                <h4 className="howrare-rank">
                                                                                    <svg width="10" height="10" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" color="#f5f3f7" className="tw-mr-[-1px]">
                                                                                        <path d="M37.7729 31.7942C40.4532 30.6818 42.6963 28.8329 44.1958 26.5C45.6765 24.1601 46.4392 21.4923 46.4021 18.7827C46.4695 16.8712 46.113 14.9671 45.3546 13.1873C44.5962 11.4075 43.4518 9.78957 41.9917 8.43269C39.05 5.67243 34.7444 4.29231 29.075 4.29231H18.95V0H7.00208V4.29423H0V14.4077H7.00208V23.8692H0V33.5096H7.00208V48H18.9542V33.5096H26.0104L34.3208 48H48L37.7729 31.7942ZM33.4896 21.5635C33.0215 22.3787 32.2899 23.0391 31.3964 23.4527C30.5029 23.8663 29.4919 24.0125 28.5042 23.8712H18.9542V14.4096H28.9542C29.8679 14.2828 30.8017 14.4296 31.6184 14.8287C32.435 15.2278 33.0916 15.858 33.4917 16.6269C33.9526 17.3803 34.1949 18.2309 34.1949 19.0962C34.1949 19.9614 33.9526 20.812 33.4917 21.5654" fill="#f5f3f7"></path>
                                                                                    </svg>
                                                                                    {item?.howrareRank}
                                                                                </h4>
                                                                            )
                                                                        }
                                                                        <img src={item?.image} alt={item?.name} />
                                                                        {
                                                                            item?.floorPrice && (
                                                                                <h4 className="fp-text">
                                                                                    {`FP: ${(item?.floorPrice / LAMPORTS_PER_SOL).toLocaleString()}`}
                                                                                </h4>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className="nft-name">
                                                                        <span>{item?.name}</span>
                                                                        <Countdown
                                                                            value={Date.now() + ((item?.endTime || 0) - (item?.currentBlockTime || 0)) * 1000}
                                                                            format="DD:HH:mm:ss"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        ) : (
                                            <h3>You have no items</h3>
                                        )
                                    ) : (
                                        <h3 className="flex justify-center items-center gap-[10px]">
                                            Loading your NFTs from your smart contract. Please hold... <ClipLoader size={24} color="#ffffff" className="flex-none" />
                                        </h3>
                                    )
                                }
                            </div>

                            <div className="flex justify-center items-center gap-[12px] mt-[35px] w-full">
                                {
                                    stakedNftList.length > 0 && !isLoading && (
                                        <button
                                            disabled={isProcessing}
                                            className="flex justify-center items-center gap-[10px] gradient-btn full-rounded min-w-[120px] h-[40px] px-[26px] text-[20px] transition duration-300 hover:transform hover:scale-110 opacity-100"
                                            onClick={handleBulkUnStake}
                                            onMouseEnter={() => playHover()}
                                        >
                                            {
                                                isProcessing ? (
                                                    <>
                                                        <span>Processing</span>{" "}
                                                        <ClipLoader size={18} color="#ffffff" />
                                                    </>
                                                ) : (
                                                    "Redeem"
                                                )
                                            }
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="overlay"></div>
                </div>

                {/* Modal for dialogue */}
                <div
                    className="fixed top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] bg-black/50 z-[1040] overflow-y-auto hidden justify-center mobile-height"
                    style={{ display: dialogueModalOpen ? "flex" : "none" }}
                >
                    <div className="flex justify-center items-center w-full h-full py-[4rem] md:py-[5rem]">
                        <div className="flex flex-col items-center max-w-[900px] max-h-[70%] w-full h-full mx-[2rem] rounded-[10px] font-semibold p-[36px_30px_30px] flex-1 bg-[#090C0E] shadow-[0px_0px_7px_#00A5FE] relative z-[1050]">
                            <button
                                className="sm:flex justify-center items-center absolute top-0 right-0 p-[10px] cursor-pointer bg-none border-none rounded-[6px] transform hover:scale-110 transition-all"
                                onClick={() => setDialogueModalOpen(false)}
                                onMouseEnter={() => playHover()}
                            >
                                <svg width="14" height="14" className="fill-white transition-[fill_200ms_ease_0s]">
                                    <path d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z"></path>
                                </svg>
                            </button>

                            <div className="w-full">
                                <h1 className="font-semibold text-[26px] leading-[41px] mt-0 w-full text-center text-white">{"#1 Brawlers’ Bounty"}</h1>
                            </div>

                            <div className="grow w-full h-[300px] mt-[25px] overflow-x-hidden overflow-y-auto no-scroll-bar">
                                <div className="flex flex-col gap-[20px] text-[20px] font-normal leading-[20px]">
                                    <h3>In the vast, untamed digital frontier of the Solana ecosystem, whispers of the renowned BiteClub reach your keen ears.</h3>
                                    <h3>As a gaming platform where skill, cunning, and fortune converge, BiteClub offers a tantalizing opportunity for those bold enough to join its ranks.</h3>
                                    <h3>Feeling the call of adventure and the allure of potential profits, you set out on a seven-day journey to become a part of the BiteClub family and earn your first cut.</h3>
                                    <h3>As you venture through the unpredictable landscape of the Solana blockchain, you will face formidable challenges and encounter fierce competitors.</h3>
                                    <h3>With each passing day, your determination and resilience will be tested as you navigate the treacherous terrain, uncovering the secrets of the blockchain and honing your skills in this digital Wild West.</h3>
                                    <h3>After seven grueling days, you will arrive at the BiteClub, weary but triumphant. As you enter the sophisticated and polished gaming platform, you know that your arduous journey has prepared you for the high-stakes games and intense competition that await.</h3>
                                    <h3>As a new member of the BiteClub family, you will stand tall and proud, ready to claim your first cut and forge your destiny in the thrilling world of Solana's most prestigious gaming platform.</h3>
                                    <h3>By completing this initiation quest, you will have earned your place among the ranks of BiteClub's elite, embracing the exciting challenges and rewards that come with being a part of this groundbreaking enterprise.</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="overlay"></div>
                </div>
            </Slide>
        </div>
    );
};

export default OldQuests;