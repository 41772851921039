import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Countdown from "react-countdown";
import * as anchor from "@project-serum/anchor";
import { Program, AnchorProvider } from "@project-serum/anchor";
import { Wallet } from '@project-serum/anchor/dist/cjs/provider';
import { useWallet } from "@solana/wallet-adapter-react";
import { Connection, LAMPORTS_PER_SOL, PublicKey, Transaction } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID, createAssociatedTokenAccountInstruction, getAssociatedTokenAddress } from '@solana/spl-token';
import { storeWagerItems } from '../../data';
import { delay, displayName, getBlockTime, getFormattedTimeDiff, getFormattedTokenBalance } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedReducer } from '../../store';
import { wagerNfts } from '../../utils/config';
import User from '../../interfaces/User';
import { IWagerUsreInfo } from '../../interfaces/WagerSystem';
import wagerSystemIdl from '../../idls/wager_system-idl.json';
import groupImg from '../../assets/store-group-img.webp';
import solLightGrayIcon from '../../assets/sol-symbol-light-gray.svg';
import solWhiteIcon from '../../assets/sol-symbol-white.svg';
import boneIcon from '../../assets/bone.svg';
import swapIcon from '../../assets/swap.png'
import axios from 'axios';
import { ISale } from '../../interfaces/Shop';
import { Sockets } from '../../reducers/sockets';
import useSound from "use-sound";

const connection = new Connection(process.env.REACT_APP_RPC_URL!, "confirmed");
const network = process.env.REACT_APP_NETWORK;
const programID = new PublicKey(wagerSystemIdl.metadata.address);
const bnsTokenMint = new PublicKey(process.env.REACT_APP_BONES_TOKEN_MINT!);
const bnsReceiverWallet = new PublicKey(process.env.REACT_APP_BNS_RECEIVER_WALLET!);

const Store = () => {
    const wallet = useWallet();
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
    const dispatch = useDispatch();
    const user = useSelector<CombinedReducer, User>((state) => state.user);
    const provider = new AnchorProvider(connection, wallet as Wallet, { commitment: 'processed' });
    const program = new Program(wagerSystemIdl as anchor.Idl, programID, provider);
    const sockets = useSelector<CombinedReducer, Sockets>((state) => state.sockets);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [wagerNftVaultBalances, setWagerNftVaultBalances] = useState<number[]>(new Array(5).fill(0));
    const [recentSales, setRecentSales] = useState<ISale[]>([]);
    const [remainingTime, setRemainingTime] = useState<number>(60);
    const [isPurchasable, setIsPurchasable] = useState<boolean>(false);
    const [isStore, setIsStore] = useState<boolean>(true);

    const getBalance = async (publicKey: PublicKey) => {
        return await connection.getBalance(publicKey);
    }

    const getTokenBalance = async (publicKey: PublicKey) => {
        try {
            return (await connection.getTokenAccountBalance(publicKey)).value.amount;
        } catch {
            return 0
        }
    }

    const initialize = async () => {
        try {
            setIsLoading(true);

            const wagerNftVaultBalances: number[] = [];

            const recentSales = (await axios.get('/api/shop/recentSales')).data;
            setRecentSales(recentSales);

            await Promise.all(wagerNfts.map(async (wagerNft, index) => {
                try {
                    const wagerNftVault = PublicKey.findProgramAddressSync(
                        [wagerNft.toBuffer()],
                        program.programId
                    )[0];

                    const wagerNftVaultBalance = await connection.getTokenAccountBalance(wagerNftVault);
                    wagerNftVaultBalances[index] = wagerNftVaultBalance.value.uiAmount || 0;
                } catch {
                    wagerNftVaultBalances[index] = 0;
                }
            }));

            setWagerNftVaultBalances(wagerNftVaultBalances);

            if (wallet?.publicKey) {
                const userInfoAccount = PublicKey.findProgramAddressSync(
                    [
                        Buffer.from('user-info'),
                        wallet.publicKey.toBuffer()
                    ],
                    program.programId
                )[0];

                const userBnsTokenAccount = await getAssociatedTokenAddress(bnsTokenMint, wallet.publicKey);

                const [userBalance, blockTime] = await Promise.all([
                    getBalance(wallet?.publicKey),
                    getBlockTime()
                ]);

                dispatch({ type: "UPDATE_USER_ONCHAIN_BALANCE", payload: userBalance });

                try {
                    const userInfo = await program.account.userInfo.fetch(userInfoAccount);
                    console.log('userInfo', userInfo);
                    const remainingTime = ((userInfo as any).boughtAt.toNumber() + 15 * 60) - blockTime;

                    setRemainingTime(remainingTime > 0 ? remainingTime : 0);
                    setIsPurchasable(remainingTime > 0 ? false : true);
                } catch {
                    setRemainingTime(0);
                    setIsPurchasable(true);
                }

                try {
                    const userTokenBalance = await getTokenBalance(userBnsTokenAccount);
                    dispatch({ type: "UPDATE_USER_ONCHAIN_TOKEN_BALANCE", payload: userTokenBalance });
                } catch { }
            }
        } catch (e) {
            console.log('e: ', e);
        }

        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }

    const handleBuyWagerNft = async (index: number, amount: number) => {
        try {
            if (!wallet?.publicKey || !user?.publicKey) {
                return toast.warn("Please connect wallet");
            }

            if (!isPurchasable) {
                return toast.warn("Not time to purchase");
            }

            if (!wagerNftVaultBalances[index] || wagerNftVaultBalances[index] < 1) {
                return toast.warn("There is no wager NFT in stock");
            }

            if ((user?.onchainTokenBalance || 0) / LAMPORTS_PER_SOL < storeWagerItems[index].bonesAmount) {
                return toast.warn("Insufficient token balance");
            }

            setIsLoading(true);

            const poolAccount = PublicKey.findProgramAddressSync(
                [Buffer.from('wager-system-pool')],
                program.programId
            )[0];

            const poolSinger = PublicKey.findProgramAddressSync(
                [poolAccount.toBuffer()],
                program.programId
            )[0];

            const userInfoAccount = PublicKey.findProgramAddressSync(
                [
                    Buffer.from('user-info'),
                    wallet.publicKey.toBuffer()
                ],
                program.programId
            )[0];

            const userBnsTokenAccount = await getAssociatedTokenAddress(bnsTokenMint, wallet.publicKey);
            const bnsReceiverTokenAccount = await getAssociatedTokenAddress(bnsTokenMint, bnsReceiverWallet);

            const wagerNftMint = wagerNfts[index];
            const wagerNftVault = PublicKey.findProgramAddressSync(
                [wagerNftMint.toBuffer()],
                program.programId
            )[0];

            const userWagerNftAccount = await getAssociatedTokenAddress(wagerNftMint, wallet.publicKey);
            const userWagerNftAccountInfo = await connection.getAccountInfo(userWagerNftAccount);

            const transaction = new Transaction();
            transaction.add(
                anchor.web3.ComputeBudgetProgram.setComputeUnitPrice({
                    microLamports: 245000,
                })
            );
            transaction.add(
                anchor.web3.ComputeBudgetProgram.setComputeUnitLimit({
                    units: 800000,
                })
            );

            if (userWagerNftAccountInfo == null) {
                transaction.add(
                    createAssociatedTokenAccountInstruction(
                        wallet.publicKey,
                        userWagerNftAccount,
                        wallet.publicKey,
                        wagerNftMint
                    )
                );
            }

            transaction.add(
                await program.methods
                    .buy(new anchor.BN(amount * LAMPORTS_PER_SOL))
                    .accounts({
                        signer: wallet.publicKey,
                        pool: poolAccount,
                        poolSigner: poolSinger,
                        userInfo: userInfoAccount,
                        bnsTokenMint: bnsTokenMint,
                        userBnsTokenAccount: userBnsTokenAccount,
                        bnsReceiver: bnsReceiverTokenAccount,
                        wagerNftMint: wagerNftMint,
                        wagerNftVault: wagerNftVault,
                        userWagerNftAccount: userWagerNftAccount,
                        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
                        systemProgram: anchor.web3.SystemProgram.programId,
                        tokenProgram: TOKEN_PROGRAM_ID,
                    })
                    .instruction()
            );

            const tx = await wallet.sendTransaction(transaction, connection);
            console.log("Your transaction signature", tx);

            await delay(3000);

            toast.success("Congratulations!");

            try {
                await axios.post('/api/shop/buy', { amount: amount * LAMPORTS_PER_SOL });
            } catch { }

            setRemainingTime(15 * 60);
            setIsPurchasable(false);
            const newWagerNftVaultBalances = wagerNftVaultBalances;
            newWagerNftVaultBalances[index] -= 1;
            setWagerNftVaultBalances(newWagerNftVaultBalances);
            dispatch({
                type: "UPDATE_USER_ONCHAIN_TOKEN_BALANCE",
                payload: (user.onchainTokenBalance! - storeWagerItems[index].bonesAmount * LAMPORTS_PER_SOL)
            });
        } catch (e) {
            console.log('e', e);
        }

        setIsLoading(false);
    }

    const renderer = ({ minutes, seconds }: any) => {
        return (
            <div className='flex justify-center items-center gap-[12px]'>
                <div className='flex flex-col w-[38px] sm:w-[50px]'>
                    <div className='flex justify-center items-center w-full h-[38px] sm:h-[60px] text-[14px] sm:text-[24px] font-bold leading-normal bg-[#222C36] border border-solid border-[#808080]'>{minutes}</div>
                    <div className='flex justify-center items-center w-full h-[18px] sm:h-[24px] text-[8px] sm:text-[8px] font-bold leading-normal bg-[#1A2129] border border-solid border-[#808080] border-t-0'>Minutes</div>
                </div>
                <div className='text-[24px] font-bold leading-normal'>:</div>
                <div className='flex flex-col w-[38px] sm:w-[50px]'>
                    <div className='flex justify-center items-center w-full h-[38px] sm:h-[60px] text-[14px] sm:text-[24px] font-bold leading-normal bg-[#222C36] border border-solid border-[#808080]'>{seconds}</div>
                    <div className='flex justify-center items-center w-full h-[18px] sm:h-[24px] text-[8px] sm:text-[8px] font-bold leading-normal bg-[#1A2129] border border-solid border-[#808080] border-t-0'>Seconds</div>
                </div>
            </div>
        );
    }

    useEffect(() => {
        if (!sockets?.shop || !sockets?.user) return

        sockets.shop.on('newSale', (sale: ISale) => {
            setRecentSales(current => [sale, ...current]);
        });
    }, [sockets]);

    useEffect(() => {
        (async () => {
            await initialize();
        })();
    }, [wallet?.publicKey, user?.publicKey]);

    return (
        <div className="flex flex-col items-center w-full min-h-[calc(100vh-59px)] md:min-h-[calc(100vh-70px)]">
            <div className='w-full max-w-[1280px] pt-0 sm:pt-[50px] p-[1px]'>
                {/* Banner */}
                <div className="w-full h-[69px] sm:h-[203px] bg-[url('/src/assets/store-banner-img.webp')] bg-center bg-no-repeat bg-cover"></div>

                {/* Description */}
                <div className='relative flex justify-center items-center w-full mt-[25px] sm:mt-[20px] pb-[80px]'>
                    <div className='flex flex-col justify-between items-center gap-[14px] sm:gap-[22px] w-full max-w-[320px] sm:max-w-[540px] pb-[30px] md:pb-[20px] sm:pb-[10px]'>
                        <div className='text-[16px] sm:text-[32px] font-bold leading-normal'>Welcome to the BiteClub store</div>
                        <div className='text-[14px] sm:text-[20px] text-center font-normal'>Please browse our wares. If the item is out of stock check back often as they are randomly restocked!</div>
                    </div>

                    {/* Buy bone & cooldown */}
                    <div className='absolute left-0 bottom-[30px] md:bottom-[0px] flex justify-center items-center w-full'>
                        <div className='grid grid-cols-12 justify-between items-end w-full h-[73px] sm:h-[118px]'>
                            <div className='col-span-12 flex md:hidden justify-center items-center'>
                                <div className='flex justify-center items-center border-b-[2px] border-b-white py-[2px]'>
                                    {
                                        isStore ? (
                                            <button
                                                className='text-[12px] font-bold leading-[17.8px]'
                                                onClick={() => setIsStore(false)}
                                                onMouseEnter={() => playHover()}
                                            >
                                                See recent Sales
                                            </button>
                                        ) : (
                                            <button
                                                className='text-[12px] font-bold leading-[17.8px]'
                                                onClick={() => setIsStore(true)}
                                                onMouseEnter={() => playHover()}
                                            >
                                                Go back to Store
                                            </button>
                                        )
                                    }
                                </div>
                            </div>

                            <div className='col-span-6 md:col-span-4 flex justify-center items-center gap-[16px] w-[200px] h-[40px] sm:h-[50px] bg-[#222C36] border border-solid border-[#808080] rounded-[5px] px-[10px]'>
                                <div className='text-[14px] sm:text-[16px] font-medium'>$BONES</div>
                                <div className='flex justify-center items-center gap-[2px]'>
                                    <span className='text-[16px] font-medium'>{getFormattedTokenBalance((user?.onchainTokenBalance || 0) / LAMPORTS_PER_SOL)}</span>
                                    <img src={boneIcon} className='w-[14px]' alt='bitcoin-icon' />
                                </div>
                                <a
                                    href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=FQaYG6QVedMoqU3Jdfgt8rVrhL7u6nzH5SgiHGRKYZqo&fixed=in'
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className='flex justify-center items-center w-[56px] h-[24px] sm:h-[28px] text-[12px] sm:text-[16px] font-medium leading-[1px] bg-[#012847] border border-solid border-[#00A5FE] rounded-[5px] shadow-[0px_0px_20px_-4px_#00A5FE] transition-all duration-300 ease-in-out hover:bg-[rgba(0,165,254,0.60)] hover:border-[#336699] hover:shadow-[0px_0px_18px_4px_#4186D7] pl-[1px] sm:pl-[2px] pr-[1px] sm:pr-[2px]'
                                >
                                    <img src={swapIcon} className='w-[14px]' alt='swap-icon' />
                                </a>
                            </div>

                            <div className='col-span-4 hidden md:flex justify-center items-center'>
                                <div className='flex justify-center items-center border-b-[2px] border-b-white py-[2px]'>
                                    {
                                        isStore ? (
                                            <button
                                                className='text-[12px] font-bold leading-[17.8px]'
                                                onClick={() => setIsStore(false)}
                                                onMouseEnter={() => playHover()}
                                            >
                                                See recent Sales
                                            </button>
                                        ) : (
                                            <button
                                                className='text-[12px] font-bold leading-[17.8px]'
                                                onClick={() => setIsStore(true)}
                                                onMouseEnter={() => playHover()}
                                            >
                                                Go back to Store
                                            </button>
                                        )
                                    }
                                </div>
                            </div>

                            <div className='col-span-6 md:col-span-4  flex flex-col justify-center items-end'>
                                <div className='flex flex-col justify-center items-center gap-[4px] sm:gap-[10px] pr-2 sm:pr-0'>
                                    <div className='text-[12px] sm:text-[16px] font-bold leading-normal'>Purchase Cooldown</div>
                                    {
                                        isLoading || remainingTime == 0 ? (
                                            renderer({
                                                minutes: 0,
                                                seconds: 0
                                            })
                                        ) : (
                                            <Countdown
                                                date={Date.now() + remainingTime * 1000}
                                                onComplete={() => {
                                                    setRemainingTime(0);
                                                    setIsPurchasable(true);
                                                }}
                                                className="text-white"
                                                renderer={renderer}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    isStore ? (
                        <div className='flex justify-center items-center w-full p-[14px_14px] sm:p-[30px_14px] bg-[#1A2129] border border-solid border-[#808080] rounded-[5px] mt-[5px] sm:mt-[20px] mb-[30px]'>
                            {/* Card group */}
                            <div className='flex flex-wrap sm:justify-between items-center w-full max-w-[1188px] gap-[12px]'>
                                {
                                    storeWagerItems.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className='flex flex-col items-center w-[110px] sm:w-[210px] h-[157px] sm:h-[280px] bg-[#010919] border-[1.1px] sm:border-[1.75px] border-solid border-[#808080] rounded-[5.5px] sm:rounded-[8.75px]'
                                            >
                                                <div className='flex justify-center items-center w-full h-[103px] sm:h-[181.5px]'>
                                                    <img src={groupImg} className='w-full h-full' alt='group-img' />
                                                </div>

                                                <div className='grow flex flex-col items-center'>
                                                    <div className='flex justify-center items-center gap-[1px] sm:gap-[2px] w-full'>
                                                        <div className='flex justify-center items-center gap-[1.8px] sm:gap-[2.75px]'>
                                                            <span className='text-[15px] sm:text-[24px] font-bold leading-[100%]'>{item.wagerAmount}</span>
                                                            <img src={solLightGrayIcon} className='w-[11.5px] sm:w-[18px]' alt='sol-symbol' />
                                                        </div>
                                                        <span className='text-[12.5px] sm:text-[20px] font-bold leading-normal'>Wager</span>
                                                    </div>

                                                    <div className='flex justify-center items-center w-full text-[7.5px] sm:text-[14px] text-[#808080] font-medium leading-normal'>
                                                        {`In Stock: ${wagerNftVaultBalances[index]}`}
                                                    </div>
                                                </div>

                                                <div className='flex justify-center items-center w-full mb-[4.5px] sm:mb-[7px]'>
                                                    {
                                                        !wagerNftVaultBalances[index] || wagerNftVaultBalances[index] < 1 ? (
                                                            <div className='flex justify-center items-end sm:items-center h-[14px] sm:h-[21px]'>
                                                                <span className='text-[7px] sm:text-[12px] text-[#46FF78] text-center font-medium'>Restocks can happen at any time!</span>
                                                            </div>
                                                        ) : (
                                                            <button
                                                                disabled={isLoading || !isPurchasable}
                                                                className={`flex justify-center items-center gap-[2px] sm:gap-[3px] w-[58px] sm:w-[120px] h-[18px] sm:h-[30px] bg-[rgba(0,165,254,0.20)] border border-solid border-[#00A5FE] rounded-[5px] shadow-[0px_0px_20px_-4px_#00A5FE] transition-all duration-300 ease-in-out ${isLoading || !isPurchasable ? 'opacity-30' : 'hover:bg-[rgba(0,165,254,0.60)] hover:border-[#336699] hover:shadow-[0px_0px_18px_4px_#336699]'} pl-[10px] sm:pl-[12px]`}
                                                                onClick={() => handleBuyWagerNft(index, item.wagerAmount)}
                                                                onMouseEnter={() => playHover()}
                                                            >
                                                                <span className='text-[10px] sm:text-[20px] font-medium'>{item.bonesAmount}</span>
                                                                <img src={boneIcon} className='w-[12px] sm:w-[16px]' alt='bone-img' />
                                                            </button>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    ) : (
                        <div className='flex justify-center items-center w-full mt-[5px] sm:mt-[20px] mb-[30px]'>
                            <div className='w-full border border-solid border-[#222C36] rounded-[4px]'>
                                <div className='w-full flex items-center h-[40px] md:h-[50px]'>
                                    <div className='w-1/3 md:w-1/4 h-full flex justify-center items-center text-[12px] md:text-[20px] font-bold border-r border-r-[#222C36]'>Item</div>
                                    <div className='w-1/3 md:w-1/2 h-full flex justify-center items-center text-[12px] md:text-[20px] font-bold border-r border-r-[#222C36]'>Buyer</div>
                                    <div className='w-1/3 md:w-1/4 h-full flex justify-center items-center text-[12px] md:text-[20px] font-bold'>Time</div>
                                </div>

                                <div className='w-full flex flex-col bg-[#1A2129] max-h-[320px] md:max-h-[300px] overflow-y-auto thin-scroll-bar'>
                                    {
                                        !isLoading ? (
                                            recentSales.length > 0 ? (
                                                recentSales.map((item, index) => {
                                                    return (
                                                        <div key={index} className='w-full flex items-center text-[10px] md:text-[18px]'>
                                                            <div className='w-1/3 md:w-1/4 h-[40px] md:h-[50px] flex items-center border-t border-t-[#222C36] border-r border-r-[#222C36]'>
                                                                <div className='flex items-center gap-[5px] md:gap-[25px] w-full px-[5px] md:px-[15px]'>
                                                                    <div className='flex justify-center items-center w-[26px] md:w-[46px]'>
                                                                        <img src={groupImg} alt='store-group-img' className='w-full rounded-[5px] md:rounded-[10px]' />
                                                                    </div>

                                                                    <div className='flex justify-center items-center gap-[5px] md:gap-[20px]'>
                                                                        <div className='flex justify-center items-center gap-[2px]'>
                                                                            <div className='leading-[100%]'>{(item.amount / LAMPORTS_PER_SOL).toFixed(2)}</div>
                                                                            <div className='flex justify-center items-center w-[13px] md:w-[21px] h-[8px] md:h-[12px]'>
                                                                                <img src={solWhiteIcon} alt='sol-white-icon' className='w-full h-full' />
                                                                            </div>
                                                                        </div>
                                                                        <div>Wager</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='w-1/3 md:w-1/2 h-[40px] md:h-[50px] flex justify-center items-center text-center font-normal border-t border-t-[#222C36] border-r border-r-[#222C36]'>
                                                                <Link to={`/user-stats/${item?.user?.publicKey}`} className='underline'>
                                                                    {displayName(item.user)}
                                                                </Link>
                                                            </div>
                                                            <div className='w-1/3 md:w-1/4 h-[40px] md:h-[50px] flex justify-center items-center text-center font-normal border-t border-t-[#222C36]'>
                                                                {getFormattedTimeDiff(Math.floor(Date.now() / 1000) - Math.floor(new Date(item.createdAt).getTime() / 1000))}
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <div className='w-full flex justify-center items-center text-[10px] md:text-[16px] text-[#808080]'>
                                                    <div className='w-full h-[40px] md:h-[50px] flex justify-center items-center border-t border-t-[#222C36]'>
                                                        <span>No sales</span>
                                                    </div>
                                                </div>
                                            )
                                        ) : (
                                            <div className='w-full flex justify-center items-center text-[10px] md:text-[16px] text-[#808080]'>
                                                <div className='w-full 4 h-[40px] md:h-[50px] flex justify-center items-center border-t border-t-[#222C36]'>
                                                    <span>Loading...</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default Store;