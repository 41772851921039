import React from "react";

const BannedUser = () => {
    return (
        <div className="flex flex-col items-center mt-[100px]">
            <h1 className="w-100 text-center">Your account has been banned</h1>
        </div>
    );
};

export default BannedUser;