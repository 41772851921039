import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Countdown from "react-countdown";
import ClipLoader from "react-spinners/ClipLoader";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import axios from "axios";
import { CombinedReducer } from "../../../store";
import { Sockets } from "../../../reducers/sockets";
import { IInitialState } from "../../../reducers/lottery";
import Lottery from "../../../interfaces/Lottery";
import { LotteryStatusEnum } from "../../../enums";

const SolanaballAdmin = () => {
    const dispatch = useDispatch();
    const [ticketPrice, setTicketPrice] = useState<number | null>(0.5);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [lastCheckedRound, setLastCheckedRound] = useState<Lottery>();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const sockets = useSelector<CombinedReducer, Sockets>(
        (state) => state.sockets
    );
    const lottery = useSelector<CombinedReducer, IInitialState>(
        (state) => state.lottery
    );

    const initialize = async () => {
        try {
            const [lastRoundRsp, finishedRoundsRsp] = await Promise.all([
                axios.get("/api/lottery-game/lastRound"),
                axios.get("/api/lottery-game/finishedRounds")
            ]);

            const [lastRound, finishedRounds] = [lastRoundRsp.data, finishedRoundsRsp.data];
            if (lastRound) {
                setLastCheckedRound(lastRound);
            } else {
                if (finishedRounds.length > 0) {
                    setLastCheckedRound(finishedRounds[0]);
                }
            }

            if (lastRound) {
                setTicketPrice(lastRound.ticketPrice / LAMPORTS_PER_SOL);
            }
            dispatch({ type: "SET_LAST_ROUND", payload: lastRound });
        } catch (_) { }
    };

    const inputTicketPrice = (input: string) => {
        if (!input) return setTicketPrice(null);

        if (Number(input) >= 0) setTicketPrice(Number(input));
        else setTicketPrice(0);
    };

    const handleTriggerLottery = async () => {
        setIsProcessing(true);

        try {
            if (lastCheckedRound === undefined) {
                return toast.warn("Lottery not opened yet.");
            }

            await axios.post("/api/lottery-game/handle-trigger-lottery");

            await initialize();

            toast.success(`Successful to ${lastCheckedRound?.hasStopped ? 'start' : 'stop'} lottery.`);
        } catch (e: any) {
            console.log('e', e);
            if (e?.response?.data) {
                toast.error(e.response.data.message.toString(), { autoClose: 3500 });
            } else {
                toast.error(`Successful to ${lastCheckedRound?.hasStopped ? 'start' : 'stop'} lottery.`);
            }
        }

        setIsProcessing(false);
    };

    const handleChangeTicketPrice = async () => {
        try {
            setIsLoading(true);
            const lastRound = lottery?.lastRound;
            if (lastRound?.status !== LotteryStatusEnum.Open) {
                return toast.warn("Lottery not opened yet.");
            }

            if (!ticketPrice) {
                return toast.warn("Invalid ticket price.");
            }

            await axios.post("/api/lottery-game/change-ticket-price", { ticketPrice });

            await initialize();

            toast.success("Successful to change ticket price.");
            setIsLoading(false);
        } catch (e: any) {
            console.log('e', e);
            if (e?.response?.data) {
                toast.error(e.response.data.message.toString(), { autoClose: 3500 });
            } else {
                toast.error("Failed to change ticket price.");
            }

            setIsLoading(false);
        }
    };

    const renderer = ({ days, hours, minutes, seconds }: any) => {
        return (
            <div>
                <div className="flex justify-center items-center gap-[5px] text-[13px] md:text-[16px] font-medium leading-[19px] md:leading-[23px]">
                    <div className="w-[20px]">{days}</div>
                    <div className="w-[10px]">:</div>
                    <div className="w-[20px]">{hours}</div>
                    <div className="w-[10px]">:</div>
                    <div className="w-[20px]">{minutes}</div>
                    <div className="w-[10px]">:</div>
                    <div className="w-[20px]">{seconds}</div>
                </div>
                <div className="flex justify-center items-center gap-[5px] text-[8.5px] md:text-[10px] text-[#808080] font-medium leading-[12px] md:leading-[14px]">
                    <div className="w-[20px]">D</div>
                    <div className="w-[10px]"></div>
                    <div className="w-[20px]">Hr</div>
                    <div className="w-[10px]"></div>
                    <div className="w-[20px]">Min</div>
                    <div className="w-[10px]">:</div>
                    <div className="w-[20px]">Sec</div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        (async () => {
            await initialize();
        })();
    }, []);

    useEffect(() => {
        if (!sockets.lottery) return;

        sockets.lottery.on("newLottery", (lottery: Lottery) =>
            dispatch({ type: "SET_LAST_ROUND", payload: lottery })
        );
        sockets.lottery.on("closedLottery", (finishedLottery: Lottery) =>
            dispatch({ type: "CLOSED_LOTTERY", payload: finishedLottery })
        );
    }, [sockets.lottery]);

    return (
        <div className="flex flex-col items-center w-full mt-[50px]">
            <h1 className="text-[20px] font-medium mb-1">Solanaball</h1>

            {lottery?.lastRound?.status === LotteryStatusEnum.Open ? (
                <>
                    <Countdown
                        date={lottery?.lastRound.endedAt}
                        className="text-white"
                        renderer={renderer}
                    />
                </>
            ) : (
                <h1>Tickets on sale soon</h1>
            )}

            <div className="flex flex-col items-center gap-[10px] mt-3">
                <div className="flex items-center w-full">
                    <span className="min-w-[110px]">Ticket price:</span>
                    <input
                        disabled={isLoading || lottery?.lastRound?.status !== LotteryStatusEnum.Open}
                        type="number"
                        className={`bg-[#1D262F] rounded-[10px] w-full h-[37px] text-[18px] px-[15px] font-medium outline-none ${(isLoading || lottery?.lastRound?.status !== LotteryStatusEnum.Open) ? 'opacity-60' : 'opacity-100'}`}
                        placeholder="0.5"
                        value={ticketPrice === null ? "" : ticketPrice}
                        onChange={(e) => inputTicketPrice((e.target as HTMLInputElement).value)}
                    />
                </div>
            </div>

            <div className="flex justify-center items-center gap-[10px]">
                <button
                    disabled={isLoading || isProcessing || lastCheckedRound === undefined}
                    className={`flex justify-center items-center gap-[10px] gradient-btn full-rounded py-[5px] w-[160px] mt-4 ${(isLoading || isProcessing || lastCheckedRound === undefined) ? 'opacity-60' : 'opacity-100'}`}
                    onClick={() => handleTriggerLottery()}
                >
                    {!isProcessing ? lastCheckedRound?.hasStopped ? 'Start' : 'Stop' : 'Processing...'}
                    {isProcessing ? <ClipLoader size={15} color="#ffffff" /> : null}
                </button>

                <button
                    disabled={isLoading || lottery?.lastRound?.status !== LotteryStatusEnum.Open}
                    className={`flex justify-center items-center gap-[10px] gradient-btn full-rounded py-[5px] w-[220px] mt-4 ${(isLoading || lottery?.lastRound?.status !== LotteryStatusEnum.Open) ? 'opacity-60' : 'opacity-100'}`}
                    onClick={() => handleChangeTicketPrice()}
                >
                    {!isLoading ? 'Change ticket price' : 'Processing...'}
                    {isLoading ? <ClipLoader size={15} color="#ffffff" /> : null}
                </button>
            </div>
        </div>
    );
};

export default SolanaballAdmin;