import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import TicketNumbers from "./TicketNumbers";
import { CombinedReducer } from "../../store";
import { IInitialState } from "../../reducers/lottery";
import { createTwitterShareUrl } from "../../utils";
import User from "../../interfaces/User";
import Lottery from "../../interfaces/Lottery";
import LotteryGame from "../../interfaces/LotteryGame";
import GlobalState from "../../interfaces/GlobalState";
import confettiImg from "../../assets/confetti.svg";
import greenSolSymbol from "../../assets/sol-symbol-green.svg";
import twitter2Img from "../../assets/twitter2.svg";
import axios from "axios";

interface IParams {
    showModal: boolean;
    onMouseEnter: () => void;
    handleShowModal: (show: boolean) => void;
}

const MyResultsModal = (params: IParams) => {
    const { showModal, onMouseEnter, handleShowModal } = params;
    const user = useSelector<CombinedReducer, User>((state) => state.user);
    const { finishedRounds } = useSelector<CombinedReducer, IInitialState>((state) => state.lottery);
    const globalState = useSelector<CombinedReducer, GlobalState>((state) => state.globalState);

    const [roundNumber, setRoundNumber] = useState<number>(0);
    const [lastFinishedRoundNumber, setLastFinishedRoundNumber] = useState<number>(0);
    const [lotteryInfo, setLotteryInfo] = useState<Lottery>();
    const [lotteryGame, setLotteryGame] = useState<LotteryGame>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const initialize = async (roundNumber: number) => {
        setIsLoading(true);

        try {
            const config = {
                params: { roundNumber },
            };
            const { lotteryInfo, lotteryGame } = (await axios.get("/api/lottery-game/myResults", config)).data;

            console.log({ lotteryInfo });
            console.log({ lotteryGame });

            setLotteryInfo(lotteryInfo);
            setLotteryGame(lotteryGame);
        } catch { }

        setIsLoading(false);
    }

    const handleNextRound = async () => {
        let nextRoundNumber = roundNumber + 1;
        if (nextRoundNumber > lastFinishedRoundNumber) {
            return;
        }
        setRoundNumber(nextRoundNumber);

        await initialize(nextRoundNumber);
    }

    const handlePrevRound = async () => {
        let prevRoundNumber = roundNumber - 1;
        if (prevRoundNumber < 1) {
            return;
        }
        setRoundNumber(prevRoundNumber);

        await initialize(prevRoundNumber);
    }

    useEffect(() => {
        if (roundNumber > 0 && !!user) {
            initialize(roundNumber);
        }
    }, [roundNumber, user]);

    useEffect(() => {
        if (finishedRounds.length > 0) {
            const lastFinishedRound = finishedRounds[0];

            setRoundNumber(lastFinishedRound.lotteryCount || 0);
            setLastFinishedRoundNumber(lastFinishedRound.lotteryCount || 0);
        }
    }, [finishedRounds.length]);

    return (
        <div
            className="justify-center fixed top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] sm:bg-black/50 z-[1040] overflow-y-auto"
            style={{ display: showModal ? "flex" : "none" }}
        >
            <div className={`flex flex-col items-center w-full h-fit mx-[25px] mt-[90px] md:mt-[100px] mb-[65px] md:mb-[40px] xl:pl-[70px] ${globalState?.isLiveChatExpanded ? 'xl:pr-[336px]' : 'xl:pr-[70px]'}`}>
                <div className="relative flex flex-col items-center gap-[16px] md:gap-[24px] w-full max-w-[500px] h-[calc(100vh-155px)] md:h-[calc(100vh-140px)] p-[30px_37px] md:p-[40px_50px] bg-[#090C0E] rounded-[8px] border-[1.5px] border-solid border-[#000000] shadow-[0px_0px_8px_#00A5FE] z-[1050]">
                    {/* Close button */}
                    <button
                        className="sm:flex justify-center items-center absolute top-0 right-0 p-[10px] cursor-pointer bg-none border-none rounded-[6px] transform hover:scale-110 transition-all"
                        onClick={() => handleShowModal(false)}
                        onMouseEnter={() => onMouseEnter}
                    >
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 16L16 1M1 1L16 16" stroke="#BEBEBE" strokeWidth="2" />
                        </svg>
                    </button>

                    {/* Modal title */}
                    <div className="text-[24px] md:text-[32px] text-center font-bold leading-[35px] gold-font md:leading-[47.5px]">My Results</div>

                    {/* Modal body */}
                    <div className="grow flex flex-col items-center gap-[16px] md:gap-[12px] w-full overflow-y-auto">
                        {/* Round number */}
                        <div className="flex justify-center items-center gap-[12px] text-[16px] md:text-[20px] font-bold leading-[24px] md:leading-[30px]">
                            <button
                                disabled={roundNumber <= 1 || isLoading || lotteryInfo == undefined}
                                className={`${roundNumber <= 1 || isLoading || lotteryInfo == undefined ? 'opacity-60' : 'opacity-100'}`}
                                onClick={handlePrevRound}
                            >
                                {'<'}
                            </button>

                            <span>{`Round ${lotteryInfo !== undefined ? roundNumber : '-'}`}</span>

                            <button
                                disabled={roundNumber >= lastFinishedRoundNumber || isLoading || lotteryInfo == undefined}
                                className={`${roundNumber >= lastFinishedRoundNumber || isLoading || lotteryInfo == undefined ? 'opacity-60' : 'opacity-100'}`}
                                onClick={handleNextRound}
                            >
                                {'>'}
                            </button>
                        </div>

                        {/* This round winning numbers */}
                        <div className="flex flex-col items-center gap-[12px] md:gap-[16px] w-full border border-solid border-[#808080] bg-[#1A2129] p-[15px] md:p-[20px]">
                            <div className="text-[12px] md:text-[16px] text-[#46FF78] text-center leading-[17px] md:leading-[22px]">This round winning numbers:</div>
                            {
                                !isLoading && lotteryInfo ? (
                                    <TicketNumbers
                                        numArray={lotteryInfo.winningNumber!}
                                        className="text-[10px] md:text-[15px] gap-[5.5px] md:gap-[7px]"
                                        ballSize="w-[25px] md:w-[35px]"
                                        plusFont="text-[18px] font-medium"
                                    />
                                ) : (
                                    <ClipLoader size={25} color="#ffffff" />
                                )
                            }
                        </div>

                        {/* Winning tickets box */}
                        <div className="grow flex flex-col items-center gap-[16px] md:gap-[32px] w-full border border-solid border-[#808080] bg-[#1A2129] p-[16px_15px] md:p-[20px] overflow-y-auto thin-scroll-bar md:no-scroll-bar">
                            {/* Congratulations */}
                            {
                                lotteryGame !== undefined && lotteryGame?.boughtAmount > 0 && (
                                    lotteryGame?.rewardAmount > 0 ? (
                                        <div className="flex justify-center items-center gap-[9px] md:gap-[12px]">
                                            <img src={confettiImg} alt="confetti" className="w-[18px] md:w-[24px] h-[18px] md:h-[24px]" />
                                            <span className="text-[16px] md:text-[24px] text-[#46FF78] leading-[22px] md:leading-[33px]">Congratulations you won!</span>
                                            <img src={confettiImg} alt="confetti" className="w-[18px] md:w-[24px] h-[18px] md:h-[24px]" />
                                        </div>
                                    ) : (
                                        <div className="flex justify-center items-center gap-[9px] md:gap-[12px]">
                                            <span className="text-[16px] md:text-[24px] text-[#808080] leading-[22px] md:leading-[33px]">Sadly you didn't win...</span>
                                        </div>
                                    )
                                )
                            }

                            {/* Winning tickets */}
                            <div className="grow flex flex-col gap-[12px] md:gap-[28px] w-full overflow-y-auto thin-scroll-bar md:no-scroll-bar">
                                <div className="text-[12px] md:text-[16px] text-[#808080] text-center md:text-left leading-[17px] md:leading-[22px]">My winning tickets</div>

                                {
                                    !isLoading && lotteryInfo ? (
                                        lotteryGame && lotteryGame.rewardAmount > 0 ? (
                                            <div className="flex flex-col gap-[20px] md:gap-[28px] w-full overflow-y-auto thin-scroll-bar md:no-scroll-bar">
                                                {
                                                    lotteryGame.matchingCountPerBracket.map((matchingCount, i) => {
                                                        return matchingCount > 0 && (
                                                            <div key={i} className="flex flex-col items-center gap-[9px] md:gap-[12px] w-full">
                                                                <div className="flex justify-between items-center w-full">
                                                                    <span className="text-[15px] md:text-[20px] leading-[21px] md:leading-[28px]">{`Division ${i + 1}:`}</span>
                                                                    <span className="text-[16px] md:text-[20px] text-[#46FF78] leading-[22px] md:leading-[28px]">
                                                                        {`+ ${(lotteryInfo.solPerBracket![i] * lotteryGame.matchingCountPerBracket[i] / LAMPORTS_PER_SOL).toLocaleString()} `}
                                                                        <img src={greenSolSymbol} alt="sol-symbol" className="w-[15px] md:w-[24px] inline-block" />
                                                                    </span>
                                                                </div>

                                                                {
                                                                    lotteryGame.matchingTicketIdsPerBracket[i].map((ticketNumbers, j) => {
                                                                        return (
                                                                            <TicketNumbers
                                                                                key={j}
                                                                                numArray={ticketNumbers}
                                                                                className="text-[10px] md:text-[15px] gap-[5.5px] md:gap-[7px]"
                                                                                ballSize="w-[25px] md:w-[35px]"
                                                                                plusFont="text-[18px] font-medium"
                                                                                matchingNumberIndexArray={lotteryGame.matchingNumberIndexArray[i][j]}
                                                                            />
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        ) : (
                                            <div className="grow flex justify-center items-center w-full">
                                                <span className="text-[14px] md:text-[18px] text-center">
                                                     You didn't have any winning tickets sadly.
                                                </span>
                                            </div>
                                        )
                                    ) : (
                                        <div className="grow flex justify-center items-center w-full">
                                            <ClipLoader size={35} color="#ffffff" />
                                        </div>
                                    )
                                }
                            </div>

                            {/* Total rewards */}
                            <div className="flex flex-col items-center gap-[8px]">
                                {
                                    !isLoading && lotteryInfo && lotteryGame && lotteryGame.rewardAmount > 0 && (
                                        <span className="text-[16px] md:text-[24px] text-[#46FF78] leading-[22px] md:leading-[33px]">You won {(lotteryGame.rewardAmount / LAMPORTS_PER_SOL).toLocaleString()} <img src={greenSolSymbol} alt="sol-symbol" className="w-[15px] md:w-[24px] inline-block" /> in total!</span>
                                    )
                                }

                                {
                                    lotteryInfo && lotteryGame && lotteryGame.rewardAmount > 0 && (
                                        <div className="flex justify-center items-center gap-[16px]">
                                            <a
                                                href={createTwitterShareUrl(`Just won ${(lotteryGame.rewardAmount / LAMPORTS_PER_SOL).toLocaleString()} Sol from Solanball! 💸 \n Thanks  @BiteClubSOL 💰`)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex justify-center items-center gap-[6px] md:gap-[8px] w-[75px] md:w-[100px] h-[24px] md:h-[32px] rounded-full border border-solid border-[#808080] bg-[rgba(0,0,0,0.20)]"
                                            >
                                                <span className="text-[12px] md:text-[16px] leading-[1px]">Share</span>
                                                <img src={twitter2Img} alt="twitter" className="w-[9px] md:w-[12px] h-[9px] md:h-[12px]" />
                                            </a>

                                            <button className="flex justify-center items-center w-[60px] md:w-[80px] h-[24px] md:h-[32px] rounded-full border border-solid border-[#46FF78] bg-[rgba(70,255,120,0.20)]">
                                                <span className="text-[12px] md:text-[16px] leading-[1px]">Claim</span>
                                            </button>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="overlay"></div>
        </div>
    );
};

export default MyResultsModal;