import User from '../interfaces/User'

interface IUserDailyStatus {
    // for rps daily task
    rpsCount: number;
    rpsWinCountInRow: number;
    rpsWinOneSolBet: boolean;
    rpsClaimedGift: boolean;
    // for dice daily task
    diceCount: number;
    diceWinCountInRow: number;
    diceWinOneSolBet: boolean;
    diceClaimedGift: boolean;
    // for coinflip daily task
    flipCount: number;
    flipWinCountInRow: number;
    flipWinOneSolBet: boolean;
    flipClaimedGift: boolean;
}

enum UserActions {
    LOAD_USER = 'LOAD_USER',
    UPDATE_USER_BALANCE = 'UPDATE_USER_BALANCE',
    UPDATE_USER_LAST_MESSAGE_AT = 'UPDATE_USER_LAST_MESSAGE_AT',
    USER_SELECTED_ITEM = 'USER_SELECTED_ITEM',
    USER_GET_CHANCE = 'USER_GET_CHANCE',
    USER_UPDATE_DAILY_STATUS = 'USER_UPDATE_DAILY_STATUS',
    UPDATE_USER_TOTAL_DAILY_REWARDS = 'UPDATE_USER_TOTAL_DAILY_REWARDS',
    UPDATE_USER_ONCHAIN_BALANCE = 'UPDATE_USER_ONCHAIN_BALANCE',
    UPDATE_USER_ONCHAIN_TOKEN_BALANCE = 'UPDATE_USER_ONCHAIN_TOKEN_BALANCE',
}

interface LoadUserAction {
    type: UserActions.LOAD_USER,
    payload: User
}

interface UpdateBalanceAction {
    type: UserActions.UPDATE_USER_BALANCE,
    payload: number
}

interface UpdateUserLastMessageAt {
    type: UserActions.UPDATE_USER_LAST_MESSAGE_AT,
    payload: number
}

interface UserSelectedItemAction {
    type: UserActions.USER_SELECTED_ITEM
}

interface UserGetChance {
    type: UserActions.USER_GET_CHANCE,
    payload: boolean
}

interface UserUpdateDailyStatus {
    type: UserActions.USER_UPDATE_DAILY_STATUS,
    payload: IUserDailyStatus
}

interface UserUpdateDailyRewards {
    type: UserActions.UPDATE_USER_TOTAL_DAILY_REWARDS,
    payload: number
}

interface UpdateUserOnchainBalance {
    type: UserActions.UPDATE_USER_ONCHAIN_BALANCE,
    payload: number
}

interface UpdateUserOnchainTokenBalance {
    type: UserActions.UPDATE_USER_ONCHAIN_TOKEN_BALANCE,
    payload: number
}

type UserAction = LoadUserAction | UpdateBalanceAction | UpdateUserLastMessageAt | UserSelectedItemAction | UserGetChance | UserUpdateDailyStatus | UserUpdateDailyRewards | UpdateUserOnchainBalance | UpdateUserOnchainTokenBalance;

export default (state: User | null = null, action: UserAction): User | null => {

    switch (action.type) {
        case UserActions.LOAD_USER: {
            return action.payload
        }
        case UserActions.UPDATE_USER_BALANCE: {
            return state && { ...state, balance: state.balance + action.payload }
        }
        case UserActions.UPDATE_USER_LAST_MESSAGE_AT: {
            return state && { ...state, lastMessageAt: Date.now() }
        }
        case UserActions.USER_SELECTED_ITEM: {
            return state && { ...state, isGetChance: false, isGetCardChance: false }
        }
        case UserActions.USER_GET_CHANCE: {
            return state && { ...state, isGetChance: action.payload, isGetCardChance: !action.payload }
        }
        case UserActions.USER_UPDATE_DAILY_STATUS: {
            return state && { ...state, ...action.payload }
        }
        case UserActions.UPDATE_USER_TOTAL_DAILY_REWARDS: {
            return state && { ...state, totalDailyRewards: action.payload }
        }
        case UserActions.UPDATE_USER_ONCHAIN_BALANCE: {
            return state && { ...state, onchainUserBalance: action.payload }
        }
        case UserActions.UPDATE_USER_ONCHAIN_TOKEN_BALANCE: {
            return state && { ...state, onchainTokenBalance: action.payload }
        }
        default:
            return state
    }
}