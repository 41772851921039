import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Virtuoso } from "react-virtuoso";
import dayjs from 'dayjs';
import TicketNumbers from "./TicketNumbers";
import { IInitialState } from "../../reducers/lottery";
import { CombinedReducer } from "../../store";
import arrwoDownIcon from "../../assets/arrow-down.svg";

const MyTickets = () => {
    const lottery = useSelector<CombinedReducer, IInitialState>((state) => state.lottery);

    const [boughtTickets, setBoughtTickets] = useState<number[][]>([]);
    const [shownItems, setShownItems] = useState<number>(5);

    useEffect(() => {
        if (lottery?.userLotteryInfo?.boughtTickets) {
            setBoughtTickets(lottery?.userLotteryInfo?.boughtTickets);
        } else {
            setBoughtTickets([]);
        }
    }, [lottery]);

    return (
        <div className={`w-1/3 py-[39.5px] hidden md:block`}>
            <div className="w-full max-w-[300px] mx-auto">
                <h3 className="text-[20px] font-extrabold leading-[30px]">Last Draws</h3>

                <div className="flex flex-col gap-[7px] mt-[15px]">
                    {
                        lottery.finishedRounds && lottery.finishedRounds.length > 0 ? (
                            lottery.finishedRounds.slice(0, 5).map((item, index) => {
                                return (
                                    <div key={index} className="flex flex-col gap-[2px]">
                                        <h6 className="text-[12px] text-[#808080] font-medium leading-[17px]">
                                            {`${dayjs(item.endedAt).format("DD.MM.YYYY")}`}
                                        </h6>
                                        <TicketNumbers
                                            key={index}
                                            numArray={item.winningNumber!}
                                        />
                                    </div>
                                )
                            })
                        ) : (
                            <h5 className="text-[14px] text-[#808080] font-medium">There is no draws yet.</h5>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default MyTickets;