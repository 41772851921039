import React from 'react';

interface IProps {
    className?: string;
}

const OnchainNotifyContainerBadgeSm = ({ className }: IProps) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="134" height="69" viewBox="0 0 134 69" fill="none" style={{ filter: "drop-shadow(0px 0px 5px #00A5FE)" }}>
            <path d="M0.5 17.4959C0.5 16.6675 1.17157 15.9959 2 15.9959H91.8093C92.4094 15.9959 92.9894 15.7801 93.4435 15.3879L110.049 1.04464C110.993 0.22963 112.461 0.854727 112.528 2.09978L113.144 13.6293C113.215 14.9563 114.311 15.9959 115.64 15.9959H132C132.828 15.9959 133.5 16.6675 133.5 17.4959V67C133.5 67.8284 132.828 68.5 132 68.5H2C1.17157 68.5 0.5 67.8284 0.5 67V17.4959Z" fill="#072B3E" stroke="#00A5FE" />
        </svg>
    );
};

export default OnchainNotifyContainerBadgeSm;
