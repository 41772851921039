import React from 'react';

interface IProps {
    color?: string;
}

const CopyIcon = (props: IProps) => {
    const { color } = props;

    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M12.7 1.66699H9.45487C7.98466 1.66698 6.82015 1.66697 5.90878 1.78999C4.97084 1.9166 4.21169 2.18336 3.613 2.78446C3.0143 3.38555 2.74861 4.14776 2.62251 5.08946C2.49998 6.00449 2.49999 7.17367 2.5 8.64978V13.5144C2.5 14.7712 3.26663 15.8481 4.35597 16.2997C4.2999 15.5418 4.29995 14.4784 4.3 13.5937L4.3 9.49832L4.3 9.419C4.29994 8.35095 4.29989 7.43069 4.39857 6.69375C4.50433 5.90397 4.74282 5.14692 5.35442 4.53287C5.96601 3.91882 6.72003 3.67937 7.50665 3.57319C8.24064 3.47411 9.15722 3.47416 10.221 3.47422L10.3 3.47422H12.7L12.779 3.47422C13.8428 3.47416 14.7574 3.47411 15.4914 3.57319C15.0523 2.45682 13.968 1.66699 12.7 1.66699Z' fill={color ?? '#77777D'} />
            <path d='M5.5 9.49799C5.5 7.22616 5.5 6.09024 6.20294 5.38448C6.90589 4.67871 8.03726 4.67871 10.3 4.67871H12.7C14.9627 4.67871 16.0941 4.67871 16.7971 5.38448C17.5 6.09024 17.5 7.22616 17.5 9.49799V13.5141C17.5 15.7859 17.5 16.9218 16.7971 17.6276C16.0941 18.3333 14.9627 18.3333 12.7 18.3333H10.3C8.03726 18.3333 6.90589 18.3333 6.20294 17.6276C5.5 16.9218 5.5 15.7859 5.5 13.5141V9.49799Z' fill={color ?? '#77777D'} />
        </svg>
    );
};

export default CopyIcon;
