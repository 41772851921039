import React, { useState } from "react";
import WinningCombination from "./WinningCombination";
import arrwoDownIcon from "../../assets/arrow-down.svg";
import "./index.scss";
import useSound from "use-sound";

interface IProps {
    handleShowBuyTicketsModal: (status: boolean) => void;
}

const HowToPlay = (props: IProps) => {
    const { handleShowBuyTicketsModal } = props;
    const [showWinningCombinationsModal, setShowWinningCombinationsModal] = useState<boolean>(false);
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });

    return (
        <div className="w-full md:w-1/3 py-[39.5px] px-[45px] md:px-[34px]">
            <h3 className="text-[15px] md:text-[20px] font-extrabold leading-[23px] md:leading-[30px]">How to Play</h3>

            <div className="flex gap-[18px] md:gap-[30px] mt-[20px] md:mt-[30px]">
                <div className="min-w-[45px] md:w-[50px] text-[12px] md:text-[14px] font-extrabold leading-[18px] md:leading-[21px]">
                    Step 1:
                </div>

                <div className="">
                    <h5 className="text-[12px] md:text-[14px] leading-[17px] md:leading-[20px]">
                        Buy one or multiple tickets.
                    </h5>

                    <button
                        className="w-[144px] h-[40px] gradient-btn full-rounded text-[14px] font-medium leading-[20px] mt-[30px] hidden md:block buy-ticket"
                        onClick={() => handleShowBuyTicketsModal(true)}
                        onMouseEnter={() => playHover()}
                    >
                        Buy Tickets Now
                    </button>
                </div>
            </div>

            <div className="flex gap-[18px] md:gap-[30px] mt-[15px] md:mt-[30px]">
                <div className="min-w-[45px] md:w-[50px] text-[12px] md:text-[14px] font-extrabold leading-[18px] md:leading-[21px]">
                    Step 2:
                </div>

                <div className="">
                    <h5 className="text-[12px] md:text-[14px] leading-[17px] md:leading-[20px]">
                        Wait for the Countdown to hit zero.
                    </h5>
                </div>
            </div>

            <div className="flex gap-[18px] md:gap-[30px] mt-[15px] md:mt-[30px]">
                <div className="min-w-[45px] md:w-[50px] text-[12px] md:text-[14px] font-extrabold leading-[18px] md:leading-[21px]">
                    Step 3:
                </div>

                <div className="">
                    <h5 className="text-[12px] md:text-[14px] leading-[17px] md:leading-[20px]">
                        Watch the draw to see if you won!
                    </h5>
                </div>
            </div>

            <div className="justify-center w-full hidden md:flex">
                <a href="https://docs.biteclub.io/games/solanaball" target="_blank" rel="noopener noreferrer">
                    <button
                        className="flex flex-col items-center text-center mt-[30px] hover-effect"
                    >
                        <h5 className="text-[14px] text-[#808080] font-medium leading-[20px]">More Information</h5>
                        <img src={arrwoDownIcon} alt="arrow-down-icon" />
                    </button>
                </a>
            </div>


            <div className="flex justify-between items-center mt-[35px] mb-[50px] md:hidden">
                <button
                    className="w-[140px] h-[40px] gradient-btn-no-bg rounded-[10px] text-[12px] font-medium leading-[17px] mr-1"
                    onClick={() => setShowWinningCombinationsModal(true)}
                    onMouseEnter={() => playHover()}
                >
                    Combinations
                </button>

            <a href="https://docs.biteclub.io/games/solanaball" target="_blank" rel="noopener noreferrer">
                <button className="w-[140px] h-[40px] gradient-btn-no-bg rounded-[10px] text-[12px] font-medium leading-[17px]">
                    View More
                </button>
            </a>
            </div>

            {/* Winning combinations modal */}
            <div
                className="justify-center fixed top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] sm:bg-black/50 z-[1040] overflow-y-auto"
                style={{ display: showWinningCombinationsModal ? "flex" : "none" }}
            >
                <div className="flex flex-col items-center w-full h-fit mx-[25px] mt-[87px] md:mt-[120px] xl:pl-[155px] xl:pr-[336px]">
                    <div className="relative flex flex-col items-center w-full h-full max-w-[463px] p-[22px_20px_30px] md:p-[30px_60px_20px] bg-[#090C0E] rounded-[8px] border-[1.5px] border-solid border-[#000000] shadow-[0px_0px_8px_#00A5FE] z-[1050]">
                        {/* Close button */}
                        <button
                            className="sm:flex justify-center items-center absolute top-0 right-0 p-[10px] cursor-pointer bg-none border-none rounded-[6px] transform hover:scale-110 transition-all"
                            onClick={() => setShowWinningCombinationsModal(false)}
                            onMouseEnter={() => playHover()}
                        >
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 16L16 1M1 1L16 16" stroke="#BEBEBE" strokeWidth="2" />
                            </svg>
                        </button>

                        <WinningCombination show={true} />
                    </div>
                </div>
                <div className="overlay"></div>
            </div>
        </div>
    );
};

export default HowToPlay;