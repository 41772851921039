import React, { useEffect, useState } from 'react';
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { Connection, LAMPORTS_PER_SOL, PublicKey, SystemProgram } from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";
import * as anchor from "@project-serum/anchor";
import { Program, AnchorProvider } from "@project-serum/anchor";
import { Wallet } from '@project-serum/anchor/dist/cjs/provider';
import coinflipIdl from "../../../idls/coinflip-idl.json";
import { ICoinflipPoolInfo } from '../../../interfaces/Coinflip';

const connection = new Connection(process.env.REACT_APP_RPC_URL!, "confirmed");
const programID = new PublicKey(coinflipIdl.metadata.address);

const CoinflipOnchainAdmin = () => {
    const wallet = useWallet();

    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isFunding, setIsFunding] = useState<boolean>(false);
    const [isWithdrawing, setIsWithdrawing] = useState<boolean>(false);
    const [poolInfo, setPoolInfo] = useState<ICoinflipPoolInfo>();
    const [treasuryBalance, setTreasuryBalance] = useState<number>(0)
    const [amount, setAmount] = useState<number | null>(null);

    async function getProvider() {
        const provider = new AnchorProvider(connection, wallet as Wallet, { commitment: 'processed' });
        return provider;
    }

    const initialize = async () => {
        try {
            const provider = await getProvider();
            const program = new Program(coinflipIdl as anchor.Idl, programID, provider);
            const poolAccount = PublicKey.findProgramAddressSync(
                [Buffer.from('coinflip-pool')],
                program.programId
            )[0];

            const poolInfo: any = await program.account.pool.fetch(poolAccount);
            const treasuryBalance = await connection.getBalance(poolInfo.treasuryVault);

            setPoolInfo(poolInfo);
            setTreasuryBalance(treasuryBalance);

            setIsInitialized(true);
        } catch (_) {
            setIsInitialized(false);
        }
    }

    const inputAmount = (input: string) => {
        if (!input) return setAmount(null);

        if (Number(input) >= 0) setAmount(Number(input));
        else setAmount(0);
    };

    const handleFund = async () => {
        if (!isInitialized) {
            return toast.warn("Pool is not initialized yet");
        }

        if (!amount || amount < 0) {
            return toast.warn('Please input amount');
        }

        if (!poolInfo) {
            return toast.warn('Coinflip pool is not initialized');
        }

        try {
            setLoading(true);
            setIsFunding(true);
            const provider = await getProvider();
            const program = new Program(coinflipIdl as anchor.Idl, programID, provider);

            const poolAccount = PublicKey.findProgramAddressSync(
                [Buffer.from('coinflip-pool')],
                program.programId
            )[0];

            const txh = await program.methods
                .fundSolToTreasuryVault(new anchor.BN(amount * LAMPORTS_PER_SOL))
                .accounts({
                    authority: provider.wallet.publicKey,
                    pool: poolAccount,
                    treasuryVault: poolInfo.treasuryVault,
                    systemProgram: anchor.web3.SystemProgram.programId,
                })
                .rpc();

            console.log("txh: ", txh);

            await initialize();
            toast.success("Successful")
        } catch (e: any) {
            console.log("error: ", e);
            toast.error("Failed to fund SOL to coinflip pool");
        }

        setLoading(false);
        setIsFunding(false);
    }

    const handleWithdraw = async () => {
        if (!isInitialized) {
            return toast.warn("Pool is not initialized yet");
        }

        if (!amount || amount < 0) {
            return toast.warn('Please input amount');
        }

        if (amount * LAMPORTS_PER_SOL > treasuryBalance) {
            return toast.warn('Insufficient treasury balance');
        }

        if (!poolInfo) {
            return toast.warn('Coinflip pool is not initialized');
        }

        try {
            setLoading(true);
            setIsWithdrawing(true);
            const provider = await getProvider();
            const program = new Program(coinflipIdl as anchor.Idl, programID, provider);

            const poolAccount = PublicKey.findProgramAddressSync(
                [Buffer.from('coinflip-pool')],
                program.programId
            )[0];

            const txh = await program.methods
                .withdrawSolFromTreasuryVault(new anchor.BN(amount * LAMPORTS_PER_SOL))
                .accounts({
                    authority: provider.wallet.publicKey,
                    pool: poolAccount,
                    treasuryVault: poolInfo.treasuryVault,
                    systemProgram: anchor.web3.SystemProgram.programId,
                })
                .rpc();

            console.log("txh: ", txh);

            await initialize();
            toast.success("Successful")
        } catch (e: any) {
            console.log("error: ", e);
            toast.error("Failed to fund SOL to coinflip pool");
        }

        setLoading(false);
        setIsWithdrawing(false);
    }

    useEffect(() => {
        (async () => {
            await initialize()
        })()
    }, []);

    return (
        <div className="flex flex-col items-center w-full mt-[50px]">
            <h1 className="text-[20px] font-medium mt-5">Manage On-chain Coinflip</h1>
            <h5 className="text-[16px] font-medium mb-2">(Treasury balance: {(treasuryBalance / LAMPORTS_PER_SOL).toLocaleString()})</h5>
            <div className='w-full max-w-[300px] px-[10px]'>
                <input
                    type="number"
                    disabled={isLoading || !isInitialized}
                    className="bg-[#1D262F] rounded-[10px] w-full h-[37px] text-[18px] px-[15px] font-medium outline-none"
                    placeholder="amount"
                    value={amount === null ? "" : amount}
                    onChange={(e) => inputAmount((e.target as HTMLInputElement).value)}
                />

                <div className='flex flex-col md:flex-row justify-center items-center gap-[10px] w-full mt-[10px]'>
                    <button
                        disabled={isLoading || !isInitialized}
                        className={`flex justify-center items-center gap-[10px] gradient-btn full-rounded py-[5px] w-full md:w-1/2 ${(isLoading || !isInitialized) ? 'opacity-60' : 'opacity-100'}`}
                        onClick={() => handleFund()}
                    >
                        {!isFunding ? 'Fund' : 'Funding...'}
                        {isFunding ? <ClipLoader size={15} color="#ffffff" /> : null}
                    </button>
                    <button
                        disabled={isLoading || !isInitialized}
                        className={`flex justify-center items-center gap-[10px] gradient-btn full-rounded py-[5px] w-full md:w-1/2 ${(isLoading || !isInitialized) ? 'opacity-60' : 'opacity-100'}`}
                        onClick={() => handleWithdraw()}
                    >
                        {!isWithdrawing ? 'Withdraw' : 'Withdrawing...'}
                        {isWithdrawing ? <ClipLoader size={15} color="#ffffff" /> : null}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CoinflipOnchainAdmin;