import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import Countdown from "react-countdown";
import { toast } from "react-toastify";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import axios from "axios";
import RaceCountDown from "./RaceCountDown";
import User from "../../interfaces/User";
import { Sockets } from "../../reducers/sockets";
import { CombinedReducer } from "../../store";
import { GameTypeEnum } from "../../enums";
import greenSolSymbolIcon from '../../assets/sol-symbol-green.svg';
import { ReactComponent as CheckIcon } from '../../assets/check.svg';
import useSound from "use-sound";

import "./index.scss";

const Rewards = () => {
    const user = useSelector<CombinedReducer, User>((state) => state.user);
    const sockets = useSelector<CombinedReducer, Sockets>((state) => state.sockets);
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
    const [remainingTime, setRemainingTime] = useState<number>(60 * 1000);
    const [totalDailyRewards, setTotalDailyRewards] = useState<number>(0);
    const [completedRpsTasks, setCompletedRpsTasks] = useState<boolean>(false);
    const [completedDiceTasks, setCompletedDiceTasks] = useState<boolean>(false);
    const [completedCoinflipTasks, setCompletedCoinflipTasks] = useState<boolean>(false);
    const [isClaimingRpsGift, setIsClaimingRpsGift] = useState<boolean>(false);
    const [isClaimingDiceGift, setIsClaimingDiceGift] = useState<boolean>(false);
    const [isClaimingFlipGift, setIsClaimingFlipGift] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleClaimGift = async (gameType: GameTypeEnum) => {
        if (user.isGetChance || user.isGetCardChance) {
            return toast.info("Please use current gift before claim.");
        }

        if (gameType == GameTypeEnum.Rps) {
            if (!completedRpsTasks) {
                return toast.warn("Task Incomplete");
            } else {
                if (user?.rpsClaimedGift) {
                    return toast.warn("Already claimed today.");
                }
            }

            setIsClaimingRpsGift(true);
        }

        if (gameType == GameTypeEnum.Dice) {
            if (!completedDiceTasks) {
                return toast.warn("Task Incomplete");
            } else {
                if (user?.diceClaimedGift) {
                    return toast.warn("Already claimed today.");
                }
            }

            setIsClaimingDiceGift(true);
        }

        if (gameType == GameTypeEnum.Coinflip) {
            if (!completedCoinflipTasks) {
                return toast.warn("Task Incomplete");
            } else {
                if (user?.flipClaimedGift) {
                    return toast.warn("Already claimed today.");
                }
            }

            setIsClaimingFlipGift(true);
        }

        try {
            await axios.post("/api/u/claimGift", { gameType: GameTypeEnum[gameType].toLowerCase() });
            toast.success("Successful");
        } catch (e) {
            console.log("e: ", e);
            toast.error("Failed to claim gift");
        }

        setIsClaimingRpsGift(false);
        setIsClaimingDiceGift(false);
        setIsClaimingFlipGift(false);
    }

    const handleSetRemainingTime = useCallback((remainingTime: number) => {
        setRemainingTime(remainingTime);
    }, [remainingTime]);

    useEffect(() => {
        if (sockets?.user) {
            sockets.user.on("newWagerRaceStart", (remainingTime: number) => {
                handleSetRemainingTime(remainingTime);
            });
        }
    }, [sockets?.user]);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            try {
                const remainingTime = (await axios.get("/api/leaderboard/remainingTime")).data;
                const dailyTotalPrizePool = (await axios.get("/api/leaderboard/dailyTotalPrizePool")).data;

                setTotalDailyRewards(dailyTotalPrizePool);
                handleSetRemainingTime(remainingTime);
            } catch { }
            setIsLoading(false);
        })();
    }, []);

    useEffect(() => {
        if (user?.rpsCount >= user?.targetGameCount && user?.rpsWinCountInRow >= user?.targetWinCountInRow && user?.rpsWinOneSolBet) {
            setCompletedRpsTasks(true);
        }

        if (user?.diceCount >= user?.targetGameCount && user?.diceWinCountInRow >= user?.targetWinCountInRow && user?.diceWinOneSolBet) {
            setCompletedDiceTasks(true);
        }

        if (user?.flipCount >= user?.targetGameCount && user?.flipWinCountInRow >= user?.targetWinCountInRow && user?.flipWinOneSolBet) {
            setCompletedCoinflipTasks(true);
        }
    }, [user]);

    return (
        <div className="flex flex-col md:flex-row w-full min-h-[calc(100vh-59px)] md:min-h-[calc(100vh-70px)] rewards relative ">
            <div className="w-full max-w-[1176px] mt-[21px] md:mt-[50px] mx-auto px-[10px]">
                {/* Wager Race */}
                <a href="/leaderboard">
                    <div className="flex justify-center items-end w-full h-[156px] md:h-[172px] bg-[#090C0E] rounded-[10px] transition duration-300 ease-in-out hover:shadow" style={({ '--tw-shadow': '0 0 10px rgba(255, 215, 0, 0.5)' } as React.CSSProperties)} onMouseEnter={() => playHover() }>
                        <div className="grid grid-cols-6 w-full h-full px-[40px] md:px-[100px]">
                            {/* Total prize */}
                            <div className="flex flex-col justify-center col-span-3 md:col-span-2 order-2 md:order-1">
                                <div className="flex flex-col items-center md:items-baseline w-fit">
                                    <h4 className="text-[12px] md:text-[16px] text-[#808080] font-medium leading-[17px] md:leading-[23px]">Total Prize Pool</h4>
                                    <div className="flex items-center gap-[7px] justify-center pt-2 pl-7">
                                        <span className="text-[32px] text-[#46FF78] font-bold leading-[47px]">
                                            {(totalDailyRewards / LAMPORTS_PER_SOL).toLocaleString()}
                                        </span>
                                        <div className="w-[34px] h-[21px]">
                                            <img src={greenSolSymbolIcon} alt="sol-symbol" className="w-full h-full pt-1" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Title */}
                            <div className="flex flex-col justify-center items-center gap-[6px] col-span-6 md:col-span-2 order-1 md:order-2">
                                <span className="text-[24px] md:text-[32px] gold-font font-bold leading-[36px] md:leading-[47px]">Wager Race</span>
                                <a href="https://docs.biteclub.io/rewards/degen-daily-giveaway" target="_blank">
                                    <span className="text-[14px] text-[#808080] font-bold leading-[21px] hidden md:block" style={{ transition: 'color 0.3s ease, transform 0.3s ease' }} onMouseEnter={(e) => { e.currentTarget.style.color = 'white'; playHover(); e.currentTarget.style.transform = 'scale(1.1)'; }} onMouseLeave={(e) => { e.currentTarget.style.color = '#808080'; e.currentTarget.style.transform = 'scale(1)'; }}>
                                        How it works?
                                    </span>
                                </a>
                            </div>

                            {/* Race countdown */}
                            <div className="flex flex-col justify-center items-end col-span-3 md:col-span-2 order-3">
                                <div className="flex flex-col items-center gap-[10px]">
                                    <span className="text-[12px] md:text-[16px] text-[#808080] font-medium leading-[17px] md:leading-[23px]">Reset</span>
                                    <div className="w-[100px]">
                                        <RaceCountDown remainingTime={remainingTime} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>

                {/* Daily Rewards */}
                <div className="w-full bg-[#090C0E] rounded-[10px] p-[10px_7px_7px] md:p-[33px_23px_31px] mt-[16px] md:mt-[51px]">
                    <h1 className="text-[24px] md:text-[32px] text-center gold-font font-bold leading-[36px] md:leading-[47px]">Daily Rewards</h1>

                    <div className="flex flex-col md:flex-row justify-between gap-[12px] w-full mt-[20px] md:mt-[43px]">
                        {/* RPS Quests */}
                        <div className="w-full md:w-[350px] p-[10px_20px_26px] md:p-[26px_27px_32px] bg-[#11161B] rounded-[10px] relative">
                            {/* Title */}
                            <h1 className="text-[14px] md:text-[20px] text-center gold-font font-bold leading-[21px] md:leading-[30px]">RPS</h1>

                            <div className="flex flex-row md:flex-col justify-between md:justify-start items-center md:gap-[53px] w-full mt-[15px] md:mt-[40px]">
                                <div className="flex flex-col gap-[12px] md:gap-[20px]">
                                    <div className="flex items-center gap-[12px] md:gap-[20px]">
                                        <div className="w-[18px] md:w-[30px] h-[18px] md:h-[30px] bg-[#1D262F] shadow-[0px_0px_8px_#00A5FE] relative">
                                            {
                                                user?.rpsCount >= user?.targetGameCount && (
                                                    <div className="w-full h-full p-[5px_2px] absolute top-0 left-0">
                                                        <CheckIcon className="w-full h-full" />
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="flex items-center gap-[5px] text-[12px] md:text-[20px] font-medium leading-[17px] md:leading-[29px]">
                                            <span>{`Play ${user?.targetGameCount ?? 0} games - `}</span>
                                            <span className="text-[#808080]">{`${user?.rpsCount ?? 0}/${user?.targetGameCount ?? 0}`}</span>
                                        </div>

                                    </div>

                                    <div className="flex items-center gap-[12px] md:gap-[20px]">
                                        <div className="w-[18px] md:w-[30px] h-[18px] md:h-[30px] bg-[#1D262F] shadow-[0px_0px_8px_#00A5FE] relative">
                                            {
                                                user?.rpsWinCountInRow >= user?.targetWinCountInRow && (
                                                    <div className="w-full h-full p-[5px_2px] absolute top-0 left-0">
                                                        <CheckIcon className="w-full h-full" />
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="flex items-center gap-[5px] text-[12px] md:text-[20px] font-medium leading-[17px] md:leading-[29px]">
                                            <span>{`Win ${user?.targetWinCountInRow ?? 0} in a row - `}</span>
                                            <span className="text-[#808080]">{`${user?.rpsWinCountInRow ?? 0}/${user?.targetWinCountInRow ?? 0}`}</span>
                                        </div>
                                    </div>

                                    <div className="flex items-center gap-[12px] md:gap-[20px]">
                                        <div className="w-[18px] md:w-[30px] h-[18px] md:h-[30px] bg-[#1D262F] shadow-[0px_0px_8px_#00A5FE] relative">
                                            {
                                                user?.rpsWinOneSolBet && (
                                                    <div className="w-full h-full p-[5px_2px] absolute top-0 left-0">
                                                        <CheckIcon className="w-full h-full" />
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="flex items-center gap-[5px] text-[12px] md:text-[20px] font-medium leading-[17px] md:leading-[29px]">
                                            <span>Play a 1 <img src={greenSolSymbolIcon} alt="sol-symbol" className="h-[10px] md:h-[16px] inline-block" /> game - </span>
                                            <span className="text-[#808080]">{`${user?.rpsWinOneSolBet ? 1 : 0}/1`}</span>
                                        </div>
                                    </div>
                                </div>

                                <button
                                    disabled={user?.rpsClaimedGift || !completedRpsTasks || isClaimingRpsGift}
                                    className={`
                                        Claim-Gift flex justify-center items-center gap-[5px] w-[100px] md:w-[155px] h-[30px] md:h-[40px] text-[14px] md:text-[20px] font-medium leading-[20px] md:leading-[29px] rounded-[10px]
                                        ${completedRpsTasks ? 'gradient-btn before:!border' : 'gray-gradient-btn !border'}                                        
                                    `}
                                    onClick={() => handleClaimGift(GameTypeEnum.Rps)}
                                    onMouseEnter={() => playHover()}
                                >
                                    {isClaimingRpsGift ? "Claiming..." : user?.rpsClaimedGift ? "Gift Claimed" : "Claim Gift"}{" "}
                                    {isClaimingRpsGift ? <ClipLoader color="#ffffff" className="!w-[15px] md:!w-[20px] !h-[15px] md:!h-[20px]" /> : null}
                                </button>
                            </div>

                            {/* Overlay */}
                            {
                                user?.rpsClaimedGift && (
                                    <div className="w-full h-full absolute top-0 left-0 bg-black/60 rounded-[10px]"></div>
                                )
                            }
                        </div>

                        {/* Dice Quests */}
                        <div className="w-full md:w-[350px] p-[10px_20px_26px] md:p-[26px_27px_32px] bg-[#11161B] rounded-[10px] relative">
                            {/* Title */}
                            <h1 className="text-[14px] md:text-[20px] text-center gold-font font-bold leading-[21px] md:leading-[30px]">Dice</h1>

                            <div className="flex flex-row md:flex-col justify-between md:justify-start items-center md:gap-[53px] w-full mt-[15px] md:mt-[40px]">
                                <div className="flex flex-col gap-[12px] md:gap-[20px]">
                                    <div className="flex items-center gap-[12px] md:gap-[20px]">
                                        <div className="w-[18px] md:w-[30px] h-[18px] md:h-[30px] bg-[#1D262F] shadow-[0px_0px_8px_#00A5FE] relative">
                                            {
                                                user?.diceCount >= user?.targetGameCount && (
                                                    <div className="w-full h-full p-[5px_2px] absolute top-0 left-0">
                                                        <CheckIcon className="w-full h-full" />
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="flex items-center gap-[5px] text-[12px] md:text-[20px] font-medium leading-[17px] md:leading-[29px]">
                                            <span>{`Play ${user?.targetGameCount ?? 0} games - `}</span>
                                            <span className="text-[#808080]">{`${user?.diceCount ?? 0}/${user?.targetGameCount ?? 0}`}</span>
                                        </div>
                                    </div>

                                    <div className="flex items-center gap-[12px] md:gap-[20px]">
                                        <div className="w-[18px] md:w-[30px] h-[18px] md:h-[30px] bg-[#1D262F] shadow-[0px_0px_8px_#00A5FE] relative">
                                            {
                                                user?.diceWinCountInRow >= user?.targetWinCountInRow && (
                                                    <div className="w-full h-full p-[5px_2px] absolute top-0 left-0">
                                                        <CheckIcon className="w-full h-full" />
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="flex items-center gap-[5px] text-[12px] md:text-[20px] font-medium leading-[17px] md:leading-[29px]">
                                            <span>{`Win ${user?.targetWinCountInRow ?? 0} in a row - `}</span>
                                            <span className="text-[#808080]">{`${user?.diceWinCountInRow ?? 0}/${user?.targetWinCountInRow ?? 0}`}</span>
                                        </div>
                                    </div>

                                    <div className="flex items-center gap-[12px] md:gap-[20px]">
                                        <div className="w-[18px] md:w-[30px] h-[18px] md:h-[30px] bg-[#1D262F] shadow-[0px_0px_8px_#00A5FE] relative">
                                            {
                                                user?.diceWinOneSolBet && (
                                                    <div className="w-full h-full p-[5px_2px] absolute top-0 left-0">
                                                        <CheckIcon className="w-full h-full" />
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="flex items-center gap-[5px] text-[12px] md:text-[20px] font-medium leading-[17px] md:leading-[29px]">
                                            <span>Play a 1 <img src={greenSolSymbolIcon} alt="sol-symbol" className="h-[10px] md:h-[16px] inline-block" /> game - </span>
                                            <span className="text-[#808080]">{`${user?.diceWinOneSolBet ? 1 : 0}/1`}</span>
                                        </div>
                                    </div>
                                </div>

                                <button
                                    disabled={user?.diceClaimedGift || !completedDiceTasks || isClaimingDiceGift}
                                    className={`
                                        Claim-Gift flex justify-center items-center gap-[5px] w-[100px] md:w-[155px] h-[30px] md:h-[40px] text-[14px] md:text-[20px] font-medium leading-[20px] md:leading-[29px] rounded-[10px]
                                        ${completedDiceTasks ? 'gradient-btn before:!border' : 'gray-gradient-btn !border'}                                        
                                    `}
                                    onClick={() => handleClaimGift(GameTypeEnum.Dice)}
                                    onMouseEnter={() => playHover()}
                                >
                                    {isClaimingDiceGift ? "Claiming..." : user?.diceClaimedGift ? "Gift Claimed" : "Claim Gift"}{" "}
                                    {isClaimingDiceGift ? <ClipLoader color="#ffffff" className="!w-[15px] md:!w-[20px] !h-[15px] md:!h-[20px]" /> : null}
                                </button>
                            </div>

                            {/* Overlay */}
                            {
                                user?.diceClaimedGift && (
                                    <div className="w-full h-full absolute top-0 left-0 bg-black/60 rounded-[10px]"></div>
                                )
                            }
                        </div>

                        {/* Coinflip Quests */}
                        <div className="w-full md:w-[350px] p-[10px_20px_26px] md:p-[26px_27px_32px] bg-[#11161B] rounded-[10px] relative">
                            {/* Title */}
                            <h1 className="text-[14px] md:text-[20px] text-center gold-font font-bold leading-[21px] md:leading-[30px]">Coinflip</h1>

                            <div className="flex flex-row md:flex-col justify-between md:justify-start items-center md:gap-[53px] w-full mt-[15px] md:mt-[40px]">
                                <div className="flex flex-col gap-[12px] md:gap-[20px]">
                                    <div className="flex items-center gap-[12px] md:gap-[20px]">
                                        <div className="w-[18px] md:w-[30px] h-[18px] md:h-[30px] bg-[#1D262F] shadow-[0px_0px_8px_#00A5FE] relative">
                                            {
                                                user?.flipCount >= user?.targetGameCount && (
                                                    <div className="w-full h-full p-[5px_2px] absolute top-0 left-0">
                                                        <CheckIcon className="w-full h-full" />
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="flex items-center gap-[5px] text-[12px] md:text-[20px] font-medium leading-[17px] md:leading-[29px]">
                                            <span>{`Play ${user?.targetGameCount ?? 0} games - `}</span>
                                            <span className="text-[#808080]">{`${user?.flipCount ?? 0}/${user?.targetGameCount ?? 0}`}</span>
                                        </div>
                                    </div>

                                    <div className="flex items-center gap-[12px] md:gap-[20px]">
                                        <div className="w-[18px] md:w-[30px] h-[18px] md:h-[30px] bg-[#1D262F] shadow-[0px_0px_8px_#00A5FE] relative">
                                            {
                                                user?.flipWinCountInRow >= user?.targetWinCountInRow && (
                                                    <div className="w-full h-full p-[5px_2px] absolute top-0 left-0">
                                                        <CheckIcon className="w-full h-full" />
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="flex items-center gap-[5px] text-[12px] md:text-[20px] font-medium leading-[17px] md:leading-[29px]">
                                            <span>{`Win ${user?.targetWinCountInRow ?? 0} in a row - `}</span>
                                            <span className="text-[#808080]">{`${user?.flipWinCountInRow ?? 0}/${user?.targetWinCountInRow ?? 0}`}</span>
                                        </div>
                                    </div>

                                    <div className="flex items-center gap-[12px] md:gap-[20px]">
                                        <div className="w-[18px] md:w-[30px] h-[18px] md:h-[30px] bg-[#1D262F] shadow-[0px_0px_8px_#00A5FE] relative">
                                            {
                                                user?.flipWinOneSolBet && (
                                                    <div className="w-full h-full p-[5px_2px] absolute top-0 left-0">
                                                        <CheckIcon className="w-full h-full" />
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="flex items-center gap-[5px] text-[12px] md:text-[20px] font-medium leading-[17px] md:leading-[29px]">
                                            <span>Play a 1 <img src={greenSolSymbolIcon} alt="sol-symbol" className="h-[10px] md:h-[16px] inline-block" /> game - </span>
                                            <span className="text-[#808080]">{`${user?.flipWinOneSolBet ? 1 : 0}/1`}</span>
                                        </div>
                                    </div>
                                </div>

                                <button
                                    disabled={user?.flipClaimedGift || !completedCoinflipTasks || isClaimingFlipGift}
                                    className={`
                                        Claim-Gift flex justify-center items-center gap-[5px] w-[100px] md:w-[155px] h-[30px] md:h-[40px] text-[14px] md:text-[20px] font-medium leading-[20px] md:leading-[29px] rounded-[10px]
                                        ${completedCoinflipTasks ? 'gradient-btn before:!border' : 'gray-gradient-btn !border'}
                                    `}
                                    onClick={() => handleClaimGift(GameTypeEnum.Coinflip)}
                                    onMouseEnter={() => playHover()}
                                >
                                    {isClaimingFlipGift ? "Claiming..." : user?.flipClaimedGift ? "Gift Claimed" : "Claim Gift"}{" "}
                                    {isClaimingFlipGift ? <ClipLoader color="#ffffff" className="!w-[15px] md:!w-[20px] !h-[15px] md:!h-[20px]" /> : null}
                                </button>
                            </div>

                            {/* Overlay */}
                            {
                                user?.flipClaimedGift && (
                                    <div className="w-full h-full absolute top-0 left-0 bg-black/60 rounded-[10px]"></div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Rewards;