import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { TwitterShareButton } from 'react-share';
import Confetti from "react-confetti";
// @ts-ignore
import Slide from "react-reveal/Slide";
import axios from "axios";
import { rewardItems } from "../../data";
import { CombinedReducer } from "../../store";
import { Sockets } from "../../reducers/sockets";
import { FreeRewardTypeEnum } from "../../enums";
import speenWeelIcon from "../../assets/spin-wheel.svg";
import speenPointerIcon from "../../assets/spin-pointer.svg";
import TwitterIcon from "../../assets/twitter.svg";
import lightGraySolIcon from "../../assets/sol-symbol-light-gray.svg";
import useSound from "use-sound";

interface IProps {
    show: boolean;
    handleShow: (status: boolean) => void
}

const SpinModal = (props: IProps) => {
    const { show, handleShow } = props;
    const dispatch = useDispatch();
    const [rotationAngle, setRotationAngle] = useState<number>(0);
    const [playShare] = useSound("/sound/win.mp3", { volume: 1 });
    const [playSpin, { stop }] = useSound("/sound/spinwheel.mp3", { volume: 1 });
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
    const [isClaimed, setIsCalimed] = useState<boolean>(false);
    const [claimedAmount, setClaimedAmount] = useState<number | null>(null);
    const [twitterTitle, setTwitterTitle] = useState<string>('');
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const sockets = useSelector<CombinedReducer, Sockets>((state) => state.sockets);

    const handleSpin = async () => {
        try {
            setIsProcessing(true);
            await axios.post("/api/u/spin");
        } catch (e: any) {
            setIsProcessing(false);

            if (e?.response?.data?.message) {
                toast.error(e.response.data.message.toString());
            } else {
                toast.error("Failed to spin wheel. please try again!");
            }
        }
    };

    const handleSpinClick = () => {
        setTimeout(() => {
            playSpin(); // Play the sound when card is selected
        }, 1000); //Timeout
    };

    const handleShareClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, link: string) => {
        playShare();
    };

    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        });
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize]);

    useEffect(() => {
        if (!show) {
            setRotationAngle(0);
            setIsCalimed(false);
            setClaimedAmount(null);
            setTwitterTitle("");
        }
    }, [show]);

    useEffect(() => {
        if (isClaimed && show) {
            playShare();
        }
    }, [isClaimed, show]);

    useEffect(() => {
        if (sockets && sockets?.user) {
            sockets.user.on("selectedItem", (selectedItemIndex: number) => {
                dispatch({ type: "USER_SELECTED_ITEM" });

                const selecteditem = rewardItems[selectedItemIndex];
                const rotationAngle = 10 * 360 + (360 - selectedItemIndex * (360 / rewardItems.length));
                setRotationAngle(rotationAngle);
                setClaimedAmount(selecteditem.amount);

                if (selecteditem.amount > 0) {
                    let title = "";

                    if (selecteditem.type == FreeRewardTypeEnum.Sol) {
                        title = `Won ${selecteditem.amount} $SOL from a free Reward on the #BiteClub platform @degen_games_sol, try your luck today at; `;
                    } else if (selecteditem.type == FreeRewardTypeEnum.Ticket) {
                        title = `Won 1x #Solanaball ticket from a free Reward on the #BiteClub platform @degen_games_sol, try your luck today at; `;
                    }

                    setTwitterTitle(title);
                }

                setTimeout(() => {
                    if (selecteditem.amount > 0) {
                        setIsCalimed(true);
                    }
                    setIsProcessing(false);
                }, 7500);
            });
        }
    }, [sockets?.user]);

    return (
        <div
            className="fund-modal fixed top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] bg-black/60 sm:bg-black/50 z-[1040] overflow-y-auto hidden justify-center"
            style={{ display: show ? "flex" : "none" }}
        >
            <div className="flex flex-col justify-center items-center w-full">
                {
                    isClaimed && show && (
                        <Confetti tweenDuration={1000} width={screenSize.dynamicWidth} height={screenSize.dynamicHeight} />
                    )
                }

                <Slide top duration={1000}>
                    <div className="relative flex flex-col items-center w-full max-w-[650px] p-[11px_15px_15px] md:p-[18px_26px_26px] bg-[#11161B] rounded-[8px] shadow-[0px_0px_10px_#00A5FE] z-[1050]">
                        {/* Close button */}
                        <button
                            className="sm:flex justify-center items-center absolute top-0 right-0 p-[10px] cursor-pointer bg-none border-none rounded-[6px] transform hover:scale-110 transition-all"
                            onClick={() => handleShow(false)}
                            onMouseEnter={() => playHover()}
                        >
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 16L16 1M1 1L16 16" stroke="#BEBEBE" strokeWidth="2" />
                            </svg>
                        </button>

                        <h1 className="text-[20px] md:text-[32px] text-center gold-font font-bold leading-[28px] md:leading-[47px]">Free Reward</h1>

                        <div className="flex justify-center items-center w-[300px] md:w-[405px] h-[300px] md:h-[405px] mt-[21px] md:mt-[33px] relative">
                            <div className="flex justify-center w-full absolute z-10 top-[-5px] md:top-[-3px] left-0">
                                <img
                                    src={speenPointerIcon}
                                    alt="spin-pointer"
                                    className="w-[15px] md:w-[20px]"
                                />
                            </div>

                            <div
                                className="w-full h-full relative transition-transform duration-[7s]"
                                style={{ transform: `rotate(${rotationAngle}deg)`, }}
                            >
                                <img
                                    src={speenWeelIcon}
                                    alt="spin-wheel"
                                    className="w-full"
                                />
                                {
                                    rewardItems.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={`flex justify-center w-full h-full absolute top-0 left-0 p-[15px] md:p-[30px] text-[10px] md:text-[12px] text-center font-bold leading-[14px] md:leading-[24px]`}
                                                style={{ transform: `rotate(${index * 360 / rewardItems.length}deg)` }}
                                            >
                                                {
                                                    item.type == FreeRewardTypeEnum.Sol ? (
                                                        <>
                                                            <span>{`${item.amount}`} <img src={lightGraySolIcon} className="w-[8px] inline-block" /></span>
                                                        </>
                                                    ) : (
                                                        <div>
                                                            <img src={item.icon!} alt="sol-ticket" className="w-[19.25px] md:w-[31.5px]" />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <button
                            disabled={isProcessing || (claimedAmount !== null && claimedAmount <= 0)}
                            className={`justify-center items-center gap-[5px] w-[118px] md:w-[200px] h-[31px] md:h-[53px] text-[12px] md:text-[20px] font-bold leading-[17px] md:leading-[30px] gradient-btn rounded-[10px] mt-[12px] md:mt-[18px] transition duration-300 hover:transform hover:scale-110 ${isClaimed ? 'hidden' : 'flex'}`}
                            onClick={() => {
                                handleSpinClick();
                                handleSpin();
                            }}
                            onMouseEnter={() => playHover()}
                        >
                            {isProcessing ? "Spinning.." : "Spin To Win!"}{" "}
                            {isProcessing && <ClipLoader color="#ffffff" className="!w-[12px] !h-[12px] md:!w-[20px] md:!h-[20px]" />}
                        </button>

                        <TwitterShareButton
                            title={twitterTitle}
                            url={`https://play.biteclub.io/`}
                            hashtags={['']}
                            className={`${isClaimed ? 'block' : 'hidden'}`}
                            onClick={handleShareClick}
                            onMouseEnter={() => playHover()}
                        >
                            <div className="flex justify-center items-center gap-[5px] w-[118px] md:w-[200px] h-[31px] md:h-[53px] text-[12px] md:text-[20px] font-bold leading-[17px] md:leading-[30px] gradient-btn rounded-[10px] mt-[12px] md:mt-[18px] transition duration-300 hover:transform hover:scale-110">
                                <img src={TwitterIcon} alt="Twitter" className="w-5 h-5 md:w-8 md:h-8 inline-block mr-2" />
                                Share!
                            </div>
                        </TwitterShareButton>
                    </div>
                </Slide>
            </div>
        </div>
    );
};

export default SpinModal;