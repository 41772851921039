import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Countdown from "react-countdown";
import { toast } from "react-toastify";
import ReactGA from "react-ga";
import useSound from "use-sound";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import axios from "axios";
import { CombinedReducer } from "../../store";
import { delay, displayName } from "../../utils";
import { RpsEnum } from "../../enums";
import User from "../../interfaces/User";
import Game from "../../interfaces/Game";
import { rpsItems } from "../../data";
import questionMarckIcon from "../../assets/question_mark.svg";
import closeIcon from "../../assets/close.svg";
// @ts-ignore
import Fade from 'react-reveal/Fade';

import "animate.css";

interface IProps {
    game: Game;
}

const GameBox = (props: IProps) => {
    const { game } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [move, setMove] = useState(0);
    const [joinModalOpen, setJoinModalOpen] = useState(false);

    const user = useSelector<CombinedReducer, User>((state) => state.user);
    const [playJoin] = useSound("/sound/join.mp3", { volume: 0 });
    const [playWin] = useSound("/sound/win.mp3", { volume: 0.5 });
    const [playLoss] = useSound("/sound/loss.mp3", { volume: 0.2 });
    const [playCountDown] = useSound("/sound/countdown.mp3", { volume: 0.1 });
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
    const [finalBg, setFinalBg] = useState<string>("");
    const [show, setShow] = useState<boolean>(true);

    const isCreator = game?.creator?._id == user?._id;

    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        if (game?.status == "flipping") {
            if (game?.creator?._id == user?._id || game?.opponent?._id == user?._id) {
                let finalBg = 'draw-bg';
                if (game?.winner) {
                    if (game?.winner?._id == user?._id) {
                        finalBg = 'win-bg';
                    } else {
                        finalBg = 'lose-bg';
                    }
                }

                setFinalBg(finalBg);
            }

            setTimeout(() => {
                changeGameStatus('revealed');
            }, 3300)
        }
    }, [game?.status]);

    useEffect(() => {
        applySound(game);
    }, [game.status]);

    useEffect(() => {
        if (!game) return;

        applySound(game);
    }, [game.status]);

    const applySound = (game: Game) => {
        if (
            !(user?._id && [game.creator._id, game.opponent?._id].includes(user._id))
        )
            return;

        if (game.status === "ended") {
            playJoin();
        } else if (game.status === "revealed") {
            if (!game.winner) return;

            if (game.winner?._id === user._id) {
                playWin();
            } else {
                playLoss();
            }
        } else if (game.status === "countdown") {
            playCountDown();
        }
    };

    const changeGameStatus = (status: string) => {
        if (!game) return;
        if (game.status === status) return;

        game.status = status;
        dispatch({ type: "UPDATE_RPS_GAME", payload: game });

        if (status === "revealed") {
            if (user !== null) {
                if (game.winner && game.winner?._id === user._id) {
                    dispatch({ type: "UPDATE_USER_BALANCE", payload: game.amount * 2 });
                } else if (
                    !game.winner &&
                    [game.creator._id, game.opponent?._id].includes(user._id)
                ) {
                    dispatch({
                        type: "UPDATE_USER_BALANCE",
                        payload: game.amount,
                    });
                }
            }

            if (game?.creator?._id !== user?._id && game?.opponent?._id !== user?._id) {
                setShow(false);
                game.status = "removed";
                dispatch({ type: "UPDATE_RPS_GAME", payload: game });
                setShow(true);
            } else {
                setTimeout(() => {
                    setShow(false);
                    setTimeout(() => {
                        game.status = "removed";
                        dispatch({ type: "UPDATE_RPS_GAME", payload: game });
                        setShow(true);
                    }, 500);
                }, 2500);
            }
        }
    };

    const joinGame = async () => {
        if (!(user && user?.publicKey && game)) return;

        if (user._id === game.creator._id)
            return toast.error("You can not join your game");
        if (user.balance < game.amount)
            return toast.error("Balance needs to be higher than the game bet");

        try {
            await axios.post("/api/rps-game/join", {
                gameId: game._id,
                move,
            });

            // google analytics event
            ReactGA.event({
                category: "DICE",
                action: "JOIN_GAME",
                label: "DEGEN_GAME"
            });

            toast.success("Joined the game");

            setJoinModalOpen(false);
        } catch (e: any) {
            toast.error(e.response.data.message.toString());
        }
    };

    const cancelGame = async () => {
        if (!(user && user?.publicKey && game)) return;

        if (game.creator._id !== user._id)
            return toast.error("You can not cancel another person`s game");

        try {
            setShow(false);
            await delay(300);
            await axios.post("/api/rps-game/cancel", {
                gameId: game._id,
            });

            setShow(true);

            // google analytics event
            ReactGA.event({
                category: "DICE",
                action: "CANCEL_GAME",
                label: "DEGEN_GAME"
            });

            toast.success("Game has been cancelled");
        } catch (e: any) {
            toast.error(e.response.data.message.toString());
        }
    };

    return (
        <Fade duration={1200} opposite when={!isAnimating ? show : false} onReveal={() => setIsAnimating(false)}>
            <div className={`
                w-[362px] md:w-[315px] h-[110px] md:h-[175px] bg-[#000000] shadow-[0px_0px_7.1746px_#00A5FE] rounded-[10px] text-center relative flex flex-col justify-center gap-0 md:gap-2 overflow-hidden pt-2
                ${game?.status == 'flipping' ? `${RpsEnum[game?.creatorMove!].toLowerCase()}-${RpsEnum[game?.opponentMove!].toLowerCase()}` : ''}
                ${game?.status == 'revealed' && finalBg ? finalBg : ''}
            `}>
                {
                    game && (
                        <>
                            {
                                isCreator && game.status == "active" && (
                                    <button
                                        className="absolute top-[12px] right-[15px]"
                                        onClick={cancelGame}
                                        onMouseEnter={() => playHover()}
                                    >
                                        <img src={closeIcon} alt="close-icon" className="close-icon" />
                                    </button>
                                )
                            }

                            <div className="flex justify-center items-center w-full">
                                {/* Left leaf */}
                                {game.status === "revealed" ? (
                                    <></>
                                ) : game.status === "flipping" ? (
                                    <div className="text-center w-[85px]">
                                        <div className="flex h-[70px] md:h-[80px]">
                                            <img
                                                src={rpsItems[RpsEnum.Rock].icon}
                                                alt={rpsItems[RpsEnum.Rock].title}
                                                className="w-full hidden"
                                            />
                                        </div>
                                        <div className="font-normal mt-1 hidden">{displayName(game.creator)}</div>
                                    </div>
                                ) : (
                                    <div className="text-center w-[85px]">
                                        <div className="flex h-[70px] md:h-[80px]">
                                            <img src={rpsItems[RpsEnum.Rock].icon} alt={rpsItems[RpsEnum.Rock].title} className="w-full" />
                                        </div>
                                        <div
                                            className="font-normal cursor-pointer mt-1"
                                                    onClick={() => navigate(`/user-stats/${game?.creator?.publicKey}`)}
                                                    onMouseEnter={() => playHover()}
                                        >
                                            {displayName(game.creator)}
                                        </div>
                                    </div>
                                )}

                                {/* Middle */}
                                <div className="text-[14px] md:text-[22px] font-medium md:font-extrabold w-[156px] md:w-[110px] md:translate-y-[-12px]">
                                    <div className="flex items-center justify-center h-[45px] md:h-[65px]">
                                        {
                                            game.status === "countdown" ? (
                                                <Countdown
                                                    date={game.countDownEnd}
                                                    onComplete={() => changeGameStatus("flipping")}
                                                    renderer={(time) => <h3>{time.seconds}</h3>}
                                                />
                                            ) : game.status === "revealed" ? (
                                                ""
                                            ) : (
                                                "VS"
                                            )
                                        }
                                    </div>
                                    {
                                        game?.status !== "revealed" && (
                                            <div className="text-[18px] font-extrabold md:hidden">
                                                {`${(game?.amount / LAMPORTS_PER_SOL).toLocaleString()} SOL`}
                                            </div>
                                        )
                                    }
                                </div>

                                {/* Right Leaf */}
                                {!game.opponent ? (
                                    !user || game.creator._id === user._id ? (
                                        <div className="text-center w-[85px] h-[85px]">
                                            <div className="flex items-center h-[45px] md:h-[55px]">
                                                <img
                                                    src={questionMarckIcon}
                                                    alt={'question-mark-icon'}
                                                    className="w-full h-[30px] md:h-full"
                                                />
                                            </div>
                                            <div className="font-normal mt-[15px]">waiting...</div>
                                        </div>
                                    ) : (
                                        <button
                                            className="w-[70px] md:w-[90px] h-[27px] md:h-[35px] text-[14px] md:text-[18px] font-extrabold gradient-btn full-rounded translate-y-[0px] md:translate-y-[-12px]"
                                                onClick={() => setJoinModalOpen(true)}
                                                onMouseEnter={() => playHover()}
                                        >
                                            Join
                                        </button>
                                    )
                                ) : game.status === "revealed" ? (
                                    <></>
                                ) : game.status === "flipping" ? (
                                    <div className="text-center w-[85px]">
                                        <div className="flex h-[45px] md:h-[55px] flip">
                                            <img
                                                src={rpsItems[RpsEnum.Rock].icon}
                                                alt={rpsItems[RpsEnum.Rock].title}
                                                className="w-full hidden"
                                            />
                                        </div>
                                        <div className="font-normal mt-1 hidden">{displayName(game.opponent)}</div>
                                    </div>
                                ) : (
                                    <div className="text-center w-[85px]">
                                        <div className="flex h-[70px] md:h-[80px]">
                                            <img src={rpsItems[RpsEnum.Rock].icon} alt={rpsItems[RpsEnum.Rock].title} className="flip w-full" />
                                        </div>
                                        <div
                                            className="font-normal cursor-pointer mt-1"
                                                        onClick={() => navigate(`/user-stats/${game?.opponent?.publicKey}`)}
                                                        onMouseEnter={() => playHover()}
                                        >
                                            {displayName(game.opponent)}
                                        </div>
                                    </div>
                                )}
                            </div>

                            {
                                game?.status !== "revealed" && (
                                    <div className="text-[24px] font-extrabold hidden md:block">
                                        {`${(game?.amount / LAMPORTS_PER_SOL).toLocaleString()} SOL`}
                                    </div>
                                )
                            }
                        </>
                    )
                }

                {/* Join Game Modal */}
                <div
                    className="fixed top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] bg-black/50 z-[1040] overflow-y-auto hidden justify-center"
                    style={{ display: joinModalOpen ? "flex" : "none" }}
                >
                    <div className="flex m-4 w-full min-h-[calc(100vh-6rem)] justify-center items-center">
                        <div className="modal-wrapper">
                            <button
                                className="flex justify-center items-center absolute top-[10px] right-[10px] p-[10px] cursor-pointer bg-none border-none rounded-[6px]"
                                onClick={() => setJoinModalOpen(false)}
                                onMouseEnter={() => playHover()}
                            >
                                <svg width="14" height="14" className="fill-white transition-[fill_200ms_ease_0s]">
                                    <path d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z"></path>
                                </svg>
                            </button>
                            <h1 className="font-semibold text-[26px] leading-[41px] mt-0 mb-[27px] w-full text-center text-white">Join Game</h1>
                            <div className="coinChooser">
                                {
                                    rpsItems.map((item, index) => {
                                        return (
                                            <img
                                                key={index}
                                                onClick={() => setMove(item.move)}
                                                onMouseEnter={() => playHover()}
                                                className={move === item.move ? "chosen flip" : "flip"}
                                                src={item.icon}
                                            />
                                        )
                                    })
                                }
                            </div>

                            <button className="gradient-btn full-rounded w-[120px] h-[40px] text-[20px] mt-2" onClick={() => joinGame()} onMouseEnter={() => playHover()}>
                                Join
                            </button>
                        </div>
                    </div>
                    <div className="overlay"></div>
                </div>
            </div>
        </Fade>
    );
};

export default GameBox;