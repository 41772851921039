import React from "react";

interface IProps {
    color?: string
}

const UserIcon = (props: IProps) => {
    const { color } = props;

    return (
        <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.8138 17.9995H2.0919C0.0105701 16.9433 -0.51688 15.3816 0.506102 10.7997C2.10269 8.83816 3.04609 8.43289 4.86705 9.67474C6.48616 10.2767 7.10721 10.1606 8.03864 9.67474C9.87783 8.20981 10.8152 8.6794 12.3996 10.7997C13.4955 16.2854 12.5688 16.9586 10.8138 17.9995Z" fill={color ?? "#FFFFFF"} />
            <ellipse cx="6.25455" cy="4.27489" rx="3.76627" ry="4.27489" fill={color ?? "#FFFFFF"} />
        </svg>
    );
};

export default UserIcon;
