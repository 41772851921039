import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import useSound from "use-sound";
import ReactGA from "react-ga";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import axios from "axios";
import RecentGameCard from "../RecentGameCard";
import GameBox from "./GameBox";
import { betAmountList } from "../../data";
import { CombinedReducer } from "../../store";
import User from "../../interfaces/User";
import { Sockets } from "../../reducers/sockets";
import Game from "../../interfaces/Game";
import closeIcon from "../../assets/close.svg";
import { motion, AnimatePresence } from "framer-motion";

const validStatuses = ["removed", "revealed"];

const DiceGame = () => {
    const dispatch = useDispatch();
    const [betAmount, setBetAmount] = useState<number>(betAmountList[1]);
    const [userGames, setUserGames] = useState<Game[]>([]);
    const [openGames, setOpenGames] = useState<Game[]>([]);
    const [recentGames, setRecentGames] = useState<Game[]>([]);
    const [showCreateBox, setShowCreateBox] = useState<boolean>(false);
    const [creatingGame, setCreatingGame] = useState(false);

    const [playNewBetSound] = useSound('/sound/pop.mp3', { volume: 1 });
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
    const user = useSelector<CombinedReducer, User>((state) => state.user);
    const games = useSelector<CombinedReducer, Game[]>((state) => state.diceGames);
    const sockets = useSelector<CombinedReducer, Sockets>(
        (state) => state.sockets
    );

    const handleCreateGame = async () => {
        if (!(user && user?.publicKey && !creatingGame)) return;

        if (user.balance < betAmount * LAMPORTS_PER_SOL) {
            return toast.error("Balance needs to be higher than the game bet");
        }

        setCreatingGame(true);

        try {
            await axios.post("/api/dice-game", {
                amount: betAmount * LAMPORTS_PER_SOL,
                creatorMove: 1,
            });

            // google analytics event
            ReactGA.event({
                category: "DICE",
                action: "CREATE_GAME",
                label: "DEGEN_GAME"
            });

            setCreatingGame(false);
            toast.success("Game created");
        } catch (e: any) {
            setCreatingGame(false);
            toast.error(e.response.data.message.toString());
        }
    };

    const gameUpdate = (game: Game) => {
        dispatch({ type: "UPDATE_DICE_GAME", payload: game });
    };

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        const getGamesData = async () => {
            const activeGames = await axios.get("/api/dice-game/allActive");
            dispatch({ type: "LOAD_DICE_GAMES", payload: activeGames.data });
        };

        const loadRecentGames = async () => {
            const recentGames = (await axios.get("/api/dice-game/recentGames")).data;
            setRecentGames(recentGames);
        };

        getGamesData();
        loadRecentGames();
    }, []);

    useEffect(() => {
        if (!sockets.dice) return;

        sockets.dice.on("newGame", (game: Game) => {
            dispatch({ type: "ADD_DICE_GAME", payload: game });
            playNewBetSound(); // Add this line to play the sound
        });
        sockets.dice.on("gameUpdate", (updatedGame: Game) => {
            gameUpdate(updatedGame);
        });

        // Clean up the event listeners when the component is unmounted or useEffect dependencies change
        return () => {
            if (sockets.dice) {
                sockets.dice.off("newGame");
                sockets.dice.off("gameUpdate");
            }
        };
    }, [sockets.rps, playNewBetSound]); // Add playNewBetSound to the dependency array

    useEffect(() => {
        let userGames: Game[] = [];
        let openGames: Game[] = [];

        games.forEach((game) => {
            if (game.status !== "removed" && game.status !== "cancelled") {
                if (game.creator._id === user?._id) userGames.push(game);
                else if (game.opponent && game.opponent._id === user?._id)
                    userGames.push(game);
                else openGames.push(game);
            }
        });

        const gameSort = (a: Game, b: Game) => {
            if (b.amount === a.amount) {
                return b.createdAt < a.createdAt ? 1 : -1;
            }
            return b.amount - a.amount;
        }

        userGames = userGames.sort(gameSort);
        openGames = openGames.sort(gameSort);

        setUserGames(userGames);
        setOpenGames(openGames);

    }, [user, games]);

    return (
        <div className="flex justify-center h-full flex-col md:flex-row items-center md:items-start">
            <div className="w-[384px] px-[32px] flex-none">
                <div className={`relative w-full ${showCreateBox ? 'mt-[13px]' : 'mt-0'} md:mt-[45px] transition-all duration-300 ${showCreateBox ? 'bg-[#090C0E]' : 'bg-inherit'} md:bg-inherit rounded-[10px] md:rounded-none pt-[25px] pb-[25px] md:pt-0`}>
                    <div className={`${showCreateBox ? '' : 'hidden'} md:block`}>
                        <button
                            className="absolute top-[10px] right-[9px] md:hidden"
                            onClick={() => setShowCreateBox(false)}
                            onMouseEnter={() => playHover()}
                        >
                            <img src={closeIcon} alt="close-icon" className="close-icon" />
                        </button>

                        <div className="mt-[21px] md:mt-[35px] mb-[8px] font-medium text-center md:text-left text-[14px] md:text-[16px]">Bet Amount</div>
                        <div className="flex flex-wrap justify-center items-center gap-x-[21px] md:gap-x-[10px] gap-y-[12px] md:gap-y-[10px] text-[19px] md:text-[22px] font-medium">
                            {
                                betAmountList.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`flex justify-center items-center cursor-pointer w-[76px] md:w-[100px] h-[57px] md:h-[57px] rounded-[10px] ${betAmount == item ? 'bg-[#03060A] shadow-[0px_0px_9.14062px_#00A5FE]' : 'bg-[#1D262F]'} hover:bg-[#03060A]`}
                                            onClick={() => setBetAmount(item)}
                                            onMouseEnter={() => playHover()}
                                        >
                                            <span className={`${betAmount == item ? 'text-[#FFFFFF]' : 'text-[#808080]'}`}>{item}</span>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>

                    <div className="hidden md:block md:w-[320px] m-auto">
                        <button
                            className={`flex justify-center items-center gap-2 mt-[21px] md:mt-[35px] w-full text-center h-[45px] md:h-[55px] text-[17.6px] md:text-[20px] font-extrabold gradient-btn full-rounded hover:bg-[#03060A] ${creatingGame ? 'opacity-60' : 'opacity-100'}`}
                            onClick={handleCreateGame}
                            onMouseEnter={() => playHover()}
                        >
                            {creatingGame ? "Creating..." : "Create Game"}{" "}
                            {creatingGame ? <ClipLoader size={20} color="#ffffff" /> : null}
                        </button>
                    </div>

                    <div className={`${showCreateBox ? 'w-[270px]' : 'w-[181px]'} md:hidden m-auto`}>
                        {
                            showCreateBox ? (
                                <button
                                    className={`flex justify-center items-center gap-2 mt-[21px] md:mt-[35px] w-full text-center h-[45px] md:h-[55px] text-[17.6px] md:text-[20px] font-extrabold gradient-btn full-rounded ${creatingGame ? 'opacity-60' : 'opacity-100'}`}
                                    onClick={handleCreateGame}
                                    onMouseEnter={() => playHover()}
                                >
                                    {creatingGame ? "Creating..." : "Create Game"}{" "}
                                    {creatingGame ? <ClipLoader size={16} color="#ffffff" /> : null}
                                </button>
                            ) : (
                                <button
                                    className="mt-[21px] md:mt-[35px] w-full text-center h-[45px] md:h-[55px] text-[17.6px] md:text-[20px] font-extrabold gradient-btn full-rounded md:hidden"
                                        onClick={() => setShowCreateBox(true)}
                                        onMouseEnter={() => playHover()}
                                >
                                    Create Game
                                </button>
                            )
                        }
                    </div>
                </div>

                <div className="w-full mt-[80px] hidden md:block max-h-[290px] overflow-y-hidden thin-scroll-bar">
                    <>
                        <div className="mb-[10px] text-[20px] font-extrabold">Recent Games</div>
                        <AnimatePresence>
                            {games
                                .filter(game => validStatuses.includes(game.status) && game.opponent && game.gameType === "dice")
                                .reverse()
                                .map((game, index) => (
                                    <motion.div key={index} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 2 } }} exit={{ opacity: 0 }}>
                                        <RecentGameCard game={game} />
                                    </motion.div>
                                ))}
                        </AnimatePresence>
                        <AnimatePresence>
                            {recentGames.map((game, index) => (
                                <motion.div key={index} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 2 } }} exit={{ opacity: 0 }}>
                                    <RecentGameCard game={game} />
                                </motion.div>
                            ))}
                        </AnimatePresence>
                    </>
                </div>

            </div>

            <div className="grow">
                <div className="bg-inherit md:bg-[#090C0E] rounded-[10px_10px_0px_0px] px-[14px] md:px-[34px] py-[16px] md:min-h-[calc(100vh-80px)]">
                    <div className="text-[22px] font-medium mb-[28px] hidden md:block">Your Games</div>
                    <div className="flex flex-wrap justify-center md:justify-start gap-[15px] mb-[24px] md:mb-[42px] md:min-h-[300px]">
                        {
                            userGames.map((game, index) => {
                                return (
                                    <GameBox key={index} game={game} />
                                )
                            })
                        }
                    </div>

                    <div className="text-[16px] md:text-[22px] font-normal md:font-medium mb-[15px] md:mb-[28px] text-center md:text-left">Open Games</div>
                    <div className="flex flex-wrap justify-center md:justify-start gap-[15px] mb-[42px] md:min-h-[300px]">
                        {
                            openGames.map((game, index) => {
                                return (
                                    <GameBox key={index} game={game} />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DiceGame;