import React, { useState } from "react";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { Statistic } from "antd";
import { useWallet } from "@solana/wallet-adapter-react";
import {
    Connection,
    PublicKey,
    LAMPORTS_PER_SOL,
    Transaction,
    SYSVAR_INSTRUCTIONS_PUBKEY,
    SYSVAR_SLOT_HASHES_PUBKEY
} from "@solana/web3.js";
import {
    ASSOCIATED_TOKEN_PROGRAM_ID,
    createAssociatedTokenAccountInstruction,
    getAssociatedTokenAddress,
    TOKEN_PROGRAM_ID
} from "@solana/spl-token";
import * as anchor from "@project-serum/anchor";
import { Program, AnchorProvider } from "@project-serum/anchor";
import { Wallet } from '@project-serum/anchor/dist/cjs/provider';
import {
    Metadata,
    PROGRAM_ID as TMETA_PROG_ID,
    PROGRAM_ADDRESS
} from '@metaplex-foundation/mpl-token-metadata';
import { Metaplex } from "@metaplex-foundation/js";
import { PROGRAM_ID as AUTH_PROG_ID } from '@metaplex-foundation/mpl-token-auth-rules';
import { ICompletedNft, IPendingNft, IQuestNftInfo, IQuestPoolInfo } from "../../interfaces/Quests";
import questsIdl from "../../idls/quests-idl.json";
import { delay, getBlockTime } from "../../utils";
import { ReactComponent as CheckIcon } from '../../assets/check.svg';
import useSound from "use-sound";
import plustIcon from "../../assets/plus.svg";

// @ts-ignore
import Slide from 'react-reveal/Slide';
import HealValueProgressBar from "./HealValueProgressBar";
import QuestResultsModal from "./QuestResultsModal";

interface IProps {
    show: boolean;
    isLoading: boolean;
    poolAccount: PublicKey | undefined;
    poolInfo: IQuestPoolInfo | undefined;
    stakedNftList: IQuestNftInfo[];
    handleShow: (status: boolean) => void;
    onComplete: (isInit: boolean) => Promise<void>;
    handleBulkHeal: (selectedNfIndexes: number[]) => Promise<void>;
}

const CompleteQuestModal = (props: IProps) => {
    const {
        show,
        isLoading,
        stakedNftList,
        poolAccount,
        poolInfo,
        handleShow,
        onComplete,
        handleBulkHeal
    } = props;

    const wallet = useWallet();
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
    const { Countdown } = Statistic;

    const [questResultsModalOpen, setQuestResultsModalOpen] = useState<boolean>(false);
    const [nftListToRedeem, setNftListToRedeem] = useState<IQuestNftInfo[]>([]);
    const [pendingNfts, setPendingNfts] = useState<IPendingNft[]>([]);
    const [completedNfts, setCompletedNfts] = useState<ICompletedNft[]>([]);
    const [selectedNfIndexes, setSelectedNftIndexes] = useState<number[]>([]);
    const [isAllNftsSelected, setIsAllNftsSelected] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    // constants
    const connection = new Connection(process.env.REACT_APP_RPC_URL!, "confirmed");
    const programID = new PublicKey(questsIdl.metadata.address);
    const provider = new AnchorProvider(connection, wallet as Wallet, { commitment: 'processed' });
    const program = new Program(questsIdl as anchor.Idl, programID, provider);
    const metaplex = new Metaplex(connection);
    const bonesTokenMint = new PublicKey(process.env.REACT_APP_BONES_TOKEN_MINT!);

    const handleSelectAllNfts = async () => {
        if (isAllNftsSelected) {
            setSelectedNftIndexes([]);
            setIsAllNftsSelected(false);
        } else {
            const currentBlockTime = await getBlockTime();
            const completedNftsIndexes: number[] = [];
            stakedNftList.map((item, index) => {
                if (item?.endTime! < currentBlockTime) {
                    completedNftsIndexes.push(index);
                }
            })
            setSelectedNftIndexes(completedNftsIndexes);
            setIsAllNftsSelected(true);
        }
    }

    const handleSelectNfts = async (index: number) => {
        if (!selectedNfIndexes.includes(index)) {
            const currentBlockTime = await getBlockTime();
            if (stakedNftList[index]?.endTime! < currentBlockTime) {
                setSelectedNftIndexes(oldState => {
                    return [...oldState, index];
                })
            } else {
                toast.warn("Not time to redeem");
            }
        } else {
            setSelectedNftIndexes(oldState => {
                return oldState.filter(item => item != index);
            })
        }
    }

    const handleBulkUnStake = async () => {
        if (!wallet || !wallet?.publicKey) {
            toast.warn("Please connect wallet");
            return;
        }

        if (!poolAccount || !poolInfo) {
            toast.warn("Quests Pool not initialized");
            return;
        }

        if (stakedNftList.length == 0) {
            toast.warning("You have no staked NFTs");
            return;
        }

        if (selectedNfIndexes.length == 0) {
            toast.warning("Please select NFTs");
            return;
        }

        const nftListToRedeem = stakedNftList.filter((_, index) => selectedNfIndexes.includes(index));

        let transaction = new Transaction();
        let transactions: Transaction[] = [];
        let pendingNfts: IPendingNft[] = [];

        try {
            setNftListToRedeem(nftListToRedeem);
            setIsProcessing(true);
            handleShow(false);
            setQuestResultsModalOpen(true);

            ///////////////////////////////////////////////////
            const userTokenAccount = await getAssociatedTokenAddress(bonesTokenMint, wallet?.publicKey!);
            const userTokenAccountInfo = await connection.getAccountInfo(userTokenAccount);

            if (!userTokenAccountInfo) {
                transaction.add(
                    createAssociatedTokenAccountInstruction(
                        wallet?.publicKey,
                        userTokenAccount,
                        wallet?.publicKey,
                        bonesTokenMint
                    )
                )
            }
            ///////////////////////////////////////////////////

            await nftListToRedeem.reduce(async (promise, item) => {
                await promise;

                const nftMint = new PublicKey(item.mintAddress);
                const userNftTokenAccount = await getAssociatedTokenAddress(nftMint, wallet?.publicKey!);

                const userNftTokenAccountInfo = await connection.getAccountInfo(userNftTokenAccount);
                if (!userNftTokenAccountInfo) {
                    transaction.add(
                        createAssociatedTokenAccountInstruction(
                            wallet?.publicKey!,
                            userNftTokenAccount,
                            wallet?.publicKey!,
                            nftMint
                        )
                    )
                }

                const nftMetadata = await metaplex.nfts().findByMint({ mintAddress: nftMint });
                const nftEdition = metaplex.nfts().pdas().edition({ mint: nftMint });
                const inflatedMeta = await Metadata.fromAccountAddress(
                    provider.connection,
                    nftMetadata.metadataAddress
                );
                const ruleSet = inflatedMeta.programmableConfig?.ruleSet;

                const stakingVault = PublicKey.findProgramAddressSync(
                    [
                        Buffer.from('quests-staking-vault'),
                        nftMint.toBuffer()
                    ],
                    program.programId
                )[0];

                const nftInfoAccount = PublicKey.findProgramAddressSync(
                    [
                        Buffer.from('quests-nft-info-account'),
                        nftMint.toBuffer(),
                    ],
                    program.programId
                )[0];

                const poolSigner = PublicKey.findProgramAddressSync(
                    [poolAccount!.toBuffer()],
                    program.programId
                )[0];

                ///////////////////////////////////////////////////
                const rewardTokenVault = PublicKey.findProgramAddressSync(
                    [Buffer.from('quests-reward-token-vault')],
                    program.programId
                )[0];
                ///////////////////////////////////////////////////

                const [ownerTokenRecordPda] = PublicKey.findProgramAddressSync(
                    [
                        Buffer.from('metadata'),
                        TMETA_PROG_ID.toBuffer(),
                        nftMint.toBuffer(),
                        Buffer.from('token_record'),
                        stakingVault.toBuffer(),
                    ],
                    TMETA_PROG_ID
                );

                const [destTokenRecordPda] = PublicKey.findProgramAddressSync(
                    [
                        Buffer.from('metadata'),
                        TMETA_PROG_ID.toBuffer(),
                        nftMint.toBuffer(),
                        Buffer.from('token_record'),
                        userNftTokenAccount.toBuffer(),
                    ],
                    TMETA_PROG_ID
                );

                const remainingAccounts = [];
                if (!!ruleSet) {
                    remainingAccounts.push({
                        pubkey: ruleSet,
                        isSigner: false,
                        isWritable: false,
                    });
                }

                const additionalComputeBudgetInstruction =
                    anchor.web3.ComputeBudgetProgram.setComputeUnitLimit({
                        units: 800000,
                    });

                transaction.add(additionalComputeBudgetInstruction);
                transaction.add(
                    anchor.web3.ComputeBudgetProgram.setComputeUnitPrice({
                        microLamports: 50000,
                    })
                );
                transaction.add(
                    await program.methods
                        .unstake(
                            !!ruleSet
                        )
                        .accounts({
                            user: wallet?.publicKey!,
                            nftMint: nftMint,
                            pnftMetadata: nftMetadata.metadataAddress,
                            pnftEdition: nftEdition,
                            userNftTokenAccount: userNftTokenAccount,
                            stakingVault: stakingVault,
                            nftInfoAccount: nftInfoAccount,
                            userRewardTokenAccount: userTokenAccount,
                            rewardTokenVault: rewardTokenVault,
                            poolSigner: poolSigner,
                            pool: poolAccount,
                            ownerTokenRecord: ownerTokenRecordPda,
                            destTokenRecord: destTokenRecordPda,
                            recentSlothashes: SYSVAR_SLOT_HASHES_PUBKEY,
                            instructions: SYSVAR_INSTRUCTIONS_PUBKEY,
                            authorizationRulesProgram: AUTH_PROG_ID,
                            tokenMetadataProgram: PROGRAM_ADDRESS,
                            tokenProgram: TOKEN_PROGRAM_ID,
                            rent: anchor.web3.SYSVAR_RENT_PUBKEY,
                            systemProgram: anchor.web3.SystemProgram.programId,
                            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
                        })
                        .remainingAccounts(remainingAccounts)
                        .instruction()
                )

                /* For raw transaction */
                const latestBlockhash = await connection.getLatestBlockhash('confirmed');
                transaction.feePayer = wallet.publicKey!;
                transaction.recentBlockhash = latestBlockhash.blockhash;

                transactions.push(transaction);
                /* ------------------- */

                if (transactions.length == 15) {
                    const signedTransactions = await wallet.signAllTransactions!(transactions);
                    console.log('signedTransactions', signedTransactions);

                    await Promise.all(signedTransactions.map(async (signedTransaction) => {
                        await connection.sendRawTransaction(signedTransaction.serialize());
                    }));

                    await delay(10000);

                    await updateCompletedStatus(pendingNfts);

                    transactions = [];
                    pendingNfts = [];
                }

                const pendingNft: IPendingNft = {
                    mintAddress: item.mintAddress,
                    nftInfoAccount: nftInfoAccount
                };

                pendingNfts.push(pendingNft);

                setPendingNfts(prev => {
                    return [...prev, pendingNft];
                });

                transaction = new Transaction();
            }, Promise.resolve());

            if (transactions.length > 0) {
                const signedTransactions = await wallet.signAllTransactions!(transactions);
                console.log('signedTransactions', signedTransactions);

                await Promise.all(signedTransactions.map(async (signedTransaction) => {
                    await connection.sendRawTransaction(signedTransaction.serialize());
                }));

                await delay(10000);

                await updateCompletedStatus(pendingNfts);

                transactions = [];
                pendingNfts = [];
            }

            await delay(5000);

            toast.success("Successful!");
            await onComplete(false);

            setSelectedNftIndexes([]);
            setIsAllNftsSelected(false);
            setIsProcessing(false);
        } catch (e: any) {
            console.log("e: ", e);
            toast.error("Failed!");
            setIsProcessing(false);
        }
    }

    const updateCompletedStatus = async (pendingNfts: IPendingNft[]) => {
        try {
            await Promise.all(pendingNfts.map(async (item) => {
                const updatedNftInfo = await program.account.nftInfo.fetch(item.nftInfoAccount);

                let tokenEarned = 0;
                if (updatedNftInfo.lastRandomNumber == 0) {
                    tokenEarned = 100;
                } else if (updatedNftInfo.lastRandomNumber == 1) {
                    tokenEarned = -10;
                } else if (updatedNftInfo.lastRandomNumber == 2) {
                    tokenEarned = 200;
                } else if (updatedNftInfo.lastRandomNumber == 3) {
                    tokenEarned = -5;
                } else if (updatedNftInfo.lastRandomNumber == 4) {
                    tokenEarned = 400;
                }

                const completedNft: ICompletedNft = {
                    mintAddress: item.mintAddress,
                    tokenEarned: tokenEarned
                };

                setCompletedNfts(prev => {
                    return [...prev, completedNft];
                });
            }));
        } catch { }
    }

    return (
        <>
            {
                show ? (
                    <Slide top duration={500}>
                        <div
                            className={`fixed top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] bg-black/50 z-[1040] overflow-y-auto justify-center mobile-height ${show ? (questResultsModalOpen ? 'hidden md:flex' : 'flex') : 'hidden'}`}
                        >
                            <div className="flex justify-center items-center w-full h-full pt-[7rem] pb-[9rem] md:py-[5rem]">
                                <div className="flex flex-col items-center max-w-[900px] md:max-h-[73%] w-full h-full mx-[2rem] md:rounded-[10px] font-semibold p-[25px_18px] md:p-[36px_30px_30px] flex-1 text-center bg-[#090C0E] shadow-[0px_0px_7px_#00A5FE] relative z-[1050]">
                                    <button
                                        className="sm:flex justify-center items-center absolute top-0 right-0 p-[10px] cursor-pointer bg-none border-none rounded-[6px] transform hover:scale-110 transition-all"
                                        onClick={() => handleShow(false)}
                                        onMouseEnter={() => playHover()}
                                    >
                                        <svg width="14" height="14" className="fill-white transition-[fill_200ms_ease_0s]">
                                            <path d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z"></path>
                                        </svg>
                                    </button>

                                    <div className="w-full relative">
                                        <h1 className="font-semibold text-[16px] md:text-[26px] text-white text-center leading-[100%] md:leading-[41px] w-full">Choose NFTs</h1>

                                        <div className="flex items-center gap-[8px] md:gap-[12px] w-full absolute left-0 top-[5px]">
                                            <span className="text-[10px] md:text-[16px] font-medium leading-[11px] md:leading-[22px]">Select All</span>

                                            <div
                                                className="w-[15px] md:w-[20px] h-[15px] md:h-[20px] bg-[#1D262F] shadow-[0px_0px_8px_#00A5FE] cursor-pointer relative"
                                                onClick={() => handleSelectAllNfts()}
                                                onMouseEnter={() => playHover()}
                                            >
                                                {
                                                    isAllNftsSelected && (
                                                        <div className="w-full h-full p-[3px_2px] absolute top-0 left-0">
                                                            <CheckIcon className="w-full h-full" />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="grow w-full mt-[25px] overflow-x-hidden overflow-y-auto no-scroll-bar">
                                        {
                                            !isLoading ? (
                                                stakedNftList.length > 0 ? (
                                                    <div className="pl-6 flex flex-wrap justify-between sm:justify-start gap-[10px] md:gap-[35px] w-full">
                                                        {
                                                            stakedNftList.map((item, index) => {
                                                                return (
                                                                    <div key={index} className="flex-none w-[132px] sm:w-[240px] transform hover:scale-105 transition-transform">
                                                                        <div
                                                                            className={`nft-img-container  ${selectedNfIndexes.includes(index) ? 'active' : ''}`}
                                                                            onClick={() => handleSelectNfts(index)}
                                                                            onMouseEnter={() => playHover()}
                                                                        >
                                                                            <div className="img-container">
                                                                                <HealValueProgressBar value={item.healthValue} maxValue={item.maxHealthValue} />
                                                                                <img src={item?.image} alt={item?.name} />
                                                                            </div>
                                                                            <div className="text-[12px] md:text-[16px] text-black py-[8px] md:py-[10px]">
                                                                                <span>{item?.name}</span>
                                                                                <Countdown
                                                                                    value={Date.now() + ((item?.endTime || 0) - (item?.currentBlockTime || 0)) * 1000}
                                                                                    format="D:HH:mm:ss"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                ) : (
                                                    <h3>You have no items</h3>
                                                )
                                            ) : (
                                                <h3 className="flex justify-center items-center gap-[10px]">
                                                    Loading your NFTs from your wallet. Please hold... <ClipLoader size={24} color="#ffffff" className="flex-none" />
                                                </h3>
                                            )
                                        }
                                    </div>

                                    <div className="flex justify-center items-center gap-[12px] mt-[35px] w-full">
                                        {/* Redeem Button */}
                                        <button
                                            disabled={isLoading || isProcessing}
                                            className="flex justify-center items-center gap-[10px] gradient-btn full-rounded min-w-[120px] h-[40px] px-[26px] text-[20px] transition duration-300 hover:transform hover:scale-110 opacity-100"
                                            onClick={handleBulkUnStake}
                                            onMouseEnter={() => playHover()}
                                        >
                                            {
                                                isProcessing ? (
                                                    <>
                                                        <span>Processing</span>{" "}
                                                        <ClipLoader size={18} color="#ffffff" />
                                                    </>
                                                ) : (
                                                    "Accept"
                                                )
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="overlay"></div>
                        </div>
                    </Slide>
                ) : (
                    null
                )
            }

            {/* Quest Results Status Modal */}
            <QuestResultsModal
                show={questResultsModalOpen}
                isProcessing={isProcessing}
                nftListToRedeem={nftListToRedeem}
                pendingNfts={pendingNfts}
                completedNfts={completedNfts}
                handleShow={setQuestResultsModalOpen}
                setNftListToRedeem={setNftListToRedeem}
                setPendingNfts={setPendingNfts}
                setCompletedNfts={setCompletedNfts}
            />
        </>
    );
};

export default CompleteQuestModal;