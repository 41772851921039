import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { TwitterShareButton } from 'react-share';
import Confetti from "react-confetti";
import axios from "axios";
// @ts-ignore
import Slide from "react-reveal/Slide";
import { rewardItems } from "../../data";
import { FreeRewardTypeEnum } from "../../enums";
import { CombinedReducer } from "../../store";
import { Sockets } from "../../reducers/sockets";
import logoImgIcon from "../../assets/logo_img.svg";
import logoTextIcon from "../../assets/logo_text.svg";
import headLargeIcon from "../../assets/head-large.svg";
import tailLargeIcon from "../../assets/tail-large.svg";
import useSound from "use-sound";
import TwitterIcon from "../../assets/twitter.svg";

interface IProps {
    show: boolean;
    handleShow: (status: boolean) => void
}

const CardModal = (props: IProps) => {
    const { show, handleShow } = props;
    const dispatch = useDispatch();
    const [playShare] = useSound("/sound/win.mp3", { volume: 1 });
    const [playPick, { stop }] = useSound("/sound/cardflip.mp3", { volume: 1 });
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
    const [selectedCard, setSelectedCard] = useState<number | null>(null);
    const [isClaimed, setIsCalimed] = useState<boolean>(false);
    const [notificationMsg, setNotificationMsg] = useState<string | null>(null);
    const [twitterTitle, setTwitterTitle] = useState<string>('');
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const sockets = useSelector<CombinedReducer, Sockets>((state) => state.sockets);

    const handleShareClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, link: string) => {
        playShare();
    };

    const handlePickClick = () => {
        setTimeout(() => {
            playPick(); // Play the sound when card is selected
        }, 1000); //Timeout
    };    
    
    const handleCardFlip = async (index: number) => {

        if (notificationMsg !== null) {
            return;
        }

        try {
            setIsProcessing(true);
            setSelectedCard(index);
            await axios.post("/api/u/card");
        
        } catch (e: any) {
            setIsProcessing(false);
            setSelectedCard(null);
            if (e?.response?.data?.message) {
                toast.error(e.response.data.message.toString());
            } else {
                toast.error("Failed to choose card. please try again!");
            }
        }
    };

    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        });
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize]);

    useEffect(() => {
        if (!show) {
            stop(); //Stop and reset the sound
            setIsCalimed(false);
            setSelectedCard(null);
            setNotificationMsg(null);
            setTwitterTitle("");
        }
    }, [show]);

    useEffect(() => {
        if (isClaimed && show) {
          playShare();
        }
      }, [isClaimed, show]);

    useEffect(() => {
        if (sockets && sockets?.user) {
            sockets.user.on("selectedCard", (selectedItemIndex: number) => {
                dispatch({ type: "USER_SELECTED_ITEM" });

                const selecteditem = rewardItems[selectedItemIndex];

                if (selecteditem.type == FreeRewardTypeEnum.Sol) {
                    setNotificationMsg(selecteditem.amount.toString());
                } else {
                    setNotificationMsg("1x Ticket");
                }

                if (selecteditem.amount > 0) {
                    let title = "";

                    if (selecteditem.type == FreeRewardTypeEnum.Sol) {
                        title = `Won ${selecteditem.amount} $SOL from a free Reward on the #BiteClub platform @degen_games_sol, try your luck today at; `;
                    } else if (selecteditem.type == FreeRewardTypeEnum.Ticket) {
                        title = `Won 1 #Solanaball ticket from a free Reward on the #BiteClub platform @degen_games_sol, try your luck today at; `;
                    }

                    setTwitterTitle(title);
                }

                setTimeout(() => {
                    if (selecteditem.amount > 0) {
                        setIsCalimed(true);
                    }
                    setIsProcessing(false);
                }, 500);
            });
        }
    }, [sockets?.user]);

    return (
        <div
            className="fund-modal fixed top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] bg-black/60 sm:bg-black/50 z-[1040] overflow-y-auto hidden justify-center"
            style={{ display: show ? "flex" : "none" }}
        >
            <div className="flex flex-col justify-center items-center w-full">
                {
                    isClaimed && show && (
                        <Confetti tweenDuration={1000} width={screenSize.dynamicWidth} height={screenSize.dynamicHeight} />
                    )
                }

                <Slide top duration={1000}>
                    <div className="relative flex flex-col items-center w-full max-w-[650px] p-[11px_15px_15px] md:p-[18px_33px_26px] bg-[#11161B] rounded-[8px] shadow-[0px_0px_10px_#00A5FE] z-[1050]">
                        {/* Close button */}
                        <button
                            className="sm:flex justify-center items-center absolute top-0 right-0 p-[10px] cursor-pointer bg-none border-none rounded-[6px] transform hover:scale-110 transition-all"
                            onClick={() => handleShow(false)}
                            onMouseEnter={() => playHover()}
                        >
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 16L16 1M1 1L16 16" stroke="#BEBEBE" strokeWidth="2" />
                            </svg>
                        </button>

                        <h1 className="text-[20px] md:text-[32px] text-center gold-font font-bold leading-[28px] md:leading-[47px]">Pick Any Card</h1>

                        <div className="flex justify-between items-center w-full h-[151px] md:h-[250px] my-[59px] md:my-[103px] card-flip-container">
                            {/* First card */}
                            <div
                                className={`
                                flex justify-center items-center w-[106px] md:w-[175px] h-full relative rounded-[5px] first-card card-flip 
                                ${selectedCard == 0 && notificationMsg !== null ? 'flipped' : ''}
                                ${notificationMsg == null ? 'cursor-pointer hover-enabled' : 'cursor-default'}
                                `}
                                onClick={() => {
                                    handlePickClick();
                                    handleCardFlip(0);
                                }}  
                                onMouseEnter={() => playHover()}
                            >
                                <figure className="back">
                                    {selectedCard !== 0 || notificationMsg === null ? (
                                        <img src={tailLargeIcon} alt="tail-icon" className="w-[52px] md:w-[86px] h-[52px] md:h-[86px]" />
                                    ) : null}

                                    {/* Blur effect */}
                                    <div className="hidden md:flex justify-center items-center w-full absolute left-0 bottom-[-55px]">
                                        <div className="w-[160px] h-[13px] bg-[#EDC967] blur-[30px]"></div>
                                    </div>
                                </figure>

                                <figure className="front">
                                    <div className="text-[14px] md:text-[25px] text-[#00FF00] text-center font-extrabold leading-[20px] md:leading-[30px]">You Won<br /><br></br> {notificationMsg === "0" ? "Nothing This Time!" : (
                                    <span style={{ fontSize: '30px' }}>{notificationMsg}</span>)}</div>
                                    {/* Blur effect */}
                                    <div className="hidden md:flex justify-center items-center w-full absolute left-0 bottom-[-55px]">
                                        <div className="w-[160px] h-[13px] bg-[#EDC967] blur-[30px]"></div>
                                    </div>
                                </figure>
                            </div>

                            {/* Second card */}
                            <div
                                className={`
                                    flex justify-center items-center w-[106px] md:w-[175px] h-full relative rounded-[5px] second-card card-flip 
                                    ${selectedCard == 1 && notificationMsg !== null ? 'flipped' : ''}
                                    ${notificationMsg == null ? 'cursor-pointer hover-enabled' : 'cursor-default'}
                                `}
                                onClick={() => {
                                    handlePickClick();
                                    handleCardFlip(1);
                                }} 
                                onMouseEnter={() => playHover()}
                            >
                                <figure className="back">
                                    {selectedCard !== 1 || notificationMsg === null ? (
                                        <>
                                            <div className="flex justify-center items-center gap-[2.5px]">
                                                <div className="flex justify-center items-center w-[29.5px] md:w-[48px] p-[5px] md:p-[8px] bg-[#090C0E] rounded-[5px]">
                                                    <img src={logoImgIcon} alt="logo" className="w-full" />
                                                </div>
                                                <img src={logoTextIcon} alt="logo" className="w-[53.5px] md:w-[88.5px]" />
                                            </div>

                                            {/* Blur effect */}
                                            <div className="hidden md:flex justify-center items-center w-full absolute left-0 bottom-[-55px]">
                                                <div className="w-[160px] h-[13px] bg-[linear-gradient(88.49deg,#9F263C_0.89%,#A52483_13.08%,#9D64B5_44.27%,#1360B1_83.02%,#00A5FE_100%)] blur-[30px]"></div>
                                            </div>
                                        </>
                                    ) : null}
                                </figure>

                                <figure className="front">
                                    <div className="text-[14px] md:text-[25px] text-[#00FF00] text-center font-extrabold leading-[20px] md:leading-[30px]">You Won<br /><br></br> {notificationMsg === "0" ? "Nothing This Time!" : (
                                    <span style={{ fontSize: '30px' }}>{notificationMsg}</span>)}</div>                                    {/* Blur effect */}
                                    <div className="hidden md:flex justify-center items-center w-full absolute left-0 bottom-[-55px]">
                                        <div className="w-[160px] h-[13px] bg-[linear-gradient(88.49deg,#9F263C_0.89%,#A52483_13.08%,#9D64B5_44.27%,#1360B1_83.02%,#00A5FE_100%)] blur-[30px]"></div>
                                    </div>
                                </figure>
                            </div>

                            {/* Third card */}
                            <div
                                className={`
                                    flex justify-center items-center w-[106px] md:w-[175px] h-full relative rounded-[5px] third-card card-flip 
                                    ${selectedCard == 2 && notificationMsg !== null ? 'flipped' : ''}
                                    ${notificationMsg == null ? 'cursor-pointer hover-enabled' : 'cursor-default'}
                                `}
                                onClick={() => {
                                    handlePickClick();
                                    handleCardFlip(2);
                                }}   
                                onMouseEnter={() => playHover()}
                            >
                                <figure className="back">
                                    {selectedCard !== 2 || notificationMsg === null ? (
                                        <img src={headLargeIcon} alt="head-icon" className="w-[52px] md:w-[86px] h-[52px] md:h-[86px] rotate-180" />
                                    ) : null}

                                    {/* Blur effect */}
                                    <div className="hidden md:flex justify-center items-center w-full absolute left-0 bottom-[-55px]">
                                        <div className="w-[160px] h-[13px] bg-[#00A5FE] blur-[30px]"></div>
                                    </div>
                                </figure>

                                <figure className="front">
                                    <div className="text-[14px] md:text-[25px] text-[#00FF00] text-center font-extrabold leading-[20px] md:leading-[30px]">You Won<br /><br></br> {notificationMsg === "0" ? "Nothing This Time!" : (
                                    <span style={{ fontSize: '30px' }}>{notificationMsg}</span>)}</div>
                                    {/* Blur effect */}
                                    <div className="hidden md:flex justify-center items-center w-full absolute left-0 bottom-[-55px]">
                                        <div className="w-[160px] h-[13px] bg-[#00A5FE] blur-[30px]"></div>
                                    </div>
                                </figure>
                            </div>
                        </div>

                        <button
                            disabled={true}
                            className={`justify-center items-center gap-[5px] w-[118px] md:w-[200px] h-[31px] md:h-[53px] text-[12px] md:text-[20px] font-bold leading-[17px] md:leading-[30px] gradient-btn rounded-[10px] mt-[12px] md:mt-[18px] transition duration-300 hover:transform hover:scale-110 ${isClaimed ? 'hidden' : 'flex'}`}
                        >
                            Pick To Win!
                        </button>

                        <TwitterShareButton
                            title={twitterTitle}
                            url={`https://play.biteclub.io/`}
                            hashtags={['']}
                            className={`${isClaimed ? 'block' : 'hidden'}`}
                            onClick={handleShareClick}
                            onMouseEnter={() => playHover()}
                        >
                            <div className="flex justify-center items-center gap-[5px] w-[118px] md:w-[200px] h-[31px] md:h-[53px] text-[12px] md:text-[20px] font-bold leading-[17px] md:leading-[30px] gradient-btn rounded-[10px] mt-[12px] md:mt-[18px] transition duration-300 hover:transform hover:scale-110">
                                <img src={TwitterIcon} alt="Twitter" className="w-5 h-5 md:w-8 md:h-8 inline-block mr-2" />
                                Share!
                            </div>
                        </TwitterShareButton>
                    </div>
                </Slide>
            </div>
        </div>
    );
};

export default CardModal;