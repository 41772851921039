import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { Virtuoso } from "react-virtuoso";
import { toast } from "react-toastify";
import Countdown from "react-countdown";
import ReactGA from 'react-ga';
import useSound from "use-sound";
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import axios from "axios";
import HowToPlay from "./HowToPlay";
import WinningCombination from "./WinningCombination";
import MyTickets from "./MyTickets";
import MyTicketsModal from "./MyTicketsModal";
import SolanaballWinnersModal from "./SolanaballWinnersModal";
import MyResultsModal from "./MyResultsModal";
import TicketNumbers from "./TicketNumbers";
import { balls, buyTicketItems } from "../../data";
import { CombinedReducer } from "../../store";
import { Sockets } from "../../reducers/sockets";
import { createTwitterShareUrl } from "../../utils";
import { IInitialState } from "../../reducers/lottery";
import { LotteryStatusEnum } from "../../enums";
import User from "../../interfaces/User";
import Lottery from "../../interfaces/Lottery";
import LotteryGame from "../../interfaces/LotteryGame";
import GlobalState from "../../interfaces/GlobalState";
import arrwoDownIcon from "../../assets/arrow-down.svg";
import ShareIcon from "../../assets/share-button.svg";

import "./index.scss";

// @ts-ignore
import Slide from 'react-reveal/Slide';

const sampleArray = [1, 2, 3, 4, 5, 6, 7, 8];

const Solanaball = () => {
    const dispatch = useDispatch();
    const user = useSelector<CombinedReducer, User>((state) => state.user);
    const sockets = useSelector<CombinedReducer, Sockets>((state) => state.sockets);
    const lottery = useSelector<CombinedReducer, IInitialState>((state) => state.lottery);
    const globalState = useSelector<CombinedReducer, GlobalState>((state) => state.globalState);

    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [showBuyTicketsModal, setShowBuyTicketsModal] = useState<boolean>(false);
    const [showMyTicketsModal, setShowMyTicketsModal] = useState<boolean>(false);
    const [showWinnersModal, setShowWinnersModal] = useState<boolean>(false);
    const [showMyResultsModal, setShowMyResultsModal] = useState<boolean>(false);
    const [isAutomaticBuy, setIsAutomaticBuy] = useState<boolean>(true);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

    const [totalTicketPrice, setTotalTicketPrice] = useState<number>(0);
    const [bulkPrice, setBulkPrice] = useState<number>(0);
    const [isDisabledBuyBtn, setIsDisabledBuyBtn] = useState<boolean>(true);
    const [duplicatedStatus, setDuplicatedStatus] = useState<number[]>(new Array(7).fill(0));
    const [errMsg, setErrMsg] = useState<string>("");
    const [isBuying, setIsBuying] = useState<boolean>(false);
    const [lastRound, setLastRound] = useState<Lottery>({});
    const [boughtTickets, setBoughtTickets] = useState<number[][]>([]);

    const [tempOneTicket, setTempOneTicket] = useState<any>([...Array(8).fill("")]);
    const [ticketArray, setTicketArray] = useState<any[]>([]);

    const [isAnimation, setIsAnimation] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [playSolanaball] = useSound("/sound/solanaball.mp3", { volume: 0.2 });
    const [playWin] = useSound("/sound/win.mp3", { volume: 1 });
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });

    const buyTicketRef = React.useRef<any>(null);

    const setInitState = () => {
        setSelectedIndex(null);
        setTotalTicketPrice(0);
        setBulkPrice(0);
        setIsDisabledBuyBtn(true);
        setErrMsg("");
        setTempOneTicket([...Array(8).fill("")]);
        setTicketArray([]);
    };

    const handleShowBuyTicketsModal = (status: boolean) => {
        if (status) {
            // document.body.style.overflow = "hidden"
        } else {
            // document.body.style.overflow = "visible"
        }
        setShowBuyTicketsModal(status);
    }

    const handleSetBuyType = (status: boolean) => {
        setIsAutomaticBuy(status);
        setInitState();
    }

    const handleShowMyTicketsModal = (status: boolean) => {
        if (status) {
            // document.body.style.overflow = "hidden"
        } else {
            // document.body.style.overflow = "visible"
        }
        setShowMyTicketsModal(status);
    }

    /* Manual buy ticket */
    const handleTempTicket = (e: any, index: number) => {
        const inputObject = buyTicketRef.current?.getElementsByClassName(
            "buy-ticket-pick-input"
        );
        let value = Number(e.target.value);
        value = value > 35 ? 35 : value;

        const inputIndex = index == 8 ? index - 1 : index;
        if (value <= 0) {
            inputObject[inputIndex].style.borderColor = "#dc3545";
        } else {
            inputObject[inputIndex].style.borderColor = "#00A5FE";
        }

        if (inputIndex < 7) {
            if (tempOneTicket.includes(value.toString())) {
                inputObject[inputIndex].style.borderColor = "#dc3545";
                duplicatedStatus[inputIndex] = 1;
            } else {
                inputObject[inputIndex].style.borderColor = "#00A5FE";
                duplicatedStatus[inputIndex] = 0;
            }

            if (duplicatedStatus.includes(1)) {
                setErrMsg("You can't pick duplicated number");
            } else {
                setErrMsg("");
            }

            if (Number(e.target.value) > 35) e.target.value = 35;
        }

        console.log('value', e.target.value);
        console.log('inputIndex', inputIndex);

        if (inputIndex == 8 && value > 20) {
            e.target.value = 20;
        }

        const tempData = tempOneTicket;
        tempData[inputIndex] = e.target.value;

        setTempOneTicket(tempData);
        setDuplicatedStatus(duplicatedStatus);
    };

    const handleAddTicket = () => {
        let actionDisabled = false;

        if (tempOneTicket.length < 8 || tempOneTicket.includes()) {
            return;
        }
        const inputObject = buyTicketRef.current?.getElementsByClassName(
            "buy-ticket-pick-input"
        );
        for (let i = 0; i < inputObject.length; i++) {
            if (
                i < 7 &&
                (Number(inputObject[i].value) < 1 ||
                    Number(inputObject[i].value) > 35 ||
                    duplicatedStatus[i] == 1)
            ) {
                inputObject[i].style.borderColor = "#dc3545";

                actionDisabled = true;
            }

            if (
                i == 7 &&
                (Number(inputObject[i].value) < 1 || Number(inputObject[i].value) > 20)
            ) {
                inputObject[i].style.borderColor = "#dc3545";
                actionDisabled = true;
            }
        }

        if (actionDisabled) return;

        for (let i = 0; i < inputObject.length; i++) {
            inputObject[i].value = "";
        }

        const sliceArray1 = tempOneTicket.slice(0, 7);
        const sliceArray2 = tempOneTicket.slice(7);
        sliceArray1.sort((a: any, b: any) => a - b);
        const tempArray = sliceArray1.concat(sliceArray2);

        ticketArray.push(tempArray);
        setTicketArray(ticketArray);
        setTempOneTicket([...Array(8).fill("")]);
    };

    const handleRemoveTicket = (index: number) => {
        ticketArray.splice(index, 1);

        setTicketArray(ticketArray);
        setTempOneTicket([...Array(8).fill("")]);
    };
    /* ----------------- */

    const handleBuyTickets = async () => {
        if (!user) {
            return toast.warn("Please connect wallet");
        }

        if (isAutomaticBuy && selectedIndex == null) {
            return toast.warn("Please select ticket count");
        }

        setIsBuying(true);

        try {
            const ticketCount = selectedIndex !== null ? buyTicketItems[selectedIndex!].ticketCount : 0;
            const randomTickets = await Promise.all(
                new Array(ticketCount).fill(0).map(async () => {
                    const rowData: number[] = [];
                    for (let i = 1; i <= 8; i++) {
                        let add = true;
                        if (i < 8) {
                            const randomNumber = Math.floor(Math.random() * 35) + 1;
                            if (rowData.includes(randomNumber)) add = false;
                            if (add) rowData.push(randomNumber);
                            else i--;
                        } else
                            rowData
                                .sort((a, b) => a - b)
                                .push(Math.floor(Math.random() * 20) + 1);
                    }

                    return rowData;
                })
            );

            const tickets = isAutomaticBuy ? randomTickets : ticketArray;
            await axios.post("/api/lottery-game/buy-tickets", {
                lotteryId: lastRound._id,
                tickets,
            });

            // google analytics event
            ReactGA.event({
                category: "LOTTERY",
                action: "BUY_TICKETS",
                label: "DEGEN_GAME"
            });

            toast.success("Success to buy tickets");
            playWin();
            setInitState();
        } catch (e: any) {
            toast.error(e?.response?.data?.message?.toString());
        }

        setIsBuying(false);
    };

    const calculateTotalPriceForBulkTickets = (ticketPrice: number, ticketCount: number): number => {
        if (ticketCount < 50) {
            return ticketPrice * ticketCount;
        } else if (ticketCount >= 50 && ticketCount < 100) {
            return ticketPrice * ticketCount * 0.95;
        } else {
            return ticketPrice * ticketCount * 0.9;
        }
    }

    const renderer = ({ days, hours, minutes, seconds }: any) => {
        return (
            <div>
                <div className="flex justify-center items-center gap-[5px] text-[13px] md:text-[16px] font-medium leading-[19px] md:leading-[23px]">
                    <div className="w-[20px]">{days}</div>
                    <div className="w-[10px]">:</div>
                    <div className="w-[20px]">{hours}</div>
                    <div className="w-[10px]">:</div>
                    <div className="w-[20px]">{minutes}</div>
                    <div className="w-[10px]">:</div>
                    <div className="w-[20px]">{seconds}</div>
                </div>
                <div className="flex justify-center items-center gap-[5px] text-[8.5px] md:text-[10px] text-[#808080] font-medium leading-[12px] md:leading-[14px]">
                    <div className="w-[20px]">D</div>
                    <div className="w-[10px]"></div>
                    <div className="w-[20px]">Hr</div>
                    <div className="w-[10px]"></div>
                    <div className="w-[20px]">Min</div>
                    <div className="w-[10px]">:</div>
                    <div className="w-[20px]">Sec</div>
                </div>
            </div>
        );
    }

    useEffect(() => {
        let ticketCount = 0;
        let isDisabledBuyBtn = false;
        if (!user) {
            isDisabledBuyBtn = true;
        }

        if (lastRound.status !== LotteryStatusEnum.Open) {
            isDisabledBuyBtn = true;
        }

        if (isAutomaticBuy) {
            ticketCount = selectedIndex !== null ? buyTicketItems[selectedIndex].ticketCount : 0;
        } else {
            ticketCount = ticketArray.length;
        }

        if (ticketCount == 0) {
            isDisabledBuyBtn = true;
        }

        const ticketPrice = (lastRound?.ticketPrice || 0) / LAMPORTS_PER_SOL;
        const totalTicketPrice = ticketPrice * ticketCount;
        const bulkPrice = calculateTotalPriceForBulkTickets(ticketPrice, ticketCount);

        if (bulkPrice * LAMPORTS_PER_SOL > user?.balance) {
            isDisabledBuyBtn = true;
        }

        setTotalTicketPrice(totalTicketPrice);
        setBulkPrice(bulkPrice);
        setIsDisabledBuyBtn(isDisabledBuyBtn);
    }, [isAutomaticBuy, selectedIndex, ticketArray.length, lastRound]);

    useEffect(() => {
        const getLotteries = async () => {
            setIsLoading(true);

            try {
                const finishedRounds = await axios.get("/api/lottery-game/finishedRounds");
                const lastRound = await axios.get("/api/lottery-game/lastRound");

                if (lastRound.data && user) {
                    const config = {
                        params: {
                            lotteryId: lastRound.data._id,
                        },
                    };
                    const currentLotteryUserInfo = await axios.get(
                        "/api/lottery-game/userLotteryInfo",
                        config
                    );
                    console.log("currentLotteryUserInfo", currentLotteryUserInfo.data);
                    dispatch({ type: "SET_USER_LOTTERY_INFO", payload: currentLotteryUserInfo.data });
                }

                dispatch({ type: "SET_FINISHED_ROUNDS", payload: finishedRounds.data });
                dispatch({ type: "SET_LAST_ROUND", payload: lastRound.data });
            } catch { }

            setTimeout(() => {
                setIsLoading(false);
            }, 150);
        };

        getLotteries();
    }, [user]);

    useEffect(() => {
        if (!(sockets && sockets.user)) return;

        sockets.user.on("boughtTickets", (price: number, boughtTickets: number[][]) => {
            dispatch({ type: "BOUGHT_TICKETS", payload: { price, boughtTickets } })
        });
    }, [sockets.user]);

    useEffect(() => {
        if (!sockets.lottery) return;

        sockets.lottery.on("newLottery", (lottery: Lottery) =>
            dispatch({ type: "SET_LAST_ROUND", payload: lottery })
        );
        sockets.lottery.on("closedLottery", (finishedLottery: Lottery) => {
            dispatch({ type: "CLOSED_LOTTERY", payload: finishedLottery })
        });
    }, [sockets.lottery]);

    useEffect(() => {
        const { lastRound } = lottery;
        setLastRound(lastRound);

        if (lottery?.userLotteryInfo?.boughtTickets) {
            setBoughtTickets(lottery?.userLotteryInfo?.boughtTickets);
        } else {
            setBoughtTickets([]);
        }
    }, [lottery]);

    /////////////////////////////////////////////////////////////////////////////
    /// Winning number animation
    /////////////////////////////////////////////////////////////////////////////
    let timer: any;
    let ballElements: any;
    let countdownInterval: any;

    const onComplete = () => {
        setIsAnimation(true);
        setTimeout(() => {
            timer = document.querySelector(".winning-animation-timer");
            ballElements = document.querySelectorAll(".winning-animation-ball");

            resetCountdown();
            resetBallAnimations();
        }, 300);
    }

    function updateCountdown() {
        let countdownValue = parseInt(timer.textContent, 10);
        countdownValue -= 1;

        if (countdownValue < 0) {
            clearInterval(countdownInterval);
            timer.style.animation = "fadeOut 1s forwards";
            startBallAnimations();

            setTimeout(() => {
                setIsAnimation(false);
            }, 20000);
        } else {
            timer.textContent = countdownValue;
        }
    }

    function startBallAnimations() {
        playSolanaball();
        ballElements.forEach((ball: any, index: number) => {
            ball.style.animation = "";
            const animationDelay = 2 * (index + 1);
            // Add rotateAroundBall animation to the number inside the ball with a delay
            ball.querySelector('.winning-animation-number').style.animation = `rotateAroundBall 2s cubic-bezier(0.5, 0, 0.1, 1) ${animationDelay}s 1 forwards`;

            // Update ball animation with delay
            ball.style.animation = `ballGrow 2s ease-out ${animationDelay}s forwards, ballTranslate 2s cubic-bezier(0.5, 0, 0.5, 1) ${animationDelay}s`;
        });
    }

    function resetCountdown() {
        timer.textContent = "10";
        timer.style.animation = "none";
        clearInterval(countdownInterval);
        setTimeout(() => {
            timer.style.animation = "";
            countdownInterval = setInterval(updateCountdown, 1000);
        }, 10);
    }

    function resetBallAnimations() {
        ballElements.forEach((ball: any) => {
            ball.style.animation = "none";
            // Remove rotateAroundBall animation from the number inside the ball
            ball.querySelector('.winning-animation-number').style.animation = "none";
        });
    }
    /////////////////////////////////////////////////////////////////////////////

    return (
        <div className="flex flex-col items-center w-full min-h-[calc(100vh-59px)] md:min-h-[calc(100vh-70px)] bg-[#090C0E] md:rounded-tr-[10px] solanaball relative">
            {/* Jackpot prize & countdown */}

            <div className="flex flex-col items-center w-full mt-[70px] md:mt-[46px]">
                <div className="flex justify-center items-center w-[300px] md:w-[370px] h-[111px] md:h-[134px] bg-[#11161B] rounded-[10px] prize-box">
                    <div className="text-center">
                        {/* Add the Twitter share button here */}
                        <a
                            href={createTwitterShareUrl(`The current #Solanaball Jackpot is ${Math.floor((lastRound?.amountCollectedInGame || 0) / LAMPORTS_PER_SOL).toLocaleString()} $SOL. Have you got your tickets yet? You gotta be in it to win it! @degen_games_sol only found @ play.biteclub.io/`)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="cursor-pointer absolute top-[6px] right-0 mr-1 -translate-y-[-1]">
                            <img
                                src={ShareIcon}
                                alt="Share"
                                className="w-[30px] h-[30px] hover:scale-105 transition-transform duration-200 ease-in-out" />
                        </a>
                        <div className="text-[13px] md:text-[16px] text-[#808080] font-extrabold leading-[20px] md:leading-[24px]">JACKPOT</div>
                        <div className="flex justify-center align-center">
                            <div className="font-orbitron text-[37px] md:text-[45px] text-[#46ff78] font-extrabold leading-[56px] md:leading-[68px]">
                                {`${Math.floor((lastRound?.amountCollectedInGame || 0) / LAMPORTS_PER_SOL).toLocaleString()}`}
                            </div>
                            <img className="solanaicon" />

                        </div>

                    </div>
                </div>

                {
                    !isLoading && !isAnimation && lottery?.lastRound?.status === LotteryStatusEnum.Open && (
                        <div className="text-center mt-[10px]">
                            <div className="text-[11.5px] md:text-[14px] text-[#808080] font-medium leading-[16px] md:leading-[20px]">Countdown to Drawing</div>

                            <div className="flex flex-col items-center">
                                <Countdown
                                    date={lottery?.lastRound.endedAt}
                                    onComplete={onComplete}
                                    className="text-white"
                                    renderer={renderer}
                                />

                                <div className="flex flex-col items-center gap-[16px] mt-[24px]">
                                    <button
                                        className={`w-[140px] md:w-[209px] h-[39px] md:h-[50px] full-rounded text-[16px] md:text-[20px] font-medium leading-[22px] rounded-full border border-solid border-[#00A5FE] bg-[rgba(0,165,254,0.20)]`}
                                        onClick={() => handleShowBuyTicketsModal(true)}
                                        onMouseEnter={() => playHover()}
                                    >
                                        Buy Tickets
                                    </button>

                                    <button
                                        className={`w-[81px] h-[25px] full-rounded text-[12px] font-normal rounded-full border border-solid border-[#808080] bg-[rgba(0,0,0,0.20)]`}
                                        onClick={() => handleShowMyTicketsModal(true)}
                                        onMouseEnter={() => playHover()}
                                    >
                                        My Tickets
                                    </button>

                                    <button
                                        className="text-[14px] text-[#808080] font-normal leading-[20px]"
                                        onClick={() => setShowInfo(!showInfo)}
                                        onMouseEnter={() => playHover()}
                                    >
                                        How it works?
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>

            {/* Lottery animation */}
            <div className={`flex justify-center items-center grow relative`}>
                {
                    !showInfo && (
                        isAnimation ? (
                            <>
                                <div className="winning-animation-timer flex justify-center items-center w-full h-full text-[100px] md:text-[120px] absolute z-[1]">
                                    10
                                </div>
                                <div className="flex justify-center items-center gap-[3px] my-[10px] relative">
                                    {lottery.finishedRounds &&
                                        lottery.finishedRounds.length > 0 &&
                                        lottery.finishedRounds[0].winningNumber!.map((item, index) => (
                                            <div key={index} className="winning-animation-stage w-[35px] md:w-[120px] h-[35px] md:h-[120px] inline-block">
                                                <div className="winning-animation-ball" id={`ball${index + 1}`}>
                                                    <div className="winning-animation-number text-[22px] md:text-[46px] font-bold">{item}</div>
                                                    <img src={balls[index]} alt={`ball`} />
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </>
                        ) : (
                            lottery?.lastRound?.status !== LotteryStatusEnum.Open && (
                                <div className="flex justify-center items-center gap-[3px] my-[10px] relative !animate-none">
                                    {lottery.finishedRounds &&
                                        lottery.finishedRounds.length > 0 &&
                                        lottery.finishedRounds[0].winningNumber!.map((item, index) => (
                                            <div key={index} className="w-[35px] md:w-[120px] h-[35px] md:h-[120px] aspect-square inline-block">
                                                <div className="w-full h-full m-0 rounded-full relative inline-block overflow-hidden">
                                                    <div className="flex justify-center items-center w-full h-full text-[22px] md:text-[46px] font-bold absolute">
                                                        {item}
                                                    </div>
                                                    <img src={balls[index]} alt="ball" className="w-full h-full rounded-full object-cover" />
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            )
                        )
                    )
                }
            </div>

            {/* Buy ticket & info */}
            <div className="flex flex-col items-center w-full">
                {
                    !isLoading && !isAnimation && (
                        lottery?.lastRound?.status === LotteryStatusEnum.Open ? (
                            <div className={`${showInfo ? 'hidden' : 'flex'} flex-col justify-center items-center gap-[20px] w-full max-w-[336px] md:max-w-[380px] h-[170px] md:h-[208px] rounded-[10px] border-2 border-solid border-[#808080] mb-[100px] md:mb-[50px]`}>
                                <div className="text-[16px] md:text-[20px] font-extrabold leading-[24px] md:leading-[30px]">Last draw</div>
                                {
                                    lottery?.finishedRounds && lottery?.finishedRounds?.length > 0 ? (
                                        <TicketNumbers
                                            numArray={lottery?.finishedRounds[0]?.winningNumber!}
                                            className="text-[12px] md:text-[12px] gap-[7px] md:gap-[7px]"
                                            ballSize="w-[28px] md:w-[28px]"
                                            plusFont="text-[20px] font-medium"
                                        />
                                    ) : (
                                        <span className="text-[#808080]">There is no round finished yet.</span>
                                    )
                                }

                                <div className="flex justify-center items-center gap-[20px]">
                                    <button
                                        disabled={!(lottery?.finishedRounds && lottery?.finishedRounds?.length > 0)}
                                        className={`flex justify-center items-center w-[120px] md:w-[140px] h-[35.5px] rounded-full border border-solid border-[#46FF78] bg-[rgba(70,255,120,0.20)] ${!(lottery?.finishedRounds && lottery?.finishedRounds?.length > 0) ? 'opacity-60' : 'opacity-100'}`}
                                        onClick={() => setShowWinnersModal(true)}
                                        onMouseEnter={() => playHover()}
                                    >
                                        <span className="text-[12px] md:text-[16px] font-medium leading-[1px]">View Winners</span>
                                    </button>

                                    <button
                                        disabled={!(lottery?.finishedRounds && lottery?.finishedRounds?.length > 0)}
                                        className={`flex justify-center items-center w-[120px] md:w-[140px] h-[35.5px] gradient-btn full-rounded before:!border ${!(lottery?.finishedRounds && lottery?.finishedRounds?.length > 0) ? 'opacity-60' : 'opacity-100'}`}
                                        onClick={() => setShowMyResultsModal(true)}
                                        onMouseEnter={() => playHover()}
                                    >
                                        <span className="text-[12px] md:text-[16px] font-medium leading-[1px]">My Results</span>
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-col items-center gap-[20px]">
                                {
                                    !showInfo && (
                                        <>
                                            {
                                                lottery?.finishedRounds && lottery?.finishedRounds?.length > 0 && (
                                                    <div className="flex justify-center items-center gap-[12px] md:gap-[20px]">
                                                        <button
                                                            className="flex justify-center items-center w-[140px] h-[40px] rounded-full border border-solid border-[#46FF78] bg-[rgba(70,255,120,0.20)]"
                                                            onClick={() => setShowWinnersModal(true)}
                                                            onMouseEnter={() => playHover()}
                                                        >
                                                            <span className="text-[12px] md:text-[16px] font-medium leading-[1px]">View Winners</span>
                                                        </button>

                                                        <button
                                                            className={`flex justify-center items-center w-[140px] h-[40px] gradient-btn full-rounded before:!border`}
                                                            onClick={() => setShowMyResultsModal(true)}
                                                            onMouseEnter={() => playHover()}
                                                        >
                                                            <span className="text-[12px] md:text-[16px] font-medium leading-[1px]">My Results</span>
                                                        </button>
                                                    </div>
                                                )
                                            }

                                            <div className='flex flex-col justify-center items-center gap-[8px] md:gap-[10px] w-full'>
                                                <div className='text-[16px] md:text-[20px] text-[#808080] font-bold'>Next Solanaball starts in:</div>
                                                {/* Countdown util the next round */}
                                                <Countdown
                                                    date={lottery?.finishedRounds && lottery?.finishedRounds?.length > 0 ? lottery?.finishedRounds[0].endedAt! + 86400 * 1000 : Date.now()}
                                                    // onComplete={() => {
                                                    // }}
                                                    renderer={({ hours, minutes, seconds }: any) => {
                                                        return (
                                                            <div className='flex justify-center items-center gap-[5.5px] md:gap-[8px] w-full'>
                                                                {/* hours */}
                                                                <div className='flex flex-col justify-center items-center w-[49px] md:w-[70px] border-[0.9px] md:border-[1.3px] border-solid border-[#808080]'>
                                                                    <div className='flex justify-center items-center w-full h-[54px] md:h-[77px] bg-[#222C36]  border-b-[1.3px] border-[#808080]'>
                                                                        <span className='text-[22.5px] md:text-[32px] font-bold'>{hours}</span>
                                                                    </div>
                                                                    <div className='flex justify-center items-center w-full h-[21px] md:h-[31px] bg-[#1A2129]'>
                                                                        <span className='text-[8.5px] md:text-[12px] font-bold'>Hours</span>
                                                                    </div>
                                                                </div>

                                                                <div className='flex justify-center items-center text-[16.5px] md:text-[24px] font-bold'>:</div>

                                                                {/* minutes */}
                                                                <div className='flex flex-col justify-center items-center w-[49px] md:w-[70px] border-[0.9px] md:border-[1.3px] border-solid border-[#808080]'>
                                                                    <div className='flex justify-center items-center w-full h-[54px] md:h-[77px] bg-[#222C36]  border-b-[1.3px] border-[#808080]'>
                                                                        <span className='text-[22.5px] md:text-[32px] font-bold'>{minutes}</span>
                                                                    </div>
                                                                    <div className='flex justify-center items-center w-full h-[21px] md:h-[31px] bg-[#1A2129]'>
                                                                        <span className='text-[8.5px] md:text-[12px] font-bold'>Minutes</span>
                                                                    </div>
                                                                </div>

                                                                <div className='flex justify-center items-center text-[16.5px] md:text-[24px] font-bold'>:</div>

                                                                {/* seconds */}
                                                                <div className='flex flex-col justify-center items-center w-[49px] md:w-[70px] border-[0.9px] md:border-[1.3px] border-solid border-[#808080]'>
                                                                    <div className='flex justify-center items-center w-full h-[54px] md:h-[77px] bg-[#222C36]  border-b-[1.3px] border-[#808080]'>
                                                                        <span className='text-[22.5px] md:text-[32px] font-bold'>{seconds}</span>
                                                                    </div>
                                                                    <div className='flex justify-center items-center w-full h-[21px] md:h-[31px] bg-[#1A2129]'>
                                                                        <span className='text-[8.5px] md:text-[12px] font-bold'>Seconds</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )
                                }

                                <button
                                    className="flex flex-col items-center text-center pt-[5px] mb-[72px] md:mb-[45px] hover-effect"
                                    onClick={() => setShowInfo(!showInfo)}
                                    onMouseEnter={() => playHover()}
                                >
                                    <div className="text-[14px] text-[#808080] font-medium leading-[20px]">View Details</div>
                                    <img
                                        src={arrwoDownIcon}
                                        alt="arrow-icon"
                                        className={showInfo ? 'rotate-180' : ''}
                                        style={{
                                            transform: showInfo ? 'rotate(0deg)' : 'rotate(180deg)',
                                            transition: 'transform 0.3s ease'
                                        }}
                                    />
                                </button>
                            </div>
                        )
                    )
                }

                <div className={`flex-col md:flex-row w-full bg-[#11161B] ${showInfo ? 'flex' : 'hidden'}`}>
                    <HowToPlay
                        handleShowBuyTicketsModal={handleShowBuyTicketsModal}
                    />
                    <WinningCombination />
                    <MyTickets />
                </div>

            </div>


            {/* My tickets modal */}
            <MyTicketsModal
                showModal={showMyTicketsModal}
                boughtTickets={boughtTickets}
                onMouseEnter={playHover}
                handleShowModal={handleShowMyTicketsModal}
            />

            {/* Winners modal */}
            {
                showWinnersModal && (
                    <SolanaballWinnersModal
                        showModal={showWinnersModal}
                        onMouseEnter={playHover}
                        handleShowModal={setShowWinnersModal}
                    />
                )
            }

            {/* My results modal */}
            {
                showMyResultsModal && (
                    <MyResultsModal
                        showModal={showMyResultsModal}
                        onMouseEnter={playHover}
                        handleShowModal={setShowMyResultsModal}
                    />
                )
            }

            {/* Buy Tickets Modal */}
            <Slide top when={showBuyTicketsModal} duration={500}>
                <div
                    className="justify-center fixed top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] sm:bg-black/50 z-[1040] overflow-y-auto"
                    style={{ display: showBuyTicketsModal ? "flex" : "none" }}
                >
                    <div className={`max-w-[300px] sm:max-w-none flex flex-col items-center w-full h-[505px] md:h-[688px] mx-[25px] mt-[87px] md:mt-[120px] xl:pl-[70px] ${globalState?.isLiveChatExpanded ? 'xl:pr-[336px]' : 'xl:pr-[70px]'}`}>
                        <div className="relative flex flex-col items-center w-full h-full max-w-[463px] p-[21px_45px_14px] md:p-[30px_60px_20px] bg-[#090C0E] rounded-[8px] border-[1.5px] border-solid border-[#000000] shadow-[0px_0px_8px_#00A5FE] z-[1050]">
                            {/* Close button */}
                            <button
                                className="sm:flex justify-center items-center absolute top-0 right-0 p-[10px] cursor-pointer bg-none border-none rounded-[6px] transform hover:scale-110 transition-all"
                                onClick={() => handleShowBuyTicketsModal(false)}
                                onMouseEnter={() => playHover()}
                            >
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 16L16 1M1 1L16 16" stroke="#BEBEBE" strokeWidth="2" />
                                </svg>
                            </button>


                            {/* Modal header */}
                            <div className="w-full">
                                <div className="w-full text-[17.5px] md:text-[24px] text-white text-center font-medium leading-[25px] md:leading-[34px]">
                                    Buy Tickets
                                </div>
                            </div>

                            {/* Modal body */}
                            <div className="grow w-full mt-[24px] md:mt-[33px]">
                                <div className="w-full">
                                    <button
                                        className={`hover-button w-1/2 h-[34px] md:h-[46px] text-[13px] md:text-[18px] font-medium leading-[19px] md:leading-[26px] ${isAutomaticBuy ? 'buy-ticket-selector border-b-[1.5px] md:border-b-2 border-solid' : 'text-[#808080]'}`}
                                        onClick={() => handleSetBuyType(true)}
                                        onMouseEnter={() => playHover()}
                                    >
                                        Auto
                                    </button>

                                    <button
                                        className={`hover-button w-1/2 h-[34px] md:h-[46px] text-[13px] md:text-[18px] font-medium leading-[19px] md:leading-[26px] ${!isAutomaticBuy ? 'buy-ticket-selector border-b-[1.5px] md:border-b-2 border-solid' : 'text-[#808080]'}`}
                                        onClick={() => handleSetBuyType(false)}
                                        onMouseEnter={() => playHover()}
                                    >
                                        Manual
                                    </button>

                                </div>

                                {
                                    isAutomaticBuy ? (
                                        <>
                                            <div className="text-[13px] md:text-[18px] font-medium leading-[19px] md:leading-[26px] mt-[31px] md:mt-[43px]">Number of Tickets:</div>

                                            <div className="grid grid-cols-3 gap-x-[15px] md:gap-x-[20px] gap-y-[20px] md:gap-y-[27px] mt-[9.5px] md:mt-[13px]">
                                                {
                                                    buyTicketItems.map((item, index) => {
                                                        return (
                                                            <button
                                                                key={index}
                                                                className={`
                                                            hover-button
                                                            flex justify-center items-center w-full h-[44px] md:h-[60px] bg-[#03060A] border-[1.5px] md:border-2 border-solid border-[rgba(128,128,128,0.5)] rounded-[10px] relative 
                                                            ${selectedIndex == index ? 'active-btn' : ''}
                                                        `}
                                                                onClick={() => setSelectedIndex(index)}
                                                                onMouseEnter={() => playHover()}
                                                            >
                                                                <span className={`
                                                            text-[16px] md:text-[22px] text-[#808080] font-medium
                                                            ${item.discountRate > 0 ? 'mb-[10px]' : 'mb-0'}
                                                            ${selectedIndex == index ? 'active-ticket-count leading-[24px] md:leading-[33px]' : 'leading-[23px] md:leading-[32px] '}
                                                        `}>
                                                                    {item.ticketCount}
                                                                </span>
                                                                {
                                                                    item.discountRate > 0 && (
                                                                        <div className="flex justify-center items-baseline w-full text-center absolute left-0 bottom-[3px]">
                                                                            <span className={`text-[#00FF00] font-medium ${selectedIndex == index ? 'active-discount-rate text-[9px] md:text-[12px] leading-[13px] md:leading-[17px]' : 'text-[7px] md:text-[10px] leading-[10px] md:leading-[14px]'}`}>
                                                                                {`${item.discountRate}% Discount`}
                                                                            </span>
                                                                        </div>
                                                                    )
                                                                }
                                                            </button>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="text-[13px] md:text-[18px] text-[#808080] font-medium leading-[19px] md:leading-[26px] mt-[15px] md:mt-[22px]">
                                                <span className="relative">
                                                    Select your numbers:
                                                </span>
                                                <span className="block text-[9px] md:text-[11px] font-normal leading-[13px] md:leading-[17px] mt-[2px]">
                                                    Must be #1-35 or #1-20 for the Solanaball.
                                                </span>
                                            </div>

                                            <div className="flex flex-col items-center gap-[5px] md:gap-[10px] mt-[6px] md:mt-[11px]">
                                                {
                                                    ticketArray.map((item, index) => {
                                                        return (
                                                            <div className="relative">
                                                                <div className="flex justify-center items-center w-full h-full absolute z-[1030] opacity-0 hover:opacity-100 bg-[rgba(0,0,0,0.5)] rounded-[5px] transition-all duration-500">
                                                                    <RemoveCircleOutlineOutlinedIcon
                                                                        color="error"
                                                                        className="cursor-pointer"
                                                                        onClick={() => handleRemoveTicket(index)}
                                                                        onMouseEnter={() => playHover()}
                                                                    />
                                                                </div>
                                                                <TicketNumbers
                                                                    key={index}
                                                                    numArray={item}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                            <div
                                                ref={buyTicketRef}
                                                className="flex justify-center gap-[4px] md:gap-[6px] mt-[11px] relative"
                                            >
                                                {[...Array(9)].map((_, index) => {
                                                    if (index == 7) {
                                                        return (
                                                            <div key={index} className="w-[8px] md:w-[12px]"></div>
                                                        )
                                                    } else {
                                                        return (
                                                            <input
                                                                key={index}
                                                                min={1}
                                                                type="number"
                                                                className="w-[22px] md:w-[30px] h-[22px] md:h-[30px] bg-[#11161B] border border-solid border-[#00A5FE] text-white text-center buy-ticket-pick-input"
                                                                onChange={(e) => handleTempTicket(e, index)}
                                                            />
                                                        );
                                                    }
                                                })}

                                                <div className="flex justify-center items-center absolute right-[-30px] md:right-[-25px] w-[25px] md:w-[30px] aspect-square ">
                                                    <button
                                                        className="spin-button flex justify-center items-center w-[20px] md:w-[25px] aspect-square gradient-btn full-rounded text-[15px] md:text-[20px] font-bold leading-none p-[1px] md:p-[2px]"
                                                        onClick={handleAddTicket}
                                                        onMouseEnter={() => playHover()}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </div>

                            {/* Modal footer */}
                            <div className="w-full mt-[21px] md:mt-[30px]">
                                <div className="w-[170px] md:w-[232px] mx-auto">
                                    <div className="flex justify-between items-center w-full">
                                        <div className="text-[13px] md:text-[18px] text-[#808080] font-medium leading-[19px] md:leading-[26px]">Ticket Price:</div>
                                        <div className="flex items-center gap-[12px] text-right">
                                            <span className="text-[13px] md:text-[18px] font-medium leading-[19px] md:leading-[26px]">
                                                {
                                                    lastRound?.ticketPrice ? lastRound?.ticketPrice / LAMPORTS_PER_SOL : '-'
                                                }
                                            </span>
                                            <span className="text-[10px] md:text-[14px] font-medium leading-[15px] md:leading-[20px]">Sol</span>
                                        </div>
                                    </div>

                                    <div className="flex justify-between items-center w-full mt-[12.5px] md:mt-[17px]">
                                        <div className="flex items-center text-[13px] md:text-[18px] font-medium leading-[19px] md:leading-[26px]">
                                            <div className="w-[15px] md:w-[18px]"></div>
                                            <div>Total Cost</div>
                                        </div>
                                        <div className="flex items-center gap-[12px] text-right">
                                            <span className="text-[13px] md:text-[18px] font-medium leading-[19px] md:leading-[26px]">
                                                {totalTicketPrice > 0 ? totalTicketPrice : '-'}
                                            </span>
                                            <span className="text-[10px] md:text-[14px] font-medium leading-[15px] md:leading-[20px]">Sol</span>
                                        </div>
                                    </div>

                                    <div className="flex justify-between items-center w-full mt-[3px] text-[#00FF00]">
                                        <div className="flex items-center text-[13px] md:text-[18px] font-medium leading-[19px] md:leading-[26px]">
                                            <div className="w-[15px] md:w-[18px]">-</div>
                                            <div>Discount</div>
                                        </div>
                                        <div className="flex items-center gap-[12px] text-right">
                                            <span className="text-[13px] md:text-[18px] font-medium leading-[19px] md:leading-[26px]">
                                                {(totalTicketPrice - bulkPrice) > 0 ? (totalTicketPrice - bulkPrice) : '-'}
                                            </span>
                                            <span className="text-[10px] md:text-[14px] font-medium leading-[15px] md:leading-[20px]">Sol</span>
                                        </div>
                                    </div>

                                    <hr className="border-t-[1.5px] md:border-t-2 border-solid border-[#808080] my-[9px] md:my-[13px]" />

                                    <div className="flex justify-between items-center w-full mt-[17px]">
                                        <div className="flex items-center text-[13px] md:text-[18px] font-medium leading-[19px] md:leading-[26px]">
                                            <div className="w-[15px] md:w-[18px]"></div>
                                            <div>Final Cost</div>
                                        </div>
                                        <div className="flex items-center gap-[12px] text-right">
                                            <span className="text-[13px] md:text-[18px] font-medium leading-[19px] md:leading-[26px]">
                                                {bulkPrice > 0 ? bulkPrice : '-'}
                                            </span>
                                            <span className="text-[10px] md:text-[14px] font-medium leading-[15px] md:leading-[20px]">Sol</span>
                                        </div>
                                    </div>
                                </div>

                                <button
                                    disabled={isBuying || isDisabledBuyBtn}
                                    className={`buy-ticket flex justify-center items-center gap-[10px] gradient-btn full-rounded w-[147px] md:w-[200px] h-[35px] md:h-[48px] text-[13px] md:text-[18px] font-extrabold leading-[20px] md:leading-[27px] mx-auto mt-[18px] md:mt-[25px] ${isDisabledBuyBtn ? 'opacity-60' : 'opacity-100'}`}
                                    onClick={handleBuyTickets}
                                    onMouseEnter={() => playHover()}
                                >
                                    {
                                        isBuying ? (
                                            <>
                                                <span>Processing</span>{" "}
                                                <ClipLoader size={18} color="#ffffff" />
                                            </>
                                        ) : (
                                            "Buy Tickets"
                                        )
                                    }
                                </button>

                                <div className="text-center text-[7px] md:text-[10px] text-[#808080] font-medium leading-[10px] md:leading-[14px] mt-[14.5px] md:mt-[20px]">
                                    Random Ticket purchases are unique. Purchases are final.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="overlay"></div>
                </div>
            </Slide >
        </div >
    );
};

export default Solanaball;