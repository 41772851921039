import React, { memo } from "react";
import Countdown from "react-countdown";

interface IProps {
    remainingTime: number;
}

const RaceCountDown = ({ remainingTime }: IProps) => {

    return (
        (remainingTime && remainingTime > 0) ? (
            <Countdown
                date={Date.now() + remainingTime}
                className="text-white"
                renderer={({ hours, minutes, seconds }: any) => {
                    return (
                        <div className="flex flex-col items-center gap-[10px]">
                            <div className="flex items-center gap-[10px] md:gap-[15px] text-[14px] md:text-[24px] font-medium leading-[20px] md:leading-[34px]">
                                <span>{hours}</span>
                                <span>:</span>
                                <span>{minutes}</span>
                                <span>:</span>
                                <span>{seconds}</span>
                            </div>

                            <div className="flex items-center gap-[10px] md:gap-[15px] text-[12px] md:text-[16px] text-[#808080] font-medium leading-[17px] md:leading-[23px]">
                                <span>Hr</span>
                                <span>Min</span>
                                <span>Sec</span>
                            </div>
                        </div>
                    );
                }}
            />
        ) : (
            <div className="flex flex-col items-center gap-[10px]">
                <div className="flex items-center gap-[10px] md:gap-[15px] text-[14px] md:text-[24px] font-medium leading-[20px] md:leading-[34px]">
                    <span>00</span>
                    <span>:</span>
                    <span>00</span>
                    <span>:</span>
                    <span>00</span>
                </div>

                <div className="flex items-center gap-[10px] md:gap-[15px] text-[12px] md:text-[16px] text-[#808080] font-medium leading-[17px] md:leading-[23px]">
                    <span>Hr</span>
                    <span>Min</span>
                    <span>Sec</span>
                </div>
            </div>
        )
    );
};

export default memo(RaceCountDown);