import React from "react";
import { ReactComponent as LightBlueBall } from '../../assets/light-blue-ball.svg';
import { ReactComponent as RedBall } from '../../assets/red-ball.svg';
import arrwoDownIcon from "../../assets/arrow-down.svg";

interface IProps {
    show?: boolean;
}

const WinningCombination = (props: IProps) => {
    const { show } = props;

    return (
        <div className={`w-full md:w-1/3 md:py-[28px] ${show ? '' : 'hidden'} md:block`}>
            <h3 className="text-[17.5px] md:text-[20px] text-center md:text-start font-extrabold leading-[25px] md:leading-[30px]">Winning Combinations</h3>

            <div className="w-full mt-[22px] md:mt-[30px]">
                <div className="flex items-center w-full text-[10.5px] md:text-[16px] font-extrabold leading-[16px] md:leading-[24px]">
                    <div className="min-w-[68px] md:min-w-[100px]">Division</div>
                    <div className="grow">Winning Combination</div>
                    <div className="text-right">Division Payouts</div>
                </div>

                <hr className="border-t-[1.5px] md:border-t-2 border-[#808080] my-[7.5px] md:my-[10px]" />

                <div className="flex flex-col gap-[6.6px] md:gap-[10px] w-full">

                    <div className="flex items-center w-full text-[9px] md:text-[14px] text-[#808080] font-medium leading-[13px] md:leading-[20px]">
                        <div className="min-w-[68px] md:min-w-[100px]">Division 1</div>
                        <div className="flex items-center gap-[2px] md:gap-[3px] grow">
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <span className="text-[14px] text-white font-normal leading-[20px]">+</span>
                            <RedBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                        </div>
                        <div className="text-right">40% of the prizepool</div>
                    </div>

                    <div className="flex items-center w-full text-[9px] md:text-[14px] text-[#808080] font-medium leading-[13px] md:leading-[20px]">
                        <div className="min-w-[68px] md:min-w-[100px]">Division 2</div>
                        <div className="flex items-center gap-[2px] md:gap-[3px] grow">
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                        </div>
                        <div className="text-right">1.1% of the prizepool</div>
                    </div>

                    <div className="flex items-center w-full text-[9px] md:text-[14px] text-[#808080] font-medium leading-[13px] md:leading-[20px]">
                        <div className="min-w-[68px] md:min-w-[100px]">Division 3</div>
                        <div className="flex items-center gap-[2px] md:gap-[3px] grow">
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <span className="text-[14px] text-white font-normal leading-[20px]">+</span>
                            <RedBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                        </div>
                        <div className="text-right">1.1% of the prizepool</div>
                    </div>

                    <div className="flex items-center w-full text-[9px] md:text-[14px] text-[#808080] font-medium leading-[13px] md:leading-[20px]">
                        <div className="min-w-[68px] md:min-w-[100px]">Division 4</div>
                        <div className="flex items-center gap-[2px] md:gap-[3px] grow">
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                        </div>
                        <div className="text-right">2% of the prizepool</div>
                    </div>

                    <div className="flex items-center w-full text-[9px] md:text-[14px] text-[#808080] font-medium leading-[13px] md:leading-[20px]">
                        <div className="min-w-[68px] md:min-w-[100px]">Division 5</div>
                        <div className="flex items-center gap-[2px] md:gap-[3px] grow">
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <span className="text-[14px] text-white font-normal leading-[20px]">+</span>
                            <RedBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                        </div>
                        <div className="text-right">1.5% of the prizepool</div>
                    </div>

                    <div className="flex items-center w-full text-[9px] md:text-[14px] text-[#808080] font-medium leading-[13px] md:leading-[20px]">
                        <div className="min-w-[68px] md:min-w-[100px]">Division 6</div>
                        <div className="flex items-center gap-[2px] md:gap-[3px] grow">
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <span className="text-[14px] text-white font-normal leading-[20px]">+</span>
                            <RedBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                        </div>
                        <div className="text-right">9.7% of the prizepool</div>
                    </div>

                    <div className="flex items-center w-full text-[9px] md:text-[14px] text-[#808080] font-medium leading-[13px] md:leading-[20px]">
                        <div className="min-w-[68px] md:min-w-[100px]">Division 7</div>
                        <div className="flex items-center gap-[2px] md:gap-[3px] grow">
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                        </div>
                        <div className="text-right">7.6% of the prizepool</div>
                    </div>

                    <div className="flex items-center w-full text-[9px] md:text-[14px] text-[#808080] font-medium leading-[13px] md:leading-[20px]">
                        <div className="min-w-[68px] md:min-w-[100px]">Division 8</div>
                        <div className="flex items-center gap-[2px] md:gap-[3px] grow">
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <span className="text-[14px] text-white font-normal leading-[20px]">+</span>
                            <RedBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                        </div>
                        <div className="text-right">15% of the prizepool</div>
                    </div>

                    <div className="flex items-center w-full text-[9px] md:text-[14px] text-[#808080] font-medium leading-[13px] md:leading-[20px]">
                        <div className="min-w-[68px] md:min-w-[100px]">Division 9</div>
                        <div className="flex items-center gap-[2px] md:gap-[3px] grow">
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <LightBlueBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                            <span className="text-[14px] text-white font-normal leading-[20px]">+</span>
                            <RedBall className="w-[10px] md:w-[15px] h-[10px] md:h-[15px]" />
                        </div>
                        <div className="text-right">22% of the prizepool</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WinningCombination;