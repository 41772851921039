import React, { useEffect, useState } from 'react';
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { Connection, LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction } from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";
import * as anchor from "@project-serum/anchor";
import { Program, AnchorProvider } from "@project-serum/anchor";
import { Wallet } from '@project-serum/anchor/dist/cjs/provider';
import { createTransferInstruction, getAssociatedTokenAddress } from '@solana/spl-token';
import { wagerNfts } from '../../../utils/config';
import { storeWagerItems } from '../../../data';
import wagerSystemIdl from "../../../idls/wager_system-idl.json";
import useSound from "use-sound";

const connection = new Connection(process.env.REACT_APP_RPC_URL!, "confirmed");
const programID = new PublicKey(wagerSystemIdl.metadata.address);

interface IProps {
    index: number;
    balance: number;
    isLoading: boolean;
    initialize: () => Promise<void>;
}

const FundWagerNFT = ({ index, balance, isLoading, initialize }: IProps) => {
    const wallet = useWallet();

    const [isFunding, setIsFunding] = useState<boolean>(false);
    const [amount, setAmount] = useState<number | null>(null);
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
    const provider = new AnchorProvider(connection, wallet as Wallet, { commitment: 'processed' });
    const program = new Program(wagerSystemIdl as anchor.Idl, programID, provider);

    const inputAmount = (input: string) => {
        if (!input) return setAmount(null);

        if (Number(input) >= 0) setAmount(Number(input));
        else setAmount(0);
    };

    const handleFund = async () => {
        if (!wallet?.publicKey) {
            return toast.warn('Please connect wallet');
        }

        if (!amount || amount < 0) {
            return toast.warn('Please input amount');
        }

        try {
            setIsFunding(true);

            const wagerNftMint = wagerNfts[index];
            const userWagerNftAccount = await getAssociatedTokenAddress(wagerNftMint, wallet.publicKey);
            const wagerNftVault = PublicKey.findProgramAddressSync(
                [wagerNftMint.toBuffer()],
                program.programId
            )[0];

            console.log('wagerNftMint', wagerNftMint.toString())
            console.log('userWagerNftAccount', userWagerNftAccount.toString())
            console.log('wagerNftVault', wagerNftVault.toString())
            console.log('amount', amount)

            const transaction = new Transaction();
            transaction.add(
                createTransferInstruction(
                    userWagerNftAccount,
                    wagerNftVault,
                    wallet.publicKey,
                    amount
                )
            );

            const tx = await wallet.sendTransaction(transaction, connection);
            console.log('tx', tx);

            await initialize();
            toast.success("Successful")
        } catch (e: any) {
            console.log("error: ", e);
            toast.error("Failed to fund SOL to coinflip pool");
        }

        setIsFunding(false);
    }

    return (
        <div className='flex flex-col px-[10px] w-full max-w-[600px] my-[15px]'>
            <div className='flex justify-center items-center gap-[10px]'>
                <span className='flex-none w-[86px]'>{`${storeWagerItems[index].wagerAmount} Wager`}</span>

                <div className='w-full max-w-[300px] relative'>
                    <input
                        type="number"
                        disabled={isLoading || isFunding}
                        className="bg-[#1D262F] rounded-[10px] w-full h-[37px] text-[18px] px-[15px] font-medium outline-none"
                        placeholder="amount"
                        value={amount === null ? "" : amount}
                        onChange={(e) => inputAmount((e.target as HTMLInputElement).value)}
                    />
                    <span className='absolute left-0 bottom-[-20px] text-[12px] text-[#808080]'>{`Balance: ${balance}`}</span>
                </div>

                <button
                    disabled={isLoading || isFunding}
                    className={`flex justify-center items-center gap-[10px] gradient-btn full-rounded py-[5px] w-[100px] ${(isLoading || isFunding) ? 'opacity-60' : 'opacity-100'}`}
                    onClick={() => handleFund()} onMouseEnter={() => playHover()}
                >
                    {!isFunding ? 'Fund' : 'Funding...'}
                    {isFunding ? <ClipLoader size={15} color="#ffffff" /> : null}
                </button>
            </div>
        </div>
    );
};

export default FundWagerNFT;