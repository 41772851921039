import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @ts-ignore
import Fade from 'react-reveal/Fade';
import useSound from "use-sound";
import unmuteIcon from "../../assets/unmuteIcon.svg";
import muteIcon from "../../assets/muteIcon.svg";

interface IProps {
  show: boolean;
  handleShow: (status: boolean) => void;
}

const LandingPage = (props: IProps) => {
  const { show, handleShow } = props;
  const [showModal, setShowModal] = useState<boolean>(true);
  const { pathname } = useLocation();
  const landingPageSound = "/sound/landingpage.mp3";
  const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
  const [isMuted, setIsMuted] = useState(true);
  const [playLandingpageSound, { stop }] = useSound(landingPageSound, {
    volume: 0.2,
    loop: true,
    muted: isMuted,
  });

  const soundBtnRef = useRef<null | HTMLButtonElement>(null);

  const handlShowLandingPage = (event: any) => {
    if (soundBtnRef.current && !soundBtnRef.current.contains(event.target)) {
      setIsMuted(true);
      stop();
      handleShow(false);
      setTimeout(() => {
        setShowModal(false);
      }, 1500);
    }
  }

  const toggleMute = () => {
    setIsMuted((prevMuteStatus) => !prevMuteStatus);
    if (!isMuted) {
      stop(); // Stop the sound when muting
    } else {
      playLandingpageSound(); // Play the sound when unmuting
    }
  };


  return (
    <Fade duration={1200} opposite when={show && pathname === '/'}>

      <div
        className={`${showModal && pathname === '/' ? 'flex flex-col items-center justify-end' : 'hidden'} fixed top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] bg-[url('/src/assets/landingpage-wolves.webp')] bg-center bg-no-repeat bg-cover z-[1040] overflow-y-auto landing-page`}
        onClick={handlShowLandingPage}
        onMouseEnter={() => playHover()}
      >

        <button ref={soundBtnRef} onClick={toggleMute} onMouseEnter={() => playHover()} className="absolute top-0 left-0 m-2 p-2">
          {isMuted ? (
            <img src={muteIcon} alt="Mute" className="w-10 h-10" />
          ) : (
            <img src={unmuteIcon} alt="Unmute" className="w-10 h-10" />
          )}
        </button>
        <span className="text-white text-[32px] font-bold mb-4 landing-text">Click to enter the BiteClub</span>
        {/* Add any additional content or styling for the landing page */}
      </div>

    </Fade>
  );
};

export default LandingPage;
