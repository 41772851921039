import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { CombinedReducer } from '../../store';
import User from '../../interfaces/User';
import { IArenaConfig } from '../../interfaces/ArenaEvent';
import { ARENA_MIN_DAILY_WAGER_AMOUNT } from '../../utils/config';
import { arenaEventRewardsList } from '../../data';
import { getPointsAccordingToIndex } from '../../utils';
import fireworks5Icon from '../../assets/fireworks5.svg';
import closeIcon from '../../assets/close.svg';
import pinkSolSymbol from '../../assets/sol-symbol-pink.svg';
import greenSolSymbol from '../../assets/sol-symbol-green.svg';
import graySolSymbol from '../../assets/sol-symbol-gray.svg';
import yellowStarIcon from '../../assets/star-yellow.svg';
import grayStarIcon from '../../assets/star-gray.svg';
import axios from 'axios';
import useSound from "use-sound";

interface IProps {
    show: boolean;
    handleShow: (show: boolean) => void;
}

const ArenaEventCard = (props: IProps) => {
    const { show, handleShow } = props;
    const [playClaim] = useSound("/sound/claim.mp3", { volume: 0.1 });
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
    const [remainingTime, setRemainingTime] = useState<number>(600);
    const [remainingTimeUntilEventDay, setRemainingTimeUntilEventDay] = useState<number>(60 * 1000);
    const [arenaConfig, setArenaConfig] = useState<IArenaConfig>();
    const [isEventDay, setIsEventDay] = useState<boolean>(false);
    const [totalClaimableCount, setTotalClaimableCount] = useState<number>(0);
    const [claimedCount, setClaimedCount] = useState<number>(0);
    const [progressValue, setProgressValue] = useState<number>(0);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [isClaiming, setIsClaiming] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const user = useSelector<CombinedReducer, User>((state) => state.user);

    const initialize = async () => {
        try {
            const [currentDateTimeRsp, arenaConfigRsp] = await Promise.all([
                axios.post('/api/u/getCurrentDateAndTime'),
                axios.get('/api/arena/arenaConfig'),
            ]);

            const arenaConfig = arenaConfigRsp.data as (IArenaConfig | undefined);
            const { currentTime } = currentDateTimeRsp.data;

            console.log({ currentTime });
            console.log({ arenaConfig });

            if (arenaConfig) {
                if (arenaConfig.isRunning && currentTime >= arenaConfig.startTime) {
                    setIsEventDay(true);
                    setRemainingTime(arenaConfig.startTime * 1000 + arenaConfig.period * 86400 * 1000 - Date.now());
                }

                setRemainingTimeUntilEventDay(arenaConfig.startTime * 1000 - Date.now());
            }

            setArenaConfig(arenaConfig);
        } catch { }
    };

    const handleClaim = async () => {
        setIsClaiming(true);

        try {
            await axios.post('/api/u/claimArenaEventReward');
            toast.success("Successful to claim reward");
        } catch (e: any) {
            console.log('e', e);
            if (e?.response?.data?.message) {
                toast.error(e?.response?.data?.message?.toString(), { autoClose: 5000 });
            } else {
                toast.error("Failed to claim reward");
            }
        }

        setIsClaiming(false);
    }

    useEffect(() => {
        if (user && arenaConfig) {
            const userArenaWagerAmount = user?.arenaWagerAmount || 0;
            let totalClaimableCount = 0;
            let progressValue = 0;

            if (arenaConfig?.arenaType == 'option1') {
                if (userArenaWagerAmount / ARENA_MIN_DAILY_WAGER_AMOUNT == 1) {
                    totalClaimableCount = 1;
                    progressValue = 20;
                } else if (userArenaWagerAmount / ARENA_MIN_DAILY_WAGER_AMOUNT == 2) {
                    totalClaimableCount = 2;
                    progressValue = 40;
                } else if (userArenaWagerAmount / ARENA_MIN_DAILY_WAGER_AMOUNT == 3) {
                    totalClaimableCount = 3;
                    progressValue = 60;
                } else if (userArenaWagerAmount / ARENA_MIN_DAILY_WAGER_AMOUNT == 4) {
                    totalClaimableCount = 3;
                    progressValue = 70;
                } else if (userArenaWagerAmount / ARENA_MIN_DAILY_WAGER_AMOUNT == 5) {
                    totalClaimableCount = 4;
                    progressValue = 80;
                } else if (userArenaWagerAmount / ARENA_MIN_DAILY_WAGER_AMOUNT == 6) {
                    totalClaimableCount = 4;
                    progressValue = 90;
                } else if (userArenaWagerAmount / ARENA_MIN_DAILY_WAGER_AMOUNT >= 7) {
                    totalClaimableCount = 5;
                    progressValue = 100;
                }
            }

            if (arenaConfig?.arenaType == 'option2') {
                if (userArenaWagerAmount / LAMPORTS_PER_SOL >= 5) {
                    totalClaimableCount = 1;
                    // progressValue = 20;
                }
                if (userArenaWagerAmount / LAMPORTS_PER_SOL >= 10) {
                    totalClaimableCount = 2;
                    // progressValue = 40;
                }
                if (userArenaWagerAmount / LAMPORTS_PER_SOL >= 25) {
                    totalClaimableCount = 3;
                    // progressValue = 60;
                }
                if (userArenaWagerAmount / LAMPORTS_PER_SOL >= 100) {
                    totalClaimableCount = 4;
                    // progressValue = 80;
                }
                if (userArenaWagerAmount / LAMPORTS_PER_SOL >= 300) {
                    totalClaimableCount = 5;
                    // progressValue = 100;
                }

                if (userArenaWagerAmount / LAMPORTS_PER_SOL <= 5) {
                    progressValue = 20 * userArenaWagerAmount / LAMPORTS_PER_SOL / 5;
                }

                if (userArenaWagerAmount / LAMPORTS_PER_SOL > 5 && userArenaWagerAmount / LAMPORTS_PER_SOL <= 10) {
                    progressValue = 20 + 20 * (userArenaWagerAmount / LAMPORTS_PER_SOL - 5) / 5;
                }

                if (userArenaWagerAmount / LAMPORTS_PER_SOL > 10 && userArenaWagerAmount / LAMPORTS_PER_SOL <= 25) {
                    progressValue = 40 + 20 * (userArenaWagerAmount / LAMPORTS_PER_SOL - 10) / 15;
                }

                if (userArenaWagerAmount / LAMPORTS_PER_SOL > 25 && userArenaWagerAmount / LAMPORTS_PER_SOL <= 100) {
                    progressValue = 60 + 20 * (userArenaWagerAmount / LAMPORTS_PER_SOL - 25) / 75;
                }

                if (userArenaWagerAmount / LAMPORTS_PER_SOL > 100 && userArenaWagerAmount / LAMPORTS_PER_SOL <= 300) {
                    progressValue = 80 + 20 * (userArenaWagerAmount / LAMPORTS_PER_SOL - 100) / 200;
                }

                if (userArenaWagerAmount / LAMPORTS_PER_SOL > 300) {
                    progressValue = 100;
                }
            }

            setTotalClaimableCount(totalClaimableCount);
            setProgressValue(progressValue >= 100 ? 100 : progressValue);
            setClaimedCount(user?.arenaClaimedCount || 0);
        }
    }, [user, arenaConfig]);

    useEffect(() => {
        (async () => {
            await initialize();
        })();
    }, []);

    return (
        <div
            className={`${show ? 'fixed md:absolute' : 'hidden'} ${isEventDay ? 'top-[59px]' : 'top-[150px]'} md:top-[52.5px] left-0 md:left-[-180px] w-full md:w-[625px] px-[5px] md:px-0`}
        >
            <div className='relative flex flex-col justify-center items-center w-full min-h-[409px] bg-[#222C36] bg-gradient-to-r from-[rgba(48,39,47,0.9)] to-[rgba(29,35,42,0.9)] border-2 border-solid border-[#6F3A51] rounded-[10px] p-[16px_20px_19px] md:p-[19px_24px_22px] overflow-y-auto'>
                {/* Close Button */}
                <div className='md:hidden absolute top-[10px] right-[10px]'>
                    <button
                        className='flex justify-center items-center'
                        onClick={() => handleShow(false)}
                        onMouseEnter={() => playHover()}
                    >
                        <img src={closeIcon} alt='close-icon' className='w-[15px] h-[15px]' />
                    </button>
                </div>

                <div className={`flex justify-between items-center w-full mt-[10px] md:mt-0`}>
                    <div className='hidden md:flex justify-center items-center w-[40px] h-[40px]'>
                        <img src={fireworks5Icon} alt='new-year-event' className='w-full' />
                    </div>
                    <div className={`text-[18px] md:text-[24px] text-center font-bold arena-title uppercase w-full`}>Arena</div>
                    <div className='hidden md:flex justify-center items-center w-[40px] h-[40px]'>
                        <img src={fireworks5Icon} alt='new-year-event' className='w-full' />
                    </div>
                </div>

                <div className='grow flex justify-center items-start w-full max-h-[calc(100vh-150px)] overflow-y-auto thin-scroll-bar'>
                    {
                        !isEventDay ? (
                            <div className='flex flex-col justify-center items-center gap-[18px] md:gap-[16px] w-full mt-[70px] md:mt-[67px]'>
                                <div className='text-[16px] md:text-[20px] text-[#808080] font-bold'>Event starts in:</div>
                                {/* Countdown util event days */}
                                <Countdown
                                    date={Date.now() + remainingTimeUntilEventDay}
                                    onComplete={() => {
                                        setRemainingTime((arenaConfig?.period || 0) * 86400 * 1000);
                                        setIsEventDay(true);
                                    }}
                                    renderer={({ days, hours, minutes, seconds }: any) => {
                                        return (
                                            <div className='flex justify-center items-center gap-[5.5px] md:gap-[8px] w-full'>
                                                {/* days */}
                                                <div className='flex flex-col justify-center items-center w-[49px] md:w-[70px] border-[0.9px] md:border-[1.3px] border-solid border-[#808080]'>
                                                    <div className='flex justify-center items-center w-full h-[54px] md:h-[77px] bg-[#222C36]  border-b-[1.3px] border-[#808080]'>
                                                        <span className='text-[22.5px] md:text-[32px] font-bold'>{days}</span>
                                                    </div>
                                                    <div className='flex justify-center items-center w-full h-[21px] md:h-[31px] bg-[#1A2129]'>
                                                        <span className='text-[8.5px] md:text-[12px] font-bold'>Days</span>
                                                    </div>
                                                </div>

                                                <div className='flex justify-center items-center text-[16.5px] md:text-[24px] font-bold'>:</div>

                                                {/* hours */}
                                                <div className='flex flex-col justify-center items-center w-[49px] md:w-[70px] border-[0.9px] md:border-[1.3px] border-solid border-[#808080]'>
                                                    <div className='flex justify-center items-center w-full h-[54px] md:h-[77px] bg-[#222C36]  border-b-[1.3px] border-[#808080]'>
                                                        <span className='text-[22.5px] md:text-[32px] font-bold'>{hours}</span>
                                                    </div>
                                                    <div className='flex justify-center items-center w-full h-[21px] md:h-[31px] bg-[#1A2129]'>
                                                        <span className='text-[8.5px] md:text-[12px] font-bold'>Hours</span>
                                                    </div>
                                                </div>

                                                <div className='flex justify-center items-center text-[16.5px] md:text-[24px] font-bold'>:</div>

                                                {/* minutes */}
                                                <div className='flex flex-col justify-center items-center w-[49px] md:w-[70px] border-[0.9px] md:border-[1.3px] border-solid border-[#808080]'>
                                                    <div className='flex justify-center items-center w-full h-[54px] md:h-[77px] bg-[#222C36]  border-b-[1.3px] border-[#808080]'>
                                                        <span className='text-[22.5px] md:text-[32px] font-bold'>{minutes}</span>
                                                    </div>
                                                    <div className='flex justify-center items-center w-full h-[21px] md:h-[31px] bg-[#1A2129]'>
                                                        <span className='text-[8.5px] md:text-[12px] font-bold'>Minutes</span>
                                                    </div>
                                                </div>

                                                <div className='flex justify-center items-center text-[16.5px] md:text-[24px] font-bold'>:</div>

                                                {/* seconds */}
                                                <div className='flex flex-col justify-center items-center w-[49px] md:w-[70px] border-[0.9px] md:border-[1.3px] border-solid border-[#808080]'>
                                                    <div className='flex justify-center items-center w-full h-[54px] md:h-[77px] bg-[#222C36]  border-b-[1.3px] border-[#808080]'>
                                                        <span className='text-[22.5px] md:text-[32px] font-bold'>{seconds}</span>
                                                    </div>
                                                    <div className='flex justify-center items-center w-full h-[21px] md:h-[31px] bg-[#1A2129]'>
                                                        <span className='text-[8.5px] md:text-[12px] font-bold'>Seconds</span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }}
                                />
                            </div>
                        ) : (
                            arenaConfig && (
                                <div className='flex flex-col justify-center items-center w-full max-w-[472px] mx-auto mt-[20px] h-full'>
                                    {/* Description & Events ends in */}
                                    <div className={`${isCompleted ? 'hidden' : 'flex'} flex-col md:flex-row justify-center items-center gap-[20px] md:gap-[32px]`}>
                                        {/* Description */}
                                        <div className='flex flex-col items-center gap-[10px] w-[250px] md:w-[220px] h-[140px] md:h-[120px] bg-[#222C36] border border-solid border-[#808080] rounded-[10px] p-[10px_16px]'>
                                            <div className='text-[14px] text-[#808080] font-bold leading-[21px]'>Description</div>
                                            {
                                                arenaConfig?.arenaType == 'option1' ? (
                                                    <div className='text-[10px] font-bold leading-[15px]'>Wager a minimum of 25 Sol daily to earn one point. You can earn a maximum of one point per day.</div>
                                                ) : (
                                                    <div className='text-[10px] font-bold leading-[20px]'>Wager 1 Sol to get 1 point. <br />No daily limit!</div>
                                                )
                                            }
                                        </div>

                                        {/* Event ends in */}
                                        <div className='flex flex-col items-center gap-[10px] w-[250px] md:w-[220px] h-[140px] md:h-[120px] bg-[#222C36] border border-solid border-[#808080] rounded-[10px] p-[10px_16px]'>
                                            <div className='text-[14px] text-[#808080] font-bold leading-[21px]'>Event ends in:</div>
                                            <div className='text-[10px] font-bold leading-[15px]'>
                                                <Countdown
                                                    date={Date.now() + remainingTime}
                                                    onComplete={() => setIsCompleted(true)}
                                                    renderer={({ days, hours, minutes, seconds }: any) => {
                                                        return (
                                                            <div className='flex justify-center items-center gap-[2px] md:gap-[8px] w-full'>
                                                                {/* days */}
                                                                <div className='flex flex-col justify-center items-center w-[43px] md:w-[35px] border-[0.65px] border-solid border-[#808080]'>
                                                                    <div className='flex justify-center items-center w-full h-[47px] md:h-[38.5px] bg-[#222C36] border-b-[0.65px] border-[#808080]'>
                                                                        <span className='text-[20px] md:text-[16px] font-bold'>{days}</span>
                                                                    </div>
                                                                    <div className='flex justify-center items-center w-full h-[20px] md:h-[15.5px] bg-[#1A2129]'>
                                                                        <span className='hidden md:block text-[9.5px] font-bold leading-[100%]'>D.</span>
                                                                        <span className='block md:hidden text-[10px] font-bold leading-[100%]'>Days</span>
                                                                    </div>
                                                                </div>

                                                                <div className='flex md:hidden justify-center items-center text-[24px] font-bold pb-[10px]'>:</div>

                                                                {/* hours */}
                                                                <div className='flex flex-col justify-center items-center w-[43px] md:w-[35px] border-[0.65px] border-solid border-[#808080]'>
                                                                    <div className='flex justify-center items-center w-full h-[47px] md:h-[38.5px] bg-[#222C36] border-b-[0.65px] border-[#808080]'>
                                                                        <span className='text-[20px] md:text-[16px] font-bold'>{hours}</span>
                                                                    </div>
                                                                    <div className='flex justify-center items-center w-full h-[20px] md:h-[15.5px] bg-[#1A2129]'>
                                                                        <span className='hidden md:block text-[9.5px] font-bold leading-[100%]'>H.</span>
                                                                        <span className='block md:hidden text-[10px] font-bold leading-[100%]'>Hours</span>
                                                                    </div>
                                                                </div>

                                                                <div className='flex md:hidden justify-center items-center text-[24px] font-bold pb-[10px]'>:</div>

                                                                {/* minutes */}
                                                                <div className='flex flex-col justify-center items-center w-[43px] md:w-[35px] border-[0.65px] border-solid border-[#808080]'>
                                                                    <div className='flex justify-center items-center w-full h-[47px] md:h-[38.5px] bg-[#222C36] border-b-[0.65px] border-[#808080]'>
                                                                        <span className='text-[20px] md:text-[16px] font-bold'>{minutes}</span>
                                                                    </div>
                                                                    <div className='flex justify-center items-center w-full h-[20px] md:h-[15.5px] bg-[#1A2129]'>
                                                                        <span className='hidden md:block text-[9.5px] font-bold leading-[100%]'>Min.</span>
                                                                        <span className='block md:hidden text-[10px] font-bold leading-[100%]'>Minutes</span>
                                                                    </div>
                                                                </div>

                                                                <div className='flex md:hidden justify-center items-center text-[24px] font-bold pb-[10px]'>:</div>

                                                                {/* seconds */}
                                                                <div className='flex flex-col justify-center items-center w-[43px] md:w-[35px] border-[0.65px] border-solid border-[#808080]'>
                                                                    <div className='flex justify-center items-center w-full h-[47px] md:h-[38.5px] bg-[#222C36] border-b-[0.65px] border-[#808080]'>
                                                                        <span className='text-[20px] md:text-[16px] font-bold'>{seconds}</span>
                                                                    </div>
                                                                    <div className='flex justify-center items-center w-full h-[20px] md:h-[15.5px] bg-[#1A2129]'>
                                                                        <span className='hidden md:block text-[9.5px] font-bold leading-[100%]'>Sec.</span>
                                                                        <span className='block md:hidden text-[10px] font-bold leading-[100%]'>Seconds</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Notification of end of countdown */}
                                    {
                                        <div className={`${isCompleted ? 'flex' : 'hidden'} flex-col justify-center items-center gap-[20px] md:gap-[16px] mt-[10px]`}>
                                            <div className='w-full max-w-[338px] text-[16px] text-center font-normal leading-normal'>The arena has now ended. Claim your open rewards and stay tuned for the next arena!</div>
                                            <div className='text-[16px] text-center font-bold leading-normal'>Thanks for playing!</div>
                                        </div>
                                    }

                                    {/* Your progress */}
                                    <div className='flex flex-col w-full mt-[30px]'>
                                        <div className='text-[14px] text-[#808080] font-bold leading-[21px]'>Your progress</div>
                                    </div>

                                    {/* progress status */}
                                    <div className='relative flex justify-start items-center w-full h-[29px] md:h-[40px] mt-[90px] mb-[110px] md:mb-[15px]'>
                                        {/* progress bar */}
                                        <div className='w-[calc(100%-calc(69px/2))] h-[7px] md:h-[10px]'>
                                            <div className='relative w-full h-full bg-[#090C0E] rounded-full'>
                                                <div
                                                    className='absolute top-0 left-0 h-full bg-[#6F3A51] rounded-full transition-all duration-300'
                                                    style={{ width: `${progressValue}%` }}
                                                />
                                            </div>
                                        </div>

                                        {/* stars */}
                                        <div className='absolute top-0 left-0 w-full h-full'>
                                            <div className='w-[calc(100%-calc(69px/2))] h-full'>
                                                <div className='relative w-full h-full'>
                                                    {
                                                        arenaEventRewardsList.map((item, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className='absolute top-0 translate-x-[5.5px] md:translate-x-0'
                                                                    style={{ left: `calc(${20 * (index + 1)}% - 20px)` }}
                                                                >
                                                                    <div className='flex flex-col items-center justify-between'>
                                                                        <div className='relative flex justify-center items-center w-[29px] md:w-[40px] h-[29px] md:h-[40px]'>
                                                                            <div className={`absolute ${index % 2 == 1 ? 'top-[60px] md:top-[-78px]' : 'top-[-78px]'} w-full h-full`}>
                                                                                <div className='relative w-full h-full'>
                                                                                    {
                                                                                        totalClaimableCount > index ? (
                                                                                            claimedCount > index ? (
                                                                                                <button
                                                                                                    disabled
                                                                                                    className='absolute top-0 left-[-20px] md:left-[-14.5px] flex flex-col items-center justify-between w-[69px] h-[70px] py-[10px] bg-[linear-gradient(134.16deg,rgba(111,58,81,0.4)_0%,rgba(111,58,81,0)_116%)] border border-solid border-[#6F3A51]/40 rounded-[10px]'
                                                                                                >
                                                                                                    <div className='text-[12px] text-[#6F3A51] font-bold leading-[18px] uppercase'>Claimed</div>
                                                                                                    <div className='flex justify-center items-center gap-[5px]'>
                                                                                                        <span className='text-[16px] text-[#6F3A51] font-bold leading-[24px]'>{item.toFixed(2)}</span>
                                                                                                        <img src={pinkSolSymbol} alt='pink-sol-symbol' className='w-[17px] h-[12.5px]' />
                                                                                                    </div>
                                                                                                </button>
                                                                                            ) : (
                                                                                                <button
                                                                                                    disabled={isClaiming}
                                                                                                    className='absolute top-0 left-[-20px] md:left-[-14.5px] flex flex-col items-center justify-between w-[69px] h-[70px] py-[10px] bg-[linear-gradient(134.16deg,rgba(70,255,120,0.4)_0%,rgba(70,255,120,0)_116%)] border border-solid border-[#46FF78]/40 rounded-[10px]'
                                                                                                    onClick={() => {
                                                                                                        if (claimedCount == index) {
                                                                                                            playClaim();
                                                                                                            handleClaim()
                                                                                                        } else {
                                                                                                            toast.warn('Please claim rewards in sequence.')
                                                                                                        }
                                                                                                    }}
                                                                                                    onMouseEnter={() => playHover()}
                                                                                                >
                                                                                                    <div className='text-[12px] text-[#46FF78] font-bold leading-[18px] uppercase'>Claim</div>
                                                                                                    <div className='flex justify-center items-center gap-[5px]'>
                                                                                                        <span className='text-[16px] text-[#46FF78] font-bold leading-[24px]'>{item.toFixed(2)}</span>
                                                                                                        <img src={greenSolSymbol} alt='green-sol-symbol' className='w-[17px] h-[12.5px]' />
                                                                                                    </div>
                                                                                                </button>
                                                                                            )
                                                                                        ) : (
                                                                                            <button
                                                                                                disabled
                                                                                                className='absolute top-0 left-[-20px] md:left-[-14.5px] flex flex-col items-center justify-between w-[69px] h-[70px] py-[10px] bg-[linear-gradient(134.16deg,rgba(128,128,128,0.4)_0%,rgba(128,128,128,0)_116%)] border border-solid border-[#808080]/40 rounded-[10px]'
                                                                                            >
                                                                                                <div className='text-[12px] text-[#808080] font-bold leading-[18px] uppercase'>Claim</div>
                                                                                                <div className='flex justify-center items-center gap-[5px]'>
                                                                                                    <span className='text-[16px] text-[#808080] font-bold leading-[24px]'>{item.toFixed(2)}</span>
                                                                                                    <img src={graySolSymbol} alt='green-sol-symbol' className='w-[17px] h-[12.5px]' />
                                                                                                </div>
                                                                                            </button>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                            {
                                                                                totalClaimableCount > index ? (
                                                                                    <img src={yellowStarIcon} alt='star-icon' className='w-full h-full' />
                                                                                ) : (
                                                                                    <img src={grayStarIcon} alt='star-icon' className='w-full h-full' />
                                                                                )
                                                                            }

                                                                            <div className='absolute bottom-[-30px] left-0 w-full h-full flex justify-center items-center text-[14px] font-bold leading-[21px]'>
                                                                                {
                                                                                    arenaConfig?.arenaType == 'option1' ? (
                                                                                        index <= 2 ? index + 1 : index == 3 ? index + 2 : index + 3
                                                                                    ) : (
                                                                                        getPointsAccordingToIndex(index)
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                    {
                                                        arenaConfig?.arenaType == 'option1' && (
                                                            <div className='md:hidden absolute top-[11px] left-[70%]'>
                                                                <div className='flex flex-col items-center justify-between'>
                                                                    <div className='relative flex justify-center items-center'>
                                                                        <div className='border-r border-[#00A5FE] h-[7px]'></div>
                                                                        <div className='absolute bottom-[-10px] left-0 w-full h-full flex justify-center items-center text-[14px] text-[#00A5FE] font-bold leading-[21px]'>
                                                                            4
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        )
                    }
                </div>
            </div>
        </div >
    );
};

export default ArenaEventCard;