import React from "react";
import { useNavigate } from "react-router-dom";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { rpsItems, diceItems } from "../../data";
import Game from "../../interfaces/Game";
import { displayName } from "../../utils";
import blankUserIcon from '../../assets/blank-user.svg';
import useSound from "use-sound";

interface IProps {
    game: Game;
}

const RecentGameCard = (props: IProps) => {
    const { game } = props;
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
    const navigate = useNavigate();

    let winnerPosition = 0;

    if (game?.creator?._id == game?.winner?._id) {
        winnerPosition = -1;
    }
    if (game?.opponent?._id == game?.winner?._id) {
        winnerPosition = 1
    }

    let background = "";
    switch (winnerPosition) {
        case -1:
            background = "left-winner-gradient-bg";
            break;
        case 1:
            background = "right-winner-gradient-bg"
            break;
        default:
            background = "draw-gradient-bg"
            break;
    }

    return (
        <div className={`flex justify-between items-center px-2 w-full h-[75px] rounded-[10px] mb-[10px] ${background}`}>
            <div
                className="text-center cursor-pointer"
                onClick={() => navigate(`/user-stats/${game?.creator?.publicKey}`)}
                onMouseEnter={() => playHover()}
            >
                <div className="flex justify-center items-center">
                    <img
                        src={game?.creator?.userAvatar || blankUserIcon}
                        alt="avatar"
                        className="w-[40px] h-[40px] rounded-full"
                    />
                </div>
                <div className="text-[14px] font-medium">{displayName(game?.creator)}</div>
            </div>

            <div className="text-center">
                <div className="flex justify-center items-center gap-[26px]">
                    {
                        game?.gameType == "rps" && (
                            <>
                                <img
                                    src={rpsItems[game.creatorMove!]?.icon}
                                    alt={rpsItems[game.creatorMove!]?.title}
                                    className="w-[45px] h-[45px]"
                                />
                                <div className="text-[14px] font-medium">VS</div>
                                <img
                                    src={rpsItems[game.opponentMove!]?.icon}
                                    alt={rpsItems[game.opponentMove!]?.title}
                                    className="w-[45px] h-[45px]"
                                />
                            </>
                        )
                    }

                    {
                        game?.gameType == "dice" && (
                            <>
                                <img
                                    src={diceItems[game.creatorMove! - 1]?.icon}
                                    alt={diceItems[game.creatorMove! - 1]?.title}
                                    className="w-[45px] h-[45px]"
                                />
                                <div className="text-[14px] font-medium">VS</div>
                                <img
                                    src={diceItems[game.opponentMove! - 1]?.icon}
                                    alt={diceItems[game.opponentMove! - 1]?.title}
                                    className="w-[45px] h-[45px]"
                                />
                            </>
                        )
                    }
                </div>
                <div className="text-[16px] font-bold">
                    {`${(game?.amount / LAMPORTS_PER_SOL).toLocaleString()} SOL`}
                </div>
            </div>

            <div
                className="text-center cursor-pointer"
                onClick={() => navigate(`/user-stats/${game?.opponent?.publicKey}`)}
                onMouseEnter={() => playHover()}
            >
                <div className="flex justify-center items-center">
                    <img
                        src={game?.opponent?.userAvatar || blankUserIcon}
                        alt="avatar"
                        className="w-[40px] h-[40px] rounded-full"
                    />
                </div>
                <div className="text-[14px] font-medium">{game.opponent && displayName(game?.opponent)}</div>
            </div>
        </div>
    );
};

export default RecentGameCard;