import React from "react";

interface IProps {
    color?: string
}

const SolanaIcon = (props: IProps) => {
    const { color } = props;

    return (
        <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.15675 0.6875H20.9999L16.8438 4.24985H2.00067L6.15675 0.6875Z" fill="url(#paint0_linear_1094_1481)" stroke="#00A5FE" />
            <path d="M16.843 6.625H1.99988L6.15596 10.1874H20.9991L16.843 6.625Z" fill="url(#paint1_linear_1094_1481)" stroke="#00A5FE" />
            <path d="M6.15632 12.5605H20.9994L16.8434 16.1229H2.00024L6.15632 12.5605Z" fill="url(#paint2_linear_1094_1481)" stroke="#00A5FE" />
            <defs>
                <linearGradient id="paint0_linear_1094_1481" x1="-0.556902" y1="2.54264" x2="21.6246" y2="4.11821" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00A5FE" />
                    <stop offset="1" stopColor="#11161B" />
                </linearGradient>
                <linearGradient id="paint1_linear_1094_1481" x1="23.5567" y1="8.48014" x2="1.37515" y2="10.0557" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00A5FE" />
                    <stop offset="1" stopColor="#11161B" />
                </linearGradient>
                <linearGradient id="paint2_linear_1094_1481" x1="-0.55733" y1="14.4157" x2="21.6242" y2="15.9913" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00A5FE" />
                    <stop offset="1" stopColor="#11161B" />
                </linearGradient>
            </defs>
        </svg>

    );
};

export default SolanaIcon;
