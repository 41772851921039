import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RecentGameCard from "../RecentGameCard";
import User from "../../interfaces/User";
import Game from "../../interfaces/Game";
import { CombinedReducer } from "../../store";
import { routes } from "../../data/routes";
import { RoutesTypeEnum } from "../../enums";
import axios from "axios";
import mobileCloseIcon from "../../assets/mobile_close.svg";
import blankUserIcon from '../../assets/blank-user.svg';
import useSound from "use-sound";

interface IProps {
    showSideMenubar?: boolean;
    handleShowSideMenubar?: (status: boolean) => void
}

const validStatuses = ["removed", "revealed"];

const SideBar = (props: IProps) => {
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { showSideMenubar, handleShowSideMenubar } = props;
    const [recentGames, setRecentGames] = useState<Game[]>([]);
    const [hoveredRoute, setHoveredRoute] = useState<string>();

    const user = useSelector<CombinedReducer, User>((state) => state.user);
    const rpsGames = useSelector<CombinedReducer, Game[]>((state) => state.rpsGames);
    const diceGames = useSelector<CombinedReducer, Game[]>((state) => state.diceGames);

    useEffect(() => {
        const loadRecentGames = async () => {
            if (pathname === "/rps") {
                const recentGames = (await axios.get("/api/rps-game/recentGames")).data;
                setRecentGames(recentGames);
            } else if (pathname === "/dice") {
                const recentGames = (await axios.get("/api/dice-game/recentGames")).data;
                setRecentGames(recentGames);
            }
        };

        loadRecentGames();
    }, [pathname]);

    return (
        <aside
            className={`group flex-none w-screen max-w-full sm:w-[70px] sm:hover:w-[140px] transition-all h-[calc(100vh-59px)] md:h-[calc(100vh-70px)] bg-[#090C0E] ${showSideMenubar ? '' : 'hidden'} xl:block overflow-x-hidden`}
        >
            {/* Sidebar Close button */}
            <button
                className="absolute top-[20px] right-[34px] md:hidden"
                onClick={() => handleShowSideMenubar && handleShowSideMenubar(false)}
                onMouseEnter={() => playHover()}
            >
                <img
                    src={mobileCloseIcon}
                    alt="close-icon"
                    className="w-[26px] h-[26px]"
                />
            </button>

            <div className="flex flex-col items-center sm:items-start h-full">
                {/* User picture for mobile */}
                <div className="flex w-full justify-center py-[15px] sm:hidden">
                    <div className="flex w-[50px] h-[50px]">
                        <img
                            src={user?.userAvatar || blankUserIcon}
                            alt="avatar"
                            className="w-full rounded-full"
                        />
                    </div>
                </div>

                {/* Main routes */}
                <div className="flex flex-col sm:block w-fit sm:w-full">
                    {
                        routes.filter(x => x.visible == true && x?.type == RoutesTypeEnum.Main).map((item, index) => {
                            return (
                                <Link
                                    key={index}
                                    to={item.to}
                                    className='flex items-center w-full h-[45px] md:h-[60px]'
                                    onMouseEnter={() => {
                                        playHover();
                                        setHoveredRoute(item.to);
                                    }}
                                    onMouseLeave={() => setHoveredRoute(undefined)}
                                    onClick={() => handleShowSideMenubar && handleShowSideMenubar(false)}
                                >
                                    <div
                                        className={`
                                            flex justify-start items-center gap-[11px] w-full h-full text-[12px] text-[#808080] hover:text-white font-bold pl-[16px]
                                            ${pathname === item.to ? `text-white ${item.activeBgColor}` : 'hover:bg-[#11161B]/50'}
                                            ${item.isAuth ? (user !== null ? '!flex' : '!hidden') : '!flex'}
                                        `}
                                    >
                                        <div className="flex justify-center items-center min-w-[37px]">
                                            {
                                                pathname === item.to || hoveredRoute == item.to ? (
                                                    <img src={item.hoverIcon} />
                                                ) : (
                                                    <img src={item.icon} />
                                                )
                                            }
                                        </div>

                                        <span className="opacity-100 group-hover:opacity-100 sm:opacity-0 transition-opacity whitespace-nowrap">{item.title}</span>
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>

                {/* Feature routes */}
                <div className="sm:grow flex flex-row sm:flex-col gap-[20px] sm:gap-0 sm:block w-fit sm:w-full no-scroll-bar sm:overflow-y-auto pt-[20px] sm:pt-[50px]">
                    {
                        routes.filter(x => x.visible == true && x?.type == RoutesTypeEnum.Feature).map((item, index) => {
                            return (
                                <Link
                                    key={index}
                                    to={item.to}
                                    className={`${item.order}`}
                                    onMouseEnter={() => {
                                        playHover();
                                        setHoveredRoute(item.to);
                                    }}
                                    onMouseLeave={() => setHoveredRoute(undefined)}
                                    onClick={() => handleShowSideMenubar && handleShowSideMenubar(false)}
                                >
                                    <div
                                        className={`
                                            flex flex-col sm:flex-row justify-start items-center gap-[5px] sm:gap-[11px] w-full h-[55px] md:h-[60px] text-[12px] text-[#808080] hover:text-white font-bold pl-0 sm:pl-[16px]
                                            ${pathname === item.to ? `text-white ${item.activeBgColor}` : ''}
                                            ${item.hoverBgColor}
                                            ${item.isAuth ? (user !== null ? '!flex' : '!hidden') : '!flex'}
                                        `}
                                    >
                                        <div className="flex justify-center items-center min-h-[30px] sm:h-fit min-w-[37px]">
                                            <img src={item.icon} />
                                        </div>

                                        <span className="opacity-100 group-hover:opacity-100 sm:opacity-0 transition-opacity whitespace-nowrap">{item.title}</span>
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>

                <div className="grow flex justify-center sm:hidden overflow-y-auto no-scroll-bar pt-[20px]">
                    {
                        (pathname === "/rps" || pathname === "/dice") && (
                            <div className="w-[320px] mx-auto max-h-[285px] overflow-y-auto no-scroll-bar">
                                <div className="mb-[10px] text-[16px] font-medium">Recent Games</div>
                                {
                                    pathname === "/rps" && rpsGames
                                        .map((game, index) => {
                                            if (!validStatuses.includes(game.status) || !game.opponent || game.gameType != "rps")
                                                return;
                                            return (
                                                <RecentGameCard key={index} game={game} />
                                            )
                                        })
                                        .reverse()
                                }

                                {
                                    pathname === "/dice" && diceGames
                                        .map((game, index) => {
                                            if (!validStatuses.includes(game.status) || !game.opponent || game.gameType != "rps")
                                                return;
                                            return (
                                                <RecentGameCard key={index} game={game} />
                                            )
                                        })
                                        .reverse()
                                }

                                {
                                    recentGames.map((game, index) => {
                                        return (
                                            <RecentGameCard key={index} game={game} />
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                </div>

                {/* Other routes */}
                <div className="flex flex-row sm:flex-col gap-[50px] sm:gap-0 sm:block w-fit sm:w-full py-[15px] sm:py-0">
                    {
                        routes.filter(x => x.visible == true && x?.type == RoutesTypeEnum.Other).map((item, index) => {
                            return (
                                <Link
                                    key={index}
                                    to={item.to == '/user-stats' ? `${item.to}/${user?.publicKey}` : item.to}
                                    className='flex items-center w-full h-[50px]'
                                    onMouseEnter={() => {
                                        playHover();
                                        setHoveredRoute(item.to);
                                    }}
                                    onMouseLeave={() => setHoveredRoute(undefined)}
                                    onClick={() => handleShowSideMenubar && handleShowSideMenubar(false)}
                                >
                                    <div
                                        className={`
                                            flex justify-start items-center gap-[10px] sm:gap-[11px] w-full h-full text-[12px] text-[#808080] hover:text-white font-bold pl-0 sm:pl-[16px] hover:bg-[#11161B]
                                            ${pathname === item.to ? `text-white ${item.activeBgColor}` : ''}
                                            ${item.isAuth ? (user !== null ? '!flex' : '!hidden') : '!flex'}
                                        `}
                                    >
                                        <div className="flex justify-center items-center min-w-fit sm:min-w-[37px]">
                                            {
                                                pathname === item.to || hoveredRoute == item.to ? (
                                                    <img src={item.hoverIcon} />
                                                ) : (
                                                    <img src={item.icon} />
                                                )
                                            }
                                        </div>

                                        <span className="opacity-100 group-hover:opacity-100 sm:opacity-0 transition-opacity whitespace-nowrap">{item.title}</span>
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>
            </div >
        </aside >
    );
};

export default SideBar;