import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Menu } from '@mui/material';
import { useWallet } from "@solana/wallet-adapter-react";
import { Metaplex } from "@metaplex-foundation/js";
import {
    Connection,
    PublicKey,
    Transaction,
} from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";
import { Program, AnchorProvider } from "@project-serum/anchor";
import { Wallet } from '@project-serum/anchor/dist/cjs/provider';
import { getAssociatedTokenAddress, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { IQuestPoolInfo, IQuestNftInfo } from "../../interfaces/Quests";
import { QuestTypeEnum } from "../../enums";
import { delay, getBlockTime } from "../../utils";
import questsIdl from "../../idls/quests-idl.json";
import HealingStatusModal from "./HealingStatusModal";
import AcceptQuestModal from "./AcceptQuestModal";
import DescriptionModal from "./DescriptionModal";
import CompleteQuestModal from "./CompleteQuestModal";
import { questCoolDownPeriod, questHealDecreaseamount, questInitialHealValue } from "../../data";
import { ReactComponent as CheckIcon } from '../../assets/check.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/arrow-down-filled.svg';
import { ReactComponent as ArrowRightWhiteIcon } from '../../assets/arrow-right-white.svg';
import filledHeartIcon from "../../assets/filledheart.svg";
import wolvesNft from "../../data/wolves.json";
import useSound from "use-sound";

import "./index.scss";

const Quests = () => {
    const wallet = useWallet();
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [poolAccount, setPoolAccount] = useState<PublicKey>();
    const [poolInfo, setPoolInfo] = useState<IQuestPoolInfo>();
    const [nftList, setNftList] = useState<IQuestNftInfo[]>([]);
    const [acceptModalOpen, setAcceptModalOpen] = useState<boolean>(false);
    const [stakedNftList, setStakedNftList] = useState<IQuestNftInfo[]>([]);
    const [completeModalOpen, setCompleteModalOpen] = useState<boolean>(false);
    const [descriptionModalOpen, setDescriptionModalOpen] = useState<boolean>(false);
    const [healingStatusModalOpen, setHealingStatusModalOpen] = useState<boolean>(false);
    const [questType, setQuestType] = useState<QuestTypeEnum>(QuestTypeEnum.Standard);
    const [nftListToHeal, setNftListToHeal] = useState<IQuestNftInfo[]>([]);
    const [healedNfts, setHealedNfts] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isHealing, setIsHealing] = useState<boolean>(false);

    const connection = new Connection(process.env.REACT_APP_RPC_URL!, "confirmed");
    const programID = new PublicKey(questsIdl.metadata.address);
    const provider = new AnchorProvider(connection, wallet as Wallet, { commitment: 'confirmed' });
    const program = new Program(questsIdl as anchor.Idl, programID, provider);
    const metaplex = new Metaplex(connection);
    const bonesTokenMint = new PublicKey(process.env.REACT_APP_BONES_TOKEN_MINT!);

    const open = Boolean(anchorEl);
    const handleOpenSort = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseSort = () => {
        setAnchorEl(null);
    };

    const initialize = useCallback(async (isInit: boolean) => {
        if (isInit)
            setIsLoading(true);

        try {
            if (wallet && wallet?.publicKey) {
                try {
                    /* Fetch pool info */
                    const poolPda = PublicKey.findProgramAddressSync(
                        [Buffer.from(anchor.utils.bytes.utf8.encode('quests-pool'))],
                        program.programId
                    )[0];
                    const poolPdaInfo: any = await program.account.pool.fetch(poolPda);
                    const poolInfo: IQuestPoolInfo = {
                        authority: poolPdaInfo?.authority,
                        rewardTokenMint: poolPdaInfo?.rewardTokenMint,
                        rewardTokenVault: poolPdaInfo?.rewardTokenVault,
                        stakedCount: poolPdaInfo?.stakedCount,
                        createdAt: poolPdaInfo?.createdAt.toNumber()
                    };

                    setPoolInfo(poolInfo);
                    setPoolAccount(poolPda);
                    /* --------------- */


                    /* Fetch staked NFTs */
                    const stakedNftList: IQuestNftInfo[] = [];
                    const currentBlockTime = await getBlockTime();
                    if (!currentBlockTime) {
                        toast.error("Please check network status.");
                        return;
                    }

                    const nftInfoAccounts: any[] = await program.account.nftInfo.all();
                    console.log("nftInfoAccounts", nftInfoAccounts)

                    await Promise.all(
                        nftInfoAccounts.map(async (item, _) => {
                            if (item.account.userPubkey?.toString() == wallet?.publicKey?.toString() && item.account.isStaked) {
                                const stakedNftMint = item.account.nftMint;
                                try {
                                    const stakedNftMetadata = await metaplex.nfts().findByMint({ mintAddress: stakedNftMint });
                                    if (stakedNftMetadata) {
                                        let rowData: IQuestNftInfo = {
                                            name: stakedNftMetadata?.name,
                                            symbol: stakedNftMetadata?.symbol,
                                            image: stakedNftMetadata?.json?.image || "",
                                            mintAddress: stakedNftMint?.toString(),
                                            lastUpdatedTime: item?.account?.lastUpdatedTime.toNumber(),
                                            lockingPeriod: item?.account?.lockingPeriod.toNumber(),
                                            endTime: (item?.account?.lastUpdatedTime.toNumber() + item?.account?.lockingPeriod.toNumber()),
                                            healthValue: item?.account?.healthValue.toNumber(),
                                            maxHealthValue: item?.account?.maxHealthValue.toNumber(),
                                            questType: item?.account?.questType,
                                            isStaked: item?.account?.isStaked,
                                            isAccountCreated: item?.account?.isAccountCreated,
                                            currentBlockTime: currentBlockTime,
                                        }

                                        stakedNftList.push(rowData);
                                    }
                                } catch { }
                            }
                        })
                    );
                    console.log("stakedNftList", stakedNftList)

                    setStakedNftList(stakedNftList);
                    /* ----------------- */

                    /* Fetch owner NFTs */
                    const nfts = await metaplex.nfts().findAllByOwner({ owner: wallet?.publicKey });

                    let metadata: any;
                    if (nfts) {
                        const nftList: IQuestNftInfo[] = [];

                        await Promise.all(
                            nfts.map(async (item, _) => {
                                if (item?.uri) {
                                    const mintAddress = (item as any)?.mintAddress?.toString();

                                    let healthValue = 0;
                                    const currentNftInfo = nftInfoAccounts.find(x => x.account.nftMint?.toString() == mintAddress);
                                    if (currentNftInfo) {
                                        const totalHealDecreaseAmount = Math.floor((currentBlockTime - currentNftInfo.account.lastUpdatedTime.toNumber()) / questCoolDownPeriod) * questHealDecreaseamount;
                                        healthValue = currentNftInfo.account.healthValue.toNumber() - totalHealDecreaseAmount;
                                    } else {
                                        const totalHealDecreaseAmount = Math.floor(((currentBlockTime - poolInfo.createdAt) / questCoolDownPeriod)) * questHealDecreaseamount;
                                        healthValue = questInitialHealValue - totalHealDecreaseAmount;
                                    }

                                    if (healthValue < 0) {
                                        healthValue = 0;
                                    }

                                    // let rowData: IQuestNftInfo = {
                                    //     name: item?.name,
                                    //     symbol: item?.symbol,
                                    //     image: "https://lh3.googleusercontent.com/vG0A5p-vA5ovAJkPYNRDFV3s1RuVIoVPVi4sqwUWqU1hB4g_LCLCxtGOu2ProLsbqz77kMvs7SbxsAPsHu4X0G2sFcfD7QfM8rc=s250-k",
                                    //     mintAddress: (item as any)?.mintAddress?.toString(),
                                    //     lastUpdatedTime: currentNftInfo ? currentNftInfo.account.lastUpdatedTime.toNumber() : poolInfo.createdAt,
                                    //     healthValue: healthValue,
                                    //     isAccountCreated: currentNftInfo ? true : false,
                                    // }

                                    // nftList.push(rowData);
                                    try {
                                        metadata = await (await fetch(item?.uri)).json();
                                        const mintAddress = (item as any)?.mintAddress?.toString();
                                        if (wolvesNft.includes(mintAddress)) {
                                            let rowData: IQuestNftInfo = {
                                                name: item?.name,
                                                symbol: item?.symbol,
                                                image: metadata?.image,
                                                mintAddress: mintAddress,
                                                lastUpdatedTime: currentNftInfo ? currentNftInfo.account.lastUpdatedTime.toNumber() : poolInfo.createdAt,
                                                healthValue: healthValue,
                                                maxHealthValue: currentNftInfo ? currentNftInfo?.account?.maxHealthValue.toNumber() : questInitialHealValue,
                                                isAccountCreated: currentNftInfo ? true : false,
                                            }

                                            nftList.push(rowData);
                                        }
                                    } catch (_) { }
                                }
                            })
                        );

                        setNftList(nftList);
                    }
                    /* ---------------- */
                } catch { }
            }

            setIsLoading(false);
        } catch (e) {
            console.log("e", e);
            setIsLoading(false);
        }
    }, [wallet?.publicKey]);

    const onComplete = async (isInit: boolean) => {
        await initialize(isInit);
    }

    const handleBulkHeal = async (selectedNfIndexes: number[]) => {
        if (!wallet || !wallet?.publicKey) {
            toast.warn("Please connect wallet");
            return;
        }

        if (!poolAccount || !poolInfo) {
            toast.warn("Quests Pool not initialized");
            return;
        }

        if (selectedNfIndexes.length == 0) {
            toast.warning("Please select NFTs");
            return;
        }

        let healedNfts: string[] = [];
        let transaction = new Transaction();
        let transactions: Transaction[] = [];

        let counter = 0;
        try {
            const nftListToHeal = nftList.filter((item, index) => selectedNfIndexes.includes(index) && item.healthValue < item.maxHealthValue);
            console.log('nftListToHeal', nftListToHeal);

            if (nftListToHeal.length == 0) {
                toast.warn("Threre is no NFT to heal");
                return;
            }

            setNftListToHeal(nftListToHeal);
            setIsHealing(true);
            setAcceptModalOpen(false);
            setCompleteModalOpen(false);
            setHealingStatusModalOpen(true);

            await nftListToHeal.reduce(async (promise, item) => {
                await promise;

                const nftMint = new PublicKey(item.mintAddress);
                const userNftTokenAccount = await getAssociatedTokenAddress(nftMint, wallet?.publicKey!);
                const ownerTokenAccount = await getAssociatedTokenAddress(bonesTokenMint, wallet?.publicKey!)

                const nftInfoAccount = PublicKey.findProgramAddressSync(
                    [
                        Buffer.from('quests-nft-info-account'),
                        nftMint.toBuffer(),
                    ],
                    program.programId
                )[0];

                const rewardTokenVault = PublicKey.findProgramAddressSync(
                    [Buffer.from('quests-reward-token-vault')],
                    program.programId
                )[0];

                counter += 1;
                healedNfts.push(item.mintAddress);

                if (counter <= 5) {
                    const healAmount = item.maxHealthValue - item.healthValue;
                    transaction.add(
                        await program.methods
                            .healNft(
                                new anchor.BN(healAmount)
                            )
                            .accounts({
                                owner: wallet?.publicKey!,
                                pool: poolAccount,
                                nftMint: nftMint,
                                userNftTokenAccount: userNftTokenAccount,
                                nftInfoAccount: nftInfoAccount,
                                ownerTokenAccount: ownerTokenAccount,
                                rewardTokenVault: rewardTokenVault,
                                tokenProgram: TOKEN_PROGRAM_ID,
                                rent: anchor.web3.SYSVAR_RENT_PUBKEY,
                                systemProgram: anchor.web3.SystemProgram.programId,
                            })
                            .instruction()
                    )
                }

                if (counter == 5) {
                    /* For raw transaction */
                    const latestBlockhash = await connection.getLatestBlockhash('confirmed');
                    transaction.feePayer = wallet.publicKey!;
                    transaction.recentBlockhash = latestBlockhash.blockhash;

                    transactions.push(transaction);
                    /* ------------------- */

                    transaction = new Transaction();
                    counter = 0;
                }

                if (transactions.length == 20) {
                    const signedTransactions = await wallet.signAllTransactions!(transactions);
                    console.log('signedTransactions', signedTransactions);

                    await Promise.all(signedTransactions.map(async (signedTransaction) => {
                        await connection.sendRawTransaction(signedTransaction.serialize());
                    }));

                    setHealedNfts(prev => {
                        return [...prev, ...healedNfts];
                    })

                    transactions = [];
                    healedNfts = [];
                }
            }, Promise.resolve());

            if (counter > 0) {
                /* For raw transaction */
                const latestBlockhash = await connection.getLatestBlockhash('confirmed');
                transaction.feePayer = wallet.publicKey!;
                transaction.recentBlockhash = latestBlockhash.blockhash;

                transactions.push(transaction);
                /* ------------------- */

                transaction = new Transaction();
                counter = 0;
            }

            if (transactions.length > 0) {
                const signedTransactions = await wallet.signAllTransactions!(transactions);
                console.log('signedTransactions', signedTransactions);

                await Promise.all(signedTransactions.map(async (signedTransaction) => {
                    await connection.sendRawTransaction(signedTransaction.serialize());
                }));

                setHealedNfts(prev => {
                    return [...prev, ...healedNfts];
                })

                transactions = [];
                healedNfts = [];
            }

            await delay(5000);

            toast.success("Successful!");
            await onComplete(false);

            setIsHealing(false);
        } catch (e: any) {
            console.log("e: ", e);
            toast.error("Failed!");
            setIsHealing(false);
        }
    }

    useEffect(() => {
        if (acceptModalOpen || completeModalOpen || descriptionModalOpen || healingStatusModalOpen) {
            // document.body.style.overflow = "hidden"
        } else {
            // document.body.style.overflow = "visible"
        }
    }, [acceptModalOpen, completeModalOpen, descriptionModalOpen, healingStatusModalOpen]);

    useEffect(() => {
        (async () => {
            await initialize(true);
        })()
    }, [wallet?.publicKey]);

    return (
        <div className={`flex flex-col items-center w-full min-h-[calc(100vh-59px)] md:min-h-[calc(100vh-70px)] rounded-tr-[10px] ${questType == QuestTypeEnum.Risk ? 'bg-[url("/src/assets/quests-bg2.webp")]' : 'bg-[url("/src/assets/quests-bg.webp")]'} bg-center bg-no-repeat bg-cover`}>            <div className="grow w-full pt-[20px] md:pt-[30px]">
            <div className="flex flex-col md:flex-row w-full">
                {/* First */}
                <div className="flex flex-col items-center min-w-[380px] md:border-r-2 md:border-r-[#808080]">
                    <h1 className="text-[38px] font-orbitron font-bold leading-[100%] hidden md:block">All Quests</h1>

                    {/* For mobile */}
                    <h5 className="md:hidden text-[16px] font-orbitron font-bold leading-[100%]">Put your Wolf on a Journey!</h5>

                    <button
                        className="flex md:hidden justify-center items-center w-[230px] h-[30px] bg-[#11161B] shadow-[inset_0px_0px_4px_#808080] rounded-[10px] mt-[27px] relative"
                        onClick={handleOpenSort}
                        onMouseEnter={() => playHover()}
                    >
                        <span className="text-[14px] font-medium leading-[20px]">
                            {
                                questType == QuestTypeEnum.Standard && (
                                    "#1 - Brawlers’  Bounty"
                                )
                            }

                            {
                                questType == QuestTypeEnum.Risk && (
                                    "#2 - Enter the Arena"
                                )
                            }
                        </span>
                        <div className="flex justify-end items-center w-full h-full absolute top-0 left-0 px-[10px]">
                            <ArrowDownIcon className={`w-[13.5px] h-[8px] ${open ? 'rotate-180' : 'rotate-0'}`} />
                        </div>
                    </button>

                    <Menu
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        sx={{
                            '& .MuiPaper-root': {
                                borderRadius: '10px',
                                minWidth: '230px',
                                marginTop: '4px',
                                background: '#11161B',
                                boxShadow: 'inset 0px 0px 4px #808080'
                            }
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleCloseSort}
                    >
                        <div className="flex flex-col justify-center gap-[5px] text-[14px] text-white font-medium leading-[20px] px-[5px]">
                            <div
                                className={`w-full p-[2px_10px] hover:bg-[#808080]/60 rounded-[8px] cursor-pointer ${questType == QuestTypeEnum.Standard ? 'bg-[#808080]/60' : ''}`}
                                onClick={() => {
                                    setQuestType(QuestTypeEnum.Standard);
                                    handleCloseSort();
                                }}
                                onMouseEnter={() => playHover()}
                            >
                                {"#1 - Brawlers' Bounty"}
                            </div>

                            <div
                                className={`w-full p-[2px_10px] hover:bg-[#808080]/60 rounded-[8px] cursor-pointer ${questType == QuestTypeEnum.Risk ? 'bg-[#808080]/60' : ''}`}
                                onClick={() => {
                                    setQuestType(QuestTypeEnum.Risk);
                                    handleCloseSort();
                                }}
                                onMouseEnter={() => playHover()}
                            >
                                {"#2 - Enter the Arena"}
                            </div>
                        </div>
                    </Menu>

                    <button
                        className="md:hidden flex justify-center items-center w-[112px] h-[30px] bg-[#11161B] shadow-[inset_0px_0px_4px_#808080] text-[12px] font-bold leading-[100%] rounded-[10px] mt-[16px]"
                        onClick={() => setDescriptionModalOpen(true)}
                        onMouseEnter={() => playHover()}
                    >
                        Quest Dialogue
                    </button>
                    {/* ---------- */}

                    <div className="hidden md:flex flex-col items-center gap-[26px] mt-[100px]">
                        <div
                            className={`flex items-center w-[291px] h-[54px] pl-[13px] text-[22px] font-orbitron font-semibold leading-[100%] rounded-[10px] gradient-btn hover:scale-110 transition-transform cursor-pointer active:translate-y-[2px]`}
                            onClick={() => setQuestType(QuestTypeEnum.Standard)}
                            onMouseEnter={() => playHover()}
                        >
                            #1 - Brawlers' Bounty
                        </div>
                        <div
                            className={`flex items-center w-[291px] h-[54px] pl-[13px] text-[22px] font-orbitron font-semibold leading-[100%] rounded-[10px] gradient-btn hover:scale-110 transition-transform cursor-pointer active:translate-y-[2px]`}
                            onClick={() => setQuestType(QuestTypeEnum.Risk)}
                            onMouseEnter={() => playHover()}
                        >
                            #2 - Enter the Arena
                        </div>
                    </div>
                </div>

                {/* Second */}
                <div className="hidden md:flex flex-col grow md:border-r-2 md:border-r-[#808080]">
                    <h1 className="text-[32px] text-center font-orbitron font-bold leading-[100%] mt-[12px]">
                        {
                            questType == QuestTypeEnum.Standard ? (
                                "#1 Brawlers’ Bounty"
                            ) : questType == QuestTypeEnum.Risk ? (
                                "#2 Enter the Arena"
                            ) : (
                                "-"
                            )
                        }
                    </h1>

                    <div className="flex flex-col gap-[20px] text-[20px] font-normal leading-[20px] mt-[25px] pl-[35px] pr-[19px] pb-[20px]">
                        {
                            questType == QuestTypeEnum.Standard ? (
                                <>
                                    <h3>In the Solana ecosystem's vast digital frontier, the renowned BiteClub calls to you.</h3>
                                    <h3>BiteClub, a gaming platform where skill and fortune meet, offers a thrilling opportunity for the bold.</h3>
                                    <h3>Driven by adventure and potential profits, you embark on a seven-day journey to join BiteClub and earn your first cut.</h3>
                                    <h3>As you traverse the unpredictable Solana blockchain, you'll face challenges and competitors alike.</h3>
                                    <h3>Each day tests your determination and resilience as you navigate the terrain, uncover blockchain secrets, and hone your skills.</h3>
                                    <h3>After seven intense days, you arrive at BiteClub, weary but victorious. The polished platform awaits, ready for the high-stakes games you're now prepared for.</h3>
                                    <h3>As a new BiteClub member, you're ready to claim your first cut and carve your destiny in Solana's premier gaming platform.</h3>
                                    <h3>Completing this initiation quest earns you a place among BiteClub's elite, ready for the challenges and rewards of this innovative enterprise.</h3>
                                </>
                            ) : questType == QuestTypeEnum.Risk ? (
                                <>
                                    <h3>As a new wolf in the BiteClub, you step into the arena for the first time, your eyes set on the prize ahead.</h3>
                                    <h3>In the center of the arena, a bag of bones lies untouched, a tantalizing bounty for those bold enough to claim it.</h3>
                                    <h3>Driven by curiosity and the thrill of competition, you decide to fetch it, not knowing what lies inside.</h3>
                                    <h3>As you venture into the heart of the arena, you're aware of the risks. Other wolves, also drawn to the bag of bones, could pose a threat.</h3>
                                    <h3>Each step tests your courage and strategy. Will you successfully claim the bones, or will you face the wrath of competing wolves?</h3>
                                    <h3>Regardless of the outcome, you know this is just the beginning. Life is a game of chance, with potential for great rewards or significant losses.</h3>
                                    <h3>As a participant in the arena, you're ready to face the challenges and embrace the thrill of this high-stakes game.</h3>
                                    <h3>Whether you emerge with the bag of bones or suffer injuries, you know each quest is a step towards becoming a seasoned member of the BiteClub.</h3>

                                </>
                            ) : (
                                <h3>-</h3>
                            )
                        }
                    </div>

                </div>

                {/* Third */}
                <div className="min-w-[320px]">
                    <div className="w-full mt-[30px] px-[20px] md:px-0">
                        <div className="grid grid-cols-2 md:grid-cols-1 gap-x-[10px] gap-y-[76px] md:gap-[80px]">
                            {/* Required Stats */}
                            <div className="flex flex-col items-center gap-[15px] md:gap-[25px]">
                                <h3 className="text-[16px] md:text-[22px] text-center font-orbitron font-bold leading-[100%]">Required Stats</h3>
                                <div className="flex items-center gap-[9px]">
                                    <div className="w-[22.5px] h-[22.5px] bg-[#1D262F] shadow-[0px_0px_8px_#00A5FE] relative">
                                        <div className="w-full h-full p-[5px_2px] absolute top-0 left-0">
                                            <CheckIcon className="w-full h-full" />
                                        </div>
                                    </div>
                                    <div className="flex items-center text-[14px] md:text-[20px] font-normal leading-[100%]">
                                        None
                                    </div>
                                </div>
                            </div>

                            {/* Required Traits */}
                            <div className="flex flex-col items-center gap-[15px] md:gap-[25px]">
                                <h3 className="text-[16px] md:text-[22px] text-center font-orbitron font-bold leading-[100%]">Required Traits</h3>
                                <div className="flex items-center gap-[9px]">
                                    <div className="w-[22.5px] h-[22.5px] bg-[#1D262F] shadow-[0px_0px_8px_#00A5FE] relative">
                                        <div className="w-full h-full p-[5px_2px] absolute top-0 left-0">
                                            <CheckIcon className="w-full h-full" />
                                        </div>
                                    </div>
                                    <div className="flex items-center text-[14px] md:text-[20px] font-normal leading-[100%]">
                                        None
                                    </div>
                                </div>
                            </div>

                            {/* Duration */}
                            <div className="flex flex-col items-center gap-[15px] md:gap-[25px]">
                                <h3 className="text-[16px] md:text-[22px] text-[#EDC967] text-center font-orbitron font-bold leading-[100%]">Duration</h3>
                                <div className="flex items-center text-[14px] md:text-[20px] font-normal leading-[100%]">
                                    7 Days
                                </div>
                            </div>

                            {/* Risk Level */}
                            <div className="flex flex-col items-center">
                                <h3 className="text-[16px] md:text-[22px] text-[#FF3535] text-center font-orbitron font-bold leading-[100%]">Risk Level</h3>
                                {
                                    questType == QuestTypeEnum.Standard ? (
                                        <div className="flex items-center text-[14px] md:text-[20px] font-normal leading-[100%] mt-[15px] md:mt-[25px]">
                                            0%
                                        </div>
                                    ) : (
                                        <div className="flex flex-col items-center text-[14px] md:text-[20px] font-normal leading-[100%] mt-[10px] md:mt-[12px]">
                                            <div className="flex flex-col items-center gap-[10px] md:gap-[15px]">
                                                <div className="flex flex-row md:flex-col items-center gap-[20px]">
                                                    <div className="flex flex-col items-center">
                                                        <span className="text-[14px] md:text-[20px] text-[#D2AC47] font-normal">25%</span>

                                                        <div className="flex justify-center items-center gap-[5px]">
                                                            <span className="text-[14px] md:text-[20px] font-normal">- 5</span>
                                                            <img src={filledHeartIcon} className="w-[11px] md:w-[16px]" />
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-col items-center">
                                                        <span className="text-[14px] md:text-[20px] text-[#D2AC47] font-normal">20%</span>

                                                        <div className="flex justify-center items-center gap-[5px]">
                                                            <span className="text-[14px] md:text-[20px] font-normal">- 10</span>
                                                            <img src={filledHeartIcon} className="w-[11px] md:w-[16px]" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex justify-center items-center gap-[5px] text-[8px] md:text-[12px]">
                                                    <span>(if you lose</span>
                                                    <img src={filledHeartIcon} className="w-[8px] md:w-[12px]" />
                                                    <span>you will not receive Bones)</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

            <div className="flex flex-col md:flex-row w-full mb-[60px] md:mb-[20px]">
                {/* Check Quest Status */}
                <div className="flex flex-col justify-center items-center min-w-[380px] order-2 md:order-1 mb-[14px] md:mb-0">
                    <button
                        className="flex justify-center items-center w-[200px] md:w-[291px] h-[36px] md:h-[54px] text-[16px] md:text-[22px] font-orbitron font-extrabold md:font-semibold leading-[24px] md:leading-[100%] rounded-[10px] gradient-btn quests-action-btn"
                        onClick={() => setCompleteModalOpen(true)}
                        onMouseEnter={() => playHover()}
                    >
                        <span className="hidden md:block">Check Quest Status</span>
                        <span className="md:hidden">Quest Status</span>
                    </button>
                </div>

                {/* REWARDS */}
                <div className="flex justify-center items-center grow order-1 md:order-2 mb-[90px] md:mb-0">
                    <div className="flex flex-col justify-center items-center gap-[21px] md:gap-[10px] w-[257px] md:w-[350px] h-[93px] md:h-[110px] bg-[#11161B] mix-blend-screen border-x-0 border-y-2 md:border-y-[3px] border-solid border-[#00FF00] rounded-[10px]">
                        <div className="text-[18px] md:text-[26px] font-orbitron font-bold leading-[100%]">REWARDS</div>

                        {
                            questType == QuestTypeEnum.Standard ? (
                                <div className="flex flex-col justify-center items-center w-[140px] rounded-[10px] bg-black/50">
                                    <div className="text-[15px] md:text-[22px] text-[#95FFB1] font-medium leading-[100%]">100%</div>
                                    <div className="text-[15px] md:text-[22px] text-[#46FF78] font-medium leading-[100%]">100 Bones</div>
                                </div>
                            ) : (
                                <div className="flex justify-center items-center gap-[15px]">
                                    <div className="flex flex-col justify-center items-center w-[100px] md:w-[140px] rounded-[10px] bg-black/50">
                                        <div className="text-[15px] md:text-[22px] text-[#95FFB1] font-medium leading-[100%]">50%</div>
                                        <div className="text-[15px] md:text-[22px] text-[#46FF78] font-medium leading-[100%]">200 Bones</div>
                                    </div>

                                    <div className="flex flex-col justify-center items-center w-[100px] md:w-[140px] rounded-[10px] bg-black/50">
                                        <div className="text-[15px] md:text-[22px] text-[#95FFB1] font-medium leading-[100%]">5%</div>
                                        <div className="text-[15px] md:text-[22px] text-[#46FF78] font-medium leading-[100%]">400 Bones</div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>

                {/* Accept Quest */}
                <div className="flex flex-col justify-center items-center min-w-[320px] order-3">
                    <button
                        className="flex justify-center items-center gap-[10px] w-[200px] md:w-[260px] h-[64px] md:h-[75px] text-[20px] md:text-[22px] font-bold leading-[30px] md:leading-[100%] rounded-[10px] gradient-btn quests-action-btn"
                        onClick={() => setAcceptModalOpen(true)}
                        onMouseEnter={() => playHover()}
                    >
                        <span>Accept Quest</span>
                        <ArrowRightWhiteIcon className="w-[25px] md:w-[37px]" />
                    </button>
                </div>
            </div>

            {/* Modal to accept quest */}
            <AcceptQuestModal
                show={acceptModalOpen}
                isLoading={isLoading}
                questType={questType}
                poolAccount={poolAccount}
                poolInfo={poolInfo}
                nftList={nftList}
                handleShow={setAcceptModalOpen}
                handleBulkHeal={handleBulkHeal}
                onComplete={onComplete}
            />

            {/* Modal to healing status */}
            <HealingStatusModal
                show={healingStatusModalOpen}
                isHealing={isHealing}
                nftListToHeal={nftListToHeal}
                healedNfts={healedNfts}
                handleShow={setHealingStatusModalOpen}
                setNftListToHeal={setNftListToHeal}
                setHealedNfts={setHealedNfts}
            />

            {/* Modal to complete quest */}
            <CompleteQuestModal
                show={completeModalOpen}
                isLoading={isLoading}
                handleShow={setCompleteModalOpen}
                poolAccount={poolAccount}
                poolInfo={poolInfo}
                stakedNftList={stakedNftList}
                onComplete={onComplete}
                handleBulkHeal={handleBulkHeal}
            />

            {/* Modal to introduce a specific quest */}
            <DescriptionModal
                show={descriptionModalOpen}
                questType={questType}
                handleShow={setDescriptionModalOpen}
            />
        </div>
    );
};

export default Quests;