import React from 'react';

interface IProps {
    className?: string;
}

const SpinIcon = ({ className }: IProps) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
            <path d="M0 25C4.1292e-07 20.2767 1.33803 15.6501 3.85912 11.656C6.38021 7.66182 9.98121 4.46357 14.2451 2.43164C18.5089 0.399702 23.2611 -0.382778 27.9514 0.174821C32.6416 0.73242 37.0779 2.60728 40.7465 5.58227C44.4151 8.55725 47.1659 12.5106 48.6802 16.9845C50.1946 21.4584 50.4106 26.2698 49.3032 30.8614C48.1958 35.453 45.8103 39.637 42.423 42.9287C39.0357 46.2204 34.7853 48.4853 30.1639 49.4609L29.0013 43.9539C32.5823 43.198 35.8758 41.443 38.5005 38.8924C41.1252 36.3417 42.9736 33.0997 43.8317 29.5418C44.6898 25.9839 44.5225 22.2558 43.349 18.7891C42.1756 15.3224 40.0441 12.259 37.2014 9.95383C34.3588 7.64861 30.9212 6.19584 27.2869 5.76377C23.6526 5.33171 19.9703 5.93803 16.6663 7.51251C13.3624 9.08699 10.5721 11.5652 8.61862 14.6601C6.6651 17.7551 5.62831 21.3401 5.62831 25H0Z" fill="url(#paint0_linear_1096_9081)" />
            <defs>
                <linearGradient id="paint0_linear_1096_9081" x1="2.96018e-07" y1="25.4239" x2="50" y2="25.4239" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#575757" />
                    <stop offset="0.463542" stopColor="#DBDBDB" />
                    <stop offset="0.755208" stopColor="#7C7C7C" />
                    <stop offset="1" stopColor="#808080" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default SpinIcon;