import React from 'react';

interface IProps {
    className?: string;
}

const OnchainNotifyContainerBadgeLg = ({ className }: IProps) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="198" height="73" viewBox="0 0 198 73" fill="none" style={{ filter: "drop-shadow(0px 0px 5px #00A5FE)" }}>
            <path d="M0.5 19.2352C0.5 18.4067 1.17157 17.7352 2 17.7352H136.197C136.656 17.7352 137.106 17.6089 137.498 17.3701L164.16 1.12575C165.117 0.54287 166.352 1.17634 166.436 2.29342L167.431 15.424C167.53 16.7277 168.617 17.7352 169.924 17.7352H196C196.828 17.7352 197.5 18.4067 197.5 19.2352V71C197.5 71.8284 196.828 72.5 196 72.5H2C1.17157 72.5 0.5 71.8284 0.5 71V19.2352Z" fill="#072B3E" stroke="#00A5FE" />
        </svg>
    );
};

export default OnchainNotifyContainerBadgeLg;
