import React, { useEffect, useState } from 'react';
import { ClipLoader } from "react-spinners";
import { toast } from 'react-toastify';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import axios from 'axios';

const BotConfigAdmin = () => {
    const [amount, setAmount] = useState<number | null>(null);
    const [currentBotMaxBalance, setCurrentBotMaxBalance] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const initialize = async () => {
        try {
            const currentBotMaxBalance = (await axios.get('/api/coinflip-game/botMaxValue')).data;
            setCurrentBotMaxBalance(currentBotMaxBalance);
        } catch (_) {
        }
    }

    const handleSet = async () => {
        if (!amount || amount <= 0) {
            return toast.warn("Invalid value");
        }

        setIsProcessing(true);

        try {
            await axios.post('/api/coinflip-game/changeBotMaxValue', { maxValue: amount * LAMPORTS_PER_SOL });
            await initialize();
        } catch (e) {
            console.log('e', e);
            toast.error("Faile to set bot max balance");
        }

        setIsProcessing(false);
    }

    useEffect(() => {
        (async () => {
            await initialize()
        })()
    }, []);

    return (
        <div className="flex flex-col items-center w-full mt-[50px]">
            <h1 className="text-[20px] font-medium mt-5">Manage Bot Max Balance</h1>
            <h5 className="text-[16px] font-medium mb-2">(Current Bot Max Balance: {(currentBotMaxBalance / LAMPORTS_PER_SOL).toLocaleString()}) SOL</h5>
            <div className='w-full max-w-[300px] px-[10px]'>
                <input
                    type="number"
                    disabled={isLoading || isProcessing}
                    className="bg-[#1D262F] rounded-[10px] w-full h-[37px] text-[18px] px-[15px] font-medium outline-none"
                    placeholder="max balance"
                    value={amount === null ? "" : amount}
                    onChange={(e) => setAmount(e.target.value ? Number(e.target.value) : null)}
                />

                <div className='flex flex-col md:flex-row justify-center items-center gap-[10px] w-full mt-[10px]'>
                    <button
                        disabled={isLoading}
                        className={`flex justify-center items-center gap-[10px] gradient-btn full-rounded py-[5px] w-full md:w-1/2 ${(isLoading || isProcessing) ? 'opacity-60' : 'opacity-100'}`}
                        onClick={() => handleSet()}
                    >
                        {!isProcessing ? 'Set' : 'Setting...'}
                        {isProcessing ? <ClipLoader size={15} color="#ffffff" /> : null}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BotConfigAdmin;