import React from "react";
import LightBlueBall from '../../assets/light-blue-ball.svg';
import GreenBall from '../../assets/green-ball.svg';
import PinkBall from '../../assets/pink-ball.svg';
import YellowBall from '../../assets/yellow-ball.svg';
import GrayBall from '../../assets/gray-ball.svg';
import BlueBall from '../../assets/blue-ball.svg';
import GoldBall from '../../assets/gold-ball.svg';
import RedBall from '../../assets/red-ball.svg';

interface IProps {
    numArray: number[];
    rowGap?: number;
    colGap?: number;
    className?: string;
    ballSize?: string;
    plusFont?: string;
    matchingNumberIndexArray?: number[];
}

const TicketNumbers = (props: IProps) => {
    const { numArray, className, ballSize, plusFont, matchingNumberIndexArray } = props;

    return (
        <div className={`flex items-center gap-[5px] md:gap-[6px] ${className ?? ''}`}>
            <div className={`relative w-[25px] md:w-[30px] ${ballSize ?? ''} aspect-square`}>
                {
                    matchingNumberIndexArray !== undefined && matchingNumberIndexArray.find(x => x == 0) !== undefined && (
                        <div className="absolute left-0 top-0 w-full h-full bg-[#46FF78] rounded-full blur-[3.2px] md:blur-[4.25px]"></div>
                    )
                }
                <img src={LightBlueBall} className="absolute left-0 top-0 w-full h-full" />
                <div className="flex justify-center items-center w-full h-full absolute left-0 top-0">
                    <span>{numArray[0]}</span>
                </div>
            </div>

            <div className={`relative w-[25px] md:w-[30px] ${ballSize ?? ''} aspect-square`}>
                {
                    matchingNumberIndexArray !== undefined && matchingNumberIndexArray.find(x => x == 1) !== undefined && (
                        <div className="absolute left-0 top-0 w-full h-full bg-[#46FF78] rounded-full blur-[3.2px] md:blur-[4.25px]"></div>
                    )
                }
                <img src={GreenBall} className="absolute left-0 top-0 w-full h-full" />
                <div className="flex justify-center items-center w-full h-full absolute left-0 top-0">
                    <span>{numArray[1]}</span>
                </div>
            </div>

            <div className={`relative w-[25px] md:w-[30px] ${ballSize ?? ''} aspect-square`}>
                {
                    matchingNumberIndexArray !== undefined && matchingNumberIndexArray.find(x => x == 2) !== undefined && (
                        <div className="absolute left-0 top-0 w-full h-full bg-[#46FF78] rounded-full blur-[3.2px] md:blur-[4.25px]"></div>
                    )
                }
                <img src={PinkBall} className="absolute left-0 top-0 w-full h-full" />
                <div className="flex justify-center items-center w-full h-full absolute left-0 top-0">
                    <span>{numArray[2]}</span>
                </div>
            </div>

            <div className={`relative w-[25px] md:w-[30px] ${ballSize ?? ''} aspect-square`}>
                {
                    matchingNumberIndexArray !== undefined && matchingNumberIndexArray.find(x => x == 3) !== undefined && (
                        <div className="absolute left-0 top-0 w-full h-full bg-[#46FF78] rounded-full blur-[3.2px] md:blur-[4.25px]"></div>
                    )
                }
                <img src={YellowBall} className="absolute left-0 top-0 w-full h-full" />
                <div className="flex justify-center items-center w-full h-full absolute left-0 top-0">
                    <span>{numArray[3]}</span>
                </div>
            </div>

            <div className={`relative w-[25px] md:w-[30px] ${ballSize ?? ''} aspect-square`}>
                {
                    matchingNumberIndexArray !== undefined && matchingNumberIndexArray.find(x => x == 4) !== undefined && (
                        <div className="absolute left-0 top-0 w-full h-full bg-[#46FF78] rounded-full blur-[3.2px] md:blur-[4.25px]"></div>
                    )
                }
                <img src={GrayBall} className="absolute left-0 top-0 w-full h-full" />
                <div className="flex justify-center items-center w-full h-full absolute left-0 top-0">
                    <span>{numArray[4]}</span>
                </div>
            </div>

            <div className={`relative w-[25px] md:w-[30px] ${ballSize ?? ''} aspect-square`}>
                {
                    matchingNumberIndexArray !== undefined && matchingNumberIndexArray.find(x => x == 5) !== undefined && (
                        <div className="absolute left-0 top-0 w-full h-full bg-[#46FF78] rounded-full blur-[3.2px] md:blur-[4.25px]"></div>
                    )
                }
                <img src={BlueBall} className="absolute left-0 top-0 w-full h-full" />
                <div className="flex justify-center items-center w-full h-full absolute left-0 top-0">
                    <span>{numArray[5]}</span>
                </div>
            </div>

            <div className={`relative w-[25px] md:w-[30px] ${ballSize ?? ''} aspect-square`}>
                {
                    matchingNumberIndexArray !== undefined && matchingNumberIndexArray.find(x => x == 6) !== undefined && (
                        <div className="absolute left-0 top-0 w-full h-full bg-[#46FF78] rounded-full blur-[3.2px] md:blur-[4.25px]"></div>
                    )
                }
                <img src={GoldBall} className="absolute left-0 top-0 w-full h-full" />
                <div className="flex justify-center items-center w-full h-full absolute left-0 top-0">
                    <span>{numArray[6]}</span>
                </div>
            </div>

            <div className="w-[8px] md:w-[12px] text-center">
                <span className={`text-[14px] text-white font-normal leading-[20px] ${plusFont ?? ''}`}>+</span>
            </div>

            <div className={`relative w-[25px] md:w-[30px] ${ballSize ?? ''} aspect-square`}>
                {
                    matchingNumberIndexArray !== undefined && matchingNumberIndexArray.find(x => x == 7) !== undefined && (
                        <div className="absolute left-0 top-0 w-full h-full bg-[#46FF78] rounded-full blur-[3.2px] md:blur-[4.25px]"></div>
                    )
                }
                <img src={RedBall} className="absolute left-0 top-0 w-full h-full" />
                <div className="flex justify-center items-center w-full h-full absolute left-0 top-0">
                    <span>{numArray[7]}</span>
                </div>
            </div>
        </div>
    );
};

export default TicketNumbers;