import GlobalState from "../interfaces/GlobalState";

enum GlobalStateActionTypes {
    SET_LIVE_CHAT_EXPANDED_STATUS = 'SET_LIVE_CHAT_EXPANDED_STATUS',
}

interface SetLiveChatExpandedStatusAction {
    type: GlobalStateActionTypes.SET_LIVE_CHAT_EXPANDED_STATUS,
    payload: boolean
}

type GlobalStateAction = SetLiveChatExpandedStatusAction;

const initialState: GlobalState = {
    isLiveChatExpanded: false,
}

export default (state = initialState, action: GlobalStateAction): GlobalState => {

    switch (action.type) {
        case GlobalStateActionTypes.SET_LIVE_CHAT_EXPANDED_STATUS: {
            return { ...state, isLiveChatExpanded: action.payload }
        }

        default:
            return state
    }
}