import React, { useEffect, useState } from 'react';
import { ClipLoader } from "react-spinners";
import { toast } from 'react-toastify';
import type { DatePickerProps } from 'antd';
import { DatePicker, InputNumber } from 'antd';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { ArenaType, IArenaConfig } from '../../../interfaces/ArenaEvent';
import axios from 'axios';
import { Dayjs } from 'dayjs';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

const ArenaEventAdmin = () => {
    const [period, setPeriod] = useState<number | null>(7);
    const [date, setDate] = useState<Dayjs | null>(null);
    const [dateString, setDateString] = useState<string | null>(null);
    const [arenaConfig, setArenaConfig] = useState<IArenaConfig>();
    const [arenaType, setArenaType] = useState<string | ArenaType>('option1');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isStarting, setIsStarting] = useState<boolean>(false);
    const [isStopping, setIsStopping] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const initialize = async () => {
        try {
            const arenaConfig = (await axios.get('/api/arena/arenaConfig')).data;
            console.log({ arenaConfig });
            setArenaConfig(arenaConfig);
        } catch (_) {
        }
    }

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        console.log({ dateString });
        setDate(date);
        setDateString(dateString);
    };

    const handleStartEvent = async () => {
        // if (arenaConfig && arenaConfig.isRunning) {
        //     return toast.warn("Already running");
        // }

        if (!date || !dateString) {
            return toast.warn("Please input start time");
        }

        if (!period || period <= 0) {
            return toast.warn("Invalid period");
        }

        setIsStarting(true);
        setIsProcessing(true);

        try {
            const startTime = Math.floor((new Date(new Date(dateString).toISOString())).getTime() / 1000);
            await axios.post('/api/arena/startEvent', { startTime, period, arenaType });
            await initialize();

            toast.success("Successful to start an event");
        } catch (e: any) {
            console.log('e', e);
            if (e?.response?.data) {
                toast.error(e.response.data.message.toString(), { autoClose: 3500 });
            } else {
                toast.error("Failed to start an event");
            }
        }

        setIsStarting(false);
        setIsProcessing(false);
    }

    const handleStopEvent = async () => {
        setIsStopping(true);
        setIsProcessing(true);

        try {
            await axios.post('/api/arena/stopEvent');
            await initialize();

            toast.success("Successful to stop an event");
        } catch (e: any) {
            console.log('e', e);
            if (e?.response?.data) {
                toast.error(e.response.data.message.toString());
            } else {
                toast.error("Failed to stop an event");
            }
        }

        setIsStopping(false);
        setIsProcessing(false);
    }

    useEffect(() => {
        (async () => {
            await initialize()
        })()
    }, []);

    return (
        <div className="flex flex-col items-center w-full">
            <h1 className="text-[20px] font-medium mt-5">Manage Arena Event</h1>

            <div className='mt-[20px]'>
                <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group" style={{ color: 'white', fontSize: '18px' }}>Arena type</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={arenaType}
                        onChange={(e) => setArenaType(e.target.value)}
                    >
                        <FormControlLabel value="option1" control={<Radio />} label="Option1" />
                        <FormControlLabel value="option2" control={<Radio />} label="Option2" />
                    </RadioGroup>
                </FormControl>
            </div>
            <div className='w-full max-w-[460px] px-[10px]'>
                {/* Start Event */}
                <div className='flex flex-col gap-[10px] w-full'>
                    <div className='text-[20px]'>Start Event</div>

                    {/* Start Date */}
                    <div className='flex items-center gap-[10px] w-full'>
                        <span className='whitespace-nowrap'>Start Date:</span>
                        <DatePicker
                            className='w-full'
                            value={date}
                            onChange={onChange}
                        />
                    </div>

                    {/* Event Period */}
                    <div className='flex items-center gap-[10px] w-full'>
                        <span className='whitespace-nowrap'>Period:</span>
                        <InputNumber
                            min={1}
                            max={7}
                            value={period}
                            className='w-full'
                            onChange={(value) => setPeriod(value)}
                        />
                    </div>

                    <div className='flex justify-end items-center w-full'>
                        <button
                            disabled={isLoading || isProcessing}
                            className={`flex justify-center items-center gap-[10px] gradient-btn full-rounded py-[5px] w-full md:w-1/2 ${(isLoading || isProcessing) ? 'opacity-60' : 'opacity-100'}`}
                            onClick={() => handleStartEvent()}
                        >
                            {!isStarting ? 'Start Event' : 'Processing...'}
                            {isStarting ? <ClipLoader size={15} color="#ffffff" /> : null}
                        </button>
                    </div>
                </div>

                <hr className='my-[20px]' />

                {/* Stop Event */}
                <div className='flex flex-col gap-[10px] w-full'>
                    <div className='text-[20px]'>Stop Event</div>

                    <div className='flex justify-end items-center w-full'>
                        <button
                            disabled={isLoading || isProcessing || !arenaConfig?.isRunning}
                            className={`flex justify-center items-center gap-[10px] gradient-btn full-rounded py-[5px] w-full md:w-1/2 ${(isLoading || isProcessing || !arenaConfig?.isRunning) ? 'opacity-60' : 'opacity-100'}`}
                            onClick={() => handleStopEvent()}
                        >
                            {!isStopping ? 'Stop Event' : 'Processing...'}
                            {isStopping ? <ClipLoader size={15} color="#ffffff" /> : null}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ArenaEventAdmin;