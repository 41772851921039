import React from "react";

interface IProps {
    value: number;
    maxValue: number;
}

const HealValueProgressBar = (props: IProps) => {
    const { value, maxValue } = props;

    return (
        <div className="absolute w-[48px] md:w-[78px] h-[12px] md:h-[20px] top-[5px] left-[5px] text-center border md:border-2 border-solid border-[#090C0E] rounded-full drop-shadow-[0px_8px_8px_rgba(0,0,0,0.15)] overflow-hidden">
            <div className="relative w-full h-full">
                <div
                    className="h-full bg-[linear-gradient(90deg,#46FF78_97%,rgba(17,22,27,0)_100%)]"
                    style={{ width: `${value / maxValue * 100}%` }}
                />

                <div className="absolute flex justify-center items-center w-full h-full top-0 left-0">
                    <span className="text-[8px] md:text-[15px] text-black">
                        {`${value}/${maxValue}`}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default HealValueProgressBar;