import { WalletContextState } from '@solana/wallet-adapter-react'
import { combineReducers, createStore } from 'redux'
import User from './interfaces/User'
import userReducer from './reducers/user'
import rpsGamesReducer from './reducers/rpsGames'
import diceGamesReducer from './reducers/diceGames'
import lotteryReducer, { IInitialState } from './reducers/lottery'
import coinflipReducer, { ICoinflipInitialState } from './reducers/coinflip'
import dice2GamesReducer, { IDice2InitialState } from './reducers/dice2Games'
import walletReducer from './reducers/wallet'
import globalStateRecuder from './reducers/globalState';
import socketsReducer, { Sockets } from './reducers/sockets'
import Game from './interfaces/Game'
import GlobalState from './interfaces/GlobalState'

const combined = combineReducers({
    user: userReducer,
    wallet: walletReducer,
    rpsGames: rpsGamesReducer,
    diceGames: diceGamesReducer,
    lottery: lotteryReducer,
    coinflip: coinflipReducer,
    dice2Games: dice2GamesReducer,
    globalState: globalStateRecuder,
    sockets: socketsReducer,
})

export interface CombinedReducer {
    user: User,
    wallet: WalletContextState,
    rpsGames: Game[],
    diceGames: Game[],
    lottery: IInitialState,
    coinflip: ICoinflipInitialState,
    dice2Games: IDice2InitialState,
    globalState: GlobalState,
    sockets: Sockets,
}

export default createStore(combined)