import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Countdown from "react-countdown";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import { useWallet } from "@solana/wallet-adapter-react";
import {
    Connection,
    LAMPORTS_PER_SOL,
    PublicKey,
    SystemProgram
} from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";
import { Program, AnchorProvider } from "@project-serum/anchor";
import { Wallet } from '@project-serum/anchor/dist/cjs/provider';
import { Metaplex } from "@metaplex-foundation/js";
import { getAssociatedTokenAddress, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import axios from "axios";
import GameStatsBar from "./GameStasBar";
import { INFTList } from "../../interfaces/NftList";
import { IShuffleData } from "../../interfaces/ShuffleData";
import { IShuffleUser } from "../../interfaces/ShuffleUser";
import { IUserMainInfo } from "../../interfaces/User";
import { colorScheme } from "../../data";
import { ShuffleBetTypeEnum } from "../../enums";
import { createTwitterShareUrl } from "../../utils";
import triangleDownIcon from "../../assets/triangle-down.svg";
import shuffleIdl from "../../idls/shuffle-idl.json";
import useSound from "use-sound";

import "./index.scss";
import ShareIcon from "../../assets/share-button.svg";
import blankUserIcon from '../../assets/blank-user.svg';
// @ts-ignore
import Slide from 'react-reveal/Slide';

const feePercent = 4;

const SolShuffle = () => {
    const wallet = useWallet();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [endTime, setEndTime] = useState<number>(0);
    const [timeDifference, setTimeDifference] = useState<number>(0);
    const [lastWinnerAvatar, setLastWinnerAvatar] = useState<string | undefined>();

    const [betSolAmount, setBetSolAmount] = useState<number | null>(0);

    const [winningChance, setWinningChance] = useState<number>(0);
    const [totalBetAmount, setTotalBetAmount] = useState<number>(0);
    const [background, setBackground] = useState<string>('initial');
    const [rotationAngle, setRotationAngle] = useState<number>(0);
    const [solBalance, setSolBalance] = useState<number>(0);
    const [nftList, setNftList] = useState<INFTList[]>([]);

    const [poolAccount, setPoolAccount] = useState<PublicKey>();
    const [lotteryAccount, setLotteryAccount] = useState<PublicKey>();
    const [escrowVault, setEscrowVault] = useState<PublicKey>();
    const [treasuryWallet, setTreasuryWallet] = useState<PublicKey>();
    const [poolSigner, setPoolSigner] = useState<PublicKey>();
    const [lotteryManager, setLotteryManager] = useState<PublicKey>();
    const [entrants, setEntrants] = useState<PublicKey>();
    const [lastWinner, setLastWinner] = useState<string>("");
    const [lastWinnerBettingAmount, setLastWinnerBettingAmount] = useState<number>(0);
    const [lastBettingAmount, setLastBettingAmount] = useState<number>(0);
    const [lastBettingSolAmount, setLastBettingSolAmount] = useState<number>(0);
    const [totalUserData, setTotalUserData] = useState<IShuffleData[]>([]);
    const [statsBarData, setStatsBarData] = useState<IShuffleData[]>([]);
    const [ended, setEnded] = useState<boolean>(false);
    const [isOpened, setIsOpened] = useState<boolean>(false);

    const [showSolModal, setShowSolModal] = useState<boolean>(false);
    const [showNftModal, setShowNftModal] = useState<boolean>(false);
    const [selectedNftIndex, setSelectedNftIndex] = useState<number | null>(null);

    const [tempWinner, setTempWinner] = useState<string>('');
    const [playShuffle] = useSound("/sound/shuffle.mp3", { volume: 1 });
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });

    const connection = new Connection(process.env.REACT_APP_RPC_URL!, "confirmed");
    const programID = new PublicKey(shuffleIdl.metadata.address);
    const provider = new AnchorProvider(connection, wallet as Wallet, { commitment: 'processed' });
    const program = new Program(shuffleIdl as anchor.Idl, programID, provider);
    const metaplex = new Metaplex(connection);

    const initialize = async (isInit: boolean, isNftLoading: boolean) => {
        if (isInit) {
            setIsLoading(true);
        }

        try {
            /* fetch sc data */
            try {
                const poolAccount = PublicKey.findProgramAddressSync(
                    [Buffer.from('sol-shuffle-pool')],
                    program.programId
                )[0];

                const [poolAccountInfo, lotteryUsers]: any = await Promise.all([
                    program.account.pool.fetch(poolAccount),
                    program.account.lotteryUser.all(),
                ]);

                const {
                    escrowVault,
                    currentLottery,
                    treasuryWallet,
                    poolSigner,
                    lastWinner,
                    lastWinnerBettingAmount,
                    lastBettingAmount,
                    lastBettingSolAmount,
                } = poolAccountInfo;
                lotteryUsers.sort((a: IShuffleUser, b: IShuffleUser) => a.account.userNumber - b.account.userNumber);

                let totalBetAmount = 0;
                const totalUserData: IShuffleData[] = [];

                await Promise.all(lotteryUsers.map(async (item: IShuffleUser, index: number) => {
                    totalBetAmount += item.account.bettingAmount.toNumber();

                    totalUserData.push({
                        pubkey: item.account.user.toString(),
                        betAmount: item.account.bettingAmount.toNumber(),
                        color: colorScheme[index]
                    });
                }));

                // const winningChance = totalBetAmount > 0 ? betAmount / totalBetAmount * 100 : 0;
                setTotalUserData(totalUserData);
                setTotalBetAmount(totalBetAmount);
                // setWinningChance(winningChance);
                /* ------------- */

                /* set spin */
                let background = '';

                if (totalUserData.length > 0) {
                    if (totalUserData.length == 1) {
                        background = totalUserData[0].color;
                    } else {
                        let sum = 0;
                        background = "conic-gradient(";

                        await Promise.all(totalUserData.map(async (item, index) => {
                            sum += item.betAmount;
                            if (index == 0) {
                                background += `${item.color} ${item.betAmount / totalBetAmount * 100}%`;
                            } else {
                                background += `, ${item.color} 0deg, ${item.color} ${sum / totalBetAmount * 100}%`
                            }
                        }));

                        background += ")";
                    }
                } else {
                    background = "initial";
                }

                setBackground(background);
                /* -------- */

                if (currentLottery.toString() != PublicKey.default.toString()) {
                    const currentLotteryInfo: any = await program.account.lottery.fetch(currentLottery);

                    setEnded(currentLotteryInfo?.ended);
                    setLotteryManager(currentLotteryInfo?.lotteryManager);

                    if (isInit) {
                        let slot = await connection.getSlot();
                        let currentTime = await connection.getBlockTime(slot);
                        const endTime = currentLotteryInfo?.endTime?.toNumber();
                        const timeDifference = currentTime! * 1000 - Date.now();
                        console.log('timeDifference', timeDifference);

                        setEndTime(endTime);
                        setTimeDifference(timeDifference);
                    }

                    try {
                        const shuffleNfts = (await axios.get(`/api/shuffle/getNfts/${currentLottery.toString()}`)).data;

                        const { entries, bettingAmounts, isSolBet, totalEntrants }: any = await program.account.entrants.fetch(currentLotteryInfo?.entrants);
                        const statsBarData: IShuffleData[] = [];

                        let index = 0;
                        for (let i = 0; i < totalEntrants; i++) {
                            statsBarData.push({
                                pubkey: entries[i].toString(),
                                betType: isSolBet[i] == 1 ? ShuffleBetTypeEnum.SOL : ShuffleBetTypeEnum.NFT,
                                betAmount: bettingAmounts[i].toNumber(),
                                color: totalUserData.find(x => x.pubkey == entries[i].toString())?.color!,
                                imgUrl: isSolBet[i] == 1 ? "" : shuffleNfts[index]?.imgUrl,
                            })

                            if (isSolBet[i] == 0) {
                                index += 1;
                            }
                        }
                        setStatsBarData(statsBarData);
                    } catch (_) { }

                    setEntrants(currentLotteryInfo?.entrants);
                }

                try {
                    const allUsers: IUserMainInfo[] = (await axios.post("/api/u/getAllUsers")).data;
                    const lastWinnerAvatar = allUsers.find(x => x.publicKey == lastWinner?.toString())?.userAvatar;
                    setLastWinnerAvatar(lastWinnerAvatar);
                } catch { }

                setPoolAccount(poolAccount);
                setLotteryAccount(currentLottery);
                setEscrowVault(escrowVault);
                setTreasuryWallet(treasuryWallet);
                setPoolSigner(poolSigner);
                setLastWinner(lastWinner?.toString());
                setLastWinnerBettingAmount(lastWinnerBettingAmount?.toNumber());
                setLastBettingAmount(lastBettingAmount?.toNumber());
                setLastBettingSolAmount(lastBettingSolAmount?.toNumber());
            } catch { }

            /* get sol balance and nfts for the user */
            if (wallet?.publicKey) {
                const solBalance = await connection.getBalance(wallet?.publicKey);

                dispatch({ type: "UPDATE_USER_ONCHAIN_BALANCE", payload: solBalance });

                setSolBalance(solBalance / LAMPORTS_PER_SOL)

                if (isNftLoading) {
                    try {
                        const nfts = await metaplex.nfts().findAllByOwner({ owner: wallet?.publicKey });
                        let metadata: any;
                        if (nfts) {
                            const nftList: INFTList[] = [];

                            await Promise.all(
                                nfts.map(async (item, _) => {
                                    if (item?.uri) {
                                        try {
                                            metadata = await (await fetch(item?.uri)).json();
                                            const mintAddress = (item as any)?.mintAddress?.toString();
                                            let rowData: INFTList = {
                                                name: item?.name,
                                                symbol: item?.symbol,
                                                description: metadata?.description,
                                                royalty: item?.sellerFeeBasisPoints,
                                                image: metadata?.image,
                                                mintAddress: mintAddress,
                                                tokenAddress: item?.address?.toString()
                                            }

                                            try {
                                                const { floorPrice, rarityRank } = (await axios.get(`/api/shuffle/getFpAndRarity/${mintAddress}`)).data;
                                                if (floorPrice) {
                                                    rowData = { ...rowData, floorPrice: floorPrice };
                                                }

                                                if (rarityRank) {
                                                    rowData = { ...rowData, howrareRank: rarityRank };
                                                }
                                            } catch { }

                                            nftList.push(rowData);
                                        } catch (_) { }
                                    }
                                })
                            );

                            console.log("nftlist", nftList);

                            setNftList(nftList);
                        }
                    } catch { }
                }
            }
            /* ------------------------------------- */

            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    }

    const handleSetBetSolAmount = (input: string) => {
        if (!input) return setBetSolAmount(null);

        if (Number(input) >= 0) setBetSolAmount(Number(input));
        else setBetSolAmount(0);
    };

    const handleBetSol = async () => {
        if (!wallet?.publicKey) {
            return toast.warn("Please connect wallet");
        }

        if (betSolAmount == null || betSolAmount <= 0) {
            return toast.warn("Invalid amount");
        }

        if (betSolAmount * (1 + feePercent / 100) > solBalance) {
            return toast.warn("Insufficient Sol balance");
        }

        if (!poolAccount) {
            return toast.warn("Sol Shuffle is not initialized yet");
        }

        if (!lotteryAccount) {
            return toast.warn("Sol Shuffle is not started");
        }

        try {
            setIsProcessing(true);

            const lotteryUser = PublicKey.findProgramAddressSync(
                [
                    poolAccount!.toBuffer(),
                    lotteryAccount!.toBuffer(),
                    wallet?.publicKey.toBuffer()
                ],
                program.programId
            )[0];

            const txh = await program.methods
                .betSol(new anchor.BN(betSolAmount * LAMPORTS_PER_SOL))
                .accounts(
                    {
                        player: wallet?.publicKey,
                        lottery: lotteryAccount,
                        lotteryUser: lotteryUser,
                        entrants: entrants,
                        pool: poolAccount,
                        escrowVault: escrowVault,
                        treasuryWallet: treasuryWallet,
                        systemProgram: SystemProgram.programId,
                        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
                    }
                )
                .rpc();

            console.log("txh: ", txh);
            toast.success("Successful");

            setIsProcessing(false);
        } catch (e: any) {
            console.log("error: ", e);
            setIsProcessing(false);
            toast.error("Failed to Bet Sol");
        }
    }

    const handleShowSolModal = (status: boolean) => {
        if (status) {
            // document.body.style.overflow = "hidden"
        } else {
            // document.body.style.overflow = "visible"
        }
        setShowSolModal(status);
    }

    const handleShowNftModal = (status: boolean) => {
        if (status) {
            // document.body.style.overflow = "hidden"
        } else {
            // document.body.style.overflow = "visible"
        }
        setShowNftModal(status);
    }

    const handleBetNft = async () => {
        if (!wallet?.publicKey) {
            return toast.warn("Please connect wallet");
        }

        if (!poolAccount) {
            return toast.warn("Sol Shuffle is not initialized yet");
        }

        if (!lotteryAccount) {
            return toast.warn("Sol Shuffle is not started");
        }

        if (ended) {
            return toast.warn("Sol Shuffle has been ended");
        }

        if (selectedNftIndex == null) {
            return toast.warn("Please choose a NFT");
        }

        try {
            setIsProcessing(true);

            const nftMint = new PublicKey(nftList[selectedNftIndex]?.mintAddress);
            const nftTokenAccount = await getAssociatedTokenAddress(nftMint, wallet?.publicKey);

            const lotteryUser = PublicKey.findProgramAddressSync(
                [
                    poolAccount!.toBuffer(),
                    lotteryAccount!.toBuffer(),
                    wallet?.publicKey.toBuffer()
                ],
                program.programId
            )[0];

            const stakingVault = PublicKey.findProgramAddressSync(
                [
                    Buffer.from('staking-vault'),
                    nftMint.toBuffer(),
                ],
                program.programId
            )[0];


            const data = {
                lottery: lotteryAccount.toString(),
                nftMint: nftMint.toString(),
                imgUrl: nftList[selectedNftIndex]?.image
            }

            const rsp = (await axios.post("/api/shuffle/checkNft", data)).data;
            if (rsp.status == "failed") {
                toast.error(rsp.message);
                setIsProcessing(false);
                return;
            }

            const nftStoreAccount = new PublicKey(rsp.nftStoreAccount);

            const txh = await program.methods
                .betNft()
                .accounts(
                    {
                        player: wallet?.publicKey,
                        lottery: lotteryAccount,
                        lotteryManager: lotteryManager,
                        lotteryUser: lotteryUser,
                        entrants: entrants,
                        pool: poolAccount,
                        poolSigner: poolSigner,
                        nftMint: nftMint,
                        nftTokenAccount: nftTokenAccount,
                        nftStore: nftStoreAccount,
                        stakingVault: stakingVault,
                        treasuryWallet: treasuryWallet,
                        tokenProgram: TOKEN_PROGRAM_ID,
                        systemProgram: SystemProgram.programId,
                        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
                    }
                )
                .rpc();
            console.log("txh: ", txh);

            await axios.post("/api/shuffle/updateShuffle", { shuffleId: rsp.shuffleId });

            toast.success("Successful");

            setNftList((oldStatus) => {
                return oldStatus.filter(item => item.mintAddress != nftMint.toString())
            });
            setSelectedNftIndex(null);
            setIsProcessing(false);
        } catch (e: any) {
            console.log("error: ", e);
            setIsProcessing(false);
            toast.error("Failed to Bet NFT");
        }
    }

    const handleCalculateRotationAngle = async () => {

        if (totalBetAmount) {
            const totalUserDataCopy = totalUserData.slice();
            const winningIndex = totalUserDataCopy.findIndex(x => x.pubkey == tempWinner);

            let angle = 0;
            let sum = 0;
            await Promise.all(totalUserDataCopy.map(async (item, index) => {
                if (index <= winningIndex) {
                    angle = sum / totalBetAmount * 360 + item.betAmount / totalBetAmount * 360 / 2;
                    sum += item.betAmount;
                }
            }));

            angle = 360 * 20 + 360 - angle;

            setRotationAngle(angle);
        }
    }

    useEffect(() => {
        (async () => {
            if (isOpened) {
                await initialize(true, true);
            }
        })();
    }, [isOpened]);

    useEffect(() => {
        if (wallet?.publicKey) {
            const betAmount = totalUserData.find(x => x.pubkey == wallet?.publicKey?.toString())?.betAmount ?? 0;
            const winningChance = totalBetAmount > 0 ? betAmount / totalBetAmount * 100 : 0;

            setWinningChance(winningChance);
        }
    }, [totalBetAmount]);

    useEffect(() => {
        if (tempWinner) {
            handleCalculateRotationAngle();
            playShuffle();
        }
    }, [tempWinner]);

    useEffect(() => {
        (async () => {
            program.addEventListener("StartLotteryEvent", (_event, _slot) => {
                console.log('StartLotteryEvent');
                (async () => {
                    setIsOpened(true);
                })();
            });

            program.addEventListener("BetSolEvent", (event, _slot) => {
                console.log('BetSolEvent', event.data.toNumber());
                (async () => {
                    setEndTime(event.data.toNumber());
                    await initialize(false, false);
                })();
            });

            program.addEventListener("BetNftEvent", (event, _slot) => {
                console.log('BetNftEvent');
                (async () => {
                    setEndTime(event.data.toNumber());
                    await initialize(false, false);
                })();
            });

            program.addEventListener("PickWinnerEvent", (event, _slot) => {
                console.log('PickWinnerEvent');
                setEnded(true);
                setIsOpened(false);
                setTempWinner(event.data.toString());
            });

            program.addEventListener("CloseLotteryEvent", (_event, _slot) => {
                console.log('CloseLotteryEvent');
                (async () => {
                    setTimeout(async () => {
                        setBackground("initial");
                        setRotationAngle(0);
                        setTempWinner('');
                    }, 10000);
                })();
            });
        })();
    }, []);

    useEffect(() => {
        (async () => {
            await initialize(true, true);
        })();
    }, [wallet?.publicKey]);

    useEffect(() => {
        const bar: any = document.querySelector(".shuffle-countdown-bar");
        document.documentElement.style.setProperty("--initial", "30000");

        console.log("endTime", endTime);

        const remainingTime = endTime * 1000 - Date.now() - timeDifference;
        if (remainingTime > 0) {
            document.documentElement.style.setProperty(
                "--duration",
                remainingTime.toString()
            );
            bar.classList.remove("shuffle-countdown-bar");
            const offsetWidth = bar.offsetWidth;
            bar.classList.add("shuffle-countdown-bar");
        } else {
            document.documentElement.style.setProperty("--duration", "0");
        }

    }, [endTime]);

    return (
        <div className="flex flex-col md:flex-row justify-center min-h-full h-fit px-[5px]">
            <GameStatsBar
                position="left"
                currentUsersInfo={statsBarData}
                className="hidden md:block"
            />

            <div className="flex flex-col md:grow bg-[#090C0E]">
                {/* Countdown bar */}
                <div className="shuffle-countdown-bar rounded-[5px] pt-[1px] pb-[1px] md:rounded-[10px] md:pt-[1px] md:pb-[2px] relative">
                    <div className="progress-bar h-[23px] rounded-[5px_9999px_9999px_5px] md:h-[33px] md:rounded-[10px_9999px_9999px_10px]"></div>
                    <div className="absolute w-full h-full top-0 left-0 flex justify-center items-center gap-[5px]">
                        <span>{'Starting in: '}</span>
                        {totalUserData.length >= 2 && (endTime * 1000 - Date.now() - timeDifference) > 0 ? (
                            <div className="w-[20px]">
                                <Countdown
                                    date={endTime * 1000 - timeDifference}
                                    intervalDelay={0}
                                    precision={1}
                                    renderer={(time: any) => (
                                        <h3>
                                            {`${time.seconds.toString().padStart(2, "0")}.${time.milliseconds / 100}`}
                                        </h3>
                                    )}
                                    className="w-50px"
                                />
                            </div>
                        ) : (
                            <span>{"00.0"}</span>
                        )}
                    </div>
                </div>

                {/* Shuffle body */}
                <div className="flex flex-col items-center grow fade-in">
                    {/* Last winner */}
                    <div className="w-[353px] md:w-[323px] wider-winner winner md:pl-[18px] pt-[10px] pb-[11px] md:pb-[13px] mt-[17px] md:mt-[27px] bg-[#21272C] rounded-[5px] relative fade-in">
                        <div className="flex justify-center md:justify-between w-full">
                            <div className="last-winner-mobile flex flex-col items-center absolute top-[5px] left-[15px] md:relative">
                                <h6 className="last-winner-text pl-extra">Last Winner:</h6>
                                <div className="pl-extra">
                                    <div className="w-[40px] h-[40px] mt-[12px] hidden md:block">
                                        <img
                                            src={lastWinnerAvatar || blankUserIcon}
                                            className="w-full h-full rounded-full"
                                            alt="avatar"
                                        />
                                    </div>
                                </div>
                                <h5 className="text-[12px] md:text-[14px] font-medium leading-[14px] md:leading-[20px] mt-[4px] md:mt-[6px] pl-extra">
                                    {
                                        lastWinner && lastWinner !== PublicKey.default.toString() ? (
                                            lastWinner.slice(0, 3) + "..." + lastWinner.slice(-3)
                                        ) : (
                                            "-"
                                        )
                                    }
                                </h5>
                            </div>

                            <div className="flex flex-col items-center px-[35px]">
                                <div className="text-center mt-[5px] hidden md:block">
                                    <h6 className="text-[12px] md:text-[14px] text-[#FFFFFF] font-semibold leading-[18px] md:leading-[21px]">Win Chance:</h6>
                                    <a
                                        href={createTwitterShareUrl(`Congratulations!! ${lastWinner} just won with a ${lastWinner && lastWinner !== PublicKey.default.toString() ? `${((lastWinnerBettingAmount / lastBettingAmount) * 100).toFixed(2)}%` : ''} win chance for a total pot of ${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((lastBettingSolAmount + (lastBettingAmount - lastBettingSolAmount)) / LAMPORTS_PER_SOL)} $SOL! Join the #BiteClub today with Solana's best on chain shuffle! @degen_games_sol only found @ play.biteclub.io/`)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="cursor-pointer absolute top-1 right-0 mr-1 -translate-y-[-1]">
                                        <img
                                            src={ShareIcon}
                                            alt="Share"
                                            className="w-[30px] h-[30px] hover:scale-105 transition-transform duration-200 ease-in-out" />
                                    </a>
                                    <h5 className="text-[16px] text-[#C19C37] font-semibold leading-[24px]">
                                        {
                                            lastWinner && lastWinner !== PublicKey.default.toString() ? (
                                                `${((lastWinnerBettingAmount / lastBettingAmount) * 100).toLocaleString()}%`
                                            ) : (
                                                "-"
                                            )
                                        }
                                    </h5>
                                </div>
                                <div className="flex items-center gap-[15px] md:gap-[31px] mt-[12px] md:mt-[7px]">
                                    <div className="text-center md:hidden">
                                        <h6 className="text-[12px] md:text-[14px] text-[#FFFFFF] font-medium leading-[18px] md:leading-[21px] no-wrap">Win Chance:</h6>
                                        <a
                                            href={createTwitterShareUrl(`Congratulations!! ${lastWinner} just won a pot with a ${lastWinner && lastWinner !== PublicKey.default.toString() ? `${((lastWinnerBettingAmount / lastBettingAmount) * 100).toFixed(2)}%` : ''} win chance for a total of ${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((lastBettingSolAmount + (lastBettingAmount - lastBettingSolAmount)) / LAMPORTS_PER_SOL)} $SOL! Join the #BiteClub pack today with Solana's best on chain shuffle @degen_games_sol only found @ play.biteclub.io/`)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="cursor-pointer absolute top-1 right-0 mr-1 -translate-y-[-1]">
                                            <img
                                                src={ShareIcon}
                                                alt="Share"
                                                className="w-[30px] h-[30px] hover:scale-105 transition-transform duration-200 ease-in-out" />
                                        </a>
                                        <h5 className="text-[16px] text-[#C19C37] font-semibold leading-[24px]">
                                            {
                                                lastWinner && lastWinner !== PublicKey.default.toString() ? (
                                                    `${((lastWinnerBettingAmount / lastBettingAmount) * 100).toLocaleString()}%`
                                                ) : (
                                                    "-"
                                                )
                                            }
                                        </h5>
                                    </div>

                                    <div className="text-center">
                                        <h6 className="text-[12px] md:text-[14px] text-[#FFFFFF] font-medium leading-[18px] md:leading-[21px] no-wrap">Sol Won:</h6>
                                        <h5 className="text-[17px] text-[#46ff78] font-semibold leading-[26px]">
                                            {(lastBettingSolAmount / LAMPORTS_PER_SOL).toLocaleString()}
                                        </h5>
                                    </div>

                                    <div className="text-center">
                                        <h6 className="text-[12px] md:text-[14px] text-[#FFFFFF] font-medium leading-[18px] md:leading-[21px] no-wrap">NFT Value Won:</h6>
                                        <h5 className="text-[17px] text-[#46ff78] font-semibold leading-[26px]">
                                            {((lastBettingAmount - lastBettingSolAmount) / LAMPORTS_PER_SOL).toLocaleString()}
                                        </h5>                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Spin */}
                    <div className="w-[220px] md:w-[390px] aspect-square mt-[15px] md:mt-[25px] rounded-full p-[2.8px] md:p-[5px] relative">
                        <div className="absolute flex justify-center w-full left-0 top-[-14px] md:top-[-18px]">
                            <img src={triangleDownIcon} alt="triangle-icon" className="w-[25.6px] md:w-[36.8px] z-10" />
                        </div>
                        <div
                            className="absolute"
                            style={{
                                width: "calc(100% - 2px)",
                                height: "calc(100% - 2px)",
                                borderRadius: "50%",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1), inset 0px 4px 8px rgba(0, 0, 0, 0.1)",
                                background: "linear-gradient(90deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%)",
                                border: "2px solid #808080", // Reduce the border width to 2px
                                top: "1px",
                                left: "1px"
                            }}
                        >
                            <div
                                className={`flex justify-center items-center wheel`}
                                style={{
                                    background: background,
                                    transform: `rotate(${rotationAngle}deg)`,
                                    padding: "8px",
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "50%"
                                }}
                            >
                                <div className="w-[160px] md:w-[280px] aspect-square rounded-full p-[2.8px] md:p-[5px] bg-[#090C0E]"></div>
                            </div>
                        </div>
                        <div className="flex justify-center items-center w-full aspect-square text-center absolute left-0 top-0">
                            <div className="w-[155px] md:w-[270px] aspect-square rounded-full center-gradient-bg mx-auto">
                                <h5 className="text-[12px] md:text-[14px] text-[#808080] font-extrabold leading-[18px] md:leading-[21px] pt-[16px] md:pt-[36px]">Players:</h5>
                                <h3 className="text-[20px] md:text-[24px] font-extrabold leading-[20px] md:leading-[20px]">{totalUserData.length}</h3>
                                <h5 className="text-[12px] md:text-[14px] text-[#808080] font-extrabold leading-[18px] md:leading-[21px] pt-[15px] md:pt-[35px] pb-[10px]">Total Value:</h5>
                                <div className="flex justify-center align-center">
                                    <h1 className="font-orbitron text-[16px] md:text-[40px] text-[#46ff78] font-extrabold leading-[20px] md:leading-[35px]">{`${(totalBetAmount / LAMPORTS_PER_SOL).toLocaleString()} `}</h1>
                                    <img className="solanaicon" />
                                </div>


                            </div>
                        </div>
                    </div>

                    {/* Winning chance */}
                    <div className="flex flex-col items-center w-[115px] md:w-[209px] mt-[8px] md:mt-[25px]">
                        <div className="flex justify-between items-center w-full">
                            <span className="text-[8px] md:text-[10px] text-[#808080] font-medium leading-[11px] md:leading-[14px]">Low</span>
                            <span className="text-[8px] md:text-[10px] text-[#808080] font-medium leading-[11px] md:leading-[14px]">Medium</span>
                            <span className="text-[8px] md:text-[10px] text-[#808080] font-medium leading-[11px] md:leading-[14px]">High</span>
                        </div>

                        <div className="w-full md:w-full h-[8px] md:h-[16px] rounded-[2.5px] md:rounded-[5px] bg-[#BEBEBE] overflow-hidden">
                            <div
                                className="h-full winning-chance-bar"
                                style={{ width: `${winningChance}%` }}
                            />
                        </div>

                        <div className="mt-[2px] md:mt-[12px] text-[12px] md:text-[22px] text-center font-medium leading-[17px] md:leading-[32px]">
                            <span className="text-[#808080]">{'Your Win Chance: '}</span>
                            <span style={{ display: 'block' }}>{`${totalBetAmount ? winningChance.toFixed(2) : 0}%`}</span>                        </div>
                    </div>

                    {/* Control buttons */}
                    <div className="w-full flex flex-wrap justify-center items-center gap-[10px] md:gap-[20px] mt-[8px] md:mt-[22px]">
                        <button
                            disabled={ended}
                            className={`
                                    flex justify-center items-center gap-2 sol-nft-button sol-enter-btn w-[180px] h-[36px] md:w-[340px] md:h-[70px] text-[12px] md:text-[24px] font-extrabold leading-[18px] md:leading-[36px] 
                                    ${(ended) ? 'opacity-30' : 'opacity-100'}
                                `}
                            onClick={() => handleShowSolModal(true)}
                            onMouseEnter={() => playHover()}
                        >
                            ENTER SOL
                        </button>
                        <button
                            disabled={ended}
                            className={`
                                    flex justify-center items-center gap-2 sol-nft-button nft-enter-btn w-[180px] h-[36px] md:w-[340px] md:h-[70px] text-[12px] md:text-[24px] font-extrabold leading-[18px] md:leading-[36px] 
                                    ${(ended) ? 'opacity-30' : 'opacity-100'}
                                `}
                            onClick={() => handleShowNftModal(true)}
                            onMouseEnter={() => playHover()}
                        >
                            ENTER NFT
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile game stats */}
            <div className="grow w-full flex justify-center gap-[10px] bg-[#090C0E] pt-[24px] pb-[60px] md:hidden">
                <GameStatsBar
                    position="left"
                    currentUsersInfo={statsBarData}
                />
                <GameStatsBar
                    position="right"
                    currentUsersInfo={statsBarData}
                />
            </div>

            <GameStatsBar
                position="right"
                currentUsersInfo={statsBarData}
                className="hidden md:block"
            />

            {/* Modal to enter SOL */}
            <Slide top duration={500} when={showSolModal || showNftModal}>
                <div
                    className="fund-modal fixed top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] sm:bg-black/50 z-[1040] overflow-y-auto hidden justify-center"
                    style={{ display: showSolModal ? "flex" : "none" }}
                >
                    <div className="flex m-0 sm:m-4 w-full min-h-[calc(100vh-6rem)] justify-center items-start sm:items-center">
                        <div className="modal-wrapper !mt-[59px] !p-[30px_35px_0px] sm:!p-[30px_35px_30px] !max-w-full sm:!max-w-fit !shadow-none sm:!shadow-[0px_0px_10px_#00a5fe] border-b-2 border-solid border-[#00A5FE] sm:border-b-0 !rounded-tr-none !rounded-tl-none sm:!rounded-[7px]">
                            <button
                                className="sm:flex justify-center items-center absolute top-0 right-0 p-[10px] cursor-pointer bg-none border-none rounded-[6px] transform hover:scale-110 transition-all"
                                onClick={() => handleShowSolModal(false)}
                                onMouseEnter={() => playHover()}
                            >
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 16L16 1M1 1L16 16" stroke="#BEBEBE" strokeWidth="2" />
                                </svg>
                            </button>
                            <h1 className="font-semibold text-[26px] leading-[41px] mt-0 w-full text-center text-white">Enter SOL</h1>
                            <h5 className="text-green-500 mt-0">{`You have ${solBalance.toLocaleString()} SOL`}</h5>
                            <div className="w-full mt-[25px]">
                                <input
                                    autoFocus
                                    type="number"
                                    value={betSolAmount == null ? "" : betSolAmount}
                                    className="bg-[#1D262F] rounded-[10px] w-full h-[37px] text-[18px] font-medium pl-[19px] outline-none"
                                    onChange={(event) => handleSetBetSolAmount(event.target.value)}
                                />
                            </div>

                            <button
                                disabled={isProcessing}
                                className="flex justify-center items-center gap-[10px] gradient-btn full-rounded min-w-[120px] h-[40px] px-[26px] text-[20px] mt-[35px] mb-[10px] sm:mb-0 transition duration-300 hover:transform hover:scale-110 opacity-100" onClick={handleBetSol} onMouseEnter={() => playHover()}
                            >
                                {
                                    isProcessing ? (
                                        <>
                                            <span>Processing</span>{" "}
                                            <ClipLoader size={18} color="#ffffff" />
                                        </>
                                    ) : (
                                        "Submit"
                                    )
                                }
                            </button>
                        </div>
                    </div>
                    <div className="overlay"></div>
                </div>

                {/* Modal to enter NFT */}
                <div
                    className="fixed top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] bg-black/50 z-[1040] overflow-y-auto hidden justify-center mobile-height"
                    style={{ display: showNftModal ? "flex" : "none" }}
                >
                    <div className="flex justify-center items-center w-full h-full py-[4rem] md:py-[5rem]">
                        <div className="flex flex-col items-center max-w-[900px] max-h-[73%] w-full h-full mx-[2rem] rounded-[10px] font-semibold p-[36px_30px_30px] flex-1 text-center bg-[#090C0E] shadow-[0px_0px_7px_#00A5FE] relative z-[1050]">
                            <button
                                className="sm:flex justify-center items-center absolute top-0 right-0 p-[10px] cursor-pointer bg-none border-none rounded-[6px] transform hover:scale-110 transition-all" onClick={() => handleShowNftModal(false)} onMouseEnter={() => playHover()}
                            >
                                <svg width="14" height="14" className="fill-white transition-[fill_200ms_ease_0s]">
                                    <path d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z"></path>
                                </svg>
                            </button>

                            <div className="w-full">
                                <h1 className="font-semibold text-[26px] leading-[41px] mt-0 w-full text-center text-white">Choose NFTs</h1>
                            </div>

                            <div className="grow w-full h-[300px] mt-[25px] overflow-x-hidden overflow-y-auto no-scroll-bar">
                                {
                                    !isLoading ? (
                                        nftList.length > 0 ? (
                                            <div className="flex flex-wrap justify-center sm:justify-start gap-[35px] w-full">
                                                {
                                                    nftList.map((item, index) => {
                                                        return (
                                                            <div key={index} className="flex-none w-[250px] sm:w-[240px]">
                                                                <div
                                                                    className={`nft-img-container ${selectedNftIndex == index ? 'active' : ''}`}
                                                                    onClick={() => setSelectedNftIndex(index)}
                                                                    onMouseEnter={() => playHover()}
                                                                >
                                                                    <div className="img-container">
                                                                        {
                                                                            item?.howrareRank && (
                                                                                <h4 className="howrare-rank">
                                                                                    <svg width="10" height="10" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" color="#f5f3f7" className="tw-mr-[-1px]">
                                                                                        <path d="M37.7729 31.7942C40.4532 30.6818 42.6963 28.8329 44.1958 26.5C45.6765 24.1601 46.4392 21.4923 46.4021 18.7827C46.4695 16.8712 46.113 14.9671 45.3546 13.1873C44.5962 11.4075 43.4518 9.78957 41.9917 8.43269C39.05 5.67243 34.7444 4.29231 29.075 4.29231H18.95V0H7.00208V4.29423H0V14.4077H7.00208V23.8692H0V33.5096H7.00208V48H18.9542V33.5096H26.0104L34.3208 48H48L37.7729 31.7942ZM33.4896 21.5635C33.0215 22.3787 32.2899 23.0391 31.3964 23.4527C30.5029 23.8663 29.4919 24.0125 28.5042 23.8712H18.9542V14.4096H28.9542C29.8679 14.2828 30.8017 14.4296 31.6184 14.8287C32.435 15.2278 33.0916 15.858 33.4917 16.6269C33.9526 17.3803 34.1949 18.2309 34.1949 19.0962C34.1949 19.9614 33.9526 20.812 33.4917 21.5654" fill="#f5f3f7"></path>
                                                                                    </svg>
                                                                                    {item?.howrareRank}
                                                                                </h4>
                                                                            )
                                                                        }
                                                                        <img src={item?.image} alt={item?.name} />
                                                                        {
                                                                            item?.floorPrice && (
                                                                                <h4 className="fp-text">
                                                                                    {`FP: ${(item?.floorPrice / LAMPORTS_PER_SOL).toLocaleString()}`}
                                                                                </h4>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className="nft-name">
                                                                        <span>{item?.name}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        ) : (
                                            <h3>You have no items</h3>
                                        )
                                    ) : (
                                        <h3 className="flex justify-center items-center gap-[10px]">
                                            Loading your NFTs from your wallet. Please hold... <ClipLoader size={24} color="#ffffff" className="flex-none" />
                                        </h3>
                                    )
                                }
                            </div>

                            <div className="flex justify-center w-full">
                                <button
                                    disabled={isLoading || isProcessing}
                                    className="flex justify-center items-center gap-[10px] gradient-btn full-rounded min-w-[120px] h-[40px] px-[26px] text-[20px] mt-[35px] transition duration-300 hover:transform hover:scale-110 opacity-100"
                                    onClick={handleBetNft}
                                    onMouseEnter={() => playHover()}
                                >
                                    {
                                        isProcessing ? (
                                            <>
                                                <span>Processing</span>{" "}
                                                <ClipLoader size={18} color="#ffffff" />
                                            </>
                                        ) : (
                                            "Submit"
                                        )
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="overlay"></div>
                </div>
            </Slide>
        </div>
    );
};

export default SolShuffle;