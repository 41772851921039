import React, { useEffect, useRef } from "react";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { ShuffleBetTypeEnum } from "../../enums";
import { IShuffleData } from "../../interfaces/ShuffleData";
import UserGroupICon from "../svgs/UserGroupIcon";
import SolanaIcon from "../svgs/SolanaIcon";
import UserIcon from "../svgs/UserIcon";
import useSound from "use-sound";

interface IProps {
    position?: string;
    currentUsersInfo?: IShuffleData[] | undefined;
    winningPubkey?: string;
    isCompleted?: boolean;
    isMobile?: boolean;
    className?: string
}

const GameStatsBar = (props: IProps) => {
    const { position, currentUsersInfo, winningPubkey, isCompleted, isMobile, className } = props;
    const color = position === "left" ? "#00A5FE" : "#EDC967";
    const textColor = position === "left" ? "text-[#00A5FE]" : "text-[#EDC967]";

    const itemFilter = position == "left" ? ShuffleBetTypeEnum.SOL : ShuffleBetTypeEnum.NFT;
    const filteredUsersInfo = (currentUsersInfo || []).filter((x) => x.betType == itemFilter);
    const prevFilteredUsersInfoLengthRef = useRef<number>();
    const [playNewBetSound] = useSound('/sound/pop.mp3', { volume: 1 });

    useEffect(() => {
        if (
          prevFilteredUsersInfoLengthRef.current !== undefined &&
          filteredUsersInfo.length > prevFilteredUsersInfoLengthRef.current
        ) {
          playNewBetSound();
        }
    
        prevFilteredUsersInfoLengthRef.current = filteredUsersInfo.length;
      }, [filteredUsersInfo.length]);

    return (
        <div className={`flex flex-col min-w-[180px] md:min-w-[274px] md:z-20 ${className ?? ''}`}>
            <div className="py-[3px] h-full">
                <div className={`
                    h-full bg-[#11161B] rounded-[5px] 
                    ${position == 'left' ? 'shadow-[0px_0px_10px_#00A5FE]' : 'shadow-[0px_0px_10px_#EDC967]'} 
                `}>
                    <div className={`flex justify-between items-center w-full h-[36px] ${position == 'left' ? 'px-[14px]' : 'pl-[14px] pr-[50px]'} ${position == 'left' ? 'md:px-[22px]' : 'md:pl-[22px] md:pr-[58px]'} ${position}-header`}>
                        <div className="flex items-center gap-[9px]">
                            <UserGroupICon color={color} />
                            <span className={`text-[10px] md:text-[16px] font-medium leading-[15px] md:leading-[23px] ${textColor}`}>
                                {filteredUsersInfo.length}
                            </span>
                        </div>

                        <div className="flex items-center gap-[5px]">
                            <span className={`text-[10px] md:text-[16px] font-medium leading-[15px] md:leading-[23px] ${textColor}`}>
                                {(filteredUsersInfo.reduce((total, obj) => total + obj.betAmount, 0) / LAMPORTS_PER_SOL).toLocaleString()}
                            </span>
                            {
                                position == "left" && (
                                    <SolanaIcon/>
                                )
                            }
                        </div>
                    </div>

                    <div className={`grow pl-[19px] md:pl-[27px] ${position == 'left' ? 'pr-[44px]' : 'pr-[15px]'} ${position == 'left' ? 'md:pr-[52px]' : 'md:pr-[23px]'}`}>
                        {
                            filteredUsersInfo && filteredUsersInfo.map((item, index) => {
                                return (
                                    <div key={index} className="flex justify-between items-center w-full mt-[10px]">
                                        <div className="flex items-center gap-[6px]">
                                            <UserIcon color={item.color} />
                                            <span
                                                className={`text-[10px] md:text-[16px] font-medium leading-[15px] md:leading-[23px]`}
                                                style={{ color: item.color }}
                                            >
                                                {
                                                    isMobile
                                                        ?
                                                        String(item.pubkey).substring(0, 2) +
                                                        "..." +
                                                        String(item.pubkey).substring(42)
                                                        :
                                                        String(item.pubkey).substring(0, 4) +
                                                        "..." +
                                                        String(item.pubkey).substring(40)
                                                }
                                            </span>
                                        </div>

                                        <div className="flex items-center gap-[10px]">
                                            <span
                                                className={`text-[10px] md:text-[16px] font-medium leading-[15px] md:leading-[23px]`}
                                                style={{ color: item.color }}
                                            >
                                                {((item?.betAmount || 0) / LAMPORTS_PER_SOL).toLocaleString()}
                                            </span>

                                            {
                                                position !== "left" && (
                                                    <div className="w-[25px] aspect-square">
                                                        <img src={item?.imgUrl} alt="nft" className="w-full aspect-square" />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>

        </div>
    );
};

export default GameStatsBar;