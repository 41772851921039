import React, { useEffect, useState } from "react";
import axios from "axios";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { shortName } from "../../utils";
import { TimeFilterTypeEnum } from "../../enums";
import { IBiggestWinner } from "../../interfaces/Home";
import solSymbolLightGrayIcon from "../../assets/sol-symbol-light-gray.svg";
import CustomSlider from './CustomSlider';
import './index.scss';

const Home = () => {
    const [totalGamesPlayed, setTotalGamesPlayed] = useState<number>(0);
    const [totalAmountWagered, setTotalAmountWagered] = useState<number>(0);
    const [uniqueWallets, setUniqueWallets] = useState<number>(0);
    // const [solRewarded, setSolRewarded] = useState<number>(0);
    const [biggestWinner, setBiggestWinner] = useState<IBiggestWinner | null>(null);

    const initialize = async () => {
        try {
            const config = {
                params: {
                    timeFilterType: TimeFilterTypeEnum.All,
                },
            };

            const [leaderboardInfoRes, statisticsInfoRes] = await Promise.all([
                axios.get("/api/leaderboard", config),
                axios.get("/api/leaderboard/statistics")
            ]);

            const [leaderboardInfo, statisticsInfo] = [leaderboardInfoRes.data, statisticsInfoRes.data];

            const userRankInfoRes: any[] = leaderboardInfo.userRankInfo;
            // const dailyRewardInfo: any[] = leaderboardInfo.dailyRewardInfo;

            // const totalRewardedSol = dailyRewardInfo.reduce((sum, obj) => sum + obj.amount, 0);

            setTotalGamesPlayed(statisticsInfo.totalGamesPlayed);
            setTotalAmountWagered(statisticsInfo.totalAmountWagered);
            setBiggestWinner(statisticsInfo.biggestWinner);
            // setSolRewarded(Number(((totalRewardedSol / statisticsInfo.totalAmountWagered) * 100).toFixed(2)));
            setUniqueWallets(userRankInfoRes.length);

            console.log("statisticsInfo", statisticsInfo);
        } catch { }
    }

    useEffect(() => {
        initialize();
    }, []);

    return (
        <div className="flex flex-col w-full h-[calc(100vh-59px)] md:h-[calc(100vh-70px)] relative">
            <CustomSlider />

            <div className="smaller-screen-pad absolute left-0 bottom-[56px] md:bottom-[90px] flex justify-center items-center w-full z-10">
                <div className="w-full max-w-[1318px] px-[29px] md:px-0">
                    <div className="w-full main-box">
                        <h3 className="text-[14px] md:text-[24px] font-bold leading-normal tracking-[0.7px] md:tracking-[1.2px]">Statistics</h3>

                        <div className="flex flex-wrap justify-start md:justify-between items-center gap-x-[30px] gap-y-[12px] md:gap-x-[5px] md:gap-y-[5px] w-full mt-0 md:mt-[30px]">
                            {/* Game played */}
                            <div className="stat-box flex justify-center items-center w-[90px] md:w-[222px] h-[60px] md:h-[100px] border border-solid border-[#808080] bg-[rgba(128,128,128,0.30)] rounded-[10px]">
                                <div className="flex flex-col items-center">
                                    <div className="text-[12px] md:text-[24px] font-bold leading-normal tracking-[0.6px] md:tracking-[1.2px]">{totalGamesPlayed.toLocaleString()}</div>
                                    <div className="text-[8.5px] md:text-[14px] text-[#808080] text-center font-bold leading-normal tracking-[0.5px] md:tracking-[0.7px]">Total Games Played</div>
                                </div>
                            </div>

                            {/* SOL Wagered */}
                            <div className="stat-box flex justify-center items-center w-[90px] md:w-[222px] h-[60px] md:h-[100px] border border-solid border-[#808080] bg-[rgba(128,128,128,0.30)] rounded-[10px]">
                                <div className="flex flex-col items-center">
                                    <div className="flex justify-center items-center gap-[10px]">
                                        <div className="text-[12px] md:text-[24px] font-bold leading-normal tracking-[0.6px] md:tracking-[1.2px]">{(totalAmountWagered / LAMPORTS_PER_SOL).toLocaleString()}</div>
                                        <img src={solSymbolLightGrayIcon} alt="sol-symbol" className="w-[18px]" />
                                    </div>
                                    <div className="text-[8.5px] md:text-[14px] text-[#808080] text-center font-bold leading-normal tracking-[0.5px] md:tracking-[0.7px]">Total SOL Wagered</div>
                                </div>
                            </div>

                            {/* SOL rewarded to Players */}
                            <div className="stat-box flex justify-center items-center w-[90px] md:w-[222px] h-[60px] md:h-[100px] border border-solid border-[#808080] bg-[rgba(128,128,128,0.30)] rounded-[10px]">
                                <div className="flex flex-col items-center">
                                    <div className="flex flex-col md:flex-row justify-center items-center gap-0 md:gap-[10px]">
                                        <span className="text-[12px] md:text-[24px] text-[#808080]">
                                            {biggestWinner && shortName(biggestWinner?.username || biggestWinner?.publicKey)}
                                        </span>
                                        <div className="flex justify-center items-center gap-0 md:gap-[10px]">
                                            <div className="text-[12px] md:text-[24px] font-bold leading-normal tracking-[0.6px] md:tracking-[1.2px]">{((biggestWinner?.userProfit || 0) / LAMPORTS_PER_SOL).toLocaleString()}</div>
                                            <img src={solSymbolLightGrayIcon} alt="sol-symbol" className="w-[18px]" />
                                        </div>
                                    </div>
                                    <div className="text-[8.5px] md:text-[14px] text-[#808080] text-center font-bold leading-normal tracking-[0.5px] md:tracking-[0.7px]">Biggest Total Winner</div>
                                </div>
                            </div>

                            {/* Unique Wallets  played */}
                            <div className="stat-box flex justify-center items-center w-[90px] md:w-[222px] h-[60px] md:h-[100px] border border-solid border-[#808080] bg-[rgba(128,128,128,0.30)] rounded-[10px]">
                                <div className="flex flex-col items-center">
                                    <div className="text-[12px] md:text-[24px] font-bold leading-normal tracking-[0.6px] md:tracking-[1.2px]">{uniqueWallets}</div>
                                    <div className="text-[8.5px] md:text-[14px] text-[#808080] text-center font-bold leading-normal tracking-[0.5px] md:tracking-[0.7px]">Total Unique Users</div>
                                </div>
                            </div>

                            {/* Game played */}
                            <div className="stat-box flex justify-center items-center w-[90px] md:w-[222px] h-[60px] md:h-[100px] border border-solid border-[#808080] bg-[rgba(128,128,128,0.30)] rounded-[10px]">
                                <div className="flex flex-col items-center">
                                    <div className="smaller-text text-[12px] md:text-[24px] font-bold leading-normal tracking-[0.6px] md:tracking-[1.2px]">Coinflip</div>
                                    <div className="text-[8.5px] md:text-[14px] text-[#808080] text-center font-bold leading-normal tracking-[0.5px] md:tracking-[0.7px]">Most Popular Game</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;

