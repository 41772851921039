import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { Virtuoso } from "react-virtuoso";
import { InputNumber } from 'antd';
import { EditOutlined as EditOutlinedIcon, CheckOutlined as CheckOutlinedIcon } from '@mui/icons-material';
import { Connection, LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';
import * as anchor from "@project-serum/anchor";
import { AnchorProvider, Program } from '@project-serum/anchor';
import { Wallet } from '@project-serum/anchor/dist/cjs/provider';
import { CombinedReducer } from "../../store";
import User from "../../interfaces/User";
import { ICollectionInfo, ILoanBotOrderbook, ILogs, IUserOffer } from "../../interfaces/Bot";
import { getLoanEventTitle } from "../../utils";
import { Sharky } from './sharky-types';
import sharkyIdl from './sharky-idl.json';
import searchIcon from '../../assets/search.svg';
import groupImg from '../../assets/store-group-img.webp';
import axios from "axios";
import orderBooks from './order_books.json';

const Bot = () => {
    const wallet = useWallet();
    const navigate = useNavigate();
    const user = useSelector<CombinedReducer, User>((state) => state.user);

    const [collections, setCollections] = useState<ICollectionInfo[]>([]);
    const [registeredOrderbooks, setRegisteredOrderbooks] = useState<string[]>([]);
    const [logs, setLogs] = useState<ILogs[]>([]);
    const [searchWord, setSearchWord] = useState<string>('');
    const [selectedOrderbook, setSelectedOrderbook] = useState<string>('');
    const [selectedMaxLtv, setSelectedMaxLtv] = useState<number>(0);
    const [isSettingMaxLtv, setIsSettingMaxLtv] = useState<boolean>(false);
    const [height, setHeight] = useState<number>(50);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const connection = new Connection('https://mainnet.helius-rpc.com/?api-key=6bad2ffe-d003-11ed-afa1-0242ac120002', "confirmed");
    const sharkyProgramId = new PublicKey('SHARKobtfF1bHhxD2eqftjHBdVSCbKo9JtgK71FhELP');
    const provider = new AnchorProvider(connection, wallet as Wallet, { commitment: 'confirmed' });
    const program = new Program(sharkyIdl as anchor.Idl, sharkyProgramId, provider) as unknown as Program<Sharky>;

    const initialize = async (isInit?: boolean) => {
        try {
            if (!wallet?.publicKey) {
                return;
            }

            setIsLoading(true);

            const [userOffersRsp, floorPricesRsp, loanBotOrderbooksRsp, logsRsp] = await Promise.all([
                axios.get('/api/loan-bot/userOffers'),
                axios.get('/api/loan-bot/floorPrices'),
                axios.get('/api/loan-bot/loanBotOrderbooks'),
                axios.get('/api/loan-bot/logs'),
            ]);

            console.log({ userOffers: userOffersRsp.data });

            const defaultMaxLtv = 50;

            const userOffers = (userOffersRsp.data) as IUserOffer[];
            const floorPrices = floorPricesRsp.data?.floorPrices;
            const loanBotOrderbooks = (loanBotOrderbooksRsp.data) as ILoanBotOrderbook[];
            const registeredOrderbooks = loanBotOrderbooks.filter(x => x.isActive).map(x => x.orderbook);
            const logs = logsRsp.data as ILogs[];

            const collections: ICollectionInfo[] = [];

            await Promise.all(Object.entries(orderBooks).map(async (item) => {
                const [orderBookName, orderBookInfo] = item;
                if (orderBookInfo.enabled) {
                    if (logs.find(x => x.orderbook == orderBookInfo.pubkey)) {
                        const allMatches = logs.filter(x => x.orderbook == orderBookInfo.pubkey);
                        for (let i = 0; i < allMatches.length; i++) {
                            allMatches[i].orderbookName = orderBookName;
                        }
                    }

                    const floorPrice = floorPrices ? (floorPrices[orderBookName]?.floorPriceSol || 0) : 0;

                    const openOffers = userOffers.filter(loan => loan.loanStatus !== "Offer" && loan.orderBook == orderBookInfo.pubkey);
                    const takenOffers = userOffers.filter(loan => loan.loanStatus !== "Taken" && loan.orderBook == orderBookInfo.pubkey);

                    const totalSolAllocated = userOffers.filter(loan => loan.orderBook == orderBookInfo.pubkey).reduce((sum, obj) => sum + obj.principalLamports, 0);
                    const isRegistered = registeredOrderbooks.includes(orderBookInfo.pubkey);

                    const rowData: ICollectionInfo = {
                        orderbook: orderBookInfo.pubkey,
                        orderbookName: orderBookName,
                        maxLtv: isRegistered ? (loanBotOrderbooks.find(x => x.orderbook == orderBookInfo.pubkey)?.maxLtv || defaultMaxLtv) : defaultMaxLtv,
                        fp: floorPrice,
                        offer: openOffers.length,
                        taken: takenOffers.length,
                        totalSolAllocated: totalSolAllocated,
                        isRegistered: isRegistered ? 1000000000 + totalSolAllocated : totalSolAllocated,
                    };

                    collections.push(rowData);
                }
            }));

            console.log('loanBotOrderbooks', loanBotOrderbooks);

            setCollections(collections.sort((a, b) => b.isRegistered - a.isRegistered));
            setRegisteredOrderbooks(registeredOrderbooks);
            setLogs(logs);
        } catch (e) {
            console.log({ e });
        }

        setIsLoading(false);
    }

    const handleStartOffering = async (orderbook: string, name: string) => {

        if (!wallet?.publicKey) {
            return toast.warn('Please connect wallet');
        }

        setSelectedOrderbook(orderbook);
        setIsProcessing(true);

        try {
            const data = {
                orderbook,
                name,
            };

            await axios.post('/api/loan-bot/startOffering', data);

            setRegisteredOrderbooks(current => {
                return [...current, orderbook];
            });
            toast.success('Successful to start offering.');
        } catch (e) {
            console.log('e: ', e);
            toast.success('Failed to start offering.');
        }

        setSelectedOrderbook('');
        setIsProcessing(false);
    }

    const handleStopOffering = async (orderbook: string) => {
        if (!wallet?.publicKey) {
            return toast.warn('Please connect wallet');
        }

        setSelectedOrderbook(orderbook);
        setIsProcessing(true);

        try {
            const data = { orderbook };

            await axios.post('/api/loan-bot/stopOffering', data);

            setRegisteredOrderbooks(current => {
                return [...current.filter(x => x !== orderbook)];
            });
            toast.success('Successful to stop offering.');
        } catch (e: any) {
            console.log('e: ', e);
            if (e?.response?.data) {
                toast.error(e.response.data.message.toString(), { autoClose: 5000 });
            } else {
                toast.success('Failed to stop offering.');
            }
        }

        setSelectedOrderbook('');
        setIsProcessing(false);
    }

    const handleSetMaxLtv = async (orderbook: string, orderBookName: string, maxLtv: number) => {
        if (!wallet?.publicKey) {
            return toast.warn('Please connect wallet');
        }

        if (!maxLtv) {
            return toast.warn('Invalid value');
        }

        try {
            const data = { orderbook, name: orderBookName, maxLtv };

            await axios.post('/api/loan-bot/setMaxLTV', data);

            setCollections(currentCollections => {
                currentCollections.find(x => x.orderbook == orderbook)!.maxLtv = maxLtv;
                return [...currentCollections];
            });

            setTimeout(() => {
                setSelectedOrderbook('');
                setIsSettingMaxLtv(false);
                setSelectedMaxLtv(0);

                toast.success('Successful to set max LTV');
            }, 500);
        } catch (e) {
            console.log('e: ', e);
            toast.error('Failed to set max LTV');
        }
    }

    useEffect(() => {
        const filteredCount = Object.entries(orderBooks).filter(x => x[1].enabled && x[0].toLowerCase().includes(searchWord.toLowerCase())).length;
        if (filteredCount >= 6) {
            setHeight(300);
        } else {
            setHeight(filteredCount * 50);
        }
    }, [searchWord]);

    useEffect(() => {
        initialize();
    }, [wallet?.publicKey]);

    useEffect(() => {
        if (user !== null) {
            if (!user?.isAdmin) {
                navigate("/");
            }
        }
    }, [user]);
    return (
        <div className="w-full flex justify-center">
            <div className="flex flex-col w-full max-w-[1280px] px-[5px] md:px-0">
                {/* Title & Description */}
                <div className="flex flex-col gap-[12px] md:gap-[16px] w-full max-w-[824px] mt-[19px] md:mt-[40px] px-[14px] md:px-0">
                    {/* Title */}
                    <div className="text-[16px] md:text-[36px] font-bold leading-[100%]">Automate loan offers on NFT collections</div>

                    {/* Description */}
                    <div className="text-[14px] md:text-[20px] font-normal leading-[100%]">Browse collections and set a MaxLTV ratio (%) the BiteClub agent will locate the best available loan on your behalf.</div>
                </div>

                {/* NFT Collections */}
                <div className="flex flex-col gap-[33px] w-full mt-[32px] md:mt-[58px]">
                    {/* Search input */}
                    <div className="flex items-center px-[14px] md:px-0">
                        <div className="flex justify-center items-center gap-[32px] md:gap-[58px] w-full md:max-w-[329px] h-[40px] md:h-[50px] bg-[#1A2129] border border-solid border-[#222C36] rounded-[5px] px-[14px]">
                            <div className="flex justify-center items-center w-[21px] h-[21px]">
                                <img src={searchIcon} className="w-full h-full" />
                            </div>

                            <input
                                value={searchWord}
                                placeholder="Search collections"
                                className="grow text-[14px] md:text-[18px] leading-[100%] placeholder:text-[#808080] bg-[#1A2129] border-none outline-none"
                                onChange={(e) => setSearchWord(e.target.value)}
                            />
                        </div>
                    </div>

                    {/* Collections table */}
                    <div className='w-full border border-solid border-[#222C36] rounded-[4px]'>
                        <div className='w-full flex items-center h-[40px] md:h-[50px]'>
                            <div className='w-[25%] md:w-[25%] h-full flex justify-start items-center text-[12px] md:text-[20px] font-bold px-[21px]'>Collection</div>
                            <div className='w-[12%] md:w-[12%] h-full flex justify-center items-center text-[12px] md:text-[20px] font-bold'>MaxLTV</div>
                            <div className='w-[12%] md:w-[12%] h-full flex justify-center items-center text-[12px] md:text-[20px] font-bold'>FP</div>
                            <div className='w-[12%] md:w-[12%] h-full flex justify-center items-center text-[12px] md:text-[20px] font-bold'>Taken</div>
                            <div className='w-[12%] md:w-[12%] h-full flex justify-center items-center text-[12px] md:text-[20px] font-bold'>Offer</div>
                            <div className='w-[12%] md:w-[12%] h-full flex justify-center items-center text-[12px] md:text-[20px] font-bold'>SOL</div>
                            <div className='w-[15%] md:w-[15%] h-full flex justify-center items-center text-[12px] md:text-[20px] font-bold'>Action</div>
                        </div>


                        <div
                            className='w-full flex flex-col bg-[#1A2129]'
                            style={{ height: `${height}px` }}
                        >
                            {
                                !isLoading ? (
                                    collections.length > 0 ? (
                                        <Virtuoso
                                            className="flex justify-center w-full h-full overflow-x-hidden thin-scroll-bar"
                                            data={collections.filter(item => item.orderbookName.toLowerCase().includes(searchWord.toLowerCase()))}
                                            itemContent={(index, rowData) => {
                                                return (
                                                    <div key={index} className='w-full flex items-center text-[10px] md:text-[18px] leading-[8px] md:leading-[16px]'>
                                                        <div className='w-[25%] md:w-[25%] h-[40px] md:h-[50px] flex items-center border-t border-t-[#222C36]'>
                                                            <div className='flex items-center gap-[5px] md:gap-[25px] w-full px-[5px] md:px-[21px]'>
                                                                <div className='flex justify-center items-center w-[26px] md:w-[46px]'>
                                                                    <img src={groupImg} alt='store-group-img' className='w-full rounded-full' />
                                                                </div>

                                                                <span>{rowData.orderbookName}</span>
                                                            </div>
                                                        </div>
                                                        <div className='w-[12%] md:w-[12%] h-[40px] md:h-[50px] flex justify-center items-center text-center font-normal border-t border-t-[#222C36]'>
                                                            {
                                                                isSettingMaxLtv && selectedOrderbook == rowData.orderbook ? (
                                                                    <div className="flex justify-center items-center gap-[5px]">
                                                                        <InputNumber
                                                                            className="w-[50px]"
                                                                            value={selectedMaxLtv}
                                                                            onChange={value => setSelectedMaxLtv(value || 0)}
                                                                        />
                                                                        <button
                                                                            className="flex justify-center items-center w-[12px] h-[12px] md:w-[20px] md:h-[20px]"
                                                                            onClick={() => handleSetMaxLtv(rowData.orderbook, rowData.orderbookName, selectedMaxLtv)}
                                                                        >
                                                                            <CheckOutlinedIcon sx={{ width: '100%', height: '100%' }} />
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <div className="flex justify-center items-center gap-[5px]">
                                                                        <span>{`${rowData.maxLtv.toLocaleString()}%`}</span>
                                                                        <button
                                                                            className="flex justify-center items-center w-[12px] h-[12px] md:w-[20px] md:h-[20px]"
                                                                            onClick={() => {
                                                                                setSelectedMaxLtv(rowData.maxLtv);
                                                                                setSelectedOrderbook(rowData.orderbook);
                                                                                setIsSettingMaxLtv(true);
                                                                            }}
                                                                        >
                                                                            <EditOutlinedIcon sx={{ width: '100%', height: '100%' }} />
                                                                        </button>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        <div className='w-[12%] md:w-[12%] h-[40px] md:h-[50px] flex justify-center items-center text-center font-normal border-t border-t-[#222C36]'>
                                                            {`${rowData.fp.toLocaleString()} SOL`}
                                                        </div>
                                                        <div className='w-[12%] md:w-[12%] h-[40px] md:h-[50px] flex justify-center items-center text-center font-normal border-t border-t-[#222C36]'>
                                                            {`${rowData.offer.toLocaleString()}`}
                                                        </div>
                                                        <div className='w-[12%] md:w-[12%] h-[40px] md:h-[50px] flex justify-center items-center text-center font-normal border-t border-t-[#222C36]'>
                                                            {`${rowData.taken.toLocaleString()}`}
                                                        </div>
                                                        <div className='w-[12%] md:w-[12%] h-[40px] md:h-[50px] flex justify-center items-center text-center font-normal border-t border-t-[#222C36]'>
                                                            {`${(rowData.totalSolAllocated / LAMPORTS_PER_SOL).toLocaleString()} SOL`}
                                                        </div>
                                                        <div className='w-[15%] md:w-[15%] h-[40px] md:h-[50px] flex justify-center items-center text-center font-normal border-t border-t-[#222C36]'>
                                                            {
                                                                registeredOrderbooks.includes(rowData.orderbook) ? (
                                                                    <button
                                                                        disabled={isProcessing}
                                                                        className="flex justify-center items-center gap-[10px] text-[10px] md:text-[18px] font-bold leading-normal w-full max-w-[90px] md:max-w-[155px] h-[24px] md:h-[34px] bg-[#012847] border border-solid border-[#00A5FE] rounded-[5px] shadow-[0px_0px_23px_-4.5px_#00A5FE] px-[11px] md:px-[18px]"
                                                                        onClick={() => handleStopOffering(rowData.orderbook)}
                                                                    >
                                                                        {
                                                                            !(isProcessing && selectedOrderbook == rowData.orderbook)
                                                                                ? <span className="text-red-500">Stop Offer</span>
                                                                                : <span>Processing...</span>
                                                                        }
                                                                        {isProcessing && selectedOrderbook == rowData.orderbook ? <ClipLoader size={15} color="#ffffff" /> : null}
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        disabled={isProcessing}
                                                                        className="flex justify-center items-center gap-[10px] text-[10px] md:text-[18px] font-bold leading-normal w-full max-w-[90px] md:max-w-[155px] h-[24px] md:h-[34px] bg-[#012847] border border-solid border-[#00A5FE] rounded-[5px] shadow-[0px_0px_23px_-4.5px_#00A5FE] px-[11px] md:px-[18px]"
                                                                        onClick={() => handleStartOffering(rowData.orderbook, rowData.orderbookName)}
                                                                    >
                                                                        {
                                                                            !(isProcessing && selectedOrderbook == rowData.orderbook)
                                                                                ? <span>Start Offer</span>
                                                                                : <span>Processing...</span>
                                                                        }
                                                                        {isProcessing && selectedOrderbook == rowData.orderbook ? <ClipLoader size={15} color="#ffffff" /> : null}
                                                                    </button>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        />
                                    ) : (
                                        <div className='w-full flex justify-center items-center text-[10px] md:text-[16px] text-[#808080]'>
                                            <div className='w-full h-[40px] md:h-[50px] flex justify-center items-center border-t border-t-[#222C36]'>
                                                <span>{wallet.publicKey ? 'No collections' : 'Please connect wallet'}</span>
                                            </div>
                                        </div>
                                    )
                                ) : (
                                    <div className='w-full flex justify-center items-center text-[10px] md:text-[16px] text-[#808080]'>
                                        <div className='w-full 4 h-[40px] md:h-[50px] flex justify-center items-center border-t border-t-[#222C36]'>
                                            <span>Loading...</span>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>

                {/* Logs */}
                <div className="flex flex-col gap-[9px] md:gap-[8px] w-full mt-[25px] md:mt-[44px] mb-[22px] md:mb-[48px]">
                    {/* Title */}
                    <div className="text-[14px] md:text-[20px] font-bold leading-normal px-[12px] md:px-0">Log</div>

                    <div className="flex flex-col gap-[5px] md:gap-[10px] w-full max-h-[294px] bg-[#090C0E] overflow-y-auto thin-scroll-bar p-[8px_11px] md:p-[18px_29px]">
                        {
                            logs.map((item, index) => {
                                return (
                                    <div key={index} className="flex flex-col md:flex-row items-start md:items-center gap-0 md:gap-[10px] text-[12px] md:text-[14px]">
                                        <div className="font-bold">{`${new Date(item.blockTime * 1000).toLocaleString()}  -- BiteClub Agent\\holder>`}</div>
                                        <div className="font-normal">{`${getLoanEventTitle(item.status)} at ${item?.orderbookName || item.orderbook} for ${item.offerPrice.toLocaleString()} Sol`}</div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Bot;