import React, { useEffect, useState } from 'react';
import { Connection, PublicKey } from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';
import * as anchor from "@project-serum/anchor";
import { AnchorProvider, Program } from '@project-serum/anchor';
import { Wallet } from '@project-serum/anchor/dist/cjs/provider';
import { wagerNfts } from '../../../utils/config';
import FundWagerNFT from './FundWagerNFT';
import wagerSystemIdl from "../../../idls/wager_system-idl.json";

const connection = new Connection(process.env.REACT_APP_RPC_URL!, "confirmed");
const programID = new PublicKey(wagerSystemIdl.metadata.address);

const StoreAdmin = () => {
    const wallet = useWallet();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [wagerNftVaultBalances, setWagerNftVaultBalances] = useState<number[]>(new Array(5).fill(0));

    const provider = new AnchorProvider(connection, wallet as Wallet, { commitment: 'processed' });
    const program = new Program(wagerSystemIdl as anchor.Idl, programID, provider);

    const initialize = async () => {
        setIsLoading(true);

        const wagerNftVaultBalances: number[] = [];
        await Promise.all(wagerNfts.map(async (wagerNft, index) => {
            try {
                const wagerNftVault = PublicKey.findProgramAddressSync(
                    [wagerNft.toBuffer()],
                    program.programId
                )[0];

                const wagerNftVaultBalance = await connection.getTokenAccountBalance(wagerNftVault);
                wagerNftVaultBalances[index] = wagerNftVaultBalance.value.uiAmount || 0;
            } catch {
                wagerNftVaultBalances[index] = 0;
            }
        }));

        setWagerNftVaultBalances(wagerNftVaultBalances);

        setIsLoading(false);
    }

    useEffect(() => {
        (async () => {
            await initialize()
        })()
    }, []);

    return (
        <div className="flex flex-col items-center w-full mt-[50px]">
            <h1 className="text-[20px] font-medium mt-5">Manage Store</h1>

            {
                wagerNfts.map((item, index) => {
                    return (
                        <FundWagerNFT
                            key={index}
                            index={index}
                            balance={wagerNftVaultBalances[index]}
                            isLoading={isLoading}
                            initialize={initialize}
                        />
                    )
                })
            }
        </div>
    );
};

export default StoreAdmin;