import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ShuffleAdmin from "./ShuffleAdmin";
import CoinflipOnchainAdmin from "./CoinflipOnchainAdmin";
import StoreAdmin from "./StoreAdmin";
import SolanaballAdmin from "./SolanaballAdmin";
import UserStatsAdmin from "./UserStatsAdmin";
import QuestsAdmin from "./QuestsAdmin";
import { CombinedReducer } from "../../store";
import User from "../../interfaces/User";
import BotConfigAdmin from "./BotConfigAdmin";
import ArenaEventAdmin from "./ArenaEventAdmin";
import MintSaleAdmin from "./MintSaleAdmin";
import NetworkStatusAdmin from "./NetworkStatusAdmin";

const Admin = () => {
    const navigate = useNavigate();
    const user = useSelector<CombinedReducer, User>((state) => state.user);

    useEffect(() => {
        if (user !== null) {
            if (!user?.isAdmin) {
                navigate("/");
            }
        }
    }, [user]);
    return (
        <div className="w-full flex flex-col items-center">
            {
                user && (
                    <>
                        {/* NetworkStatusAdmin */}
                        <NetworkStatusAdmin />

                        {/* ArenaEventAdmin */}
                        <ArenaEventAdmin />

                        {/* MintSaleAdmin */}
                        <MintSaleAdmin />

                        {/* BotConfigAdmin */}
                        <BotConfigAdmin />

                        {/* ShuffleAdmin */}
                        <ShuffleAdmin />

                        {/* CoinflipOnchainAdmin */}
                        <CoinflipOnchainAdmin />

                        {/* StoreAdmin */}
                        <StoreAdmin />

                        {/* SolanaballAdmin */}
                        <SolanaballAdmin />

                        {/* UserStatsAdmin */}
                        <UserStatsAdmin />

                        {/* QuestsAdmin */}
                        <QuestsAdmin />
                    </>
                )
            }
        </div>
    );
};

export default Admin;