import React, { useEffect, useState } from "react";
import { Button, InputNumber, Spin } from "antd";
import { toast } from "react-toastify";
import axios from "axios";
import { useWallet } from "@solana/wallet-adapter-react";
import { Connection, clusterApiUrl, PublicKey, Cluster, SystemProgram, Transaction } from "@solana/web3.js";
import { getAssociatedTokenAddress, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import * as anchor from "@project-serum/anchor";
import { Program, AnchorProvider } from "@project-serum/anchor";
import { Wallet } from '@project-serum/anchor/dist/cjs/provider';
import { IQuestPoolInfo } from "../../../interfaces/Quests";
import questsIdl from "../../../idls/quests-idl.json";
import wolvesNFtList from "../../../data/wolves.json";
import rareNftList from "../../../data/rare-nfts.json";
import { questInitialHealValue } from "../../../data";

const connection = new Connection(process.env.REACT_APP_RPC_URL!, "processed");
const programID = new PublicKey(questsIdl.metadata.address);
const bonesTokenMint = new PublicKey(process.env.REACT_APP_BONES_TOKEN_MINT!);

const QuestsAdmin = () => {
    const wallet = useWallet();
    const [isQuestsInitialized, setIsQuestsInitialized] = useState<boolean>(false);
    const [poolInfo, setPoolInfo] = useState<IQuestPoolInfo>();
    const [rewardTokenVaultBalance, setRewardTokenVaultBalance] = useState<number>(0);
    const [amount, setAmount] = useState<number>(0);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [isHealing, setIsHealing] = useState<boolean>(false);
    const [isWithdrawing, setIsWithdrawing] = useState<boolean>(false);

    async function getProvider() {
        const provider = new AnchorProvider(connection, wallet as Wallet, { commitment: 'processed' });
        return provider;
    }

    const initialize = async () => {
        try {
            const provider = await getProvider();
            const program = new Program(questsIdl as anchor.Idl, programID, provider);
            const poolPda = PublicKey.findProgramAddressSync(
                [Buffer.from('quests-pool')],
                program.programId
            )[0];

            const poolPdaInfo: any = await program.account.pool.fetch(poolPda);
            const poolInfo: IQuestPoolInfo = {
                authority: poolPdaInfo?.authority,
                rewardTokenMint: poolPdaInfo?.rewardTokenMint,
                rewardTokenVault: poolPdaInfo?.rewardTokenVault,
                stakedCount: poolPdaInfo?.stakedCount,
                createdAt: poolPdaInfo?.createdAt.toNumber()
            };

            const rewardTokenVaultBalance = (await connection.getTokenAccountBalance(poolInfo?.rewardTokenVault)).value.uiAmount || 0;
            setRewardTokenVaultBalance(rewardTokenVaultBalance);

            setPoolInfo(poolInfo);
            setIsQuestsInitialized(true);
        } catch (e) {
            console.log('e', e);
            setIsQuestsInitialized(false);
        }
    }

    const handleInitializeQuests = async () => {
        if (isQuestsInitialized) {
            return toast.warn("Already initialized");
        }

        try {
            setLoading(true);
            const provider = await getProvider();
            const program = new Program(questsIdl as anchor.Idl, programID, provider);

            const poolAccount = PublicKey.findProgramAddressSync(
                [Buffer.from('quests-pool')],
                program.programId
            )[0];

            const [poolSigner, poolSignerBump] = PublicKey.findProgramAddressSync(
                [poolAccount.toBuffer()],
                program.programId
            );

            const rewardTokenVault = PublicKey.findProgramAddressSync(
                [Buffer.from('quests-reward-token-vault')],
                program.programId
            )[0];

            const txh = await program.methods
                .initializePool(poolSignerBump)
                .accounts({
                    authority: wallet?.publicKey!,
                    pool: poolAccount,
                    poolSigner: poolSigner,
                    rewardTokenMint: bonesTokenMint,
                    rewardTokenVault: rewardTokenVault,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    rent: anchor.web3.SYSVAR_RENT_PUBKEY,
                    systemProgram: anchor.web3.SystemProgram.programId,
                })
                .rpc();

            console.log("txh: ", txh);
            await initialize();
            toast.success("Successful")
            setLoading(false);
            setIsQuestsInitialized(true);
        } catch (e: any) {
            console.log("error: ", e);
            setLoading(false);
            toast.error("Failed to initialize quests");
        }
    }

    const handleSetPowerNfts = async () => {
        if (!wallet || !wallet?.publicKey) {
            toast.warn("Please connect wallet");
            return;
        }

        const provider = await getProvider();
        const program = new Program(questsIdl as anchor.Idl, programID, provider);

        const poolAccount = PublicKey.findProgramAddressSync(
            [Buffer.from(anchor.utils.bytes.utf8.encode('quests-pool'))],
            program.programId
        )[0];

        let healedNfts: string[] = [];
        let transaction = new Transaction();
        let transactions: Transaction[] = [];

        let counter = 0;
        try {
            setIsHealing(true);

            await rareNftList.reduce(async (promise, item) => {
                await promise;

                const nftMint = new PublicKey(item.nft_mint);

                const nftInfoAccount = PublicKey.findProgramAddressSync(
                    [
                        Buffer.from('quests-nft-info-account'),
                        nftMint.toBuffer(),
                    ],
                    program.programId
                )[0];

                counter += 1;
                healedNfts.push(item.nft_mint);

                if (counter <= 5) {
                    const healAmount = item.hp;
                    transaction.add(
                        await program.methods
                            .setPowerNfts(
                                new anchor.BN(healAmount)
                            )
                            .accounts({
                                owner: wallet?.publicKey!,
                                pool: poolAccount,
                                nftMint: nftMint,
                                nftInfoAccount: nftInfoAccount,
                                rent: anchor.web3.SYSVAR_RENT_PUBKEY,
                                systemProgram: anchor.web3.SystemProgram.programId,
                            })
                            .instruction()
                    )
                }

                if (counter == 5) {
                    /* For raw transaction */
                    const latestBlockhash = await connection.getLatestBlockhash('confirmed');
                    transaction.feePayer = wallet.publicKey!;
                    transaction.recentBlockhash = latestBlockhash.blockhash;

                    transactions.push(transaction);
                    /* ------------------- */

                    transaction = new Transaction();
                    counter = 0;
                }

                if (transactions.length == 20) {
                    const signedTransactions = await wallet.signAllTransactions!(transactions);
                    console.log('signedTransactions', signedTransactions);

                    await Promise.all(signedTransactions.map(async (signedTransaction) => {
                        await connection.sendRawTransaction(signedTransaction.serialize());
                    }));

                    transactions = [];
                    healedNfts = [];
                }
            }, Promise.resolve());

            if (counter > 0) {
                /* For raw transaction */
                const latestBlockhash = await connection.getLatestBlockhash('confirmed');
                transaction.feePayer = wallet.publicKey!;
                transaction.recentBlockhash = latestBlockhash.blockhash;

                transactions.push(transaction);
                /* ------------------- */

                transaction = new Transaction();
                counter = 0;
            }

            if (transactions.length > 0) {
                const signedTransactions = await wallet.signAllTransactions!(transactions);
                console.log('signedTransactions', signedTransactions);

                await Promise.all(signedTransactions.map(async (signedTransaction) => {
                    await connection.sendRawTransaction(signedTransaction.serialize());
                }));

                transactions = [];
                healedNfts = [];
            }

            toast.success("Successful!");

            setIsHealing(false);
        } catch (e: any) {
            console.log("e: ", e);
            toast.error("Failed!");
            setIsHealing(false);
        }
    }

    const handleFunds = async () => {
        if (amount <= 0) {
            return toast.warn("Invaild amount");
        }

        try {
            setLoading(true);
            const provider = await getProvider();
            const program = new Program(questsIdl as anchor.Idl, programID, provider);

            const poolMangerTokenAccount = await getAssociatedTokenAddress(bonesTokenMint, wallet?.publicKey!);

            const poolAccount = PublicKey.findProgramAddressSync(
                [Buffer.from('quests-pool')],
                program.programId
            )[0];

            const [poolSigner, poolSignerBump] = PublicKey.findProgramAddressSync(
                [poolAccount.toBuffer()],
                program.programId
            );

            const rewardTokenVault = PublicKey.findProgramAddressSync(
                [Buffer.from('quests-reward-token-vault')],
                program.programId
            )[0];

            const txh = await program.methods
                .fundRewardTokens(new anchor.BN(amount * 10 ** 9))
                .accounts({
                    owner: wallet.publicKey!,
                    pool: poolAccount,
                    ownerRewardTokenAccount: poolMangerTokenAccount,
                    rewardTokenVault: rewardTokenVault,
                    tokenProgram: TOKEN_PROGRAM_ID,
                })
                .rpc();

            console.log("txh: ", txh);
            await initialize();
            toast.success("Successful")
            setLoading(false);
        } catch (e: any) {
            console.log("error: ", e);
            setLoading(false);
            toast.error("Failed to fund");
        }
    }

    const withdrawFunds = async () => {
        const amount = 225000;

        try {
            setIsWithdrawing(true);
            const provider = await getProvider();
            const program = new Program(questsIdl as anchor.Idl, new PublicKey('DfgN9iCs8jVJzSyrWedC6g3Lxt3gKB1cFf2pp9sPr5zW'), provider);

            const poolMangerTokenAccount = await getAssociatedTokenAddress(bonesTokenMint, wallet?.publicKey!);

            const poolAccount = PublicKey.findProgramAddressSync(
                [Buffer.from('quests-pool')],
                program.programId
            )[0];

            const [poolSigner, poolSignerBump] = PublicKey.findProgramAddressSync(
                [poolAccount.toBuffer()],
                program.programId
            );

            const rewardTokenVault = PublicKey.findProgramAddressSync(
                [Buffer.from('quests-reward-token-vault')],
                program.programId
            )[0];

            const txh = await program.methods
                .withdrawRewardTokens(new anchor.BN(amount * 10 ** 9))
                .accounts({
                    owner: wallet.publicKey!,
                    pool: poolAccount,
                    poolSigner: poolSigner,
                    ownerRewardTokenAccount: poolMangerTokenAccount,
                    rewardTokenVault: rewardTokenVault,
                    tokenProgram: TOKEN_PROGRAM_ID,
                })
                .rpc();

            console.log("txh: ", txh);
            await initialize();
            toast.success("Successful")
            setIsWithdrawing(false);
        } catch (e: any) {
            console.log("error: ", e);
            setIsWithdrawing(false);
            toast.error("Failed to withdraw");
        }
    }

    const downloadFile = async () => {
        try {
            setIsExporting(true);

            let holderList: any = {};

            const provider = await getProvider();
            const program = new Program(questsIdl as anchor.Idl, programID, provider);
            const stakedNftList: string[] = [];
            const userStakingAccounts: any[] = await program.account.userStakeInfo.all();
            await Promise.all(userStakingAccounts.map(async (item) => {
                try {
                    const userPubkey = item.account.userPubkey;
                    if (!holderList[userPubkey]) {
                        holderList[userPubkey] = {
                            mints: [],
                            amount: 1
                        };
                    } else {
                        holderList[userPubkey].amount += 1;
                    }

                    stakedNftList.push(item.account.nftMint.toString());
                } catch { }
            }))

            await Promise.all(wolvesNFtList.map(async (item) => {
                try {
                    if (!stakedNftList.includes(item)) {
                        const largestAccounts = await connection.getTokenLargestAccounts(
                            new PublicKey(item)
                        );
                        const largestAccountInfo = await connection.getParsedAccountInfo(
                            largestAccounts.value[0].address
                        );
                        const nftOwner = (largestAccountInfo.value?.data as any).parsed?.info?.owner;
                        if (nftOwner) {
                            if (!holderList[nftOwner]) {
                                holderList[nftOwner] = {
                                    mints: [],
                                    amount: 1
                                };
                            } else {
                                holderList[nftOwner].amount += 1;
                            }
                        }
                    }
                } catch { }
            }))

            const fileName = "nft-holder-list";
            const json = JSON.stringify(holderList, null, 2);
            const blob = new Blob([json], { type: "application/json" });
            const href = URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = href;
            link.download = fileName + ".json";
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);

            setIsExporting(false);
        } catch (e: any) {
            console.log("e", e);
            setIsExporting(false);
            toast.error("Failed to export holder list");
        }
    }

    useEffect(() => {
        (async () => {
            await initialize()
        })()
    }, []);

    return (
        <div className="flex flex-col items-center w-full mt-[50px] mb-[20px]">
            <h1 className="text-[20px] font-medium mb-1">Quests</h1>

            <Button
                type="primary"
                loading={isWithdrawing}
                onClick={() => withdrawFunds()}
                className="bg-[#1890ff]"
            >
                Withdraw
            </Button>

            <div className="w-full max-w-[1200px] px-[60px]">
                {
                    isQuestsInitialized && (
                        <div className="flex justify-center items-center">
                            <button
                                className='text-center underline'
                                onClick={downloadFile}
                            >
                                {isExporting ? <Spin /> : 'Export Holder List'}
                            </button>
                        </div>
                    )
                }

                {
                    !isQuestsInitialized && (
                        <div className="flex justify-center items-center w-full">
                            <Button
                                type="primary"
                                loading={isLoading}
                                onClick={() => handleInitializeQuests()}
                                className="flex justify-center items-center gradient-btn full-rounded p-[5px_15px] shadow-none"
                            >
                                Initialize
                            </Button>
                        </div>
                    )
                }

                {
                    isQuestsInitialized && poolInfo && (
                        <div className="w-full bg-[#090C0E] rounded-[10px] p-[20px_30px]">
                            <p><strong>Staked Count: </strong> {poolInfo?.stakedCount?.toString()}</p>
                            <p><strong>Reward Vault Balance: </strong> {rewardTokenVaultBalance}</p>

                            {/* Set power NFTs */}
                            <div className="py-[20px]">
                                <h1>Set Power NFTs</h1>

                                <Button
                                    type="primary"
                                    loading={isHealing}
                                    onClick={() => handleSetPowerNfts()}
                                    className="bg-[#1890ff] w-[160px] mt-[5px]"
                                >
                                    Submit
                                </Button>
                            </div>

                            <label>Fund Tokens to Reward Vault</label>
                            <div className="flex items-center mb-2">
                                <InputNumber
                                    min={0}
                                    className="w-full max-w-[600px]"
                                    value={amount}
                                    onChange={e => setAmount(Number(e))}
                                />
                                <Button
                                    type="primary"
                                    loading={isLoading}
                                    onClick={() => handleFunds()}
                                    className="bg-[#1890ff]"
                                >
                                    Fund
                                </Button>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default QuestsAdmin;