import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import TextareaAutosize from 'react-textarea-autosize';
import Countdown from 'react-countdown';
import EmojiPicker, { EmojiClickData, Theme } from 'emoji-picker-react';
import GifPicker, { Theme as GifTheme } from 'gif-picker-react';
import DOMPurify from 'dompurify';
import axios from "axios";
import MessageItem from "./MessageItem";
import { displayName, shortMessage, shortName } from "../../utils";
import { CombinedReducer } from "../../store";
import { Sockets } from "../../reducers/sockets";
import Message from "../../interfaces/Message";
import User from "../../interfaces/User";
import onlineUsersIcon from "../../assets/onlineusers.svg";
import sendIcon from "../../assets/send.svg";
import closeIcon from "../../assets/close.svg";
import livechatCloseIcon from "../../assets/livechat_close.svg";
import emojiIcon from "../../assets/emoji.svg";
import messageIcon from "../../assets/message.svg";
import arrowRightGrayIcon from "../../assets/arrow-right-gray.svg";
import useSound from "use-sound";

interface IProps {
    show?: boolean;
    handleShowLiveChat?: (status: boolean) => void;
}

const botPubkey = process.env.REACT_APP_BOT_PUBKEY;
const tenorApiKey = process.env.REACT_APP_TENOR_API_KEY;

const LiveChat = (props: IProps) => {
    const { pathname } = useLocation();
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
    const { show, handleShowLiveChat } = props;
    const dispatch = useDispatch();
    const messagesRef = useRef<null | HTMLDivElement>(null);
    const inputRef = useRef<null | HTMLTextAreaElement>(null);
    const emojiBoxRef = useRef<null | HTMLDivElement>(null);
    const emojiTriggerBtnRef = useRef<null | HTMLButtonElement>(null);
    const gifBoxRef = useRef<null | HTMLDivElement>(null);
    const gifTriggerBtnRef = useRef<null | HTMLButtonElement>(null);

    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState<Message[]>([]);
    const [favouriteMsgIds, setFavouriteMsgIds] = useState<string[]>([]);
    const [isScroll, setIsScroll] = useState<boolean>(true);
    const [isReply, setIsReply] = useState<boolean>(false);
    const [referenceMsgId, setReferenceMsgId] = useState<string>("");
    const [referenceUsername, setReferenceUsername] = useState<string>("");
    const [referenceMsg, setReferenceMsg] = useState<string>("");
    const [onlineUserCount, setOnlineUserCount] = useState<number>(0);
    const [showPl, setShowPl] = useState<boolean>(true);
    const [triggerScroll, setTriggerScroll] = useState(0);
    const [triggerEmojiBox, setTriggerEmojiBox] = useState<boolean>(false);
    const [showEmojiBox, setShowEmojiBox] = useState<boolean>(false);
    const [triggerGifBox, setTriggerGIfBox] = useState<boolean>(false);
    const [showGifBox, setShowGifBox] = useState<boolean>(false);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const user = useSelector<CombinedReducer, User>((state) => state.user);
    const sockets = useSelector<CombinedReducer, Sockets>((state) => state.sockets);

    const sendMessage = async () => {
        const cleanedMessage = DOMPurify.sanitize(message);
        // if (cleanedMessage != message) {
        //     return toast.warn('You are trying to put unsafe content!');
        // }

        if (message.length === 0) return toast.error('Message can not be empty');
        // if (/<[a-z][\s\S]*>/i.test(message)) {
        //     return toast.warn('You are trying to put unsafe content!');
        // }

        try {
            setIsScroll(true);
            let data: any = { content: message };
            if (isReply) {
                data = { ...data, isReply, referenceUsername, referenceMsg };
            }
            await axios.post('/api/message', data)

            if (isReply) {
                cancelReply();
            }
            dispatch({ type: 'UPDATE_USER_LAST_MESSAGE_AT' })
        } catch (e: any) {
            toast.error(e.response.data.message.toString())
        }

        setMessage('')
    }

    const sendGif = async (gifUrl: string) => {
        try {
            setIsScroll(true);
            setShowGifBox(false);
            let data: any = { content: gifUrl, isGif: true };
            await axios.post('/api/message', data)

            dispatch({ type: 'UPDATE_USER_LAST_MESSAGE_AT' })
        } catch (e: any) {
            toast.error(e.response.data.message.toString())
        }
    }

    const scrollMessagesToBottom = (isSmooth?: boolean) => {
        if (!messagesRef.current) return;

        if (isSmooth) {
            messagesRef.current.scroll({
                top: messagesRef.current.scrollHeight,
                behavior: 'smooth'
            })
        } else {
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
    };

    const triggerScrollToBottom = () => {
        setTriggerScroll((prevTriggerScroll) => prevTriggerScroll + 1);
    };

    const handleScrollDown = (offsetY: number) => {
        if (!messagesRef.current) return;

        messagesRef.current.scroll({
            top: messagesRef.current.scrollTop + offsetY,
            behavior: 'smooth'
        })
    };

    const inputMessage = (content: string) => {
        const contentWords = content.split(" ").map(c => c.trim())

        setMessage(contentWords.join(" "))
    }


    const handleStarMessage = async (messageId: string) => {
        try {
            setIsScroll(false);
            await axios.post("/api/message/starMessage", { messageId });
            if (favouriteMsgIds.includes(messageId)) {
                setFavouriteMsgIds(favouriteMsgIds.filter(item => item !== messageId));
            } else {
                setFavouriteMsgIds(current => {
                    return [...current, messageId];
                })
            }
        } catch (e: any) {
            console.log("e", e);
            toast.error("Failed");
        }
    }

    const handleSetReply = (msgId: string, username: string, message: string) => {
        setIsReply(true);
        setReferenceMsgId(msgId);
        setReferenceUsername(username);
        setReferenceMsg(message);
    }

    const cancelReply = () => {
        setIsReply(false);
        setReferenceMsgId("");
        setReferenceUsername("");
        setReferenceMsg("");
    }

    const handleTipping = async (receiverPubkey: string, tippingAmount: number, tippingMessage: string): Promise<boolean> => {
        try {
            const data = {
                receiverPubkey,
                tippingAmount,
                tippingMessage,
            };

            await axios.post('/api/gift/tipping', data);

            return true;
        } catch (e) {
            console.log('error in tipping ', e);
            toast.error("Failed to give a tip");

            return false;
        }
    }

    const emojiHandler = (emojiData: EmojiClickData, event: MouseEvent) => {
        setMessage(prevMsg => prevMsg + emojiData.emoji);
        setShowEmojiBox(false);

        if (inputRef) {
            inputRef?.current?.focus();
        }
    };

    const useMenuOutsideAlerter = () => {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (emojiBoxRef.current && emojiTriggerBtnRef.current && !emojiBoxRef.current.contains(event.target) && !emojiTriggerBtnRef.current.contains(event.target)) {
                    setShowEmojiBox(false);
                }

                if (gifBoxRef.current && gifTriggerBtnRef.current && !gifBoxRef.current.contains(event.target) && !gifTriggerBtnRef.current.contains(event.target)) {
                    setShowGifBox(false);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [emojiBoxRef, emojiTriggerBtnRef]);
    }

    useMenuOutsideAlerter();

    // useEffect(() => {
    //     if (pathname == "/rps" || pathname == "/dice") {
    //         setShowPl(true);
    //     } else {
    //         setShowPl(false);
    //     }
    // }, [pathname]);

    useEffect(() => {
        dispatch({ type: 'SET_LIVE_CHAT_EXPANDED_STATUS', payload: isExpanded });
    }, [isExpanded]);

    useEffect(() => {
        try {
            const getLast = async () => {
                setIsScroll(true);
                const messages = (await axios.get('/api/message/last')).data;
                console.log({ messages });

                setMessages(messages);
            };

            getLast();
        } catch { }
    }, []);

    useEffect(() => {
        if (isScroll) {
            scrollMessagesToBottom();
        }
    }, [messages, isScroll]);

    useEffect(() => {
        scrollMessagesToBottom(true);
    }, [triggerScroll]);

    useEffect(() => {
        if (user?.publicKey) {
            const getFavouriteMsgs = async () => {
                try {
                    const favouriteMsgs = (await axios.get('/api/message/favouriteMsgs')).data

                    const tempItems: any = [];
                    favouriteMsgs.map((item: any) => tempItems.push(item.message));

                    setFavouriteMsgIds(tempItems);
                } catch { }
            }

            getFavouriteMsgs()
        }
    }, [user?.publicKey]);

    useEffect(() => {
        if (!sockets?.message || !sockets?.user) return

        sockets.message.on('newMessage', (message: Message) => {
            setMessages(current => [message, ...current]);
        })

        sockets.user.on('updateOnlineUserCount', (count: number) => {
            setOnlineUserCount(count)
        })

        sockets.message.on('updateMessage', (message: Message) => {
            setMessages(current => {
                return current.map(item => {
                    if (item?._id == message?._id) {
                        return message;
                    }

                    return item;
                });
            })
        })
    }, [sockets])

    return (
        <div className={`flex-none ${isExpanded ? 'w-full xl:w-[336px]' : 'w-full md:w-[70px]'} transition-all duration-300 h-[calc(100vh-59px)] md:h-[calc(100vh-70px)] ${show ? '' : 'hidden'} xl:block`}>
            <div className={`w-full md:w-[336px] h-full bg-[#11161B] ${isExpanded ? 'block' : 'block xl:hidden'}`}>
                <div
                    className={`flex flex-col pl-[17px] pr-[7px] h-full py-[14px] ${showPl ? 'md:pl-[17px]' : 'md:pl-0'}`}>
                    <div className="relative pr-[7px] mt-[20px] sm:mt-0">
                        <div className="text-[24px] font-extrabold text-center">Live Chat</div>

                        {/* Online user count */}
                        <div className="absolute flex justify-start sm:justify-end items-center gap-1 w-full h-full top-0 sm:right-[12px] pl-[40px] sm:pl-0">
                            <img src={onlineUsersIcon} alt="onlineusers" />
                            <div className="text-[16px] text-[#46FF78]">{onlineUserCount}</div>
                        </div>

                        {/* Chat close button */}
                        <div
                            className="absolute flex justify-end items-center w-full h-full top-0 right-[40px] sm:hidden"
                        >
                            <button
                                onClick={() => handleShowLiveChat && handleShowLiveChat(false)}
                                onMouseEnter={() => playHover()}
                            >
                                <img src={livechatCloseIcon} alt="close" />
                            </button>
                        </div>

                        {/* Arrow right button */}
                        <div
                            className="absolute hidden sm:flex justify-start items-center w-full h-full top-0 left-0"
                        >
                            <button
                                onClick={() => {
                                    setIsExpanded(false);
                                    handleShowLiveChat && handleShowLiveChat(false);
                                }}
                                onMouseEnter={() => playHover()}
                            >
                                <img src={arrowRightGrayIcon} alt="arrow-right" />
                            </button>
                        </div>
                    </div>

                    <div ref={messagesRef} className="grow mt-[28px] mb-[18px] overflow-y-auto thin-scroll-bar pr-[7px]">
                        {
                            Array.isArray(messages) && messages.map((message, index) => {
                                return (
                                    <MessageItem
                                        key={index}
                                        messageId={message?._id}
                                        username={message?.creator?.username ?? message?.creator?.publicKey}
                                        publickey={message?.creator?.publicKey}
                                        userAvatar={message?.creator?.userAvatar}
                                        message={message?.content}
                                        timestamp={Math.floor((new Date(message?.createdAt)).getTime() / 1000)}
                                        isFavourite={favouriteMsgIds.includes(message?._id)}
                                        staredCount={message?.staredCount}
                                        isReply={message?.isReply}
                                        referenceUsername={message?.referenceUsername}
                                        referenceMsg={message?.referenceMsg}
                                        userRole={message?.creator?.userRole ?? 'user' as any}
                                        isAdmin={message?.creator?.isAdmin}
                                        isBot={message?.creator?.publicKey == botPubkey}
                                        referenceMsgId={referenceMsgId}
                                        isGift={message.isGift}
                                        isGif={message.isGif}
                                        userPubkey={user?.publicKey || ''}
                                        userBalance={user?.balance || 0}
                                        handleStarMessage={handleStarMessage}
                                        handleSetReply={handleSetReply}
                                        handleTipping={handleTipping}
                                        handleScrollDown={handleScrollDown}
                                        scrollMessagesToBottom={scrollMessagesToBottom}
                                        triggerScrollToBottom={triggerScrollToBottom}
                                    />
                                )
                            }).reverse()
                        }
                    </div>

                    <div>
                        {
                            isReply && (
                                <div className="w-[calc(100%-46px)] flex justify-center items-center">
                                    <span className="grow text-[12px] font-light text-gray-500 my-1">
                                        {`@${shortName(referenceUsername)}: ${shortMessage(referenceMsg)}`}
                                    </span>
                                    <button onClick={cancelReply} onMouseEnter={() => playHover()} className="hover:transition duration-300">
                                        <img src={closeIcon} alt="close-icon" className="close-icon" />
                                    </button>
                                </div>
                            )
                        }

                        <div className="flex justify-between items-center gap-2 pr-[7px] relative">
                            {
                                user?.publicKey && (
                                    <TextareaAutosize
                                        ref={inputRef}
                                        onChange={(event) => inputMessage((event.target as HTMLTextAreaElement).value)}
                                        onKeyPress={(event) => event.key === 'Enter' && Date.now() - user.lastMessageAt > 3000 ? sendMessage() : null}
                                        value={message}
                                        placeholder='Type Message...'
                                        className="grow w-0 bg-[#090C0E] rounded-[10px] text-[14px] font-normal pl-[19px] pr-[80px] pt-[13px] pb-[14px] resize-none outline-none"
                                    />
                                )
                            }
                            {
                                user?.publicKey ? (
                                    Date.now() - user.lastMessageAt > 3000
                                        ?
                                        <button onClick={() => sendMessage()} onMouseEnter={() => playHover()} className='flex justify-center items-center w-[30px] button-chat'>
                                            <img src={sendIcon} alt="send-icon" />
                                        </button>
                                        :
                                        <Countdown date={user.lastMessageAt + 3000} intervalDelay={0}
                                            precision={1}
                                            renderer={time => (
                                                time.seconds + time.milliseconds > 0
                                                    ?
                                                    <button className='flex justify-center items-center w-[30px]'>{time.seconds}.{time.milliseconds / 100}</button>
                                                    :
                                                    <button onClick={() => sendMessage()} onMouseEnter={() => playHover()} className='flex justify-center items-center w-[30px]'>
                                                        <img src={sendIcon} alt="send-icon" />
                                                    </button>
                                            )} />
                                ) : (
                                    <h4 className="w-full text-center">You have to Log In</h4>
                                )
                            }
                            {
                                user?.publicKey && (
                                    <>
                                        {
                                            triggerEmojiBox && (
                                                <div className="absolute w-full pr-[7px] bottom-[55px]">
                                                    <div
                                                        ref={emojiBoxRef}
                                                        className={`${showEmojiBox ? 'block opacity-100' : 'hidden opacity-0'} transition-all duration-300`}
                                                    >
                                                        <EmojiPicker
                                                            onEmojiClick={emojiHandler}
                                                            theme={Theme.DARK}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }

                                        {
                                            triggerGifBox && (
                                                <div className="absolute w-full pr-[7px] bottom-[55px]">
                                                    <div
                                                        ref={gifBoxRef}
                                                        className={`${showGifBox ? 'block opacity-100' : 'hidden opacity-0'} transition-all duration-300`}
                                                    >
                                                        <GifPicker
                                                            tenorApiKey={tenorApiKey!}
                                                            theme={GifTheme.DARK}
                                                            onGifClick={e => sendGif(e.url)}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }

                                        <div className="absolute flex justify-center items-center w-[20px] h-[20px] top-[14px] right-[56px] hover:scale-110 transition-transform">
                                            <button
                                                ref={emojiTriggerBtnRef}
                                                className="w-full h-full"
                                                onClick={() => {
                                                    if (!triggerEmojiBox) {
                                                        setTriggerEmojiBox(true);
                                                    }
                                                    setShowEmojiBox(!showEmojiBox);
                                                }}
                                                onMouseEnter={() => playHover()}
                                            >
                                                <img src={emojiIcon} alt="emoji-icon" className="w-full h-full" />
                                            </button>
                                        </div>

                                        <div className="absolute flex justify-center items-center w-[30px] h-[20px] top-[14px] right-[86px] hover:scale-110 transition-transform">
                                            <button
                                                ref={gifTriggerBtnRef}
                                                className="w-full h-full text-[14px] text-black font-inter font-semibold leading-[14px] bg-[#808080] rounded-[4px]"
                                                onClick={() => {
                                                    if (!triggerGifBox) {
                                                        setTriggerGIfBox(true);
                                                    }
                                                    setShowGifBox(!showGifBox);
                                                }}
                                                onMouseEnter={() => playHover()}
                                            >
                                                <span>GIF</span>
                                            </button>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className={`flex justify-center items-center w-[70px] h-full bg-[#090C0E] ${isExpanded ? 'hidden' : 'hidden xl:flex'}`}>
                <button
                    className="flex justify-center items-center w-full"
                    onClick={() => setIsExpanded(true)}
                    onMouseEnter={() => playHover()}
                >
                    <img src={messageIcon} />
                </button>
            </div>
        </div>
    );
};

export default LiveChat;