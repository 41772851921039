import React from "react";

const SolanaIconPurple = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="17" viewBox="0 0 23 17" fill="none">
            <g clipPath="url(#clip0_508_1267)">
                <path d="M6.15706 0.6875H21.0002L16.8441 4.24985H2.00098L6.15706 0.6875Z" fill="url(#paint0_linear_508_1267)" stroke="#8056F6" />
                <path d="M16.8434 6.625H2.00024L6.15632 10.1874H20.9995L16.8434 6.625Z" fill="url(#paint1_linear_508_1267)" stroke="#8056F6" />
                <path d="M6.15657 12.5605H20.9996L16.8436 16.1229H2.00049L6.15657 12.5605Z" fill="url(#paint2_linear_508_1267)" stroke="#8056F6" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_508_1267" x1="-0.556596" y1="2.54264" x2="21.6249" y2="4.11821" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#8056F6" />
                    <stop offset="1" stopColor="#11161B" />
                </linearGradient>
                <linearGradient id="paint1_linear_508_1267" x1="23.5571" y1="8.48014" x2="1.37551" y2="10.0557" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#8056F6" />
                    <stop offset="1" stopColor="#11161B" />
                </linearGradient>
                <linearGradient id="paint2_linear_508_1267" x1="-0.557082" y1="14.4157" x2="21.6244" y2="15.9913" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#8056F6" />
                    <stop offset="1" stopColor="#11161B" />
                </linearGradient>
                <clipPath id="clip0_508_1267">
                    <rect width="23" height="17" fill="white" transform="translate(0.000244141)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default SolanaIconPurple;